import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import { Route , Switch, withRouter} from "react-router-dom";
import UserProfileSidebar from './Sidebar';
import ProfileForm from './ProfileForm';
import ConnectWithApps from './ConnectWithApps';
import AdministrateApplications from './AdministrateApplications';
import LinkAccounts from './LinkAccounts';
import ManageSubscriptions from './ManageSubscriptions';
import Preferences from './Preferences';
import Profeatures from './Profeatures';
import ChangePassword from './ChangePassword';
import API from '../api';
import { setUserInfo, getUserInfo , getIsUserInfoReload, setIsUserInfoReload} from '../Common/i3library';
import Loading from '../Common/Loading';
import { withTranslation } from 'react-i18next';

class UserProfileLanding extends React.Component{
    state={
        isUserInfoRenderd: (getUserInfo() ? true : false)
    }
    componentDidMount(){
        if(!this.state.isUserInfoRenderd){
            this.callUserInfo();
        }
        if(getIsUserInfoReload()){
            this.callUserInfo();
            setIsUserInfoReload(false);
        }
    }
    callUserInfo = async () => {
        await API.get('/users/info')
                .then(res => {
                    if(res.data.status === "ok" ){
                        setUserInfo(res.data.user);
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                    }
                    if (error.request) {
                    }
                    else {
                    }
                })
                .finally( () => {
                    // always executed
                    this.setState({
                        isUserInfoRenderd: true
                    })
                });
    }
    render(){
        if (!this.state.isUserInfoRenderd) {
            return (
                <Loading />
            );
        }
        return (
            <LandingStepBase container={
                <div className="container-fluid profile-container">
                    <div className="row mt-5">
                        <div className="sidebar">
                            <UserProfileSidebar {...this.props}></UserProfileSidebar>
                        </div>
                        <Switch>
                            <Route exact path={`${this.props.match.url}`} component={() => <ProfileForm callUserInfo={this.callUserInfo} {...this.props} />} />
                            <Route exact path={`${this.props.match.url}/connect-to-apps`} component={ () => <ConnectWithApps callUserInfo={this.callUserInfo} {...this.props}></ConnectWithApps>} />
                            <Route exact path={`${this.props.match.url}/admin-your-apps`} component={ () => <AdministrateApplications callUserInfo={this.callUserInfo} {...this.props}></AdministrateApplications>} />
                            <Route exact path={`${this.props.match.url}/links-accounts`} component={ () => <LinkAccounts callUserInfo={this.callUserInfo} {...this.props}></LinkAccounts>} />
                            <Route exact path={`${this.props.match.url}/manage-subscriptions`} component={ () => <ManageSubscriptions callUserInfo={this.callUserInfo} {...this.props}></ManageSubscriptions>} />
                            <Route exact path={`${this.props.match.url}/preferences`} component={ () => <Preferences callUserInfo={this.callUserInfo} {...this.props}></Preferences>} />
                            <Route exact path={`${this.props.match.url}/change_password`} component={ () => <ChangePassword callUserInfo={this.callUserInfo} {...this.props}></ChangePassword>} />
                            <Route exact path={`${this.props.match.url}/:page/pro`} component={Profeatures} />
                        </Switch>
                    </div>
                </div>
            }>
            </LandingStepBase>
        )
    }
}

export default withTranslation()(withRouter(UserProfileLanding));