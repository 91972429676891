import React from 'react';
import Errors from './Common/Errors/Errors';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {

    constructor(props) {
      super(props);
      this.state = { hasError: false,errorMessage: '', status : null };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, info) {
      // You can also log the error to an error reporting service
      this.setState({
        hasError: true,
        errorMessage: "Something went horribly wrong please try again",
        status : 404
      })
      console.log(error);
      console.log(info);
      //logErrorToMyService(error, info);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return  <Errors {...this.props} hasError={this.state.hasError} status={this.state.status} type="error" message={this.state.errorMessage}/>
      }
  
      return this.props.children; 
    }
}
export default withRouter(ErrorBoundary);