import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import Modal from "react-bootstrap/Modal";
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
class DeleteModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: props.type,
            id: props.id,
            isOpen: props.isOpen,
            isFromViewUser: props.isFromViewUser || false
        }
    }
    deleteUser = () => {
        let _this = this;
        API.get('/oldview/users/' + this.state.id + '/hard_delete_user')
        .then(res => {
            if(_this.state.isFromViewUser){
                window.location.href = "/admin/users/paid"
            }
            else{
                window.location.reload()
            }
        })
        .catch(function (error) {
        })
    }
    hideModal = () => {
        this.setState({isOpen: false})
        if (this.props && this.props.hideModal)
            this.props.hideModal()
    }
    render(){
        return(
            <Modal show={this.state.isOpen} id="deleteModal">
                <div className="modal-content">
                        <div className="modal-header modal-image-header">
                            <div className="modal-header-content">
                                <h4 className="modal-title">{i18n.t('admin.delete_modal.title')}</h4>
                            </div>
                            <button type="button" className="close" onClick={this.hideModal}><span className="lh-icon-os-close"></span></button>
                        </div>
                        <div className="modal-body">
                            <div className="middle-sec">
                                <div className="col-md-12">
                                    <h5 className="text-primary">{i18n.t('admin.delete_modal.message_1')}</h5>
                                </div>
                                <div className="mt-3 col-md-12">
                                    {i18n.t('admin.delete_modal.message_2')}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-accenta" onClick={this.hideModal}>{i18n.t('userprofile.btn_cancel')}</button>
                            <button type="button" className="btn btn-accenta" onClick={this.deleteUser}>{i18n.t('admin.btn_ok')}</button>
                        </div>
                </div>
            </Modal>
        )
    }

}
export default withTranslation()(DeleteModal)