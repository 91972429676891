import React from "react";
import FeatureBlock from "./FeatureBlock";
import i18n from '../../packs/i18n';
import PlanDetailsButton from './PlanDetailsButton';
class PlanDetails extends React.Component {
    constructor(props){
        super(props);
    }

    handleChoosePlan(details,key, userInfo){
        this.props.handler(details,key, userInfo);
    }

    render() {
        const all_features = [];
        for(const[index,value] of this.props.features.entries()){
            all_features.push(<FeatureBlock key={value[0]} details={value[1]} />)
        }
        const plans_buttons = [];
        for (var i in this.props.plans) {
            if (plans_buttons.length < 3) {
                plans_buttons.push(<PlanDetailsButton 
                    handler={this.handleChoosePlan.bind(this)} 
                    key={i} 
                    plan_detail={this.props.plans[i]} 
                    plan_key={i}
                    current_plan={this.props.current_plan}
                    userInfo={this.props.userInfo} />);
            }
        }
        return (
            <section className="bg-light text-primary pt-5 pb-5" id="tblsec" ref={(node) => { this.refPlanSection = node; }}>
                <div className="container-fluid ">
                    <div className="feature-section-header">
                        <h2 className="pt-4"> {i18n.t("signup.step3.plan_details.title")}</h2>
                        <p className="explainer">{i18n.t("signup.step3.plan_details.sub_title")}</p>
                    </div>
                    <div className="feature-table">
                        <div className="feature-table-header">
                            <div className="hide-md">
                                <h2>&nbsp;</h2>
                            </div>
                            <div>
                                <h3>{i18n.t("signup.step3.plans_name.free")}</h3>
                            </div>
                            <div>
                                <h3>{i18n.t("signup.step3.plans_name.individual")}</h3>
                            </div>
                            <div>
                                <h3>{i18n.t("signup.step3.plans_name.team")}</h3>
                            </div>
                        </div>
                        {all_features}
                        <div className="feature-table-footer">
                            <div className="hide-md">
                                <h2>&nbsp;</h2>
                            </div>
                           {plans_buttons}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default PlanDetails;
