import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import API from '../api';
import Errors from "../Common/Errors/Errors";
import { Loading_IMG } from '../Common/assets_paths';
import countryList from 'react-select-country-list';
import { getAvailableLanguages } from "../Common/i3library";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import SelectValidator from "../Common/SelectValidator";
import TextValidator from "../Common/TextValidator";
import TextareaValidator from "../Common/TextareaValidator";
import { event } from "react-ga";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import Modal from "react-bootstrap/Modal";
import { EventEmitter } from "../Common/Events";
import moment from 'moment/min/moment-with-locales';
class Distributors extends React.Component {
    constructor(props) {
        super(props);
        const countryOptions = countryList().getData();
        //ILEARN-4927 Tiwan Name Update 
        let taiwan_item = countryOptions.find(item => item.value == 'TW');
        taiwan_item.label = i18n.t("userprofile.labels.tiwan_label");
        let tiwan_index = countryOptions.findIndex(item => item.value == 'TW');
        countryOptions[tiwan_index] = taiwan_item;
        //ILEARN-4927 Tiwan Name Update End
        const languagesOptions = getAvailableLanguages();
        this.state = {
            total_count: 0,
            current_status: 'all',
            previous_status: 'all',
            per_page: 10,
            current_page: 1,
            data: [],
            type: 'distributor',
            toggleDistributorFilter: false,
            email: '',
            first_name: '',
            last_name: '',
            company: '',
            countryOpt: countryOptions,
            languageOpt: languagesOptions,
            country: '',
            language: '',
            isEditOpen: false,
            edit_name: '',
            edit_address: '',
            edit_max_connect_sessions: '',
            current_edit_id: '',
            isLoading: false,
            searchTerm: '',
            fromDate: '',
            toDate: '',
            current_date: new Date(),
            orderBy: '',
            isFromSearch: props.isFromSearch || false,
        }
        EventEmitter.subscribe('globalSearchTrigger', (event) => { this.handleGlobalSearch(event) })
        EventEmitter.subscribe('editDistributorSearch', (event) => { this.handleEditDistributorOutSide(event) })
        EventEmitter.subscribe('deleteDistributorSearch', (event) => { this.handleDeleteDistributorOutSide(event) })
    }
    distributorAction = (cell, row) => {
        let distributor_id = row.id;
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void (0)} data-id={distributor_id} onClick={this.editDistributor}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
                <NavDropdown.Item href={void (0)} data-id={distributor_id} onClick={this.deleteDistributor}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
            </NavDropdown>
        );
    }
    handleEditDistributorOutSide = (val) => {
        let userInfo = val;
        this.setState({ edit_name: userInfo.name, edit_address: userInfo.address, edit_max_connect_session: userInfo.session_count, current_edit_id: userInfo.id })
        this.showEditModal()
    }
    handleDeleteDistributorOutSide = (val) => {
        this.deleteDistributor(val)
    }
    editDistributor = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = this.state.data.find((element) => {
            return element.id == id
        })
        this.setState({ edit_name: userInfo.name, edit_address: userInfo.address, edit_max_connect_session: userInfo.session_count, current_edit_id: userInfo.id })
        this.showEditModal()
    }
    showEditModal = () => {
        this.setState({ isEditOpen: true, isValidate: false,  isValid: true, hasError: false,errorMessage: [], status: null })
    }
    handleKeyDown = event => {
        if (event.key === " " && event.target.value.trim().length === 0) {
            event.preventDefault();
        }
    };
    hideEditModal = () => {
        this.setState({ isEditOpen: false })
    }
    deleteDistributor = (event) => {
        let text = i18n.t('admin.distributor.confirmation_delete')
        // let text = "Are you absolutely sure you want to delete the selected entities? Make sure you deleted or moved all accounts and groups inside this entity first. This action cannot be undone !"
        //const payload = { ids: [event.target.getAttribute('data-id')] };
        const payload = event.target ? { ids: [event.target.getAttribute('data-id')] } : {ids: [event]};
        let _this = this;
        if (confirm(text) == true) {
            API.delete('/oldview/groups/delete', { data: payload })
                .then(res => {
                    if (res.data.status === "failed") {
                        alert(res.data.errors.join(","))
                    }
                    else {
                        window.location.reload();
                    }

                })
                .catch(function (error) {
                })
        }
        else {
            console.log("User canceled")
        }
    }
    handleGlobalSearch = (val) => {
        this.setState({ searchTerm: val })
        this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, null, val)
    }
    async componentDidMount() {
        await this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, null, this.state.searchTerm)
    }

    async callApi(per_page, current_page, current_status, htmlFormat = null, searchTerm = '') {
        if (htmlFormat != 'csv')
            this.setState({ data: [], isLoading: true });

        let { data } = await API.get('/groups?status=' + current_status + '&per_page=' + per_page + '&current_page=' + current_page + '&type=' + this.state.type + '&format=' + htmlFormat + '&searchTerm=' + encodeURIComponent(searchTerm) + '&fromDate=' + this.state.fromDate + '&toDate=' + this.state.toDate + '&order_by=' + this.state.orderBy)

        if (htmlFormat === 'csv') {
            let a = document.createElement('a');
            a.href = 'data:text/csv,' + encodeURI(data);
            a.target = '_blank';
            a.download = 'i3Learnhub_distributors.csv';
            a.click();
            history.pushState({}, null, window.location.href);
            document.getElementById("export_dis_mdl_cls").click();
            this.hideExportModal();
            return
        }

        this.setState({ data: data.groups, total_count: data.total_count, isLoading: false })
    }
    onChangeStatus = (event) => {
        this.setState({ current_status: event.target.value });
    }
    closeSideModal = () => {
        this.setState({ toggleDistributorFilter: false });
    }
    toggleSideModal = () => {
        this.setState({ toggleDistributorFilter: !this.state.toggleDistributorFilter });
    }
    onTableChange = (type, newState) => {
        if(newState.sortOrder && newState.sortField){
            this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
        }
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi(newState.sizePerPage, newState.page, this.state.current_status, null, this.state.searchTerm)
    }
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        if (event.target.name === "fromDate") {
            this.setState({ toDate: '' })
        }
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    }
    handleSubmit = (event) => {
        if (!this.state.shouldBlockNavigation) {
            return;
        }
        this.setState({ isLoading: true });
        this.inviteNewDistributor()
    }
    handleEditSubmit = (event) => {
        if (!this.state.shouldBlockNavigation) {
            return;
        }
        this.setState({ isLoading: true });
        this.submitEditDistributor()
    }
    inviteNewDistributor = () => {
        const formData = new FormData();
        const { isLangChange } = this.state;
        formData.append('invitation[account_type]', this.state.type);
        formData.append('invitation[email]', this.state.email.trim());
        formData.append('invitation[first_name]', this.state.first_name.trim());
        formData.append('invitation[last_name]', this.state.last_name.trim());
        formData.append('invitation[language]', this.state.language);
        formData.append('invitation[country]', this.state.country);
        formData.append('invitation[institution]', this.state.company.trim());

        API.post('/invitations', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };
    submitEditDistributor = () => {
        const formData = new FormData();
        formData.append('ids[]', this.state.current_edit_id)
        formData.append('name', this.state.edit_name);
        formData.append('address', this.state.edit_address);
        if (this.state.edit_max_connect_sessions)
            formData.append('session_count', this.state.edit_max_connect_sessions);
        API.patch('/oldview/groups/update', formData)
            .then(res => {
                window.location.reload();

            })
            .catch(function (error) {

            })
    }
    downloadDistributorData = (event) => {
        this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, 'csv', this.state.searchTerm)
    }
    hideExportModal = (event) => {
        this.setState({fromDate: '', toDate: ''})
    }
    hideAddDistributorModal = (event) => {
        this.setState({
            email: '',
            first_name: '',
            last_name: '',
            company: '',
            country: '',
            language: ''
        })
    }
    render() {
        const { toggleDistributorFilter } = this.state;
        const columns = [{
            dataField: 'name',
            text: i18n.t('admin.labels.name').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        }, {
            dataField: 'email',
            text: i18n.t('userprofile.labels.email').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        }, {
            dataField: 'country',
            text: i18n.t('userprofile.labels.country').toUpperCase(),
            sort: true
        }, {
            dataField: 'total_children',
            text: i18n.t('admin.labels.reseller').toUpperCase(),
            sort: false
        }, {
            dataField: 'moreOptions',
            sort: false,
            formatter: this.distributorAction,
        }];
        const defaultSorted = [{
            dataField: 'name',
            order: 'desc'
        }];
        const options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                let currentCellIndex = e.target.cellIndex;
                // let lastCellIndex = document.querySelector('table tr:last-child td:last-child').cellIndex
                // console.log(row)
                // if (currentCellIndex !== lastCellIndex && currentCellIndex !== undefined) {
                //     window.location.href = '/admin/distributor/view/' + row.id
                // }
                if(currentCellIndex == 0){
                    window.location.href = '/admin/distributor/view/' + row.id
                }
                if(currentCellIndex == 1){
                    if(row.get_user_id)
                        window.location.href = '/admin/user/view/' + row.get_user_id
                }
            }
        }
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        };

        return (
            <div className={this.state.isFromSearch ? "overflow-hidden" : "overflow-hidden h-100"}>
                {!this.state.isFromSearch ? 
                <React.Fragment>
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div className="admin-custom-table-header">
                            <button data-bs-toggle="modal" data-bs-target="#addDistributor" className="btn btn-primary mb-3 mt-2">{i18n.t('admin.distributor.btn_add')}</button>
                            <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
                                {/* <span className="lh-icon-os-filter filter-icon-users mt-2" onClick={this.toggleSideModal}></span> */}
                                <span data-bs-toggle="modal" data-bs-target="#exportModal" className="lh-icon-os-export filter-icon-users mr-4 mt-2"></span>
                            </div>
                        </div>
                        <div className="distributors-table">
                            <BootstrapTable
                                keyField='id'
                                options={options}
                                data={this.state.data}
                                fetchInfo={{ dataTotalSize: this.state.total_count }}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                remote
                                noDataIndication={() => <NoDataIndication />}
                                pagination={paginationFactory(paginatorOptions)}
                                onTableChange={this.onTableChange}
                                rowEvents={rowEvents}
                            />
                        </div>
                    </div>
                </div>
                </React.Fragment>  : null }
                {/* Add Distributor Modal */}
                <div className="modal fade" id="addDistributor" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            {/* <form id="addDistributorForm"> */}
                            <ValidatorForm
                                id="addDistributorForm"
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.distributor.add_distributor_modal.title')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal" onClick={this.hideAddDistributorModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('userprofile.labels.email')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    type="email"
                                                    name="email"
                                                    placeholder={i18n.t("userprofile.placeholder.email")}
                                                    value={this.state.email}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.email_required"),
                                                        i18n.t("userprofile.errors.email_invalid")
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4 pt-2">
                                                <span>{i18n.t("userprofile.labels.first_name")}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control "}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("userprofile.placeholder.first_name")}
                                                    type="text"
                                                    name="first_name"
                                                    value={this.state.first_name}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    required
                                                    validators={["required", "maxStringLength:40"]}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.first_name_required"),
                                                        i18n.t('login.errors.first_name_max_length')
                                                    ]}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4 pt-2">
                                                <span>{i18n.t("userprofile.labels.last_name")}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <TextValidator
                                                    tabIndex='3'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("userprofile.placeholder.last_name")}
                                                    type="text"
                                                    name="last_name"
                                                    value={this.state.last_name}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    required
                                                    validators={["required", "maxStringLength:40"]}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.last_name_required"),
                                                        i18n.t('login.errors.last_name_max_length')
                                                    ]}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4 pt-2">
                                                <span>{i18n.t("admin.labels.company")}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <TextValidator
                                                    tabIndex='4'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="company"
                                                    placeholder={i18n.t("admin.placeholder.company")}
                                                    value={this.state.company}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.company_name_required")
                                                    ]}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4 pt-2">
                                                <span>{i18n.t("userprofile.labels.country")}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <SelectValidator
                                                    tabIndex='5'
                                                    className={"form-control"}
                                                    labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("userprofile.placeholder.country")}
                                                    name="country"
                                                    options={this.state.countryOpt}
                                                    value={this.state.country}
                                                    onChange={this.handleChange}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.country_required")
                                                    ]}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4 pt-2">
                                                <span>{i18n.t("userprofile.labels.language")}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <SelectValidator
                                                    tabIndex='6'
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("userprofile.placeholder.language")}
                                                    name="language"
                                                    options={this.state.languageOpt}
                                                    value={this.state.language}
                                                    onChange={this.handleChange}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.language_required")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal" onClick={this.hideAddDistributorModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('admin.labels.invite')}</React.Fragment>
                                    }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </div>
                </div>

                {/* Edit Distributor Modal */}
                <div>
                    <Modal show={this.state.isEditOpen}>

                        {/* <div className="modal fade" id="editDistributor" tabIndex="-1">
                        <div className="modal-dialog modal-lg"> */}
                        <div className="modal-content">
                            {/* <form id="editDistributorForm"> */}
                            <ValidatorForm
                                id="editDistributorForm"
                                ref="form"
                                onSubmit={this.handleEditSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.distributor.edit_distributor_modal.title')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideEditModal} ><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.name')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                {/* <input name="name" type="name" className="form-control mt-2 mb-4" /> */}
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_name"
                                                    placeholder={i18n.t("admin.placeholder.name")}
                                                    value={this.state.edit_name}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.company_name_required")
                                                    ]}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t("userprofile.labels.address")}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <TextareaValidator
                                                        tabIndex='4'
                                                        wrapperClass=''
                                                        className={"form-control mt-2 mb-4"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t("userprofile.placeholder.address")}
                                                        name="edit_address"
                                                        value={this.state.edit_address}
                                                        noValidate
                                                        onChange={this.handleChange}
                                                    />
                                                    {/* <textarea name="address" className="form-control" rows="3"></textarea> */}
                                                </div>
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.max_conntect_session')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <input  placeholder={i18n.t('admin.placeholder.max_conntect_session')} name="edit_max_connect_sessions" value={this.state.edit_max_connect_sessions} onChange={this.handleChange} type="number" className="form-control mt-2 mb-4" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideEditModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta">{i18n.t('admin.btn_ok')}</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                        {/* </div>
                    </div> */}
                    </Modal>
                </div>
                {/* Distributor Filter */}
                <div className="sidebar-container user-filter-sidebar" style={{ display: (toggleDistributorFilter ? 'block' : 'none') }}>
                    <div className="right-sidebar">
                        <div className="sidebar-header bg-primary">
                            <a href={void (0)} className="close-btn" onClick={this.closeSideModal}><span className="lh-icon-os-close"></span> </a>
                            <h1 className="sb-head-title">Filters</h1>
                        </div>
                        <div className="sidebar-body">
                            <div className="users-filter-body payment-filters">
                                <div>
                                    <h5 className="mb-3">By Country</h5>
                                    <select name="country" defaultValue="in" className="form-control sub-filter">
                                        <option value="select">Select Country</option>
                                        <option value="in">India</option>
                                        <option value="us">United States</option>
                                        <option value="ca">Canada</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar-footer">
                            <div className="footer-action-panel justify-content-end">
                                <button className="btn btn-outline-tertiary mt-2" onClick={this.closeSideModal} >Cancel</button>
                                <button style={{ width: '6rem' }} className="btn btn-accenta ml-2">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Export Modal */}
                <div className="modal fade" id="exportModal" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="exportModalForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.export_modal.title')}</h4>
                                    </div>
                                    <button id="export_dis_mdl_cls" type="button" className="close" data-bs-dismiss="modal" onClick={this.hideExportModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="page-header export-header">
                                        <span className="text-primary">{i18n.t('admin.export_modal.message')}</span>
                                    </div>
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-3">
                                                <span>{i18n.t('admin.labels.from')}</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input value={moment(this.state.fromDate).format("YYYY-MM-DD")} name="fromDate" type="date" className="form-control mt-2 mb-4" onChange={this.handleChange} max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                                            </div>
                                            <div className="user-label col-sm-3">
                                                <span>{i18n.t('admin.labels.to')}</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input value={this.state.toDate ? moment(this.state.toDate).format("YYYY-MM-DD") : this.state.toDate} min={moment(this.state.fromDate).format("YYYY-MM-DD")} onChange={this.handleChange} name="toDate" type="date" className="form-control mt-2 mb-4" max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal" onClick={this.hideExportModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="button" className="btn btn-lg btn-accenta" onClick={this.downloadDistributorData}>{i18n.t('admin.btn_export')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default withTranslation() (Distributors)