import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import { HeartFail_IMG } from '../Common/assets_paths';
import i18n from '../../packs/i18n';
import { withTranslation } from 'react-i18next';


class SignupFail extends React.Component {
    redirectBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <LandingStepBase container={
                <div className="container">
                <div className="row">
                <div className="col-sm-12 p-0 text-center">
                    <div className="headtxt mt-5 mb-5">
                        <h1 className="text-white mb-0">{i18n.t('signup.fail.header.title')}</h1>
                        <p>{i18n.t('signup.fail.header.sub_title')}</p>
                        <div className="steps">
                            <span className="completed step mr-2 ml-2"></span><span className="completed  step  mr-2 ml-2"></span><span className="completed step  mr-2 ml-2"></span><span className="completed step mr-2 ml-2"></span><span className="activestep step mr-2 ml-2"></span>
                        </div>
                        <p className="mt-2 text-quaternary text-size-20">{i18n.t('signup.step5.header.current_step')}</p>
                    </div>
                    <div className="registration-result text-center m-auto">
                        <img src={HeartFail_IMG} className="animated-svg"/>
                        <p className="text-white mt-5 mb-5 text-size-24">
                        {i18n.t('signup.fail.message.txt_1')} <br />
                        {i18n.t('signup.fail.message.txt_2')}
                        </p>
                        <a href="javascript:void(0)" onClick = {this.redirectBack} className="btn btn-block m-auto mb-2 btn-outline-quinary text-size-22 btn-border-animate" style={{width:'200px'}}>{i18n.t('signup.fail.nav_btn')}</a>
                    </div>
                </div>
            </div>
                </div>}></LandingStepBase>
        )
    }

}

export default withTranslation()(SignupFail);