import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import { NavDropdown } from 'react-bootstrap';
import countryList from 'react-select-country-list';
import { getAvailableLanguages } from "../Common/i3library";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import SelectValidator from "../Common/SelectValidator";
import TextValidator from "../Common/TextValidator";
import Errors from "../Common/Errors/Errors";
import { event } from "react-ga";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import Modal from "react-bootstrap/Modal";
import NewInvite from './NewInvite';
import { format } from "path";
import { EventEmitter } from "../Common/Events";
import moment from 'moment/min/moment-with-locales';
import BlockModal from "./BlockModal";
import DeleteModal from "./DeleteModal";
import PromoteDemoteModal from "./PromoteDemoteModal";
class Users extends React.Component {

  constructor(props) {
    super(props);
    let __type = undefined;
    if (props && props.location && props.location.pathname) {
      let path_array = props.location.pathname.split("/users/")
      __type = path_array[path_array.length - 1]
      __type = __type || 'paid'
    }
    if (props.groupId) {
      __type = ''
    }
    const countryOptions = countryList().getData();
    //ILEARN-4927 Tiwan Name Update 
    let taiwan_item = countryOptions.find(item => item.value == 'TW');
    taiwan_item.label = i18n.t("userprofile.labels.tiwan_label");
    let tiwan_index = countryOptions.findIndex(item => item.value == 'TW');
    countryOptions[tiwan_index] = taiwan_item;
    //ILEARN-4927 Tiwan Name Update End
    const languagesOptions = getAvailableLanguages();
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      address: '',
      number_of_years: 1,
      institution_code: '',
      institution: '',
      countryOpt: countryOptions,
      languageOpt: languagesOptions,
      country: 'IN',
      language: 'en',
      plan: 'free',
      toggleUserFilter: false,
      plans: [],
      data: [],
      target_groups: [],
      total_count: 0,
      current_status: props.groupId ? 'all' : 'active',
      previous_status: props.groupId ? 'all' : 'active',
      per_page: 10,
      current_page: 1,
      current_type: __type,
      previous_type: __type,
      shouldBlockNavigation: false,
      isValid: false,
      hasError: false,
      errorMessage: "",
      isValidate: false,
      isEditOpen: false,
      edit_first_name: '',
      edit_last_name: '',
      edit_email: '',
      current_edit_email: '',
      edit_order_number:'',
      current_edit_order_number: '',
      edit_plan: '',
      edit_max_connect_sessions: null,
      edit_institution: '',
      current_edit_user_id: '',
      isChangeParentOpen: false,
      current_change_parent_user_id: '',
      selected_target_group: '',
      isLoading: false,
      searchTerm: '',
      fromDate: '',
      toDate: '',
      current_date: new Date(),
      group_id: props.groupId || '',
      is_invite_from_team: props.isInviteFromTeam || false,
      isBlockModalOpen: false,
      blockUserId: '',
      isDeleteModalOpen: false,
      deleteUserId: '',
      promoteDemoteActionType: '',
      isPromoteDemoteModalOpen: false,
      promoteUserId: '',
      import_file: null,
      isFromSearch: props.isFromSearch || false,
      isFromViewUser: props.isFromViewUser || false,
      orderBy: 'updated_at desc',
      isTopAdmin: props.isTopAdmin || false,
      isReleaseUserModalOpen: false,
      releaseUserInfo: null,
      current_release_user_id: null
    }
    //EventEmitter.subscribe('globalSearchTrigger', (event) => { this.handleGlobalSearch(event) })
    EventEmitter.subscribe('editUserSearch', (event) => { this.handleEditUserOutSide(event) })
    EventEmitter.subscribe('blockUserSearch', (event) => { this.handleBlockUserOutSide(event) })
    EventEmitter.subscribe('unblockUserSearch', (event) => { this.handleUnblockUserOutSide(event) })
    EventEmitter.subscribe('deleteUserSearch', (event) => { this.handleDeleteUserOutSide(event) })
    EventEmitter.subscribe('promotUserSearch', (event) => { this.handlePromotUserOutSide(event) })
    EventEmitter.subscribe('demoteUserSearch', (event) => { this.handleDemoteUserOutSide(event) })
    EventEmitter.subscribe('changeParentUserSearch', (event) => { this.handleChangeParentUserOutSide(event) })
    EventEmitter.subscribe('disablePWAUserSearch', (event) => { this.handleDisablePWAOutSide(event) })
    EventEmitter.subscribe('enablePWAUserSearch', (event) => { this.handleEnablePWAOutSide(event) })
  }
  handleDisablePWAOutSide = (val) => {
    let userInfo = val;
    this.callEnableDisablePWAApi(userInfo.id, false);
  }
  handleEnablePWAOutSide = (val) => {
    let userInfo = val;
    this.callEnableDisablePWAApi(userInfo.id, true);
  }
  handleEditUserOutSide = (val) => {
    let userInfo = val;
    this.setState({ edit_first_name: userInfo.first_name, edit_last_name: userInfo.last_name, edit_institution: userInfo.institution, current_edit_user_id: userInfo.id, edit_email: userInfo.email, current_edit_email: userInfo.email, edit_order_number: userInfo.order_number, current_edit_order_number: userInfo.order_number, edit_plan: userInfo.current_plan_name.toLowerCase() })

    this.showEditModal()  
  }
  handleBlockUserOutSide = (val) => {
    this.setState({isBlockModalOpen: true, blockUserId: val});
  }
  handleUnblockUserOutSide = (val) => {
    this.unblockUserApi(val)
  }
  handleGlobalSearch = (val) => {
    // this.setState({ searchTerm: val, data:[], isLoading:true, total_count:0 })
    // API.get('/react_admin/global_search?search_term=' + val+'&search_location=user')
    // .then(res =>  {
    //   if(res.data){
    //     this.setState({data: res.data.users, total_count: res.data.total_count})
    //   }
    // })
    // .catch(function (error) {
    //   if (error.response) {
    //     console.log(error.response);
    //   }
    //   if (error.request) {
    //     console.log(error.request);
    //   }
    //   else {
    //     console.log(error)
    //   }
    // })
  }
  handleDeleteUserOutSide = (val) => {
    this.setState({isDeleteModalOpen: true, deleteUserId: val});
  }
  handlePromotUserOutSide = (val) => {
    this.setState({promoteDemoteActionType: 'promote', isPromoteDemoteModalOpen: true, promoteUserId: val});
  }
  handleDemoteUserOutSide = (val) => {
    this.setState({promoteDemoteActionType: 'demote', isPromoteDemoteModalOpen: true, promoteUserId: val});
  }
  handleChangeParentUserOutSide = (val) => {
    this.changeParentView(val)
  }
  showEditModal = () => {
    this.setState({ isEditOpen: true, isValidate: false,  isValid: true, hasError: false,errorMessage: [], status: null  })
  }
  hideEditModal = () => {
    this.setState({ isEditOpen: false })
  }
  showChangeParentModal = () => {
    this.setState({ isChangeParentOpen: true })
  }
  hideChangeParentModal = () => {
    this.setState({ isChangeParentOpen: false, target_groups: [], selected_target_group: '' })
  }
  showReleaseUserModal = () => {
    this.setState({ isReleaseUserModalOpen: true})
  }
  hideReleaseUserModal = () => {
    this.setState({ isReleaseUserModalOpen: false, releaseUserInfo: null, current_release_user_id: null})
  }
  async componentDidMount() {
    await this.callApi(this.state.per_page, this.state.current_page, this.state.current_type, this.state.current_status, this.state.searchTerm)
  }
  async callApi(per_page, current_page, current_type, current_status, htmlFormat = null, searchTerm = '') {
    if (htmlFormat != 'csv')
      this.setState({ data: [], target_groups: [], plans: [], isLoading: true });

    let { data } = await API.get('/users?type=' + current_type + '&status=' + current_status + '&per_page=' + per_page + '&current_page=' + current_page + '&format=' + htmlFormat + '&searchTerm=' + encodeURIComponent(searchTerm) + '&fromDate=' + this.state.fromDate + '&toDate=' + this.state.toDate + '&group=' + this.state.group_id + '&order_by=' + this.state.orderBy)

    if (htmlFormat === 'csv') {
      let a = document.createElement('a');
      a.href = 'data:text/csv,' + encodeURI(data);
      a.target = '_blank';
      a.download = 'i3Learnhub_users.csv';
      a.click();
      history.pushState({}, null, window.location.href);
      document.getElementById("export_usr_mdl_cls").click();
      this.setState({fromDate: '', toDate: ''})
      return
    }
    let finalPlans = new Array()
    await API.get('pricing/plans')
      .then(res => {
        if (!res.data || !res.data[0])
          return
        for (var i in res.data[0]) {
          if (i !== 'team') {
            if (this.state.group_id) {
              if (i !== 'free') {
                let plan = res.data[0][i];
                let new_data = { value: plan.key, label: plan.display_text.includes('.') ? i18n.t(plan.display_text) : plan.display_text }
                finalPlans.push(new_data)
              }

            }
            else {
              let plan = res.data[0][i];
              let new_data = { value: plan.key, label: plan.display_text.includes('.') ? i18n.t(plan.display_text) : plan.display_text }
              finalPlans.push(new_data)
            }

          }

        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        }
        if (error.request) {
          console.log(error.request);
        }
        else {
          console.log(error)
        }
      });
    // if (data.target_groups) {
    //   data.target_groups.map((target_group) => {
    //     target_group["value"] = target_group[0]
    //     target_group["label"] = target_group[1]
    //   })
    // }
    this.setState({ data: data.users, total_count: data.total_count, plans: finalPlans, isLoading: false });

  }

  onChangeStatus = (event) => {
    this.setState({ current_status: event.target.value });
  }
  onChangeType = (event) => {
    this.setState({ current_type: event.target.value });
  }
  closeSideModal = (event) => {
    this.setState({ current_status: this.state.previous_status, toggleUserFilter: false, current_type: this.state.previous_type });
  }
  toggleSideModal = (event) => {
    this.setState({ toggleUserFilter: !this.state.toggleUserFilter });
  }
  applyFilters = (event) => {
    this.setState({ previous_status: this.state.current_status, toggleUserFilter: false, previous_type: this.state.current_type, current_page: 1 });
    this.callApi(this.state.per_page, 1, this.state.current_type, this.state.current_status, null, this.state.searchTerm)
  }
  onTableChange = (type, newState) => {
    if(newState.sortOrder && newState.sortField){
      this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
    }
    this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
    setTimeout(() => {
      this.callApi(newState.sizePerPage, newState.page, this.state.current_type, this.state.current_status, null, this.state.searchTerm)
    }, 0)
  }
  handleSubmit = (event) => {
    this.setState({ isLoading: true });
    this.inviteNewUser()
  }
  inviteNewUser = () => {
    const formData = new FormData();
    const { isLangChange } = this.state;

    formData.append('invitation[email]', this.state.email);
    formData.append('invitation[first_name]', this.state.first_name);
    formData.append('invitation[last_name]', this.state.last_name);
    formData.append('invitation[address]', this.state.address);
    formData.append('invitation[language]', this.state.language);
    formData.append('invitation[country]', this.state.country);
    formData.append('invitation[account_type]', this.state.plan);
    formData.append('invitation[institution_code]', this.state.institution_code);
    formData.append('invitation[institution]', this.state.institution);
    formData.append('invitation[number_of_years]', this.state.number_of_years);
    formData.append('invitation[seat_licenses]', 1);
    if (this.state.group_id) {
      formData.append('invitation[group_id]', this.state.group_id);
    }

    API.post('/invitations', formData)
      .then(res => {
        window.location.reload();
      })
      .catch(function (error) {
      })
  }
  handleEditSubmit = (event) => {
    if (this.state.edit_first_name.trim().length == 0 || this.state.edit_last_name.trim().length == 0 || this.state.edit_email.trim().length == 0) {
      if (this.state.plan && this.state.plan.includes('team')) {
        if (this.state.edit_institution.trim().length == 0) {
          return
        }
      }
      return;
    }
    this.setState({ isLoading: true });
    this.submitEditUser()
  }
  
  handleChangeParentSubmit = (event) => {
    if(!this.state.selected_target_group){
      this.setState({
        isValidate: true,
        isValid: false,
        hasError: true,
        errorMessage: i18n.t('admin.errors.require_parent'),
        status: null
      });
      return;
    }
    this.setState({ isLoading: true });
    this.submitChangePrent()
  }
  releaseUserSubmit = (event) => {
    let type = event.target.getAttribute('data-type')
    const formData = new FormData();
    let release_lesson = false;
    if (type == "with_lesson")
      release_lesson = true
    formData.append('release_lesson', release_lesson);
    API.post('/users/'+this.state.current_release_user_id+'/release', formData)
      .then(res => {
        window.location.reload();
      })
      .catch(function (error) {

      })
  }
  handleOnError = errors => {
    this.setState({
      isValidate: true,
      isValid: false,
      hasError: true,
      errorMessage: getErrorMessage(errors, true),
      status: null
    });
  };

  handleChange = event => {
    const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
    if (event.target.name === "fromDate") {
      this.setState({ toDate: '' })
    }
    this.setState({
      [event.target.name]: event.target.value,
      isLangChange
    });
  };
  handleChangeParentSelect = event => {
    this.setState({
      "selected_target_group": event.id
    });
  }
  handleChangeFile = (event) => {
    this.setState({[event.target.name]: event.target.files[0]})
  }
  rankFormatter = (cell, row) => {
    let user_id = row.id;
    let is_admin = row['admin?'];
    let is_pwa = row['is_pwa'];
    return (
      <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
        <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.editUser}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
        {
          !row.is_deleted ? <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.deleteUser}>{i18n.t('admin.labels.delete')}</NavDropdown.Item> : null
        }
        {
          !row.blocked ?
            <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.blockUser}>{i18n.t('admin.labels.block')}</NavDropdown.Item>
            :
            <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.unblockUser}>{i18n.t('admin.labels.unblock')}</NavDropdown.Item>
        }
        {!is_admin ? 
        <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.releaseUser} >{i18n.t('admin.labels.release_user')}</NavDropdown.Item> : null}
        {!is_admin ?
        <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.promotUser}>{i18n.t('admin.labels.promote')}</NavDropdown.Item> : null }
        {is_admin ?
        <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.demoteUser}>{i18n.t('admin.labels.demote')}</NavDropdown.Item> : null }
        { this.props.is_top_admin ?  
          <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.changeParent} >{i18n.t('admin.labels.change_parent')}</NavDropdown.Item> : null
        }
        { this.props.is_top_admin && is_pwa ?  
          <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.disablePWA} >{i18n.t('admin.labels.disable_pwa')}</NavDropdown.Item> : null
        }
        { this.props.is_top_admin && !is_pwa ?  
          <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.enablePWA} >{i18n.t('admin.labels.enable_pwa')}</NavDropdown.Item> : null
        }
        {row.forgot_password_link ?
          <NavDropdown.Item href={"/" + row.forgot_password_link} target="_blank">{i18n.t('admin.labels.forgot_password')}</NavDropdown.Item>
          : null
        }
        {row.confirm_email_link ?
          <NavDropdown.Item href={"/" + row.confirm_email_link} target="_blank">{i18n.t('admin.labels.confirm_email')}</NavDropdown.Item>
          : null
        }
      </NavDropdown>
    );
  }
  releaseUser = (event) => {
    let id = event.target.getAttribute('data-id')
    let userInfo = this.state.data.find((element) => {
      return element.id == id
    })
    this.setState({current_release_user_id: userInfo.id, releaseUserInfo: userInfo})
    this.showReleaseUserModal();
  }
  changeParent = (event) => {
    let id = event.target.getAttribute('data-id')
    let userInfo = this.state.data.find((element) => {
      return element.id == id
    })
    this.changeParentView(userInfo)
  }
  changeParentView = (userInfo) => {
    userInfo.target_gropus_new.map((target_group) => {
      target_group["value"] = target_group.id
      let path = ''
      for(var i = target_group.breadcrumb.length -1 ; i >= 1; i--){
        if(i > 1){
          path += target_group.breadcrumb[i].name + " -> "
        }
        else{
          path += target_group.breadcrumb[i].name
        }
      }
      target_group["label"] = target_group.name + "\n [ "+path+" ] "
    })

    this.setState({ target_groups: userInfo.target_gropus_new, current_change_parent_user_id: userInfo.id })
    this.showChangeParentModal()
  }
  submitChangePrent = () => {
    const formData = new FormData();
    formData.append('ids[]', this.state.current_change_parent_user_id);
    formData.append('target', this.state.selected_target_group);
    API.patch('/oldview/users/move', formData)
      .then(res => {
        window.location.reload();
      })
      .catch(function (error) {

      })
  }
  enablePWA = (event) => {
    let id = event.target.getAttribute('data-id')
    let userInfo = this.state.data.find((element) => {
      return element.id == id
    })
    this.callEnableDisablePWAApi(userInfo.id, true);
  }
  disablePWA = (event) => {
    let id = event.target.getAttribute('data-id')
    let userInfo = this.state.data.find((element) => {
      return element.id == id
    })
    this.callEnableDisablePWAApi(userInfo.id, false);
  }
  callEnableDisablePWAApi = (user_id, is_pwa) => {
    const formData = new FormData();
    formData.append('is_pwa', is_pwa);

    API.post('/users/'+user_id+'/enable_disable_pwa', formData)
      .then(res => {
        window.location.reload()
      })
      .catch(function (error) {

      })
  }
  promotUser = (event) => {
    this.setState({promoteDemoteActionType: 'promote', isPromoteDemoteModalOpen: true, promoteUserId: event.target.getAttribute('data-id')});
  }
  demoteUser = (event) => {
    this.setState({promoteDemoteActionType: 'demote', isPromoteDemoteModalOpen: true, promoteUserId: event.target.getAttribute('data-id')});
  }
  blockUser = (event) => {
    this.setState({isBlockModalOpen: true, blockUserId: event.target.getAttribute('data-id')});
  }
  hideBlockModal = (event) => {
    this.setState({isBlockModalOpen: false, blockUserId: ''});
  }
  hideDeleteModal = (event) => {
    this.setState({isDeleteModalOpen: false, deleteUserId: ''});
  }
  hidePromoteDemoteModal = (event) => {
    this.setState({isPromoteDemoteModalOpen: false, promoteDemoteActionType: '', promoteUserId: ''});
  }
  hideExportModal = (event) => {
    this.setState({fromDate: '', toDate: ''})
  }
  unblockUser = (event) => {
    this.unblockUserApi(event.target.getAttribute('data-id'))
  }
  unblockUserApi = (val) => {
    const formData = new FormData();
    formData.append('ids[]', val)
    let _this = this;
    API.patch('/oldview/users/unblock', formData)
      .then(res => {
        window.location.reload()
      })
      .catch(function (error) {

      })
  }
  deleteUser = (event) => {
    this.setState({isDeleteModalOpen: true, deleteUserId: event.target.getAttribute('data-id')});
  }
  editUser = (event) => {
    let id = event.target.getAttribute('data-id');
    let userInfo = this.state.data.find((element) => {
      return element.id == id
    })
    this.setState({ edit_first_name: userInfo.first_name, edit_last_name: userInfo.last_name, edit_institution: userInfo.institution, current_edit_user_id: userInfo.id, edit_email: userInfo.email, current_edit_email: userInfo.email, edit_order_number: userInfo.order_number, current_edit_order_number: userInfo.order_number, edit_plan: userInfo.current_plan_name.toLowerCase() })

    this.showEditModal()
  }
  submitEditUser = () => {
    const formData = new FormData();
    formData.append('ids[]', this.state.current_edit_user_id)
    formData.append('first_name', this.state.edit_first_name);
    formData.append('last_name', this.state.edit_last_name);
    formData.append('institution', this.state.edit_institution);
    if (this.state.edit_max_connect_sessions)
      formData.append('session_count', this.state.edit_max_connect_sessions);
    if(this.state.current_edit_email.trim() !== this.state.edit_email.trim())
      formData.append('email', this.state.edit_email);
    if(this.state.current_edit_order_number !== this.state.edit_order_number){
      formData.append('order_number', this.state.edit_order_number);
    }
    API.patch('/oldview/users/update', formData)
      .then(res => {
        window.location.reload();

      })
      .catch(function (error) {

      })
  }
  downloadUsersData = event => {
    this.callApi(this.state.per_page, this.state.current_page, this.state.current_type, this.state.current_status, 'csv', this.state.searchTerm)
  }
  importUserData = event => {
    const url = '/invitations/import_new';
    const formData = new FormData();
    formData.append('import[file]',this.state.import_file)
    formData.append('import[group_id]', this.state.group_id)
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    API.post(url, formData,config)
      .then(res => {
        window.location.reload();
      })
      .catch((err) => {
        alert(err.response.data.message || err.message)
      })
  }

  handleKeyDown = event => {
    if (event.key === " " && event.target.value.trim().length === 0) {
      event.preventDefault();
    }
  };
  render() {
    
    const columns = [{
      dataField: 'email',
      text: i18n.t('userprofile.labels.email').toUpperCase(),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '25%' };
      }
    }, {
      dataField: 'first_name',
      text: i18n.t('userprofile.labels.first_name').toUpperCase(),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '10%' };
      }
    }, {
      dataField: 'last_name',
      text: i18n.t('userprofile.labels.last_name').toUpperCase(),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '10%' };
      }
    }, {
      dataField: 'country',
      text: i18n.t('userprofile.labels.country').toUpperCase(),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '8%' };
      }
    }, {
      dataField: 'main_institution_name',
      text: i18n.t('admin.labels.institute').toUpperCase(),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: '10%' };
      }
    }, {
      dataField: 'disk_usage',
      text: i18n.t('admin.labels.lib_usage').toUpperCase(),
      sort: false
    },{
      dataField: 'current_status',
      text: i18n.t('admin.labels.status').toUpperCase(),
      sort: false
    }];
    if (this.state.current_type === "free"){
      columns.push({
        dataField: 'current_plan_name',
        text: i18n.t('admin.labels.plan').toUpperCase(),
        sort: false
      })
      columns.push({
        dataField: 'moreOptions',
        formatter: this.rankFormatter,
      })
    }
    else{
      columns.push({
        dataField: 'days_to_pay',
        text: i18n.t('admin.labels.days_left').toUpperCase(),
        sort: false
      })
      columns.push({
        dataField: 'current_plan_name',
        text: i18n.t('admin.labels.plan').toUpperCase(),
        sort: false
      })
      columns.push({
        dataField: 'order_number',
        text: i18n.t('admin.labels.order_number').toUpperCase(),
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '8%' };
        }
      })
      columns.push({
        dataField: 'moreOptions',
        formatter: this.rankFormatter,
      })
    }

    const { toggleUserFilter } = this.state
    const options = {
      onPageChange: this.handlePageChange,
      onSizePerPageList: this.handleSizePerPageChange,
      page: this.state.current_page,
      sizePerPage: this.state.per_page
    };
    const paginatorOptions = {
      pageStartIndex: 1,
      page: this.state.current_page,
      totalSize: this.state.total_count,
      sizePerPage: this.state.per_page,
      hidePageListOnlyOnePage: true,
      showTotal: true
    };
    const defaultSorted = [{
      dataField: 'email',
      order: 'desc'
    }];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        let currentCellIndex = e.target.cellIndex;
        let lastCellIndex = document.querySelector('table tr:last-child td:last-child').cellIndex
        //if (currentCellIndex !== lastCellIndex && currentCellIndex !== undefined) {
        if(currentCellIndex == 0)  
          window.location.href = '/admin/user/view/' + row.id
        //}
      }
    }
    // ...
    const NoDataIndication = () => {
      if (this.state.isLoading) {
        return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
      }
      else {
        return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
      }
    }
    return (
      <div className={this.state.isFromSearch ? "overflow-hidden" : "overflow-hidden h-100"}>
        {!this.state.isFromSearch ? 
        <React.Fragment>
        <div className={this.state.group_id ? "startscreen users-main-wrapper no-gradient" : "startscreen users-main-wrapper"}>
          <div className="start-content">
            <div className="admin-custom-table-header">
              <button data-bs-toggle="modal" data-bs-target="#addUserModaluser" className="btn btn-primary mb-3 mt-2 btn-add-user">{i18n.t('admin.user.btn_add')}</button>
              <div style={{ display: 'flex', alignItems: 'center', float: 'right', height: '50px' }}>
                <span className="lh-icon-os-filter filter-icon-users" onClick={this.toggleSideModal}></span>
                <span className="lh-icon-os-export filter-icon-users" data-bs-toggle="modal" data-bs-target="#exportModal"></span>
                <span className="lh-icon-os-import filter-icon-users" data-bs-toggle="modal" data-bs-target="#importModal"></span>
              </div>
            </div>
            <div className="mt-3">
              <BootstrapTable
                keyField='email'
                options={options}
                data={this.state.data}
                fetchInfo={{ dataTotalSize: this.state.total_count }}
                columns={columns}
                defaultSorted={defaultSorted}
                bordered={false}
                noDataIndication={() => <NoDataIndication />}
                remote
                pagination={paginationFactory(paginatorOptions)}
                onTableChange={this.onTableChange}
                rowEvents={rowEvents}
              />
            </div>
          </div>
        </div>
        <NewInvite groupId={this.state.group_id} type="user" isInviteFromTeam={this.state.is_invite_from_team} is_top_admin={this.props.is_top_admin} is_reseller={this.props.is_reseller}/>
        </React.Fragment> : null }
        {/* Edit User Modal */}
        <div>
          <Modal show={this.state.isEditOpen}>
            {/* <div className="modal-dialog modal-lg">
                <div className="modal-content"> */}
            {/* <form id="editUserForm"> */}
            <ValidatorForm
              id="editUserForm"
              ref="form"
              onSubmit={this.handleEditSubmit}
              onError={this.handleOnError}
              instantValidate={false}
              className=""
            >
              <div className="modal-header modal-image-header">
                <div className="modal-header-content">
                  <h4 className="modal-title">{i18n.t('admin.institution.edit_modal.title')}</h4>
                </div>
                <button type="button" className="close" onClick={this.hideEditModal}>
                  <span className="lh-icon-os-close"></span>
                </button>
              </div>
              <div className="modal-body">
                <div className="middle-sec">
                  <div className="row">
                    <div className="user-label text-primary col-sm-4 pt-2">
                      <span className="ln-height-4">{i18n.t("userprofile.labels.email")}</span>
                    </div>
                    <div className="col-sm-6 pt-2">
                      <TextValidator
                        tabIndex='1'
                        wrapperClass=''
                        className={"form-control mt-2 mb-4"}
                        autoComplete="off"
                        type="email"
                        name="edit_email"
                        placeholder={i18n.t("userprofile.placeholder.email")}
                        value={this.state.edit_email}
                        isValidate={this.state.isValidate}
                        validators={['required', 'isEmail']}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        errorMessages={[
                            i18n.t("userprofile.errors.email_required"),
                            i18n.t("userprofile.errors.email_invalid")
                        ]}
                      />

                    </div>
                    <div className="user-label text-primary col-sm-4 pt-2">
                      <span className="ln-height-4">{i18n.t("userprofile.labels.first_name")}</span>
                    </div>
                    <div className="col-sm-6 pt-2">
                      <TextValidator
                        tabIndex='2'
                        wrapperClass=''
                        className={"form-control mt-2 mb-4"}
                        autoComplete="off"
                        type="text"
                        name="edit_first_name"
                        placeholder={i18n.t("userprofile.placeholder.first_name")}
                        value={this.state.edit_first_name}
                        isValidate={this.state.isValidate}
                        noValidate
                        validators={["required", "maxStringLength:40"]}
                        errorMessages={[
                          i18n.t("userprofile.errors.first_name_required"),
                          i18n.t('login.errors.first_name_max_length')
                        ]}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                      />

                    </div>
                    <div className="user-label text-primary col-sm-4 pt-2">
                      <span className="ln-height-4">{i18n.t("userprofile.labels.last_name")}</span>
                    </div>
                    <div className="col-sm-6 pt-2">
                      <TextValidator
                        tabIndex='3'
                        wrapperClass=''
                        className={"form-control mt-2 mb-4"}
                        autoComplete="off"
                        type="text"
                        name="edit_last_name"
                        placeholder={i18n.t("userprofile.placeholder.last_name")}
                        value={this.state.edit_last_name}
                        isValidate={this.state.isValidate}
                        noValidate
                        validators={["required", "maxStringLength:40"]}
                        errorMessages={[
                          i18n.t("userprofile.errors.first_name_required"),
                          i18n.t('login.errors.first_name_max_length')
                        ]}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                      />
                    </div>
                    {this.state.plan && this.state.plan.includes('team') ?
                      <React.Fragment>
                        <div className="user-label text-primary col-sm-4 pt-2">
                          <span className="ln-height-4">{i18n.t('admin.labels.institute')}</span>
                        </div>
                        <div className="col-sm-6 pt-2">
                          <TextValidator
                            tabIndex='4'
                            wrapperClass=''
                            className={"form-control"}
                            autoComplete="off"
                            placeholder={i18n.t('admin.placeholder.institute')}
                            type="text"
                            name="edit_institution"
                            value={this.state.edit_institution}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.handleChange}
                            isValidate={this.state.isValidate}
                            noValidate
                            required
                            validators={["required"]}
                            errorMessages={[
                              i18n.t('admin.errors.require_institute')
                            ]}
                          />
                        </div>
                      </React.Fragment> : null
                    }
                    <div className="user-label text-primary col-sm-4 pt-2">
                      <span className="ln-height-4">{i18n.t('admin.labels.max_conntect_session')}</span>
                    </div>
                    <div className="col-sm-6 pt-2">
                      <input placeholder={i18n.t('admin.placeholder.max_conntect_session')} tabIndex="6" onChange={this.handleChange} value={this.state.edit_max_connect_sessions} name="edit_max_connect_sessions" type="number" className="form-control mt-2 mb-4" />
                    </div>
                    {
                          (this.state.edit_plan && this.state.edit_plan != 'free') && (this.props.is_top_admin || this.props.is_reseller) ? 
                          <React.Fragment>
                            <div className="user-label text-primary col-sm-4 pt-2">
                                <span className="ln-height-4">{i18n.t('admin.labels.order_number')}</span>
                            </div>
                            <div className="col-sm-6 pt-2">
                                <input placeholder={i18n.t('admin.placeholder.order_number')} tabIndex="7" onPaste={this.onPasteHandle} onChange={this.handleChange} value={this.state.edit_order_number} name="edit_order_number" type="text" className="form-control " />
                            </div>
                          </React.Fragment> : null
                    }
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideEditModal}>{i18n.t('userprofile.btn_cancel')}</button>
                <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                  <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                  :
                  <React.Fragment>{i18n.t('admin.btn_ok')}</React.Fragment>
                }</button>
              </div>

            </ValidatorForm>
            <Errors
              hasError={this.state.hasError}
              type="warning"
              message={this.state.errorMessage}
              status={this.state.status}
            />
            {/* </div>
              </div> */}

          </Modal>
        </div>

        {/* Change Parent */}
        <div>
          <Modal show={this.state.isChangeParentOpen}>
            <ValidatorForm
              id="changeParentForm"
              ref="form"
              onSubmit={this.handleChangeParentSubmit}
              onError={this.handleOnError}
              instantValidate={false}
              className=""
            >
              <div className="modal-header modal-image-header">
                <div className="modal-header-content">
                  <h4 className="modal-title">{i18n.t('admin.change_parent_modal.title')}</h4>
                </div>
                <button type="button" className="close" data-bs-dismiss="modal" onClick={this.hideChangeParentModal}><span className="lh-icon-os-close"></span></button>
              </div>
              <div className="modal-body">
                <div className="middle-sec">
                  <div className="row">
                    <div className="col-sm-12 pt-3">
                    {this.state.target_groups ? 
                        <SelectValidator
                          tabIndex='5'
                          autoComplete="off"
                          placeholder={i18n.t('admin.placeholder.move_parent')}
                          name="selected_target_group"
                          options={this.state.target_groups}
                          onChange={this.handleChangeParentSelect}
                          isSearchable={true}
                        />  : null 
                    }
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideChangeParentModal}>{i18n.t('userprofile.btn_cancel')}</button>
                <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>
                  {this.state.isLoading ?
                    <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                    :
                    <React.Fragment>{i18n.t('admin.btn_ok')}</React.Fragment>
                  }
                </button>
              </div>
            </ValidatorForm>
            <Errors
              hasError={this.state.hasError}
              type="warning"
              message={this.state.errorMessage}
              status={this.state.status}
            />
          </Modal>
        </div>
        {/* Release user */}
        <div>
        <Modal show={this.state.isReleaseUserModalOpen} size="lg">
              <div className="modal-header modal-image-header">
                <div className="modal-header-content">
                  <h4 className="modal-title">{i18n.t('admin.release_user_modal.title')}</h4>
                </div>
                <button type="button" className="close" data-bs-dismiss="modal" onClick={this.hideReleaseUserModal}><span className="lh-icon-os-close"></span></button>
              </div>
              <div className="modal-body">
                <div className="middle-sec">
                {this.state.releaseUserInfo ? <p>{this.state.releaseUserInfo.first_name + this.state.releaseUserInfo.last_name} {i18n.t('admin.release_user_modal.message')}</p> : null}
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideReleaseUserModal}>{i18n.t('userprofile.btn_cancel')}</button>
                <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.releaseUserSubmit} data-type='with_lesson'>{i18n.t('admin.release_user_modal.btn_with_lesson')}</button>
                <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.releaseUserSubmit} data-type='without_lesson'>{i18n.t('admin.release_user_modal.btn_without_lesson')}</button>
              </div>
          </Modal>
        </div>
        {/* User Table Filter */}
        <div className="sidebar-container user-filter-sidebar" style={{ display: (toggleUserFilter ? 'block' : 'none') }}>
          <div className="right-sidebar">
            <div className="sidebar-header bg-primary">
              <a href={void (0)} className="close-btn" onClick={this.closeSideModal}><span className="lh-icon-os-close"></span> </a>
              <h1 className="sb-head-title">{i18n.t('admin.labels.filter')}</h1>
            </div>
            <div className="sidebar-body">
              <div className="users-filter-body">
                <div className="mt-1">
                  <h5>{i18n.t('admin.labels.by_status')}</h5>
                  <div className="custom-control-lg custom-radio mt-3 pt-2 pb-2">
                    <input type="radio" name="status-filter" checked={this.state.current_status == 'all'} className="custom-control-input" id="all" value="all" onChange={this.onChangeStatus} />
                    <label className="custom-control-label pl-2" htmlFor="all">{i18n.t('admin.labels.all')}</label>
                  </div>
                  <div className="custom-control-lg custom-radio pt-2 pb-2">
                    <input type="radio" name="status-filter" checked={this.state.current_status == 'active'} className="custom-control-input" id="active" value="active" onChange={this.onChangeStatus} />
                    <label className="custom-control-label pl-2" htmlFor="active">{i18n.t('admin.labels.active')}</label>
                  </div>
                  <div className="custom-control-lg custom-radio pt-2 pb-2">
                    <input type="radio" name="status-filter" checked={this.state.current_status == 'expired'} className="custom-control-input" id="expired" value="expired" onChange={this.onChangeStatus} />
                    <label className="custom-control-label pl-2" htmlFor="expired">{i18n.t('admin.labels.expired')}</label>
                  </div>
                  <div className="custom-control-lg custom-radio pt-2 pb-2">
                    <input type="radio" name="status-filter" checked={this.state.current_status == 'blocked'} className="custoApplyFiltersdio custom-control-input" id="blocked" value="blocked" onChange={this.onChangeStatus} />
                    <label className="custom-control-label pl-2" htmlFor="blocked">{i18n.t('admin.labels.blocked')}</label>
                  </div>
                  {/* <div className="custom-control-lg custom-radio pt-2 pb-2">
                    <input type="radio" name="status-filter" checked={this.state.current_status == 'dormant'} className="custom-control-input" id="dormant" value="dormant" onChange={this.onChangeStatus} />
                    <label className="custom-control-label pl-2" htmlFor="dormant">Dormant</label>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="sidebar-footer">
              <div className="footer-action-panel justify-content-end">
                <button className="btn btn-outline-tertiary mt-2" onClick={this.closeSideModal} >{i18n.t('userprofile.btn_cancel')}</button>
                <button onClick={this.applyFilters} style={{ width: '6rem' }} className="btn btn-accenta ml-2">{i18n.t('admin.btn_apply')}</button>
              </div>
            </div>
          </div>
        </div>

        {/* Import Modal */}
        <div className="modal fade" id="importModal" tabIndex="-1">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <form id="userImportForm">
                <div className="modal-header modal-image-header">
                  <div className="modal-header-content">
                    <h4 className="modal-title">{i18n.t('admin.import_modal.title')}</h4>
                  </div>
                  <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                </div>
                <div className="modal-body">
                  <div className="middle-sec">
                    <span className="import-header">{i18n.t('admin.import_modal.sub_title')}</span>
                    <a href="/user_import.csv" className="temp-download">{i18n.t('admin.import_modal.link_download')}</a>
                    <div className="pt-2 pb-3">
                      <ul className="import-points">
                        <li>{i18n.t('admin.import_modal.points.1')}</li>
                        <li>{i18n.t('admin.import_modal.points.2')}</li>
                        <li>{i18n.t('admin.import_modal.points.3')}</li>
                        <li>{i18n.t('admin.import_modal.points.4')}</li>
                        <li>{i18n.t('admin.import_modal.points.5')}
                          <ul className="inner-points">
                            <li>{i18n.t('admin.import_modal.points.5_1')}</li>
                            <li>{i18n.t('admin.import_modal.points.5_2')}</li>
                          </ul>
                        </li>
                        <li>{i18n.t('admin.import_modal.points.6')}</li>
                        <li>{i18n.t('admin.import_modal.points.7')}</li>
                      </ul>
                    </div>
                    <div className="col-md-8 input-group mb-3">
                        <div className="custom-file">
                          <input required="required" name="import_file" type="file" className=" custom-file-input" accept=".csv,text/csv" id="inputUserFile" onChange={this.handleChangeFile}/>
                          <label className="custom-file-label" htmlFor="inputUserFile">{ this.state.import_file ? this.state.import_file.name : i18n.t('userprofile.choose_file') } </label>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-accenta" onClick={this.importUserData}>{i18n.t('admin.import_modal.title')}</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Export Modal */}
        <div className="modal fade" id="exportModal" tabIndex="-1">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <form id="exportModalForm">
                <div className="modal-header modal-image-header">
                  <div className="modal-header-content">
                    <h4 className="modal-title">{i18n.t('admin.export_modal.title')}</h4>
                  </div>
                  <button id="export_usr_mdl_cls" type="button" className="close" data-bs-dismiss="modal" onClick={this.hideExportModal}><span className="lh-icon-os-close"></span></button>
                </div>
                <div className="modal-body">
                  <div className="page-header export-header">
                    <span className="text-primary">{i18n.t('admin.export_modal.message')}</span>
                  </div>
                  <div className="middle-sec">
                    <div className="row">
                      <div className="user-label col-sm-3">
                        <span>{i18n.t('admin.labels.from')}</span>
                      </div>
                      <div className="col-sm-8">
                        <input value={moment(this.state.fromDate).format("YYYY-MM-DD")} name="fromDate" type="date" className="form-control mt-2 mb-4" onChange={this.handleChange} max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                      </div>
                      <div className="user-label col-sm-3">
                        <span>{i18n.t('admin.labels.to')}</span>
                      </div>
                      <div className="col-sm-8">
                        <input value={this.state.toDate ? moment(this.state.toDate).format("YYYY-MM-DD") : this.state.toDate} min={moment(this.state.fromDate).format("YYYY-MM-DD")} onChange={this.handleChange} name="toDate" type="date" className="form-control mt-2 mb-4" max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideExportModal} data-bs-dismiss="modal">{i18n.t('userprofile.btn_cancel')}</button>
                  <button type="button" className="btn btn-lg btn-accenta" onClick={this.downloadUsersData}>{i18n.t('admin.btn_export')}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {
          this.state.isBlockModalOpen ? <BlockModal type='user' id={this.state.blockUserId} isOpen={this.state.isBlockModalOpen} hideModal={this.hideBlockModal}></BlockModal> : null
        }
        {
          this.state.isDeleteModalOpen ? <DeleteModal type='user' id={this.state.deleteUserId} isOpen={this.state.isDeleteModalOpen} hideModal={this.hideDeleteModal} isFromViewUser={this.state.isFromViewUser}></DeleteModal> : null
        }
        {
          this.state.isPromoteDemoteModalOpen ? <PromoteDemoteModal type='user' id={this.state.promoteUserId} isOpen={this.state.isPromoteDemoteModalOpen} action={this.state.promoteDemoteActionType} hideModal={this.hidePromoteDemoteModal}></PromoteDemoteModal> : null
        }
        
      </div>
    )
  }
}

export default withTranslation()(Users)