import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';

const jobs = [
    { attempts: 'braintree', run_at: 'yearly', details: 'individual' },
    { attempts: 'bank wire', run_at: 'monthly', details: 'team advanced' },
    { attempts: 'bank wire', run_at: 'monthly', details: 'team advanced' }
];
const jobStats = [
    { status: 'Enqueued Jobs', count: 3 },
    { status: 'Pending Jobs', count: 4 },
    { status: 'Working Jobs', count: 0 },
    { status: 'Failed Jobs', count: 0 }
];
const jobList = [{
    dataField: 'attempts',
    text: 'attempts'.toUpperCase(),
    sort: true
}, {
    dataField: 'run_at',
    text: 'run at'.toUpperCase(),
    sort: true
}, {
    dataField: 'details',
    text: 'details'.toUpperCase(),
    sort: true
}, {
    dataField: 'moreOptions',
    sort: true,
    formatter: jobActions,
}];
const jobStatus = [{
    dataField: 'status',
    text: 'status'.toUpperCase(),
    sort: true,
}, {
    dataField: 'count',
    text: 'count'.toUpperCase(),
    sort: true
}];

const defaultSorted = [{
    dataField: 'details',
    order: 'desc'
}];

function jobActions() {
    return (
        <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item href="/admin/delayed_jobs/show">Show</NavDropdown.Item>
            <NavDropdown.Item href="#">Delete</NavDropdown.Item>
        </NavDropdown>
    );
}

class Failed extends React.Component {
    constructor(props) {
        super(props);
    }

    goToPendingJobs(){
        window.location.href = '/admin/delayed_jobs/pending'
    }
    goToWorkingJobs(){
        window.location.href = '/admin/delayed_jobs/working'
    }
    goToEnqueuedJobs(){
        window.location.href = '/admin/delayed_jobs/enqueued'
    }

    render() {
        const paginatorOptions = {
            pageStartIndex: 1,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };

        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <span className="page-header">Delayed Job Queue</span>
                        <div>
                            <button className="btn btn-outline-primary mb-2 mt-3 mr-3" onClick={this.goToEnqueuedJobs}>Enqueued Jobs (3)</button>
                            <button className="btn btn-outline-primary mb-2 mt-3 mr-3" onClick={this.goToPendingJobs}>Pending Jobs (3)</button>
                            <button className="btn btn-outline-primary mb-2 mt-3 mr-3" onClick={this.goToWorkingJobs}>Working Jobs (2)</button>
                            <button className="btn btn-outline-primary mb-2 mt-3 mr-3">Failes Jobs (0)</button>
                        </div>
                        <div className="delayed-job-table mt-2">
                            <span className="jobs-table-header mb-2">List of Failed Jobs</span>
                            <BootstrapTable
                                keyField='details'
                                data={jobs}
                                columns={jobList}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                pagination={paginationFactory(paginatorOptions)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Failed;