import React from "react";
import i18n from "../../packs/i18n";
class PlanDetailsButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            free: false,
            individual: false,
            team: false,
            current_plan_key: null
        };
    }
    handleChoosePlan = event => {
        this.props.handler(this.props.plan_detail, this.props.plan_key, this.props.userInfo);
    };

    /** Handle Disable plan arrived from Profile page subscription */
    setDisablePlan() {
        const { current_plan } = this.props;

        if (current_plan && current_plan.key == "team_advanced") {
            this.disablePlanState(true, true, true, "team");
        }
        if (
            current_plan &&
            (current_plan.key == "team_basic" ||
                current_plan.key == "team_intermediate")
        ) {
            this.disablePlanState(true, true, false, null);
        } else if (current_plan && current_plan.key == "free") {
            this.disablePlanState(true, false, false, "free");
        } else if (current_plan && current_plan.key == "individual") {
            this.disablePlanState(true, true, false, "individual");
        }
    }

    disablePlanState(free, individual, team, current_plan_key) {
        this.setState({ free, individual, team, current_plan_key });
    }

    componentDidMount() {
        this.setDisablePlan();
    }

    render() {
        return (
            <span>
            { this.state.current_plan_key == this.props.plan_key ? 
                <a href="/" className="btn btn-secondary btn-select-pack mb-2 text-size-16" > 
                    {i18n.t('signup.step3.your_current_plan')} 
                </a>
                : 
                <button href="javascript:void(0)" 
                    onClick={this.handleChoosePlan} 
                    className="btn btn-secondary btn-select-pack mb-2 text-size-16"
                    disabled={ this.state[this.props.plan_key] ? 'disabled': ''}> 
                    {i18n.t(this.props.plan_detail.display_text)}
                </button>
            }
            </span>
        );
    }
}

export default PlanDetailsButton;
