import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import { Mail_IMG } from '../Common/assets_paths';
import { withTranslation } from 'react-i18next';

class ConfirmEmail extends React.Component {
    render() {
        return (
            <LandingStepBase container={
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 p-0 text-center">
                            <div className="headtxt mt-5">
                                <h1 className="text-white">{i18n.t('forgotpassword.confirm_mail.header.title')}</h1>
                                <p>{i18n.t('forgotpassword.confirm_mail.header.sub_title')}</p>
                            </div>
                            <div className="main-wrapper text-center">
                                <img src={Mail_IMG} className="my-4 animated-svg" />
                                <p className="text-white mt-5 text-size-24">
                                    {i18n.t('forgotpassword.confirm_mail.check_email_text')}<br />
                                    {i18n.t('forgotpassword.confirm_mail.click_link')} &nbsp;
                                    <span className="text-accenta" >{this.props.location.state.email}</span>  {i18n.t('forgotpassword.confirm_mail.click_link_1')}   </p>
                            </div>
                        </div>
                    </div>
                </div>}></LandingStepBase>
        )
    }

}

export default withTranslation()(ConfirmEmail);