import React from "react";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import TextValidator from "../Common/TextValidator";
import Errors from "../Common/Errors/Errors";
import i18n from "../../packs/i18n";
import TextareaValidator from "../Common/TextareaValidator";
import SelectValidator from "../Common/SelectValidator";
import { Prompt } from 'react-router'
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import { getUserInfo, updateUserInfo, getAvailableLanguages, getLocalUrl } from "../Common/i3library";
import countryList from 'react-select-country-list';
import API from '../api';
import Success from '../Common/Errors/Sucess';
import { UserDefaultAvtar_IMG } from "../Common/assets_paths";
import ProfileImageSection from "./ProfileImageSection";
import { Loading_IMG } from '../Common/assets_paths';
import {createEvent} from '../Common/AnalyticsEvents';

class ProfileForm extends React.Component {
    constructor(props) {
        super(props);
        const userInfo = getUserInfo();
        const countryOptions = countryList().getData();
        //ILEARN-4927 Tiwan Name Update 
        let taiwan_item = countryOptions.find(item => item.value == 'TW');
        taiwan_item.label = i18n.t("userprofile.labels.tiwan_label");
        let tiwan_index = countryOptions.findIndex(item => item.value == 'TW');
        countryOptions[tiwan_index] = taiwan_item;
        //ILEARN-4927 Tiwan Name Update End
        const languagesOptions = getAvailableLanguages();
        this.state = {
            first_name: userInfo.first_name || '',
            last_name: userInfo.last_name || '',
            email: userInfo.email || '',
            address: userInfo.address || '',
            language: userInfo.language,
            country: userInfo.country,
            teamname: userInfo.institution_name || '',
            countryOpt: countryOptions,
            languageOpt: languagesOptions,
            image: userInfo.avatar ? userInfo.avatar : UserDefaultAvtar_IMG,
            isDefaultImage : userInfo.avatar ? false : true,
            persistence_token: userInfo.persistence_token,
            hasError: false,
            errorMessage: "",
            redirect: false,
            isValidate: false,
            isImageUpload: false,
            shouldBlockNavigation: false,
            isValid:false,
            show: false,
            file: null,
            isLoading: false,
            isLangChange : false,
            status: null
        };

        ValidatorForm.addValidationRule('isValidFile', (value) => {
            return this.state.file ? ValidationRules.isFile(this.state.file) : true;
        });
        ValidatorForm.addValidationRule('allowmaxFileSize', (value, max) => {
            return this.state.file ? ValidationRules.maxFileSize(this.state.file, max) : true;
        });
        ValidatorForm.addValidationRule('allowedfileExtensions', (value, fileTypes) => {
            return this.state.file ? ValidationRules.allowedExtensions(this.state.file, fileTypes) : true;
        });
        ValidatorForm.addValidationRule('allowedminfilesize', (value, min) => {
            return this.state.file ? ValidationRules.isFile(this.state.file) && this.state.file.size >= parseInt(min, 10) : true;
        });

        ValidatorForm.addValidationRule('allowedminfilesize', (value, min) => {
            return this.state.file ? ValidationRules.isFile(this.state.file) && this.state.file.size >= parseInt(min, 10) : true;
        });
    }

    static getDerivedStateFromProps(props, state){
        const historyPathName = props.history.location.pathname;
        const locationPathName = props.location.pathname;
        const locationState = props.history.location.state;

        let language = state.language;
        if(historyPathName !== locationPathName){
            language = locationState ? locationState.locale : props.match.params.locale;
        }
        return { language };

    }

    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    };

    handleFileChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();
        const url = reader.readAsDataURL(file);
        // image/png,image/jpeg,image/jpg
        reader.onloadend =  (e) => {
            if(['image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type) > -1) {
                this.setState({
                    shouldBlockNavigation: true,
                    image: [reader.result],
                    file: file
                });
            }
        };

    }

    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };

    handleSubmit = (e) => {
        if (!this.state.shouldBlockNavigation) {
            return ;
        }
        this.setState({ isLoading : true });
        this.callApi();
    }
    callApi = () => {
        const formData = new FormData();
        const {isLangChange} = this.state;
        if(this.state.file)
            formData.append('user_avatar',this.state.file);

        formData.append('persistence_token',this.state.persistence_token);
        formData.append('user[first_name]',this.state.first_name);
        formData.append('user[last_name]',this.state.last_name);
        formData.append('user[address]',this.state.address);
        formData.append('user[language]',this.state.language);
        formData.append('user[country]',this.state.country);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        API.put('profile/update', formData, config)
            .then(res => {
                createEvent("Profile", "Updated sucessfully");
                const { data } = res.data;
                // updateUserInfo(data);
                const pathName = getLocalUrl(this.state.language,'users/profile');
                this.setState({
                    shouldBlockNavigation: false,
                    isValid:true,
                    hasError:false,
                    errorMessage: i18n.t('userprofile.update_successfully'),
                    isImageUpload: false,
                    isDefaultImage: (this.state.file || !this.state.isDefaultImage) ? false : true,
                    file: null,
                    isLoading : false,
                    status: res.status
                }, () => {
                    setTimeout(() => {
                        this.props.callUserInfo();
                        console.log('isLangChange', isLangChange);
                        if(isLangChange){
                            this.props.history.replace({
                                pathname: pathName,
                                state:{ locale : this.state.language, action : 'save' }
                            });
                            window.location.reload();
                        }
                    }, 1500);
                });
            })
            .catch((error) => {
              createEvent("Profile", "Updated fail",error.response.status);
              if (error.response) {
                this.setState({
                    hasError:true,
                    errorMessage:i18n.t('signup.step5.errors.500'),
                    isValid:false,
                    isLoading : false,
                    status: error.response.status
                })
              }
              if (error.request) {
                console.log(error.request);
              }
              else {
              }
            })
            .finally(function () {
            });

          event.preventDefault();
    }

    handleConfirmClick = () => {
        API.delete('/users/remove_avatar')
        .then(res => {
            createEvent("Profile", "Remove Avtar Sucessfully");
            this.setState({
                image: UserDefaultAvtar_IMG,
                isDefaultImage : true,
            });
            this.props.callUserInfo();
        })
        .catch((error) => {
            createEvent("Profile", "Remove Avtar Failed");
        }).finally( () => {
            this.setState({
                show: false
            })
        });
    }

    componentDidUpdate = () => {
        if (this.state.shouldBlockNavigation) {
          window.onbeforeunload = () => true
        } else {
          window.onbeforeunload = undefined
        }
    }

    componentWillUnmount = () => {
        window.onbeforeunload = undefined
    }

    render() {
        return (
            <div className="profile-main-wrapper user-profile text-primary">
                <Prompt
                    when={this.state.shouldBlockNavigation}
                    message={i18n.t('userprofile.propmt_message')}
                />
                <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    onError={this.handleOnError}
                    instantValidate={false}
                    className="white-cover-fixheight"
                >
                    <ProfileImageSection
                        isImageUpload={this.state.isImageUpload}
                        image={this.state.image}
                        file={this.state.file}
                        handleRemoveImageClick={() => { this.setState({ show : true })}}
                        handleImageClick={() => { this.setState({ isImageUpload: true, isValid: false }) }}
                        handleFileChange={this.handleFileChange}
                        isValidate={this.state.isValidate}
                        isDefaultImage={this.state.isDefaultImage}
                        handleCancelClick={ () => this.setState({ show: false})}
                        handleConfirmClick={this.handleConfirmClick}
                        show={this.state.show}
                        ></ProfileImageSection>
                    <div className="profile-input-box">
                        <div className="row pb-2 mr-0">
                            <TextValidator
                                tabIndex='1'
                                wrapperClass={"col-sm-7 col-md-7 col-lg-7 col-xs-12 pr-0"}
                                className={"form-control form-control"}
                                labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                autoComplete="off"
                                placeholder={i18n.t("userprofile.placeholder.first_name")}
                                label={i18n.t("userprofile.labels.first_name")}
                                type="text"
                                name="first_name"
                                value={this.state.first_name}
                                onChange={this.handleChange}
                                isValidate={this.state.isValidate}
                                noValidate
                                required
                                validators={["required", "maxStringLength:40"]}
                                errorMessages={[
                                  i18n.t("userprofile.errors.first_name_required"),
                                  i18n.t('login.errors.first_name_max_length')
                                ]}
                            />
                        </div>
                        <div className="row pb-2 mr-0">
                            <TextValidator
                                tabIndex='2'
                                wrapperClass={"col-sm-7 col-md-7 col-lg-7 col-xs-12 pr-0"}
                                className={"form-control form-control"}
                                labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                autoComplete="off"
                                placeholder={i18n.t("userprofile.placeholder.last_name")}
                                label={i18n.t("userprofile.labels.last_name")}
                                type="text"
                                name="last_name"
                                value={this.state.last_name}
                                onChange={this.handleChange}
                                isValidate={this.state.isValidate}
                                noValidate
                                required
                                validators={["required", "maxStringLength:40"]}
                                errorMessages={[
                                  i18n.t("userprofile.errors.last_name_required"),
                                  i18n.t('login.errors.last_name_max_length')
                                ]}
                            />
                        </div>
                        <div className="row pb-2 mr-0">
                            <TextValidator
                                wrapperClass={"col-sm-7 col-md-7 col-lg-7 col-xs-12 pr-0"}
                                className={"form-control form-control"}
                                autoComplete="off"
                                type="email"
                                name="email"
                                value={this.state.email}
                                noValidate
                                disabled={'disabled'}
                                onChange={this.handleChange}
                                label={i18n.t("userprofile.labels.email")}
                                labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                            />
                        </div>
                        { this.state.teamname ?
                            <div className="row pb-2 mr-0">
                                <TextValidator
                                    wrapperClass={"col-sm-7 col-md-7 col-lg-7 col-xs-12 pr-0"}
                                    className={"form-control form-control"}
                                    autoComplete="off"
                                    type="text"
                                    name="teamname"
                                    value={this.state.teamname}
                                    noValidate
                                    disabled={'disabled'}
                                    onChange={this.handleChange}
                                    label={i18n.t("userprofile.labels.teamname")}
                                    labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                />
                            </div> : null
                        }
                        <div className="row pb-2 mr-0">
                            <SelectValidator
                                tabIndex='3'
                                wrapperClass={"col-sm-7 col-md-7 col-lg-7 col-xs-12 pr-0"}
                                className={"form-control form-control"}
                                labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                autoComplete="off"
                                placeholder={i18n.t("userprofile.placeholder.country")}
                                label={i18n.t("userprofile.labels.country")}
                                name="country"
                                options={this.state.countryOpt}
                                value={this.state.country}
                                onChange={this.handleChange}
                                isValidate={this.state.isValidate}
                                noValidate
                                required
                                validators={["required"]}
                                errorMessages={[
                                    i18n.t("userprofile.errors.country_required")
                                ]}
                            />
                        </div>
                        <div className="row pb-2 mr-0">
                            <TextareaValidator
                                tabIndex='4'
                                wrapperClass={"col-sm-7 col-md-7 col-lg-7 col-xs-12 pr-0"}
                                className={"form-control form-control"}
                                labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                autoComplete="off"
                                placeholder={i18n.t("userprofile.placeholder.address")}
                                label={i18n.t("userprofile.labels.address")}
                                name="address"
                                value={this.state.address}
                                noValidate
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="row pb-2 mr-0">
                            <SelectValidator
                                tabIndex='5'
                                wrapperClass={"col-sm-7 col-md-7 col-lg-7 col-xs-12 pr-0"}
                                className={"form-control form-control"}
                                labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                autoComplete="off"
                                placeholder={i18n.t("userprofile.placeholder.language")}
                                label={i18n.t("userprofile.labels.language")}
                                name="language"
                                options={this.state.languageOpt}
                                value={this.state.language}
                                onChange={this.handleChange}
                                isValidate={this.state.isValidate}
                                noValidate
                                required
                                validators={["required"]}
                                errorMessages={[
                                    i18n.t("userprofile.errors.language_required")
                                ]}
                            />
                        </div>
                        <div className="row justify-content-end mt-4 profile-save-btn">
                            <a href="/"
                                className={"btn btn-outline-primary mb-2 p-2 pl-5 pr-5 mr-2"}
                            >
                                {i18n.t("userprofile.btn_cancel")}
                            </a>

                            <button
                                type="submit"
                                className={"btn btn-accenta p-2 pl-5 pr-5 mb-2"}
                                disabled={ this.state.isLoading }
                            >
                                { this.state.isLoading ?
                                 <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                 :
                                 i18n.t("userprofile.btn_save")
                                }
                            </button>
                        </div>
                    </div>
                </ValidatorForm>
                <Errors
                    hasError={this.state.hasError}
                    type="warning"
                    message={this.state.errorMessage}
                    status={this.state.status}
                />
            <Success hasError={this.state.isValid} type='warning' message={this.state.errorMessage}/>
            </div>
        );
    }
}

export default ProfileForm;
