import React from "react";

class BulkUpload extends React.Component {
    constructor(props) {
        super(props);
    }

    routeBackToLibrary = () => {
        window.location.href = '/admin/libraries'
    }
    handleChange = () => {
        var a = document.getElementById('inputBulkExcelFile');
        var b = document.getElementById('inputBulkZipFile');
        if (a.value == "" ) {
            fileLabel.innerHTML = "Choose file";
        }
        else {
            var theSplit = a.value.split('\\');
            fileLabel.innerHTML = theSplit[theSplit.length - 1];
        }
        if (b.value == "" ) {
            fileLabel1.innerHTML = "Choose file";
        }
        else {
            var theSplit1 = b.value.split('\\');
            fileLabel1.innerHTML = theSplit1[theSplit1.length - 1];
        }
    }

    render() {
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div className="row">
                            <div className="col back-to-listing" >
                                <span className="back-icon lh-icon-os-back" onClick={this.routeBackToLibrary}>
                                </span>
                                <span className="user-info">
                                    Back
                                </span>
                            </div>
                        </div>
                        <form id="bulkUploadFiles">
                            <div className="row mt-3">
                                <div className="col-md-12 library-content">
                                    <div className="col-md-8 mt-3 mb-3">
                                        <div className="row g-3 align-items-center mb-3">
                                            <div className="col-auto" style={{ width: '25%' }}>
                                                <label htmlFor="library" className="col-form-label">Upload CSV *</label>
                                            </div>
                                            <div className="col-auto">
                                                <div className="custom-file details-info">
                                                    <input type="file" onChange={this.handleChange} name="file" className="custom-file-input" id="inputBulkExcelFile"
                                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                                    <label className="custom-file-label" id="fileLabel" htmlFor="inputBulkExcelFile">Choose file</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center mb-3">
                                            <div className="col-auto" style={{ width: '25%' }}>
                                                <label htmlFor="education" className="col-form-label">Upload Zip *</label>
                                            </div>
                                            <div className="col-auto">
                                                <div className="custom-file details-info">
                                                    <input type="file" onChange={this.handleChange} name="file" className="custom-file-input" id="inputBulkZipFile"
                                                        accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" />
                                                    <label className="custom-file-label" id="fileLabel1" htmlFor="inputBulkZipFile">Choose file</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center mb-3">
                                            <div className="col mt-2" style={{ maxWidth: '70%', display: 'flex', justifyContent: 'end' }}>
                                                <button type="button" className="btn btn-primary mr-3">Download example csv</button>
                                                <button type="submit" className="btn btn-outline-primary">Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default BulkUpload