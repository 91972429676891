import React from 'react';
import { Loading_IMG } from './assets_paths';

class Loading extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (            
            <div className="loading-wrapper">
                <img src={Loading_IMG}  alt="loading" className="loading" />
            </div>
        )
    }
}


export default Loading;