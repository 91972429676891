import React from "react";
import i18n from "../../packs/i18n";
import { getUserInfo, setUserInfo } from "../Common/i3library";
import API from "../api";
import Errors from "../Common/Errors/Errors";
import Success from '../Common/Errors/Sucess';
import SweetModelAlert from "../Common/SweetModelAlert";
import {createEvent} from '../Common/AnalyticsEvents';
class AdministrateApplications extends React.Component {
    constructor(props) {
        super(props);
        const { applications } = getUserInfo();
        this.state = { 
            applications,
            application: null,
            show: false,
            successMessage: null,
            errorMessage: null,
            status: null
        }
    }
    handleRemoveClick = application => {
        this.setState({
            successMessage: null,
            errorMessage: null,
            application: application,
            show: true,
            status: null
        });
    }

    handleConfirmClick = event => {
        API.delete('/users/revoke_oauth_access', { data: { id: this.state.application.id }})
            .then(res => {
                createEvent("disconnect", "app_"+this.state.application.name+"_success");
                this.setState({
                    successMessage: i18n.t('userprofile.administrate_app.success_msg'),
                    applications: this.state.applications && this.state.applications.filter((value) => {
                        return value.id != this.state.application.id
                    })
                });
                let user = getUserInfo();
                user.applications = this.state.applications;
                setUserInfo(user);
            })
            .catch((error) => {
                createEvent("disconnect", "app_"+this.state.application.name+"_fail");
              if (error.response) {
                this.setState({
                    errorMessage:i18n.t('userprofile.administrate_app.fail_msg'),
                    status: error.response.status
                })
              } else {
                this.setState({
                    errorMessage:i18n.t('common.something_went_wrong'),
                    status: error.response.status
                })
              }
            })
            .finally(() => {
                this.setState({
                    show: false,
                    application: null,
                })
            });
    }
    render() {
        const { applications } = this.state;
        
        return (
            <div className="profile-main-wrapper">
                <div className="white-cover-fixheight">
                    <h3 className="text-primary mb-3">{i18n.t('userprofile.administrate_app.heading')}</h3>
                    <p className="mb-3 text-xs text-secondary">
                        {i18n.t('userprofile.administrate_app.sub_text')} 
                    </p>
                    { applications.map((application, key) => {
                        return <div key={key} className="administrat-application text-secondary text-size-14" onClick={() => this.handleRemoveClick(application)}> { application.name } </div> 
                    })}
                </div>
                <SweetModelAlert
                    show={this.state.show}
                    title={i18n.t("userprofile.administrate_app.delete_confirm_title")}
                    text={i18n.t('userprofile.administrate_app.delete_confirm_message')}
                    showCancelButton
                    confirmButtonText={i18n.t('userprofile.confirm_yes')}
                    cancelButtonText={i18n.t('userprofile.confirm_no')}
                    onConfirm={this.handleConfirmClick}
                    onCancel={() => {
                        this.setState({ show: false});
                    }}
                />
                <Errors
                    hasError={this.state.errorMessage}
                    type="warning"
                    message={this.state.errorMessage}
                    status={this.state.status}
                />
                <Success hasError={this.state.successMessage} type='warning' message={this.state.successMessage}/>  
            </div>
        );
    }
}

export default AdministrateApplications;
