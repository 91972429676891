/* React component Created for testing routing */
import React from 'react';
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withRouter, matchPath } from 'react-router-dom';


class DevNav extends React.Component {
  
  render() {
    const match = matchPath(this.props.history.location.pathname, {
      path: '/:locale/(signup|login|forgotpassword)',
      exact: false,
      strict: false
    });
    var locale = match && match.params && match.params.locale ? match.params.locale  + '/' : '';
    
    return (
      <pre>
      <div className="Devnav">
          <ul>
            <li>
              - <Link to={{pathname: `/${locale}login/`}} >Login</Link>            
            </li>
            <li>
              - <Link to={{pathname: `/${locale}signup/`}}>Signup</Link>
              <br></br>-- <Link to={{pathname: `/${locale}signup/confirmemail`}}>SignupConfirmEmail</Link>
              <br></br>-- <Link to={{pathname: `/${locale}signup/selectpackage`}}>SelectPackage</Link>
              <br></br>-- <Link to={{pathname: `/${locale}signup/paymentmethod`}}>PaymentMethod</Link>
              <br></br>-- <Link to={{pathname: `/${locale}signup/payment`}}>Payment</Link>
              <br></br>-- <Link to={{pathname: `/${locale}signup/success`}}>Success</Link>
              <br></br>-- <Link to={{pathname: `/${locale}signup/fail`}}>Failed</Link>
            </li>
            <li>
              - <Link to={{pathname: `/${locale}forgotpassword/`}}>ForgotPassword</Link>
              <br></br>-- <Link to={{pathname: `/${locale}forgotpassword/confirmemail`}}>ForgotPasswordConfirmEmail</Link>
              <br></br>-- <Link to={{pathname: `/${locale}forgotpassword/choosenewpassword`}}>ChooseNewPassword</Link>
              <br></br>-- <Link to={{pathname: `/${locale}forgotpassword/success`}}>ForgotPasswordSuccess</Link>
            </li>
          </ul>
          <hr></hr>
          </div>
        </pre>
    )
  }
}

export default withRouter(DevNav);