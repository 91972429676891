import React from "react";
import TextValidator from "../Common/TextValidator";
import i18n from "../../packs/i18n";
import API from "../api";
import SweetModelAlert from "../Common/SweetModelAlert";

const handleAvatarChange = () => {
    let avatar = document.querySelector('.avatar');
    let chooseBtnDiv = document.querySelector('.choose-btn-box');
    let deleteBtn = document.querySelector('.deleteBtn');
    if(!avatar.classList.contains('slide-bck-tr')){
        chooseBtnDiv.style.zIndex = "0";
        if(deleteBtn){ deleteBtn.classList.add('hidden'); }
        chooseBtnDiv.classList.add('hidden');
        avatar.classList.add('slide-bck-tr');
    }else{
        avatar.classList.remove('slide-bck-tr');
    }
}

class ProfileImageSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isGoogle: false,
            slideImg : false,
            chooseBtnDiv : false
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.file && !this.props.file){
            handleAvatarChange()
        }
    }

    displayButtonClass = (name) => {
        const btn_active = 'btn btn-sm btn-primary pl-3 pr-3';
        const btn_inactive = 'btn btn-sm btn-outline-primary pl-3 pr-3';
        switch(name){
            case 'google':
                return this.state.isGoogle?btn_active:btn_inactive;
                break;
            default:
                return btn_inactive
                break;        
        }
    }
    handleRedirectToApp = (name) => {
        switch(name){
            case 'google':
                this.setState({
                    'isGoogle':!this.state.isGoogle
                });
                this.redirect(this.state.isGoogle, 'google');
                break;
      }
    }
    redirect = (value,providerId) => {
        if(value){
            //Disconnect the apps
            // API.get('/disconnect/'+providerId)
            // .then(res => {
            //     console.log(res.data);
            // })
            // .catch(function(error){

            // })

        }
        else{
            //Redirect to the perticular apps
            API.get('/link/photo/'+providerId)
            .then(res => { 
                window.location.href = res.data.link_url
            })
            .catch(function(error){

            })
        }
    }

    addSlideEffect = () => {
        this.props.handleImageClick();
        let avatar = document.querySelector('.avatar');
        let avatarWrapper = document.querySelector('.avatar-wrapper');
        let chooseBtnDiv = document.querySelector('.choose-btn-box');
        let deleteBtn = document.querySelector('.deleteBtn');

        avatar.addEventListener("animationend", function() {
            if(!avatar.classList.contains('slide-bck-tr')){
                chooseBtnDiv.style.zIndex = "2";
                if(deleteBtn){ deleteBtn.classList.remove('hidden'); }
                chooseBtnDiv.classList.remove('hidden');
            }
            avatarWrapper.onclick = function(){
                handleAvatarChange();
            }
        });
        this.setState({ slideImg : true });
    }

    render() {
        return (
            <React.Fragment>
                <div className={"pl-0 pr-0 text-cente mb-12 mt-3"}>
                    <div className={"wrapper-image"}>
                        <div className={"default-image-wrapper"}>
                            <div className={`avatar ${ this.state.slideImg ? "slide-fwd-bl" : "" }`}>
                                <div className="avatar-wrapper" onClick={this.addSlideEffect}>
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 195 189" enableBackground="new 0 0 195 189" space="preserve" >    
                                        <clipPath id="shape2">
                                            <path fill="#DD9090" d="M81.292,24.7c8.768-6.379,20.648-6.379,29.416,0l54.621,39.74c8.75,6.366,12.411,17.639,9.071,27.932 l-20.877,64.344C150.18,167.021,140.578,174,129.743,174H62.257c-10.834,0-20.436-6.979-23.78-17.284L17.6,92.372 c-3.339-10.292,0.322-21.565,9.072-27.931L81.292,24.7z" />
                                        </clipPath>
                                        <image clipPath="url(#shape2)" href={this.props.image} x="0" y="0" height="100%" width="100%" />
                                        <path fill="#ffffff" d="M96,7.715c-2.461,0-4.897,0.376-7.24,1.117c-2.396,0.757-4.651,1.884-6.704,3.349L12.578,61.773 c-2.105,1.502-3.941,3.321-5.458,5.403c-1.481,2.035-2.629,4.282-3.413,6.679c-0.783,2.397-1.183,4.889-1.188,7.405 c-0.005,2.576,0.403,5.127,1.216,7.583l26.356,79.692c0.8,2.417,1.969,4.678,3.476,6.718c1.473,1.995,3.241,3.744,5.254,5.199 s4.229,2.584,6.586,3.356c2.411,0.79,4.924,1.19,7.47,1.19h86.244c2.546,0,5.059-0.4,7.47-1.19c2.355-0.772,4.572-1.901,6.586-3.356 s3.781-3.204,5.254-5.199c1.508-2.041,2.677-4.301,3.477-6.718l26.356-79.692c0.813-2.456,1.222-5.007,1.216-7.583 c-0.006-2.517-0.405-5.008-1.188-7.405c-0.783-2.397-1.932-4.644-3.413-6.679c-1.517-2.083-3.353-3.901-5.458-5.403l-69.479-49.592 c-2.053-1.465-4.308-2.592-6.703-3.349C100.897,8.091,98.461,7.715,96,7.715 M96,6.715c5.09,0,10.18,1.55,14.523,4.652 l69.479,49.592c8.918,6.365,12.651,17.796,9.212,28.198l-26.357,79.692c-3.386,10.236-12.954,17.15-23.735,17.15 c-0.001,0,0.001,0,0,0H52.878c-10.782,0-20.35-6.913-23.735-17.15L2.786,89.157c-3.44-10.402,0.293-21.833,9.211-28.198 l69.479-49.592C85.821,8.266,90.91,6.715,96,6.715z" />
                                    </svg>
                                </div> 
                                {!this.props.isDefaultImage ? <span className="deleteBtn hidden" onClick={this.props.handleRemoveImageClick} style={{ zIndex : 3 }}>
                                    <i className="bg-danger lh-icon-os-delete"></i>
                                </span> : null}  
                            </div>
                        </div>
                        {/* { !this.props.isDefaultImage  ? <span onClick={this.props.handleRemoveImageClick}>
                            <i className="bg-danger lh-icon-os-delete"></i>
                        </span> : null} */}
                    </div>
                </div>
                <div className="pr-0 mb-auto upload-image choose-btn-box hidden" style={{ paddingTop: this.state.slideImg && this.props.isImageUpload ? "0px" : "66px" }}>
                    { this.props.isImageUpload &&
                        <React.Fragment>
                            <label className="simple-file-control">
                                { i18n.t('userprofile.choose_file')}
                                <TextValidator
                                    wrapperClass=""
                                    className="simple-file-input"
                                    id="image"
                                    type="file"
                                    name="image"
                                    accept='image/x-png,image/png,image/jpeg,image/jpg'
                                    size="60"
                                    onChange={this.props.handleFileChange}
                                    isValidate={this.props.isValidate}
                                    validators={["isValidFile", "allowedminfilesize:40000","allowmaxFileSize:5000000", "allowedfileExtensions:image/png,image/jpeg,image/jpg"]}
                                    errorMessages={[ 
                                        i18n.t("userprofile.errors.image_upload_is_file"),
                                        i18n.t("userprofile.errors.image_upload_filesize"),
                                        i18n.t("userprofile.errors.image_upload_filesize"),
                                        i18n.t("userprofile.errors.image_upload_allow_extension")
                                    ]}
                                    noValidate
                                />
                            </label>
                            <label className={"simple-file-label " + (this.props.isValidate ? 'm-0' : 'mb-0')} htmlFor="image">{ this.props.file ? this.props.file.name : i18n.t("userprofile.no_file_chosen")}</label>
                            <p>{i18n.t("userprofile.errors.image_upload_filesize")}</p>
                            <button type="button" className = {this.displayButtonClass('google')} id='google' onClick={(e) => this.handleRedirectToApp('google')} >{i18n.t("userprofile.use_google_avatar")}</button>
                        </React.Fragment>
                    }
                </div>
                <SweetModelAlert
                    customClass="remove-profile"
                    show={this.props.show}
                    title={i18n.t("userprofile.image_delete_confirm_title")}
                    text={i18n.t('userprofile.image_delete_confirm_message')}
                    showCancelButton
                    confirmButtonText={i18n.t('userprofile.confirm_yes')}
                    cancelButtonText={i18n.t('userprofile.confirm_no')}
                    onConfirm={this.props.handleConfirmClick}
                    onCancel={this.props.handleCancelClick}
                />
            </React.Fragment>
        );
    }
}

export default ProfileImageSection;
