import React from "react";

class SweetModelAlert extends React.Component {
    constructor(props) {
        super(props);
        this.onConfirm = this.onConfirm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount(){
        if(this.props.show){
            this.handleModalOpenClass('add');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.show && this.props.show){
            this.handleModalOpenClass('add');
        }else{
            this.handleModalOpenClass();
        }
    }

    handleModalOpenClass = (type) => {
        let element = document.getElementsByTagName('body')[0];
        if(type === 'add'){
            element.classList.add('modal-open');
        }else{
            element.classList.remove('modal-open');
        }
    }

    onConfirm(e){
        if(this.props.onConfirm) {
            this.props.onConfirm(e);
        }
    }

    onCancel(e) {
        if(this.props.onCancel) {
            this.props.onCancel(e);
        }
    }

    onClose(e) {
        if(this.props.onClose) {
            this.props.onClose(e);
        } else if(this.props.onCancel) {
            this.props.onCancel(e);
        }
    }

    componentWillUnmount() {
        this.handleModalOpenClass();
    }

    render() {
        const { title, text, showCancelButton, showConfirmButton, show, confirmButtonText, confirmButtonClass, cancelButtonClass, cancelButtonText, showCloseButton } = this.props;
        return (
            <React.Fragment>
                { show && 
                <React.Fragment>
                    <div className="modal fade show d-block" id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content modalgradient">
                                <div className="modal-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{ title }</h4>
                                    </div>
                                    {
                                        showCloseButton && <button type="button" className="close" data-dismiss="modal" onClick={this.onClose}><span className="lh-icon-os-close">&times;</span></button>
                                    }
                                </div>
                                <div className="modal-body pb-0">
                                    <div className="pl-2 pr-2 pt-2">
                                        <div className="m-text-header text-white text-left text-line-pre">
                                            { text }
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer pl-2 pr-2 pt-5 pb-5">
                                    {showCancelButton && <div className="col-sm-5">
                                        <button type="button" className={`btn btn-block btn-outline-tertiary ${cancelButtonClass}`} onClick={this.onCancel}>{ cancelButtonText }</button>
                                    </div>}
                                    {showConfirmButton && <div className="col-sm-5">
                                        <button type="button" className={`btn btn-block btn-accenta ${confirmButtonClass}`} onClick={this.onConfirm}>{ confirmButtonText }</button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

SweetModelAlert.defaultProps = {
    onConfirm: function () { },
    title: '',
    text: '',
    showCancelButton: false,
    showConfirmButton: true,
    showCloseButton: false,
    show: false,
    confirmButtonText: 'OK',
    confirmButtonClass: 'btn btn-accenta pl-5 pr-5',
    cancelButtonClass: 'btn btn-outline-primary pl-5 pr-5',
    cancelButtonText: 'Cancel',
}

export default SweetModelAlert;

