import React from "react";
import { NavDropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';

const products = [
    { code: '435' },
    { code: '567235' },
    { code: '57456' }
];
const columns = [{
    dataField: 'code',
    text: 'code'.toUpperCase(),
    sort: true
}, {
    dataField: 'moreOptions',
    sort: true,
    formatter: boardActions
}];

function boardActions() {
    return (
        <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item href="#">Delete Board Code</NavDropdown.Item>
        </NavDropdown>
    );
}

class BoardCodes extends React.Component {
    constructor(props) {
        super(props);
    }

    showFileName = () => {
        var boardFile = document.getElementById('inputBoardFile');
        if (boardFile.value == "") {
            fileLabel.innerHTML = "Choose file";
        }
        else {
            var theSplit = boardFile.value.split('\\');
            fileLabel.innerHTML = theSplit[theSplit.length - 1];
        }
    }

    render() {
        const paginatorOptions = {
            pageStartIndex: 1,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const NoDataIndication = () => (
            <div>
                <img src={Loading_IMG} alt="loading" className="loading" />
            </div>
        );

        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <span className="page-header mb-2">Board Codes</span>
                        <div>
                            <button data-bs-toggle="modal" data-bs-target="#addBoardCode" className="btn btn-primary mb-3 mt-1">Add Board Code</button>
                            <button data-bs-toggle="modal" data-bs-target="#importBoardCode" className="btn btn-primary mb-3 ml-3 mt-1">Import</button>
                        </div>
                        <div className="board-code-table mt-2">
                            <BootstrapTable
                                keyField='code'
                                data={products}
                                columns={columns}
                                bordered={false}
                                noDataIndication={() => <NoDataIndication />}
                                pagination={paginationFactory(paginatorOptions)}
                            />
                        </div>
                    </div>
                </div>

                {/* Add Board Code Modal */}
                <div className="modal fade" id="addBoardCode" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="addBoardCodeForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">Add board code</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="add" className="col-form-label">Code</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <input type="text" name="code" className="form-control details-info" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-accenta">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Import Board Code Modal */}
                <div className="modal fade" id="importBoardCode" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="importBoardCodeForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">Add board code</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="inputBoardFile" className="col-form-label">File</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <div className="custom-file details-info">
                                                    <input type="file" onChange={this.showFileName} name="file" className="custom-file-input" id="inputBoardFile" />
                                                    <label className="custom-file-label" id="fileLabel" htmlFor="inputBoardFile">Choose file</label>
                                                </div>
                                            </div>
                                            <div className="user-label col-sm-4 mt-3 mb-3">
                                                <label htmlFor="replace" className="col-form-label">Replace</label>
                                            </div>
                                            <div className="col-sm-6 pt-3 mt-3">
                                                <div className="custom-control-lg custom-radio">
                                                    <input type="radio" className="custom-control-input" id="rdb1" name="add" />
                                                    <label className="custom-control-label" htmlFor="rdb1"></label>
                                                </div>
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="add" className="col-form-label">Add</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <div className="custom-control-lg custom-radio">
                                                    <input type="radio" className="custom-control-input" id="rdb2" name="add" checked="checked" />
                                                    <label className="custom-control-label" htmlFor="rdb2"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-lg btn-accenta">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BoardCodes