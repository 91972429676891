import React from "react";
import i18n from "../../packs/i18n";
import API from "../api";
import LandingStepBase from "../Common/Layouts/LandingStepBase";
import Success from "../Common/Errors/Sucess";
import Errors from "../Common/Errors/Errors";
import { withTranslation } from 'react-i18next';


class ConfirmSignup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            hasError: false,
            errorMessage: null,
            status: null
        }
    }

    handleResendBtn = () => {
        this.setState({
            loading: true
        })
        API.get('/users/resend_confirmation')
        .then(res => {
            this.setState({
                hasError: false,
                errorMessage: res.data.message,
                status: res.status
            });
            let timer = setTimeout( () => {
                window.location = '/logout';
                clearTimeout(timer);
            }, 500)
        })
        .catch( (error) => {
            if(error.response){
                switch(error.response.status){
                    case 401:
                        this.setState({
                            hasError:true,
                            errorMessage:error.response.message,
                            status: error.response.status
                        })
                        break;
                    case 301:
                        this.setState({
                            hasError:true,
                            errorMessage:error.response.message,
                            status: error.response.status
                        })
                        break;
                    case 500:
                        this.setState({
                            hasError: true,
                            errorMessage:i18n.t('signup.step5.errors.500'),
                            status: error.response.status
                        })
                        break;
                    default:
                        this.setState({
                            hasError: true,
                            errorMessage:i18n.t('signup.step5.errors.500'),
                            status: error.response.status
                        })
                        break;
                    
                }
            }
        })
        .finally(() => {
            this.setState({
                loading: false
            })
        });
    }
    render() {
        const loginCount = window.localStorage.getItem('login_count');
        return (
            <LandingStepBase container={
                <div className="container mt-5">
                    <div className="row">
                        <div className="text-white">
                            <h1>{ i18n.t('signup.confirm.title')}</h1>
                            { loginCount > 1 ?
                                <React.Fragment>
                                    <p className="mb-3 mt-3" dangerouslySetInnerHTML={{ __html : i18n.t('signup.confirm.resend_message')}}></p>
                                    <button className="btn btn-accenta"
                                    onClick={this.handleResendBtn} disabled={this.state.loading}> { i18n.t('signup.confirm.resend_email') }</button> 
                                </React.Fragment>
                                : <p className="mb-3 mt-3" dangerouslySetInnerHTML={{ __html : i18n.t('signup.confirm.explaination')}}></p>
                            } 
                            <p className="mt-5" dangerouslySetInnerHTML={{ __html : i18n.t('signup.confirm.junk')}}></p>
                            { loginCount <= 1 ?
                                <a href="/logout" className="btn btn-accenta"> {i18n.t('signup.confirm.logout')}</a>
                                : null
                            }
                        </div>
                    </div>
                    <Errors hasError={this.state.hasError} message={this.state.errorMessage} status={this.state.status}/>
                    <Success hasError={!this.state.hasError && this.state.errorMessage} message={this.state.errorMessage} />
                </div>
            }/>
        );
    }
}

export default withTranslation()(ConfirmSignup);
