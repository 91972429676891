import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import Modal from "react-bootstrap/Modal";
import API from '../api';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../Common/TextValidator';
import { getErrorMessage } from '../Common/Helpers/AppHelper';
import Errors from "../Common/Errors/Errors";
import { Loading_IMG } from '../Common/assets_paths';
class TopAdminModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: props.showTopAdmin,
            top_admin_email: props.top_admin_email,
            new_email: '',
            confirm_new_email: '',
            hasError: false,
            isValidate: false,
            isValid: false,
            errorMessage: [],
            status: null

        }
        ValidatorForm.addValidationRule('isEmailMatch', (value) => {
            if (value.trim() !== this.state.new_email.trim()) {
                return false;
            }
            return true;
        });
    }
    handleSubmit = event => {
        if (this.state.new_email.trim().length == 0 ){
            return;
        }
        if (!this.state.shouldBlockNavigation) {
            return;
        }
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append('email', this.state.new_email.trim());
        API.put('/oldview/settings', formData)
            .then(res => {
                if (res.data.status === "error") {
                    this.setState({
                        isValidate: true,
                        isValid: false,
                        hasError: true,
                        errorMessage: res.data.message,
                        status: null,
                        isLoading: false
                    });
                }
                else {
                    window.location.reload();
                }
            })
            .catch(function (error) {
            })
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };
    hideModal = () => {
        this.setState({isOpen: false})
        if (this.props && this.props.closeModal)
            this.props.closeModal()
    }
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    };
    handleKeyDown = event => {
        if (event.key === " " && event.target.value.trim().length === 0) {
            event.preventDefault();
        }
    };
    render(){
        return(
            <Modal id="administratorModal" show={this.state.isOpen} >
                <div className="modal-content">
                    <ValidatorForm
                        id="administratorSettingsForm"
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={this.handleOnError}
                        instantValidate={false}
                        className=""
                    >
                    {/* <form id="administratorSettingsForm"> */}
                        <div className="modal-header modal-image-header">
                            <div className="modal-header-content">
                                <h4 className="modal-title">{i18n.t('admin.nav_menu.setting.sub_menu.support_email')}</h4>
                            </div>
                            <button type="button" className="close" onClick={this.hideModal}><span className="lh-icon-os-close"></span></button>
                        </div>
                        <div className="modal-body">
                            <div className="middle-sec">
                                <div className="row">
                                    <div className="user-label col-sm-4">
                                        <label htmlFor="current" className="col-form-label">{i18n.t('admin.labels.current')}</label>
                                    </div>
                                    <div className="col-sm-6 pt-3">
                                        <input type="email" disabled name="current" value={this.state.top_admin_email} className="form-control details-info" />
                                    </div>
                                    <div className="user-label col-sm-4">
                                        <label htmlFor="new" className="col-form-label">{i18n.t('admin.labels.new_email')}</label>
                                    </div>
                                    <div className="col-sm-6 pt-3">
                                        <TextValidator
                                            tabIndex='2'
                                            wrapperClass=''
                                            className={"form-control"}
                                            autoComplete="off"
                                            type="email"
                                            name="new_email"
                                            placeholder={i18n.t('admin.placeholder.new_email')}
                                            value={this.state.new_email}
                                            isValidate={this.state.isValidate}
                                            noValidate
                                            validators={['required', 'isEmail']}
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            errorMessages={[
                                                i18n.t("admin.errors.require_new_email"),
                                                i18n.t("admin.errors.invalid_new_email")
                                            ]}
                                            />
                                        {/* <input type="email" name="new" className="form-control details-info" /> */}
                                    </div>
                                    <div className="user-label col-sm-4">
                                        <label htmlFor="confirm" className="col-form-label">{i18n.t('admin.labels.confirm_email')}</label>
                                    </div>
                                    <div className="col-sm-6 pt-3">
                                        <TextValidator
                                            tabIndex='2'
                                            wrapperClass=''
                                            className={"form-control"}
                                            autoComplete="off"
                                            type="email"
                                            name="confirm_new_email"
                                            placeholder={i18n.t('admin.placeholder.confirm_email')}
                                            value={this.state.confirm_new_email}
                                            isValidate={this.state.isValidate}
                                            validators={['required', 'isEmail', 'isEmailMatch']}
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            errorMessages={[
                                                i18n.t("admin.errors.require_confirm_email"),
                                                i18n.t("admin.errors.invalid_confirm_email"),
                                                i18n.t("admin.errors.not_match_confirm_email")
                                            ]}
                                        />
                                        {/* <input type="email" name="confirm" className="form-control details-info" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('userprofile.btn_save')}</React.Fragment>
                                    }</button>
                        </div>
                    {/* </form> */}
                    </ValidatorForm>
                    <Errors
                        hasError={this.state.hasError}
                        type="warning"
                        message={this.state.errorMessage}
                        status={this.state.status}
                    />
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(TopAdminModal)