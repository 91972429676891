import Logo from "../../images/Learnhub-logo.svg";
import AppStore from "../../images/app_store_button.png";
import DownArrow from "../../images/Down-arrow.svg";
import En_Bedge from "../../images/en_badge_web_generic.png";
import Heart from "../../images/Heart.svg";
import HeartFail from "../../images/heart-fail.svg";
import HeartSucess from "../../images/heart-success.svg";
import Mail from "../../images/mail.svg";
import Patterns from "../../images/pattern.svg";
import QR from "../../images/qr-code.svg";
import ToolTip from "../../images/tooltip.svg";
import UserDefaultAvtar from '../../images/user-shape.jpeg';
import Close from '../../images/close.svg';
import Loading from '../../images/loader-onwhite.svg';
import Error_SVG from '../../images/error.svg';
import NotFound_SVG from '../../images/error404.svg';
import Geo_SVG from '../../images/geo.svg';
import Ruler_SVG from '../../images/Ruler.svg';
import PACKAGE_FREE from '../../images/package-free.svg';
import PACKAGE_INDIVIDUAL from '../../images/package-individual.svg';
import PACKAGE_TEAM from '../../images/package-team.svg';
import FAST_TRACK_ICON from '../../images/fasttrack.svg';
import PACK_WELCOME_IMAGE from '../../images/i3LEARNHUB-Packshot-Learnhubflow.png';
import PACK_ACTIVATE_IMAGE from '../../images/i3learnhub-packflow-registrationcard.png';
import PACK_SUB_ICON_IMAGE from '../../images/pack_sub_icon.svg';
import PPDS_LOGO_IMAGE from '../../images/ppds-logo-white.png'
import PPDS_PACK_WELCOME_IMAGE from '../../images/i3LEARNHUB-Packshot-ppds.png'
export const LOGO_IMG = Logo;
export const AppStore_IMG = AppStore;
export const DownArrow_IMG = DownArrow;
export const En_Bedge_IMG = En_Bedge;
export const Heart_IMG = Heart;
export const HeartFail_IMG = HeartFail;
export const HeartSucess_IMG = HeartSucess;
export const Mail_IMG = Mail;
export const Patterns_IMG = Patterns;
export const QR_IMG = QR;
export const ToolTip_IMG = ToolTip;
export const UserDefaultAvtar_IMG = UserDefaultAvtar;
export const Close_IMG = Close;
export const Loading_IMG = Loading;
export const Error_IMG = Error_SVG;
export const NotFound_IMG = NotFound_SVG;
export const Geo_IMG = Geo_SVG;
export const Ruler_IMG = Ruler_SVG;
export const PACK_FREE_IMG = PACKAGE_FREE;
export const PACK_INDI_IMG = PACKAGE_INDIVIDUAL;
export const PACK_TEAM_IMG = PACKAGE_TEAM;
export const FAST_TRACK_ICON_IMG = FAST_TRACK_ICON;
export const PACK_WELCOME_IMG = PACK_WELCOME_IMAGE;
export const PACK_ACTIVATE_IMG = PACK_ACTIVATE_IMAGE;
export const PACK_SUB_ICON_IMG = PACK_SUB_ICON_IMAGE;
export const PPDS_LOGO_IMG = PPDS_LOGO_IMAGE;
export const PPDS_PACK_WELCOME_IMG = PPDS_PACK_WELCOME_IMAGE;