/* React component Created for managing Forgot Password Functionality */
import React from 'react'
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import API from '../api';
import Errors from '../Common/Errors/Errors';
import TextValidator from '../Common/TextValidator';
import { ValidatorForm } from 'react-form-validator-core';
import { getErrorMessage } from '../Common/Helpers/AppHelper';
import { withTranslation } from 'react-i18next';
import { getAvailableLanguages } from '../Common/i3library';
import LanguageSelection from '../LanguageOptions/LanguageSelection';
import { createEvent } from '../Common/AnalyticsEvents';

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);

		const language = this.props.match.params ? this.props.match.params.locale : 'en';
		const languagesOptions = getAvailableLanguages();
		this.state = {
			email: "",
			hasError: false,
			errorMessage: "",
			isValidate: false,
			status: null,
      language: language,
      languageOpt: languagesOptions
		};
		this.emailRef = React.createRef();

	}
	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.name == 'email' ? event.target.value.trim() : event.target.value
		});
	}

	handleOnError = errors => {
		this.setState({
			isValidate: true,
			hasError: true,
			errorMessage: getErrorMessage(errors, true),
			status: null
		});
	}

	handleSubmit = event => {
		const user = {
			email: this.state.email
		};
		const _this = this;
		API.post('password_resets', user)
			.then(res => {
				createEvent("Reset-Password", "Reset password initiated");
				_this.props.history.push({
					pathname: `/en/forgotpassword/confirmemail`,
					state: { email: this.state.email, isForgot: true }
				});
			})
			.catch( (error) => {
				if (error.response) {
					this.emailRef.markInputInvalid();
					if (error.response.status === 404) {
						createEvent("Reset-Password", "Reset password fail due to wrong email address", error.response.data.message);
						_this.setState({
							hasError: true,
							isValidate: true,
							errorMessage: i18n.t('forgotpassword.errors.wrong_email'),
							status: error.response.status
						})
					}
					else {
						createEvent("Reset-Password", "Reset password fail due to user not found", error.response.data.message);
						_this.setState({
							hasError: true,
							isValidate: true,
							errorMessage: error.response.data.message || i18n.t('login.errors.wrong_credential'),
							status: error.response.status
						})
					}

				}
				if (error.request) {
					console.log(error.request);
				}
				else {
					_this.setState({
						hasError: true,
						isValidate: true,
						errorMessage: error.message,
						status: error.status
					})
				}
			})
			.finally(function () {
				// always executed
			});
	}

	render() {
		return (
			<React.Fragment>
				<LandingStepBase container={
					<div className="container">
					<div className="row">
						<div className="col-sm-12 p-0 text-center">
							<div className="headtxt animated fadeInDown mt-5">
								<h1 className="text-white">{i18n.t('forgotpassword.header.title')} </h1>
								<p>{i18n.t('forgotpassword.header.sub_title')}</p>
							</div>
							<div className="main-wrapper">
								<ValidatorForm
									ref="form"
									onSubmit={this.handleSubmit}
									onError={this.handleOnError}
									instantValidate={false}
									className="white-cover"
								>
									<div className="col-sm-12 pb-3 pl-0 pr-0">
										<TextValidator
											ref={ (instance) => this.emailRef = instance }
											className={'form-control form-control-lg'}
											autoComplete="off"
											placeholder={i18n.t('forgotpassword.enter_email_lbl')}
											type="text"
											name="email"
											value={this.state.email}
											onChange={this.handleChange}
											isValidate={this.state.isValidate}
											noValidate
											validators={['required', 'isEmail']}
											errorMessages={[i18n.t('login.errors.email_required'), i18n.t('login.errors.email_invalid')]} />
									</div>
									<button type="submit" className={'btn btn-accenta btn-lg btn-block mt-4'}> {i18n.t('forgotpassword.confirm_btn')}</button>
									<div className="col-sm-12 text-center text-primary" style={{display:"none"}}>
										<div className="text-size-16 mt-4">{i18n.t('forgotpassword.third_party_login_title')}</div>
										<div className="component-oath mt-3">
											<div className="btn-oath btn-office" ><div className="lh-icon-service-office365"></div></div>
											<div className="btn-oath btn-facebook"><div className="lh-icon-service-facebook"></div></div>
											<div className="btn-oath btn-Google"><div className="lh-icon-service-google"></div></div>
											<div className="btn-oath btn-Smartschool"><div className="lh-icon-service-smartschool"></div></div>
										</div>
									</div>
								</ValidatorForm>
								<Errors hasError={this.state.hasError} status={this.state.status} type="warning" message={this.state.errorMessage} />
							</div>
						</div>
						<LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="mobile mt-3" redirectPath={"forgotpassword"+this.props.location.search} />
					</div>
				</div>}>
			</LandingStepBase>
			<LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="desktop" redirectPath={"forgotpassword"+this.props.location.search} />
		</React.Fragment>
		);
	}
}

export default withTranslation()(ForgotPassword);
