import React from "react";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import TextValidator from "../Common/TextValidator";
import Errors from "../Common/Errors/Errors";
import i18n from "../../packs/i18n";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import API from '../api';
import Success from '../Common/Errors/Sucess';
import {createEvent} from '../Common/AnalyticsEvents';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            hasError: false,
            errorMessage: "",
            isValidate: false,
            status:null
        }
        ValidatorForm.addValidationRule('isNewPasswordMatch', (value) => {
            if (value == this.state.currentPassword) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.newPassword) {
                return false;
            }
            return true;
        });
    }
    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.name == 'email' ? event.target.value.trim() : event.target.value
        });
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    }
    handleSubmit = event => {
        const user = {
            user: {
                current_password: this.state.currentPassword,
                password: this.state.newPassword,
                password_confirmation: this.state.confirmNewPassword
            }
        };
        const _this = this;
        API.post('password_resets/change_password', user)
            .then(res => {
                createEvent("Change-Password", "Change password sucess");
                window.location = "/logout";
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        createEvent("Change-Password", "Change password fail due user block or delete", error.response.data.message);
                        _this.setState({
                            hasError: true,
                            errorMessage: error.response.data.message || i18n.t('login.errors.wrong_credential'),
                            status: error.response.status
                        })
                    }
                    else if (error.response.status === 401){
                        createEvent("Change-Password", "Change password fail due to current user credential are same or credential are not match with existing one", error.response.data.message);
                        _this.setState({
                            hasError: true,
                            errorMessage: error.response.data.message || i18n.t('login.errors.wrong_credential'),
                            status: error.response.status
                        })
                    }
                    else{
                        createEvent("Change-Password", "Change password failed due to unknown reason", error.response.data.message);
                        _this.setState({
                            hasError: true,
                            errorMessage: error.response.data.message || i18n.t('login.errors.wrong_credential'),
                            status: error.response.status
                        })
                    }

                }
                if (error.request) {
                    console.log(error.request);
                }
                else {
                    _this.setState({
                        hasError: true,
                        errorMessage: error.message,
                        status: error.status
                    })
                }
            })
    }
    render(){
        return (
            <div className="profile-main-wrapper">
                <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    onError={this.handleOnError}
                    instantValidate={false}
                    className="white-cover-fixheight"
                >
                    <div className="profile-input-box">
                        <div className="row pb-2 mr-0">
                            <TextValidator
                                tabIndex='1'
                                wrapperClass={"col-sm-7 col-md-7 col-lg-7 col-xs-12 pr-0"}
                                className={"form-control form-control"}
                                labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                autoComplete="off"
                                placeholder={i18n.t("userprofile.changepassword.placeholder.current_password")}
                                label={i18n.t("userprofile.changepassword.labels.current_password")}
                                type="password"
                                name="currentPassword"
                                value={this.state.currentPassword}
                                onChange={this.handleChange}
                                isValidate={this.state.isValidate}
                                noValidate
                                validators={['required']}
                                errorMessages={[i18n.t('userprofile.changepassword.errors.required.current_password')]} />
                        </div>
                        <div className="row pb-2 mr-0">
                            <TextValidator
                                tabIndex='2'
                                wrapperClass={"col-sm-7 col-md-7 col-lg-7 col-xs-12 pr-0"}
                                className={"form-control form-control"}
                                labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                autoComplete="off"
                                placeholder={i18n.t("userprofile.changepassword.placeholder.new_password")}
                                label={i18n.t("userprofile.changepassword.labels.new_password")}
                                type="password"
                                name="newPassword"
                                value={this.state.newPassword}
                                onChange={this.handleChange}
                                isValidate={this.state.isValidate}
                                noValidate
                                validators={['required', 'minStringLength:8','isNewPasswordMatch']}
                                errorMessages={[i18n.t('userprofile.changepassword.errors.required.new_password'), i18n.t('userprofile.changepassword.errors.minlength.new_password'),i18n.t('userprofile.changepassword.errors.match_password')]} />
                        </div>
                        <div className="row pb-2 mr-0">
                            <TextValidator
                                tabIndex='3'
                                wrapperClass={"col-sm-7 col-md-7 col-lg-7 col-xs-12 pr-0"}
                                className={"form-control form-control"}
                                labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                autoComplete="off"
                                placeholder={i18n.t("userprofile.changepassword.placeholder.confirm_new_password")}
                                label={i18n.t("userprofile.changepassword.labels.confirm_new_password")}
                                type="password"
                                name="confirmNewPassword"
                                value={this.state.confirmNewPassword}
                                onChange={this.handleChange}
                                isValidate={this.state.isValidate}
                                noValidate
                                validators={['required', 'minStringLength:8','isPasswordMatch']}
                                errorMessages={[i18n.t('userprofile.changepassword.errors.required.confirm_new_password'), i18n.t('userprofile.changepassword.errors.minlength.confirm_new_password'),i18n.t('userprofile.changepassword.errors.match_confirm_password')]} />
                        </div>
                        <div className="row justify-content-end mt-4 profile-save-btn">
                            <a href="/"
                                className={"btn btn-outline-primary mb-2 p-2 pl-5 pr-5 mr-2"}
                            >
                                {i18n.t("userprofile.btn_cancel")}
                            </a>

                            <button
                                type="submit"
                                className={"btn btn-accenta p-2 pl-5 pr-5 mb-2"}
                                disabled={ this.state.isLoading }
                            >
                                { this.state.isLoading ?
                                 <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                 :
                                 i18n.t("userprofile.changepassword.btn_change_password")
                                }
                            </button>
                        </div>
                    </div>
                </ValidatorForm>
            
                <Errors
                    hasError={this.state.hasError}
                    type="warning"
                    message={this.state.errorMessage}
                    status={this.state.status}
                />
                <Success hasError={this.state.isValid} type='warning' message={this.state.errorMessage}/>
            </div>
        )
    }
}

export default ChangePassword;