import React from 'react'
import i18n from '../../packs/i18n';
import API from '../api';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import { getAvailableLanguages } from '../Common/i3library';
import { withTranslation } from 'react-i18next';
import LanguageSelection from '../LanguageOptions/LanguageSelection';

class Tos extends React.Component {

  constructor(props) {
    super(props);

    const language = props.user_language || 'en';
    const languagesOptions = getAvailableLanguages();
    this.state = {
      content: null,
      language: language,
      languageOpt: languagesOptions,
      url_locale: props.match.params.locale
    };
  }

  componentDidMount() {
    const _this = this;
    console.log(this.state.url_locale)
    API.get(`/privacy_policy/tos?locale=${this.state.url_locale}`)
    .then(res => {
      _this.setState({
        content: res.data
      });
    })
    .catch(function (error) {
    })
    .finally(function () {
    });
  }

  disableClick(disable) {
    this.setState({ disableClick: disable });
    return true;
  }
  render() {
    return (
      <React.Fragment>
        <LandingStepBase container={
          <div className="container">
            <div className="row" style={{pointerEvents: this.state.disableClick ? 'none' : 'all'}}>
              <div className="col-sm-12 p-0 text-center">
                <div className="headtxt animated fadeInDown mt-5">
                  <h1 className={'text-white'}>{i18n.t('tos.heading')} </h1>
                </div>
                <div className="tos-overflow" data-simplebar dangerouslySetInnerHTML={{__html: this.state.content}} />
              </div>
              <LanguageSelection language={this.state.url_locale} languageOpt={this.state.languageOpt} className="mobile mt-3" redirectPath="tos" />
            </div>
          </div>}>
        </LandingStepBase>
        <LanguageSelection language={this.state.url_locale} languageOpt={this.state.languageOpt} className="desktop lang-top-right" redirectPath="tos" />
      </React.Fragment>
    )
  }
}

export default withTranslation()(Tos);
