import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import Modal from "react-bootstrap/Modal";
import API from '../api';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../Common/TextValidator';
import { getErrorMessage } from '../Common/Helpers/AppHelper';
import Errors from "../Common/Errors/Errors";
import { Loading_IMG } from '../Common/assets_paths';
import SelectValidator from "../Common/SelectValidator";
class DataTransferModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: props.showTopAdmin,
            hasError: false,
            isValidate: false,
            isValid: false,
            errorMessage: [],
            status: null,
            target_groups: [],
            selected_from_institute: null,
            selected_to_institute: null

        }
        ValidatorForm.addValidationRule('isInstitutionMatch', (value) => {
            if (value.trim() !== this.state.selected_from_institute.trim()) {
                return false;
            }
            return true;
        });
    }
    async componentDidMount() {
        await this.callApi()
    }
    async callApi() {
        let { data } = await API.get('/groups/get_institutions')
        data.groups.map((target_group) => {
            target_group["value"] = target_group.id
            let path = ''
            for(var i = target_group.breadcrumb.length -1 ; i >= 1; i--){
              if(i > 1){
                path += target_group.breadcrumb[i].name + " -> "
              }
              else{
                path += target_group.breadcrumb[i].name
              }
            }
            target_group["label"] = target_group.name + "\n [ "+path+" ] "
          })
          this.setState({ target_groups: data.groups})
    }
    handleSubmit = event => {
        if (!this.state.selected_from_institute){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: "From Institute Required",
                status: null,
                isLoading: false
            });
            return;
        }
        if(!this.state.selected_to_institute){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: "To Institute Required",
                status: null,
                isLoading: false
            });
            return;
        }
        const formData = new FormData();
        formData.append('source_id', this.state.selected_from_institute);
        formData.append('destination_id', this.state.selected_to_institute);
        API.post('/groups/move_data', formData)
            .then(res => {
                if (res.data.status === "error") {
                    this.setState({
                        isValidate: true,
                        isValid: false,
                        hasError: true,
                        errorMessage: res.data.message,
                        status: null,
                        isLoading: false
                    });
                }
                else {
                    window.location.reload();
                }
            })
            .catch( (error) => {
                this.setState({
                    isValidate: true,
                    isValid: false,
                    hasError: true,
                    errorMessage: error.response.data.message,
                    status: null,
                    isLoading: false
                });
            })
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };
    hideModal = () => {
        this.setState({isOpen: false})
        if (this.props && this.props.closeModal)
            this.props.closeModal()
    }
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    };
    handleKeyDown = event => {
        if (event.key === " " && event.target.value.trim().length === 0) {
            event.preventDefault();
        }
    };
    handleChangeFromInstituteSelect = event => {
        this.setState({
          "selected_from_institute": event.id
        });
    }
    handleChangeToInstituteSelect = event => {
        this.setState({
          "selected_to_institute": event.id
        });
    }
    render(){
        return(
            <Modal id="administratorModal" show={this.state.isOpen} >
                <div className="modal-content">
                    <ValidatorForm
                        id="administratorSettingsForm"
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={this.handleOnError}
                        instantValidate={false}
                        className=""
                    >
                    {/* <form id="administratorSettingsForm"> */}
                        <div className="modal-header modal-image-header">
                            <div className="modal-header-content">
                                <h4 className="modal-title">{i18n.t('admin.nav_menu.setting.sub_menu.data_transfer_group')}</h4>
                            </div>
                            <button type="button" className="close" onClick={this.hideModal}><span className="lh-icon-os-close"></span></button>
                        </div>
                        <div className="modal-body">
                            <div className="middle-sec">
                                <div className="row">
                                    <div className="user-label text-primary col-sm-12 pr-5 pl-5"> 
                                        <p>{i18n.t('admin.nav_menu.setting.data_transfer_modal.message')}</p>
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="user-label col-sm-4">
                                        <label htmlFor="new" className="col-form-label">{i18n.t('admin.nav_menu.setting.data_transfer_modal.from_text')}</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <SelectValidator
                                            tabIndex='1'
                                            autoComplete="off"
                                            placeholder={i18n.t('admin.placeholder.from_institute')}
                                            name="selected_from_institute"
                                            options={this.state.target_groups}
                                            onChange={this.handleChangeFromInstituteSelect}
                                            isSearchable={true}
                                            isValidate={this.state.isValidate}
                                        /> 
                                    </div>
                                    <div className="user-label col-sm-4 pt-3">
                                        <label htmlFor="confirm" className="col-form-label">{i18n.t('admin.nav_menu.setting.data_transfer_modal.to_text')}</label>
                                    </div>
                                    <div className="col-sm-6 pt-3">
                                        <SelectValidator
                                            tabIndex='2'
                                            autoComplete="off"
                                            placeholder={i18n.t('admin.placeholder.to_institute')}
                                            name="selected_to_institute"
                                            options={this.state.target_groups}
                                            onChange={this.handleChangeToInstituteSelect}
                                            isSearchable={true}
                                            isValidate={this.state.isValidate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('admin.nav_menu.setting.data_transfer_modal.btn_move')}</React.Fragment>
                                    }</button>
                        </div>
                    {/* </form> */}
                    </ValidatorForm>
                    <Errors
                        hasError={this.state.hasError}
                        type="warning"
                        message={this.state.errorMessage}
                        status={this.state.status}
                    />
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(DataTransferModal)