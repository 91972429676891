import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import {PACK_ACTIVATE_IMG} from '../Common/assets_paths'
import { withTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-form-validator-core';
import { getErrorMessage } from '../Common/Helpers/AppHelper';
import TextValidator from '../Common/TextValidator';
import Errors from '../Common/Errors/Errors';
import { Link } from 'react-router-dom';
import API from '../api';
import {createEvent} from '../Common/AnalyticsEvents'
import { getLocalUrl, setIsUserInfoReload, getTeamPackPrefixArray } from '../Common/i3library';

class PackKeyActivate extends React.Component {
    constructor(props) {
        super(props);
        const pack_key = this.props.match.params ? this.props.match.params.pack_key : null;
        const user_token = this.props.match.params ? this.props.match.params.token_id : null;
        const language = this.props.match.params ? this.props.match.params.locale : 'en';
        this.state = {
            license_key: pack_key || "",
            token_id: user_token || "",
            language: language,
            hasError:false,
            errorMessage: "",
            isValidate: false,
            status: null,
            institute_name: "",
            hasPackTypeSchool: pack_key && pack_key != "" && getTeamPackPrefixArray() ? ( getTeamPackPrefixArray().includes(pack_key.trim().toLowerCase().split('-')[0])
            ? true : false ): false,
            disable_submit_btn: false,
            disable_license_key: false //pack_key && pack_key != "" ? true : false
        }
    }
    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.name == "license_key" ? event.target.value.trim() : event.target.value
        });
        if(event.target.name === "license_key"){
            let pack_prefix = event.target.value.trim().toLowerCase().split('-')[0];
            this.setState({hasPackTypeSchool : getTeamPackPrefixArray() && getTeamPackPrefixArray().includes(pack_prefix) ? true : false })
        }
        
    }
    handleSubmit = event => {
        const serial_data = {
            serial_number : this.state.license_key,
            p_token: this.state.token_id
        };
        const _this = this;
        let is_api_error = false;
        let api_error_msg = null;
        API.post('/packsignup/authenticate', serial_data)
        .then(res => {
            if(res.data.error){
                is_api_error = true;
                switch(res.data.error){
                    case "invalid_license_for_product":
                        api_error_msg = i18n.t('packsignup.step4.errors.invalid_license_for_product');
                    break;
                    case "invalid_license_key":
                        api_error_msg = i18n.t('packsignup.step4.errors.invalid_license_key');
                    break;
                    case "invalid_license_date_until":
                        api_error_msg = i18n.t('packsignup.step4.errors.invalid_license_date_until');
                    break;
                    case "invalid_client" :
                        api_error_msg = i18n.t('packsignup.step4.errors.invalid_client');
                    break;
                    case "invalid_scope" :
                        api_error_msg = i18n.t('packsignup.step4.errors.invalid_scope');
                    break;
                    case "unsupported_grant_type":
                        api_error_msg = i18n.t('packsignup.step4.errors.unsupported_grant_type');
                    break;
                    case "invalid_license_max_sessions":
                        api_error_msg = i18n.t('packsignup.step4.errors.invalid_license_max_sessions');
                    break;
                }
                if(!api_error_msg){
                    api_error_msg = i18n.t('packsignup.step4.errors.server_error');
                }
                _this.setState({
                    hasError: true,
                    errorMessage: api_error_msg,
                    status: 401
                })
            }
            else{
                _this.setState({
                    hasError: false,
                    errorMessage: "",
                    status: null
                })
                this.processPayment(res.data)

            }

        }).catch(function (error){
            if (error && error.response && error.response.status && error.response.status == 401){
                api_error_msg = i18n.t('packsignup.step4.errors.key_not_match');
            }
            else
            {
                api_error_msg = i18n.t('packsignup.step4.errors.server_error');
            }

            _this.setState({
                hasError: true,
                errorMessage: api_error_msg,
                status: 401
            })
        })
    }
    processPayment(license_data){
        const payment = {
            persistence_token: license_data.persistence_token,
            subscription: {
                plan: license_data.subscription_plan,
                persistence_token: this.props.match.params.token_id,
                action: 'packsignup',
                pack_type: license_data.pack_type,
                pack_key: license_data.pack_key
            }
            
        }
        payment.subscription.voucher = null;
        payment.payment_method_type = 'wire_transfer';
        payment.subscription.payment_cycle = 'yearly';
        payment.team_name = this.state.institute_name;
        payment.to_be_process = false;
        payment.number_of_years = license_data.subscription_for_year;
        payment.is_pack_signup = true
    

        API.post('react_landing/process_payment', payment)
            .then(res => {
                const { state } = this.props.location;
                setIsUserInfoReload(true);
                let sendEventmsg =  payment.subscription.payment_cycle;
                sendEventmsg+="__"+payment.subscription.plan;
                sendEventmsg+= "__"+payment.payment_method_type;
                createEvent("PackSignup", "Payment success", sendEventmsg);
                this.props.history.push({
                    pathname: getLocalUrl(this.props.match.params.locale, 'packsignup/success'),
                    state: { success: true, number_of_years: payment.number_of_years, plan: payment.subscription.plan}
                });
            })
            .catch((error) => {
                createEvent("PackSignup", "Payment fail", error.response.data.message);
                this.props.history.push({
                    pathname: getLocalUrl(this.props.match.params.locale, 'packsignup/fail'),
                    state: { success: false }
                });
                this.setState({ loading: false, license_data: {number_of_years: payment.number_of_years, } });
            })
            .finally( () => {

            });

    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    }
    render() {

        return (
            <LandingStepBase pack_signup={true} container={
                <div className="container">
                <div className="row">
                    <div className="col-sm-12 p-0 text-center">
                        <div className="headtxt mt-5 mb-5">
                            <h1 className="text-white mb-0">{i18n.t('packsignup.step4.header.title')} </h1>
                            <p>{i18n.t('packsignup.step4.header.sub_title')}</p>
                            <div className="steps">
                                <span className="completed step mr-2 ml-2"></span><span className=" completed step  mr-2 ml-2"></span><span className="completed step  mr-2 ml-2"></span><span className="activestep step mr-2 ml-2"></span><span className="step mr-2 ml-2"></span>
                            </div>
                            <p className="mt-2 text-quaternary text-size-20">{i18n.t('packsignup.step4.header.current_step')}</p>
                        </div>
                        <div className="main-wrapper text-center">
                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className="white-cover text-center"
                            >
                                <p className = "mt-2 text-secondary">{i18n.t('packsignup.step4.text_check_key')}</p>
                                <img src={PACK_ACTIVATE_IMG} />
                                <p className="mt-1 text-secondary">{i18n.t('packsignup.step4.text_do_you_key')}</p>
                                <div className="col-sm-12 pb-2 pl-0 pr-0">
                                    <TextValidator
                                                className={'form-control form-control-lg'}
                                                autoComplete="off"
                                                placeholder=""
                                                type="text"
                                                name="license_key"
                                                disabled = {this.state.disable_license_key}
                                                value={this.state.license_key || ""}
                                                onChange={this.handleChange}
                                                isValidate={this.state.isValidate}
                                                noValidate
                                                validators={['required', "minStringLength:10", "maxStringLength:18"]}
                                                errorMessages={[i18n.t('packsignup.step4.errors.license_key_required'), i18n.t('packsignup.step4.errors.license_key_min_length'), i18n.t('packsignup.step4.errors.license_key_max_length')]} />
                                </div>                                            
                                
                                { this.state.hasPackTypeSchool ? 
                                     <div className="col-sm-12 pb-2 pl-0 pr-0">
                                        <TextValidator
                                            className={'mt-3 form-control form-control-lg'}
                                            autoComplete="off"
                                            placeholder="Enter institute name"
                                            type="text"
                                            name="institute_name"
                                            value={this.state.institute_name || ""}
                                            onChange={this.handleChange}
                                            isValidate={this.state.isValidate}
                                            noValidate
                                            validators={["required", "minStringLength:3", "maxStringLength:50"]}
                                            errorMessages={[
                                                i18n.t("signup.step4.errors.team_name_required"),
                                                i18n.t("packsignup.step4.errors.team_name_min_length"),
                                                i18n.t("signup.step4.errors.team_name_max")]} 
                                        /> 
                                    </div>
                                    : null 
                                }      
                               <button disabled={this.state.disable_submit_btn} type="submit" className={'btn btn-accenta btn-lg btn-block mt-4 mb-3'}> {i18n.t('packsignup.step4.btn_activate')}</button>
                               <Link to= {"/"+this.state.language+"/signup/selectpackage/"+this.state.token_id} className = "mt-2" style={{fontSize: "70%"}}>{i18n.t('packsignup.step4.link_redirect_signup')}</Link>
                            </ValidatorForm>
                            <Errors hasError={this.state.hasError} status={this.state.status} type="warning" message={this.state.errorMessage}/>
                        </div>
                    </div>
                </div>
                </div>}></LandingStepBase>
        )
    }

}

export default withTranslation()(PackKeyActivate);