import React from "react";

import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import Errors from "../Common/Errors/Errors";
import countryList from 'react-select-country-list';
import { getAvailableLanguages } from "../Common/i3library";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import SelectValidator from "../Common/SelectValidator";
import TextValidator from "../Common/TextValidator";
import TextareaValidator from "../Common/TextareaValidator";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import Modal from "react-bootstrap/Modal";
import { EventEmitter } from "../Common/Events";
import moment from 'moment/min/moment-with-locales';
import Invitations from "./Invitations";
class Reseller extends React.Component {
    constructor(props) {
        super(props);
        const countryOptions = countryList().getData();
        //ILEARN-4927 Tiwan Name Update 
        let taiwan_item = countryOptions.find(item => item.value == 'TW');
        taiwan_item.label = i18n.t("userprofile.labels.tiwan_label");
        let tiwan_index = countryOptions.findIndex(item => item.value == 'TW');
        countryOptions[tiwan_index] = taiwan_item;
        //ILEARN-4927 Tiwan Name Update End
        const languagesOptions = getAvailableLanguages();

        let path_array = props.location.pathname.split("/distributor/view/")
        this.state = {
            toggleResellerFilter: false,
            current_status: 'all',
            previous_status: 'all',
            per_page: 10,
            current_page: 1,
            distributor_id: path_array[path_array.length - 1],
            type: 'reseller',
            data: [],
            total_count: 0,
            email: '',
            first_name: '',
            last_name: '',
            company: '',
            countryOpt: countryOptions,
            languageOpt: languagesOptions,
            country: '',
            language: '',
            isEditOpen: false,
            edit_name: '',
            edit_address: '',
            current_edit_id: '',
            invitations: [],
            current_change_parent_edit_id: '',
            isChangeParentOpen: false,
            selected_target_group: '',
            selected_target_groups: [],
            isLoading: false,
            searchTerm: '',
            fromDate: '',
            toDate: '',
            current_date: new Date(),
            isTopAdmin: false,
            breadcrumb: [],
            orderBy: ''
        }
        EventEmitter.subscribe('globalSearchTrigger', (event) => { this.handleGlobalSearch(event) })
    }
    handleGlobalSearch = (val) => {
        this.setState({ searchTerm: val })
        this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, null, val)
    }
    resellerAction = (cell, row) => {
        let resller_id = row.id
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                {/* <NavDropdown.Item data-bs-toggle="modal" data-bs-target="#editReseller">Edit</NavDropdown.Item> */}
                <NavDropdown.Item href={void (0)} data-id={resller_id} onClick={this.editReseller}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
                <NavDropdown.Item href={void (0)} data-id={resller_id} onClick={this.deleteReseller}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
                {this.state.isTopAdmin ?
                    <NavDropdown.Item href={void (0)} data-id={resller_id} onClick={this.changeParent}>{i18n.t('admin.labels.change_parent')}</NavDropdown.Item> : null
                }
            </NavDropdown>
        );
    }
    changeParent = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = this.state.data.find((element) => {
            return element.id == id
        })
        userInfo.target_groups.map((target_group) => {
            target_group["value"] = target_group.id
            target_group["label"] = target_group.name
        })
        this.setState({ selected_target_groups: userInfo.target_groups, current_change_parent_edit_id: userInfo.id })
        this.showChangeParentModal();
    }
    showChangeParentModal = () => {
        this.setState({ isChangeParentOpen: true })
    }
    hideChangeParentModal = () => {
        this.setState({ isChangeParentOpen: false })
    }
    editReseller = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = this.state.data.find((element) => {
            return element.id == id
        })
        this.setState({ edit_name: userInfo.name, edit_address: userInfo.address, current_edit_id: userInfo.id })
        this.showEditModal()
    }
    showEditModal = () => {
        this.setState({ isEditOpen: true, isValidate: false,  isValid: true, hasError: false,errorMessage: [], status: null  })
    }
    hideEditModal = () => {
        this.setState({ isEditOpen: false })
    }
    deleteReseller = (event) => {
        let text = i18n.t('admin.distributor.confirmation_delete')
        const payload = { ids: [event.target.getAttribute('data-id')] };
        let _this = this;
        if (confirm(text) == true) {
            API.delete('/oldview/groups/delete', { data: payload })
                .then(res => {
                    if (res.data.status === "failed") {
                        alert(res.data.errors.join(","))
                    }
                    else {
                        window.location.reload();
                    }
                })
                .catch(function (error) {

                })

        }
        else {
            console.log("User canceled")
        }
    }
    async componentDidMount() {
        await this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, null, this.state.searchTerm)
    }

    async callApi(per_page, current_page, current_status, htmlFormat = null, searchTerm = '') {
        if (htmlFormat != 'csv')
            this.setState({ data: [], isLoading: true });



        let { data } = await API.get('/groups?group=' + this.state.distributor_id + '&status=' + current_status + '&per_page=' + per_page + '&current_page=' + current_page + '&type=' + this.state.type + '&format=' + htmlFormat + '&searchTerm=' + encodeURIComponent(searchTerm) + '&fromDate=' + this.state.fromDate + '&toDate=' + this.state.toDate + '&order_by=' + this.state.orderBy)

        if (!data.isTopAdmin) {
            document.body.classList.add('no-topadmin')
        }
        else {
            document.body.classList.remove('no-topadmin')
        }

        if (htmlFormat === 'csv') {
            let a = document.createElement('a');
            a.href = 'data:text/csv,' + encodeURI(data);
            a.target = '_blank';
            a.download = 'i3Learnhub_resellers.csv';
            a.click();
            history.pushState({}, null, window.location.href);
            document.getElementById("export_res_mdl_cls").click();   
            this.hideExportModal(); 
            return
        }

        this.setState({ data: data.groups, invitations: data.invitations, total_count: data.total_count, isLoading: false, isTopAdmin: data.isTopAdmin || false, breadcrumb: data.institution.breadcrumb ? data.institution.breadcrumb.reverse() : [] })
    }
    closeSideModal = () => {
        this.setState({ toggleResellerFilter: false });
    }
    toggleSideModal = () => {
        this.setState({ toggleResellerFilter: !this.state.toggleResellerFilter });
    }

    routeBackToResellers() {
        window.location.href = '/admin/distributors';
    }
    onTableChange = (type, newState) => {
        if(newState.sortOrder && newState.sortField){
            this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
        }
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi(newState.sizePerPage, newState.page, this.state.current_status, null, this.state.searchTerm)
    }
    handleChange = event => {
        if (event.target.name === "fromDate") {
            this.setState({ toDate: '' })
        }
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleChangeParentSelect = event => {
        this.setState({
          "selected_target_group": event.id
        });
    }
    handleSubmit = (event) => {
        if (this.state.email.trim().length == 0 || this.state.first_name.trim().length == 0 || this.state.last_name.trim().length == 0 || this.state.company.trim().length == 0) {
            return
        }
        this.setState({ isLoading: true });
        this.inviteNewReseller()
    }
    inviteNewReseller = () => {
        const formData = new FormData();
        const { isLangChange } = this.state;
        formData.append('invitation[account_type]', this.state.type);
        formData.append('invitation[email]', this.state.email);
        formData.append('invitation[first_name]', this.state.first_name);
        formData.append('invitation[last_name]', this.state.last_name);
        formData.append('invitation[language]', this.state.language);
        formData.append('invitation[country]', this.state.country);
        formData.append('invitation[institution]', this.state.company);
        formData.append('invitation[group_id]', this.state.distributor_id)
        API.post('/invitations', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }
    handleEditSubmit = (event) => {
        this.setState({ isLoading: true });
        this.submitEditReseller()
    }
    handleChangeParentSubmit = (event) => {
        this.setState({ isLoading: true });
        this.submitChangePrent()
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };
    submitEditReseller = () => {
        const formData = new FormData();
        formData.append('ids[]', this.state.current_edit_id)
        formData.append('name', this.state.edit_name);
        formData.append('address', this.state.edit_address);
        API.patch('/oldview/groups/update', formData)
            .then(res => {
                window.location.reload();

            })
            .catch(function (error) {

            })
    }
    submitChangePrent = () => {
        const formData = new FormData();
        formData.append('ids[]', this.state.current_change_parent_edit_id);
        formData.append('target', this.state.selected_target_group);
        API.patch('/oldview/groups/move', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {

            })
    }
    downloadResellerData = (event) => {
        this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, 'csv', this.state.searchTerm)
    }
    hideExportModal = (event) => {
        this.setState({fromDate: '', toDate: ''})
    }
    handleKeyDown = event => {
        if (event.key === " " && event.target.value.trim().length === 0) {
            event.preventDefault();
        }
    };
    render() {
        const { toggleResellerFilter } = this.state;
        const resellerFields = [{
            dataField: 'name',
            text: i18n.t('admin.labels.name').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        }, {
            dataField: 'email',
            text: i18n.t('userprofile.labels.email').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        }, {
            dataField: 'country',
            text: i18n.t('userprofile.labels.country').toUpperCase(),
            sort: true
        }, {
            dataField: 'total_children',
            text: i18n.t('admin.labels.team_account').toUpperCase(),
            sort: false
        }, {
            dataField: 'total_individual',
            text: i18n.t('admin.labels.individual_account').toUpperCase(),
            sort: false
        }, {
            dataField: 'moreOptions',
            formatter: this.resellerAction,
        }];
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        const options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                let currentCellIndex = e.target.cellIndex;
                // let lastCellIndex = document.querySelector('table tr:last-child td:last-child').cellIndex

                // if (currentCellIndex !== lastCellIndex && currentCellIndex !== undefined) {
                //     window.location.href = '/admin/reseller/view/' + row.id
                // }
                if(currentCellIndex == 0){
                    window.location.href = '/admin/reseller/view/' + row.id
                }
                if(currentCellIndex == 1){
                    if(row.get_user_id)
                        window.location.href = '/admin/user/view/' + row.get_user_id
                }
            }
        }
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        };

        return (
            <div className="overflow-hidden h-100">
                {/* <div className="startscreen h-100"> */}
                <div className="row p-3">
                    {this.state.isTopAdmin ?
                        <div className="col-md-12">
                            <div className="col-md-12 p-0">
                                <div className="col-md-4 p-0">
                                    <div className="view-user-back p-0">
                                        <span className="back-icon lh-icon-os-back" onClick={this.routeBackToResellers}></span>&nbsp;
                                        <p>
                                            {
                                                this.state.breadcrumb.map((path, index) => (
                                                    <span>
                                                        <a href={path.path}>{path.name}
                                                        </a>
                                                        {index != (this.state.breadcrumb.length - 1) ? <span> &gt; </span> : null

                                                        }
                                                    </span>
                                                ))
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                    }
                    <div className="col-md-12 mt-2">
                        <button data-bs-toggle="modal" data-bs-target="#addReseller" className="btn btn-primary mb-3 mt-1">{i18n.t('admin.reseller.btn_add')}</button>
                    </div>
                </div>
                <div className="tab-bar">
                    <input id="tab1" type="radio" name="tab-selector" defaultChecked="checked" />
                    <input id="tab2" type="radio" name="tab-selector" />
                    <nav>
                        <ul className="tab-divider">
                            <li className="tab-label-1">
                                <label htmlFor="tab1"><span className="">{i18n.t('admin.nav_menu.invitation.sub_menu.reseller')}</span></label>
                            </li>
                            <li className="tab-label-2">
                                <label htmlFor="tab2"><span className=""></span>{i18n.t('admin.nav_menu.invitation.title')}</label>
                            </li>
                        </ul>
                    </nav>
                    <section>
                        <div className="tab-content tab-1 extra-header width-tb-100">
                            <div className="mb-3" style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
                                <span data-bs-toggle="modal" data-bs-target="#exportModal" className="lh-icon-os-export filter-icon-users mr-4 mt-2"></span>
                            </div>
                            <BootstrapTable
                                keyField='id'
                                options={options}
                                data={this.state.data}
                                fetchInfo={{ dataTotalSize: this.state.total_count }}
                                columns={resellerFields}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                remote
                                noDataIndication={() => <NoDataIndication />}
                                pagination={paginationFactory(paginatorOptions)}
                                onTableChange={this.onTableChange}
                                rowEvents={rowEvents}
                            />
                        </div>

                        <div className="tab-content tab-2 extra-header width-tb-100" style={{ padding: 0 }}>
                            <Invitations groupId={this.state.distributor_id} />
                        </div>
                    </section>
                    {/* </div> */}
                </div>

                {/* Add Reseller Modal */}
                <div className="modal fade" id="addReseller" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            {/* <form id="addResellerForm"> */}
                            <ValidatorForm
                                id="addResellerForm"
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.reseller.btn_add')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('userprofile.labels.email')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    type="email"
                                                    name="email"
                                                    placeholder={i18n.t('userprofile.placeholder.email')}
                                                    value={this.state.email}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.email_required"),
                                                        i18n.t("userprofile.errors.email_invalid")
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4 pt-2">
                                                <span>{i18n.t("userprofile.labels.first_name")}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control "}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("userprofile.placeholder.first_name")}
                                                    type="text"
                                                    name="first_name"
                                                    value={this.state.first_name}
                                                    onChange={this.handleChange}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    required
                                                    validators={["required", "maxStringLength:40"]}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.first_name_required"),
                                                        i18n.t('login.errors.first_name_max_length')
                                                    ]}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4 pt-2">
                                                <span>{i18n.t("userprofile.labels.last_name")}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <TextValidator
                                                    tabIndex='3'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("userprofile.placeholder.last_name")}
                                                    type="text"
                                                    name="last_name"
                                                    value={this.state.last_name}
                                                    onChange={this.handleChange}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    required
                                                    validators={["required", "maxStringLength:40"]}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.last_name_required"),
                                                        i18n.t('login.errors.last_name_max_length')
                                                    ]}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4 pt-2">
                                                <span>{i18n.t("admin.labels.company")}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <TextValidator
                                                    tabIndex='4'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="company"
                                                    placeholder={i18n.t("admin.placeholder.company")}
                                                    value={this.state.company}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.company_name_required")
                                                    ]}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4 pt-2">
                                                <span>{i18n.t("userprofile.labels.country")}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2 pt-2">
                                                <SelectValidator
                                                    tabIndex='5'
                                                    className={"form-control"}
                                                    labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("userprofile.placeholder.country")}
                                                    name="country"
                                                    options={this.state.countryOpt}
                                                    value={this.state.country}
                                                    onChange={this.handleChange}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.country_required")
                                                    ]}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4 pt-2">
                                                <span>{i18n.t("userprofile.labels.language")}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <SelectValidator
                                                    tabIndex='6'
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("userprofile.placeholder.language")}
                                                    name="language"
                                                    options={this.state.languageOpt}
                                                    value={this.state.language}
                                                    onChange={this.handleChange}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.language_required")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                     <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal">{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('admin.labels.invite')}</React.Fragment>
                                    }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                            {/* </form> */}
                        </div>
                    </div>
                </div>

                {/* Edit Reseller Modal */}
                <div>
                    <Modal show={this.state.isEditOpen}>
                        {/* <div className="modal-dialog modal-lg"> */}
                        <div className="modal-content">
                            {/* <form id="editResellerForm"> */}
                            <ValidatorForm
                                id="editResellerForm"
                                ref="form"
                                onSubmit={this.handleEditSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.reseller.modal_edit.title')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal" onClick={this.hideEditModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.name')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_name"
                                                    placeholder={i18n.t("admin.placeholder.name")}
                                                    value={this.state.edit_name}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.company_name_required")
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t("userprofile.labels.address")}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <TextareaValidator
                                                        tabIndex='4'
                                                        wrapperClass=''
                                                        className={"form-control mt-2 mb-4"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t("userprofile.placeholder.address")}
                                                        name="edit_address"
                                                        value={this.state.edit_address}
                                                        noValidate
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideEditModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta">{i18n.t('admin.btn_ok')}</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                        {/* </div> */}
                    </Modal>
                </div>

                {/* Change Parent */}
                <div>
                    <Modal show={this.state.isChangeParentOpen}>
                        {/* <div className="modal-dialog modal-lg"> */}
                        <div className="modal-content">
                            {/* <form id="changeResellerParentForm"> */}
                            <ValidatorForm
                                id="changeResellerParentForm"
                                ref="form"
                                onSubmit={this.handleChangeParentSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.change_parent_modal.title')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideChangeParentModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="col-sm-12 pt-3">
                                                <SelectValidator
                                                    tabIndex='5'
                                                    autoComplete="off"
                                                    placeholder={i18n.t('admin.placeholder.move_parent')}
                                                    name="selected_target_group"
                                                    options={this.state.selected_target_groups}
                                                    onChange={this.handleChangeParentSelect}
                                                    isValidate={this.state.isValidate}
                                                    required
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_parent')
                                                    ]}
                                                    isSearchable={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideChangeParentModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>
                                        {this.state.isLoading ?
                                            <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                            :
                                            <React.Fragment>{i18n.t('admin.btn_ok')}</React.Fragment>
                                        }
                                    </button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                        {/* </div> */}
                    </Modal>
                </div>

                {/* Export Modal */}
                <div className="modal fade" id="exportModal" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="exportModalForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.export_modal.title')}</h4>
                                    </div>
                                    <button id="export_res_mdl_cls" type="button" className="close" data-bs-dismiss="modal" onClick={this.hideExportModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="page-header export-header">
                                        <span className="text-primary">{i18n.t('admin.export_modal.message')}</span>
                                    </div>
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.from')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <input value={moment(this.state.fromDate).format("YYYY-MM-DD")} name="fromDate" type="date" className="form-control mt-2 mb-4" onChange={this.handleChange} max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.to')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <input value={this.state.toDate ? moment(this.state.toDate).format("YYYY-MM-DD") : this.state.toDate} min={moment(this.state.fromDate).format("YYYY-MM-DD")} onChange={this.handleChange} name="toDate" type="date" className="form-control mt-2 mb-4" max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal"  onClick={this.hideExportModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" onClick={this.downloadResellerData}>{i18n.t('admin.btn_export')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation() (Reseller)