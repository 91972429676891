// Import React Libraries from the react js
import React from "react";


function MainLayout(props) {
  return (
    <div className={'mainlayout-' + props.color}>
      {props.children}
    </div>
  );
}
export default MainLayout;