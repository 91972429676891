import React from 'react';
import { withRouter } from "react-router-dom";
import i18n from '../../packs/i18n';
import { NotFound_IMG } from './assets_paths';
import { withTranslation } from 'react-i18next';
import LandingStepBase from './Layouts/LandingStepBase';
import Login from '../Login/Login';
import { ValidatorForm } from 'react-form-validator-core';
import LanguageSelection from '../LanguageOptions/LanguageSelection';
import { getAvailableLanguages, getTOSURL } from './i3library';
import CheckboxValidator from './CheckboxValidator';
import API from '../api';
class ApproveTOSChange extends React.Component {
    constructor(props) {
        super(props);
        const language = props.user_language || 'en';
        const languagesOptions = getAvailableLanguages();
        this.state = {
            language: language,
            languageOpt: languagesOptions,
            customCheck1:false,
            isValidate: false,
            tos_url: getTOSURL()
        }
        ValidatorForm.addValidationRule('isChecked', (value) => {
            return value;
        });
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    }
    handleSubmit = event => {
      const _this = this;
        API.post('react_landing/approve_tos', {email: this.props.email}).then(res => {
            if(res.data.status == "ok"){
              if(res.data.redirect_path) {
                window.location = decodeURIComponent(res.data.redirect_path);
              }
              else{
                window.location = "/";
              }
            }
            else{
                _this.setState({
                    hasError: true,
                    errorMessage: "Something went wrong!",
                    dynemichtml: true,
                    status: error.response.status
                }) 
            }
        })
        .catch((error)=> {
            _this.setState({
                hasError: true,
                errorMessage: "Something went wrong!",
                dynemichtml: true,
                status: error.response.status
            })
        })
        
    }
    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.name == 'email' ? event.target.value.trim() : event.target.value
        });
    }
    render() {
        return (
            <React.Fragment>
              <LandingStepBase container={
                <div className="container">
                  <div className="row" >
                    <div className="col-sm-12 p-0 text-center mt-7">
                      <div className="main-wrapper">
                        <ValidatorForm
                          ref="form"
                          onSubmit={this.handleSubmit}
                          onError={this.handleOnError}
                          instantValidate={false}
                          className="white-cover"
                        >
                          <div className='tos-message'>
                            <p>
                            {i18n.t("approve_tos.message")}
                            </p>
                          </div>
                          <div className="custom-control custom-checkbox my-4">
                                <CheckboxValidator
                                    className={'custom-control-input'}
                                    autoComplete="off"
                                    type="checkbox"
                                    name="customCheck1"
                                    id="customCheck1"
                                    value={this.state.customCheck1}
                                    onChange={(e) => this.handleChange(e, 'checkbox')}
                                    isValidate={this.state.isValidate}
                                    noValidate
                                    labelclass="custom-control-label text-accentb text-size-18"
                                    label={i18n.t("approve_tos.lable_checkbox")}
                                    validators={['isChecked']}
                                    errorMessages={[i18n.t('signup.step1.errors.tos_required')]} />
                                &nbsp; <a href={this.state.tos_url} target="no_blank" className="text-accentb text-size-18 text-uline">{i18n.t("approve_tos.link_text")}</a>
                            </div>
                          <button type="submit" className={'btn btn-accenta btn-lg btn-block mt-4'}> {i18n.t("approve_tos.btn_text")}</button>
                        </ValidatorForm>
                      </div>
                    </div>
                    <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="mobile mt-3" redirectPath={"accepttos?email="+encodeURIComponent(this.props.email)} />
                  </div>
                </div>}>
              </LandingStepBase>
              <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="desktop" redirectPath={"accepttos?email="+encodeURIComponent(this.props.email)} />
            </React.Fragment>
          )
    }

}

export default withRouter(withTranslation()(ApproveTOSChange));