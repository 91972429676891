import { TimelineMax as Timeline, Power4, Back } from 'greensock';
import { StepTypes, getStepByPathname } from '../Common/Helpers/LandingStepHelper'

let previous_goto_action;
let previous_backto_action;
let enterDurationInMs=0;

const baseFadeIn = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  timeline
  .fromTo(node , 1.0, {opacity: '0'},{opacity:1, ease:Power4.easeInOut},0);
  return timeline;
}

const baseFadeOut = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  timeline
  .fromTo(node , 1.2, {opacity: '1'},{opacity:0, ease:Power4.easeInOut},0);
  return timeline;
}


const slideInFromBottom = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  timeline
  .fromTo(node , 1.2, {top: '100%', opacity:0},{top:0, opacity:1, ease:Power4.easeInOut},0);
  return timeline;
}

const slideInFromTop = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  timeline
  .fromTo(node , 1.2, {top: '-100%', opacity:0},{top:0, opacity:1, ease:Power4.easeInOut},0);
  return timeline;
}

const slideInFromLeft = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  timeline
  .fromTo(node , 0.7, {left: '-100%', opacity:0},{left:0, opacity:1, ease:Back.easeIn.config(1.2)},0);
  return timeline;
}

const slideInFromRight = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  timeline
  .fromTo(node , 0.7, {left: '100%', opacity:0},{left:0, opacity:1, ease:Back.easeIn.config(1.2)},0);
  return timeline;
}

const slideOutToTop = (node) => {
  const timeline = new Timeline({ paused: true });
  timeline
  .fromTo(node , 1.2, {top: 0},{top:'-100%', opacity:0, ease:Power4.easeInOut},0);
  return timeline;
}
const slideOutToBottom = (node) => {
  const timeline = new Timeline({ paused: true });
  timeline
  .fromTo(node , 1.2, {top: 0},{top:'100%', opacity:0, ease:Power4.easeInOut},0);
  return timeline;
}

const slideOutToRight = (node) => {
  const timeline = new Timeline({ paused: true });
  timeline
  .fromTo(node , 0.7, {left: 0},{left:'100%', opacity:1, ease:Back.easeOut.config(1.2)},0);
  return timeline;
}

const slideOutToLeft = (node) => {
  const timeline = new Timeline({ paused: true });
  timeline
  .fromTo(node , 0.7, {left: 0},{left:'-100%', opacity:1, ease:Back.easeOut.config(1.2)},0);
  return timeline;
}
export const enterDuration = (history) => {
  return enterDurationInMs;
}
const revert_body_animatingstyle = () => {


  document.body.classList.remove('react_gsap_isanimating');
}

const revert_element_animatingstyle = (node) => {

  node.classList.remove('landingstepbase-animating');
}
const set_element_animatingstyle = (node) => {

  node.classList.add('landingstepbase-animating');
}

const set_body_animatingstyle = () => {

  var html = document.documentElement;

  var hasVerticalScrollbars = html.scrollHeight != html.offsetHeight;
  var hasHorizontalScrollbars = html.scrollWidth != html.offsetWidth;
  if(hasVerticalScrollbars || hasHorizontalScrollbars){
    //nothing to do
    return;
  }
  // console.log("scrollbars begone!", hasVerticalScrollbars);
  document.body.classList.add('react_gsap_isanimating');
}
export const play = (pathname, node, appears, key) => {

  let initial_page = false;
  if(typeof node ==='undefined'){
    return;
  }

  let activeStep = getStepByPathname(pathname);
//  console.log("play to: " + activeStep + " came from " + previous_goto_action);
  if(previous_goto_action === activeStep && activeStep !== StepTypes.user_profile){
    return;
  }


  const delay = appears ? 0 : 0.5;
  let timeline

  timeline = baseFadeIn(node, delay);
  if(previous_goto_action == null){
    //
    initial_page = true;
    // console.log("seems to be initial page");
  }
  else if (activeStep === StepTypes.signup && (previous_goto_action == null || previous_goto_action === StepTypes.login)){
    timeline = slideInFromTop(node, delay);
  }
  
  else if (activeStep === StepTypes.forgotpassword && (previous_goto_action == null || previous_goto_action === StepTypes.login)){
    timeline = slideInFromBottom(node, delay);
  }
  else if (activeStep === StepTypes.login && (previous_goto_action == null || previous_goto_action === StepTypes.signup)){
    timeline = slideInFromBottom(node, delay);
  }
  else if (activeStep === StepTypes.login && (previous_goto_action == null || previous_goto_action === StepTypes.forgotpassword)){
    timeline = slideInFromTop(node, delay);
  }
  else if (activeStep === StepTypes.signup_select_payment && (previous_goto_action === StepTypes.signup_select_package)){
    timeline = slideInFromRight(node, delay);
  }
  else if (activeStep === StepTypes.signup_select_package && (previous_goto_action === StepTypes.signup_select_payment)){
    timeline = slideInFromLeft(node, delay);
  }
  else if (activeStep === StepTypes.signup_confirm_payment && (previous_goto_action === StepTypes.signup_select_package)){
    timeline = slideInFromRight(node, delay);
  }
  else if (activeStep === StepTypes.signup_select_package && (previous_goto_action === StepTypes.signup_confirm_payment)){
    timeline = slideInFromLeft(node, delay);
  }
  else if (activeStep === StepTypes.signup_confirm_payment && (previous_goto_action === StepTypes.signup_select_payment)){
    timeline = slideInFromRight(node, delay);
  }
  else if (activeStep === StepTypes.signup_select_payment && (previous_goto_action === StepTypes.signup_confirm_payment)){
    timeline = slideInFromLeft(node, delay);
  }
  else if (activeStep === StepTypes.packsignup_signup && (previous_goto_action === StepTypes.packsignup)){
    timeline = slideInFromBottom(node, delay);
  }
  else if (activeStep === StepTypes.packsignup_confirmemail && (previous_goto_action === StepTypes.packsignup_signup)){
    timeline = slideInFromBottom(node, delay);
  }
  else if (activeStep === StepTypes.packsignup_activate && (previous_goto_action == null || previous_goto_action === StepTypes.packsignup_confirmemail)){
    timeline = slideInFromTop(node, delay);
  }
  else if (activeStep === StepTypes.packsignup_success && (previous_goto_action === StepTypes.packsignup_activate)){
    timeline = slideInFromBottom(node, delay);
  }
  else if (activeStep === StepTypes.packsignup_fail && (previous_goto_action === StepTypes.packsignup_activate)){
    timeline = slideInFromBottom(node, delay);
  }
  else if (activeStep === StepTypes.packsignup && (previous_goto_action == null || previous_goto_action === StepTypes.login)){
    timeline = slideInFromTop(node, delay);
  }

  set_body_animatingstyle();
  if(!initial_page){
    set_element_animatingstyle(node);
  }
  timeline.eventCallback("onComplete", function(){
    revert_element_animatingstyle(node);
    revert_body_animatingstyle();}, );

  previous_goto_action = activeStep;
  window
    .loadPromise
    .then(() => requestAnimationFrame(() => timeline.play()))
}

export const exit = (pathname, node, key) => {


  if(typeof node ==='undefined'){
    return;
  }
  let activeStep = getStepByPathname(pathname);
  // console.log("exit to: " + activeStep + " came from " + previous_goto_action);

  if(previous_goto_action === activeStep && activeStep !== StepTypes.user_profile){
    return;
  }

  let timeline;
  timeline = baseFadeOut(node);

  if (activeStep === StepTypes.login && (previous_goto_action == null || previous_goto_action === StepTypes.signup)){
    timeline = slideOutToBottom(node);
  }
  else if (activeStep === StepTypes.login && (previous_goto_action == null || previous_goto_action === StepTypes.forgotpassword)){
    timeline = slideOutToTop(node);
  }
  else if (activeStep === StepTypes.signup && (previous_goto_action == null || previous_goto_action === StepTypes.login)){
    timeline = slideOutToTop(node);
  }
  else if (activeStep === StepTypes.forgotpassword && (previous_goto_action == null || previous_goto_action === StepTypes.login)){
    timeline = slideOutToBottom(node);
  }
  else if (activeStep === StepTypes.signup_select_package  && (previous_goto_action === StepTypes.signup_select_payment)){
    timeline = slideOutToLeft(node);
  }
  else if (activeStep === StepTypes.signup_select_payment  && (previous_goto_action === StepTypes.signup_select_package)){
    timeline = slideOutToRight(node);
  }
  else if (activeStep === StepTypes.signup_select_package  && (previous_goto_action === StepTypes.signup_confirm_payment)){
    timeline = slideOutToLeft(node);
  }
  else if (activeStep === StepTypes.signup_confirm_payment  && (previous_goto_action === StepTypes.signup_select_package)){
    timeline = slideOutToRight(node);
  }
  else if (activeStep === StepTypes.signup_select_payment  && (previous_goto_action === StepTypes.signup_confirm_payment)){
    timeline = slideOutToLeft(node);
  }
  else if (activeStep === StepTypes.signup_confirm_payment  && (previous_goto_action === StepTypes.signup_select_payment)){
    timeline = slideOutToRight(node);
  }
  // else if (activeStep === StepTypes.user_profile){
  //   timeline = slideOutToBottom(node);
  // }
  set_element_animatingstyle(node);
  timeline.eventCallback("onComplete", function(){console.log(revert_element_animatingstyle(node));}, );
  previous_backto_action = activeStep;
  window
    .loadPromise
    .then(() => requestAnimationFrame(() => timeline.play()))
}
