import React from "react";
import i18n from "../../packs/i18n";
import { getUserInfo, isProTagAdd } from "../Common/i3library";
import API from "../api";
import SweetModelAlert from "../Common/SweetModelAlert";
import {createEvent} from '../Common/AnalyticsEvents';

class ConnectWithApps extends React.Component {
    constructor(props) {
        super(props);
        const userInfo = getUserInfo();
        if(isProTagAdd()) {
            props.history.goBack();
        }
        this.state = {
            isGoogle: (userInfo.connected_accounts.indexOf('google') || userInfo.oauth_connections.indexOf('google')) !== -1 ? true : false,
            isGoogleClassroom: (userInfo.connected_accounts.indexOf('google_classroom') || userInfo.oauth_connections.indexOf('google_classroom')) !== -1 ? true : false,
            isOneDrive: (userInfo.connected_accounts.indexOf('onedrive') || userInfo.oauth_connections.indexOf('onedrive')) !== -1 ? true : false,
            isDropBox: (userInfo.connected_accounts.indexOf('dropbox') || userInfo.oauth_connections.indexOf('dropbox')) !== -1 ? true : false,
            isBookWidget: (userInfo.connected_accounts.indexOf('bookwidgets') || userInfo.oauth_connections.indexOf('bookwidgets')) !== -1 ? true : false,
            modalShow: false,
            name: '',
        }
    }
    displayButtonClass = (name) => {
        const btn_active = 'btn btn-block btn-secondary mb-4';
        const btn_inactive = 'btn btn-block btn-outline-secondary mb-4';
        switch(name){
            case 'google':
                return this.state.isGoogle?btn_active:btn_inactive;
                break;
            case 'google_classroom':
                return this.state.isGoogleClassroom?btn_active:btn_inactive;
                break;
            case 'onedrive':
                return this.state.isOneDrive?btn_active:btn_inactive;
                break;
            case 'dropbox':
                return this.state.isDropBox?btn_active:btn_inactive;
                break;
            case 'bookwidgets':
                return this.state.isBookWidget?btn_active:btn_inactive;
                break;
            default:
                return btn_inactive
                break;
        }
    }

    handleConnectOption = (name, isConnected) => {
        if(isConnected){
            this.setState({ modalShow : true, name });
        } else {
            this.handleRedirectToApp(name);
        }
    }

    handleRedirectToApp = (name) => {
        switch(name) {
            case 'google':
              if(this.state.isGoogle) {
                this.setState({
                  'isGoogle': !this.state.isGoogle
                });
              }
              this.redirect(this.state.isGoogle, 'google');
              break;
            case 'google_classroom':
              if(this.state.isGoogleClassroom) {
                this.setState({
                  'isGoogleClassroom': !this.state.isGoogleClassroom
                });
              }
              this.redirect(this.state.isGoogleClassroom, 'google_classroom');
              break;
            case 'onedrive':
              if(this.state.isOneDrive) {
                this.setState({
                  'isOneDrive':!this.state.isOneDrive
                });
              }
              this.redirect(this.state.isOneDrive, 'onedrive');
              break;
            case 'dropbox':
              if(this.state.isDropBox) {
                this.setState({
                  'isDropBox':!this.state.isDropBox
                });
              }
              this.redirect(this.state.isDropBox, 'dropbox');
              break;
            case 'bookwidgets':
              if(this.state.isBookWidget) {
                this.setState({
                  'isBookWidget':!this.state.isBookWidget
                });
              }
                this.redirect(this.state.isBookWidget, 'bookwidgets');
                break;

        }
    }
    redirect = (value,providerId) => {
        if(value){
            //Disconnect the apps
            API.get('/disconnect/'+providerId+'?source=connect_to_apps')
            .then(res => {
                createEvent("disconnect", "app_"+providerId+"_success");
                this.setState({ modalShow : false });
                this.props.callUserInfo();
            })
            .catch(function(error){
              createEvent("disconnect", "app_"+providerId+"_fail");
            })
        }
        else{
            //Redirect to the perticular apps
            API.get('/connect/'+providerId)
            .then(res => {
              createEvent("connect", "app_"+providerId+"_initiated");  
              window.location.href = res.data.connect_url
            })
            .catch(function(error){
              createEvent("connect", "app_"+providerId+"_init_fail");
            })
        }
    }

    handleConfirmClick = () => {
        this.handleRedirectToApp(this.state.name);
    }

    handleCancelClick = () => {
        this.setState({ modalShow : false })
    }

    render() {
        const isGoogle = this.state.isGoogle;
        const isGoogleClassroom = this.state.isGoogleClassroom;
        const isOneDrive = this.state.isOneDrive;
        const isDropBox = this.state.isDropBox;
        const isBookWidget = this.state.isBookWidget;
        let current_app_name = 'userprofile.connect_apps.name_'+this.state.name;
        return (
            <React.Fragment>
                <div className="profile-main-wrapper">
                    <div className="white-cover-fixheight">
                        <h3 className="text-primary mb-5">{i18n.t('userprofile.connect_apps.heading')}</h3>
                        <div className="mx-auto connect-to-app">
                            <button type="button" className = {this.displayButtonClass('dropbox')} id='dropbox' onClick={(e) => this.handleConnectOption('dropbox', isDropBox)}>
                                <div className="btn-icon-wrapper">
                                    <i className="lh-icon-service-dropbox"></i>
                                    <span className="label">{i18n.t(`userprofile.connect_apps.${ isDropBox ? 'disconnect' : 'connect' }_dropbox`)}</span>
                                </div>
                            </button>
                            <button type="button" className = {this.displayButtonClass('onedrive')} id='onedrive' onClick={(e) => this.handleConnectOption('onedrive', isOneDrive)} >
                                <div className="btn-icon-wrapper">
                                    <i className="lh-icon-service-onedrive"></i>
                                    <span className="label">{i18n.t(`userprofile.connect_apps.${ isOneDrive ? 'disconnect' : 'connect' }_onecloud`)}</span>
                                </div>
                            </button>
                            <button type="button" className = {this.displayButtonClass('google')} id='google' onClick={(e) => this.handleConnectOption('google', isGoogle)}>
                              <div className="btn-icon-wrapper">
                                <i className="lh-icon-service-googledrive"></i>
                                <span className="label">{i18n.t(`userprofile.connect_apps.${ isGoogle ? 'disconnect' : 'connect' }_google_drive`)}</span>
                              </div>
                            </button>
                            <button type="button" className = {this.displayButtonClass('google_classroom')} id='google_classroom' onClick={(e) => this.handleConnectOption('google_classroom', isGoogleClassroom)}>
                              <div className="btn-icon-wrapper">
                                <i className="lh-icon-service-google"></i>
                                <span className="label">{i18n.t(`userprofile.connect_apps.${ isGoogleClassroom ? 'disconnect' : 'connect' }_google_classroom`)}</span>
                              </div>
                            </button>
                        </div>
                    </div>
                </div>
                <SweetModelAlert
                    customClass="remove-profile"
                    show={this.state.modalShow}
                    title={i18n.t('userprofile.connect_apps.title')}
                    text={i18n.t('userprofile.connect_apps.text',{ platform : i18n.t(current_app_name) })}
                    showCancelButton
                    confirmButtonText={i18n.t('userprofile.confirm_yes')}
                    cancelButtonText={i18n.t('userprofile.confirm_no')}
                    onConfirm={this.handleConfirmClick}
                    onCancel={this.handleCancelClick}
                />
            </React.Fragment>
        );
    }
}

export default ConnectWithApps;
