import React from "react";
import { Close_IMG } from "./assets_paths";

class ReactTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.state = {
            transform : "translate3d(15px, -100%, 0px)",
            position: "bs-tooltip-top",
        }
    }
    onClose(e) {
        if(this.props.onClose) {
            const timeout = setTimeout(() => {
                this.props.onClose(e)
                clearTimeout(timeout);
            }, 400);
        }
    }

    handleScrollEvent(e) {
        if(this.props.show) {
            const tooltip = document.getElementById("tooltip");
            if(window.pageYOffset > ( tooltip.getBoundingClientRect().top + window.pageYOffset)){
                this.setState({
                    transform : "translate3d(0px, 25px, 0px)",
                    position: "bs-tooltip-bottom",
                })
            } else if(window.pageYOffset < ( tooltip.getBoundingClientRect().top + window.pageYOffset) - 50){
                this.setState({
                    transform : "translate3d(15px, -100%, 0px)",
                    position: "bs-tooltip-top",
                })
            };
        }
        
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.show) {
            //window.addEventListener('scroll', this.handleScrollEvent.bind(this))
        } else if(nextProps.show == false) {
            this.setState({
                transform : "translate3d(15px, -100%, 0px)",
                position: "bs-tooltip-top",
            })
           // window.removeEventListener('scroll', this.handleScrollEvent.bind(this));
        }
    }

    componentWillUnmount() {
        this.setState({
            transform : "translate3d(15px, -100%, 0px)",
            position: "bs-tooltip-top",
        })
        window.removeEventListener('scroll', this.handleScrollEvent.bind(this));
    }

    render() {
        const { text, show } = this.props;
        if(!show) {
            return null;
        }

        return (
            <React.Fragment>
                <div id="tooltip" className={"tooltip show " + this.state.position} role="tooltip" style={{minWidth:"260px", position: "absolute", willChange: "transform", top: '0px', right: '0px', transform: this.state.transform,  maxWidth: "260px"}}>
                    <div className="tooltip-wrap">
                        <a className="ttclose" href="javascript:void(0)" onClick={this.onClose}>
                            <img src={Close_IMG} />
                        </a>
                        <div className="arrow" />
                        <div className="tooltip-inner"  dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ReactTooltip.defaultProps = {
    onClose: function () { },
    title: '',
    text: '',
    show: false,
}

export default ReactTooltip;

