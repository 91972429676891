import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import { NavDropdown } from 'react-bootstrap';
import TextValidator from "../Common/TextValidator";
import Errors from "../Common/Errors/Errors";
import { event } from "react-ga";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import SelectValidator from "../Common/SelectValidator";
import TextareaValidator from "../Common/TextareaValidator";
class Libraries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            libraries: [],
            per_page: 10,
            current_page: 1,
            total_count: 0,
            name: "",
            thumb_url: "",
            selected_thumb: "",
            widget_name: "",
            edit_item_id: null,
            edit_item_name: null,
            edit_widget_name: null,
            isEditOpen: false,
            deleteMultipleItems: new Array(),
            upload_info: null,
            selectedCategories: [],
            category_id: null,
            selected_type: null,
            query: "",
            download_url: "",
            types: [{value: 'LibExtra', label: 'Lib Extra'}, {value: 'LibExtraWeb', label: 'Lib Extra Web'}],
            orderBy: 'updated_at desc'
        }
        // http://localhost:3000/react_admin/libraries
    }
    async componentDidMount() {
        await this.callApi(this.state.per_page, this.state.current_page)
        //per_page, current_page, current_type, current_status, htmlFormat = null, searchTerm = ''
    }
    async callApi(per_page, current_page) {
        this.setState({ data: [], isLoading: true });
        let { data } = await API.get('/react_admin/libraries?per_page='+per_page+'&current_page='+current_page+
        '&query='+this.state.query + '&order_by=' + this.state.orderBy)
        
        let _libraries = []
        data.libraries.forEach(element => {
            _libraries.push({value: element.id, label: element.name, categories: element.primary_categories})
        });
        this.setState({ data: data.items, total_count: data.total_count, libraries: _libraries, isLoading: false, download_url: data.sample_download_url})
    }
    onTableChange = (type, newState) => {
        if(newState.sortOrder && newState.sortField){
            this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
        }
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi(newState.sizePerPage, newState.page)
    }
    clearFields = () => {
        document.getElementById('library-form').reset();
    }

    toBulkUpload = () => {
        // window.location.href = '/admin/libraries/bulk_upload'
    }
    handleSubmit = (event) => {
        if(this.state.selected_type == 'LibExtraWeb' && !this.state.widget_name){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t("admin.errors.require_widget_name"),
                status: null
            });
            return;
        }
        if(this.state.selected_type == 'LibExtra' && !this.state.thumb_url){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t("admin.errors.require_thumb_url"),
                status: null
            });
            return;
        }
        this.setState({ isLoading: true });
        this.createNewItem()
    }
    handleBulkUpload = (event) => {
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append('library', this.state.upload_library_id);
        formData.append('pri_category', this.state.upload_category_id);
        formData.append('file', this.state.selected_thumb);
        API.post('/library/items/bulk_upload', formData)
        .then(res => {
            this.setState({upload_info: res.data.message, isLoading: false})
        })
        .catch(function (error) {
            this.setState({ isLoading: false });
        })
    }
    createNewItem = () => {
        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('library', this.state.library_id);
        formData.append('pri_category', this.state.category_id)
        formData.append('lib_type', this.state.selected_type)
        if (this.state.selected_type === 'LibExtra')
            formData.append('file', this.state.selected_thumb);
        if (this.state.selected_type === 'LibExtraWeb')
        {
            formData.append('widget_name', this.state.widget_name);
            formData.append('thumb_url', this.state.selected_thumb);
        }
        if(this.state.search_keywords)
            formData.append('keywords', this.state.search_keywords);
        API.post('/library/items/add_item', formData)
        .then(res => {
            window.location.reload();
        })
        .catch(function (error) {
        })
    }
    editItem = (event) => {
        let id = event.target.getAttribute('data-id');
        let itemInfo = this.state.data.find((element) => {
            return element.id == id
        })
        console.log(itemInfo);
        this.setState({ edit_item_id: itemInfo.id, edit_item_name: itemInfo.name, edit_widget_name: itemInfo.widget_name})

        this.showEditModal()
    }
    handleEditSubmit = (event) => {
        if (this.state.edit_item_name.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.labels.require_item_name'),
                status: null
            });
            return;
        }
        else if(this.state.edit_widget_name.trim().length == 0) {
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.labels.require_widget_name'),
                status: null
            }); 
          return;
        }
        this.setState({ isLoading: true });
        this.submitEditItem()
    }
    submitEditItem = () => {
        const formData = new FormData();
        formData.append('item_id', this.state.edit_item_id)
        formData.append('name', this.state.edit_item_name);
        formData.append('url', this.state.edit_widget_name);
        formData.append('is_from_admin', true);
        API.post('library/items', formData)
          .then(res => {
            window.location.reload();
          })
          .catch(function (error) {
          })
    }
    handleOnError = errors => {
        this.setState({
          isValidate: true,
          isValid: false,
          hasError: true,
          errorMessage: getErrorMessage(errors, true),
          status: null
        });
    };
    showEditModal = () => {
        this.setState({ isEditOpen: true, isValidate: false,  isValid: true, hasError: false,errorMessage: [], status: null  })
    }
    hideEditModal = () => {
        this.setState({ isEditOpen: false })
    }
    deleteItem = (event) => {
        let id = event.target.getAttribute('data-id');
        let itemInfo = this.state.data.find((element) => {
            return element.id == id
        })
        const formData = new FormData();
        formData.append('item_id', itemInfo.id)
        API.post('library/items', formData)
          .then(res => {
            window.location.reload();
          })
          .catch(function (error) {
          })
    }

    rankFormatter = (cell, row) => {
        let item_id = row.id
        return (
          <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item href={void (0)} data-id={item_id} onClick={this.editItem}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
            <NavDropdown.Item href={void (0)} data-id={item_id} onClick={this.deleteItem}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
          </NavDropdown>
        );
    }
    statusFormatter = (cell, row) => {
        return(
            <div>
                { row.status == "success" ? <p className="bg-success p-1" style={{textAlign: 'center'}}>{row.status}</p> : <p className="bg-danger p-1" style={{textAlign: 'center'}}>{row.status}</p>}
            </div>
        )
    }
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        if(event.target.name === 'library_id'){
            this.state.libraries.forEach((library) => {
                if(library.value == event.target.value){
                    let _selectedCategories = []
                    library.categories.forEach((category) => { 
                        _selectedCategories.push({value: category.id, label: category.name})
                    })
                    this.setState({ selectedCategories: _selectedCategories, category_id: null})
                    return
                }
            })
        }
        if(event.target.name === 'upload_library_id'){
            this.state.libraries.forEach((library) => {
                if(library.value == event.target.value){
                    let _selectedCategories = []
                    library.categories.forEach((category) => { 
                        _selectedCategories.push({value: category.id, label: category.name})
                    })
                    this.setState({ selectedCategories: _selectedCategories, upload_category_id: null})
                    return
                }
            })
        }
        if(event.target.name === 'upload_file'){
            this.setState({
                selected_thumb: event.target.files[0]
            });
        }
        if(event.target.name === "thumb_url"){
            this.setState({
                selected_thumb: event.target.files[0]
            });    
        }
        if (event.target.name === "fromDate") {
          this.setState({ toDate: '' })
        }
        this.setState({
          [event.target.name]: event.target.value,
          isLangChange
        });
    };
    reloadUpload = (ev) => {

        window.location.reload()
    }
    removeItemFromList = (itemId) => {
        let newItems = this.state.deleteMultipleItems.filter((item) => {return item.id != itemId})
        setTimeout(() => {
            this.setState({deleteMultipleItems: newItems})
        }, 0)
    }
    multiSelectCalled = (row, isSelect, rowIndex, e) => {
        if(isSelect){
            this.state.deleteMultipleItems.push(row);
            setTimeout(() => {
                this.setState({deleteMultipleItems: this.state.deleteMultipleItems})
            }, 0)
        }
        else{
            this.removeItemFromList(row.id)
        }
    }
    multiSelectAllCalled = (isSelect, rows, e) => {
        if(isSelect){
            this.setState({deleteMultipleItems: rows})
        }
        else{
            this.setState({deleteMultipleItems: new Array()})
        }
    }
    bulkDelete = (ev) => {
        const formData = new FormData();
        this.state.deleteMultipleItems.forEach((item) => {
            formData.append('items[]', item.id);
        })
        API.post('/library/items/bulk_delete', formData)
          .then(res => {
            window.location.reload();
          })
          .catch(function (error) {
        })
    }
    _handleKeyDown = event => {
        if (event.key === 'Enter') {
            this.doSearch();
        }
    }
    doSearch = () => {
        this.callApi(this.state.per_page, this.state.current_page)
        // if(this.state.query.trim().length > 0){
        //     //window.location.href = '/admin/search/'+this.state.searchTerm+'/location/'+this.state.search_location;
        // }
        // else{
        //     this.callApi()
        // }
    }
    render() {
        const columns = [{
            dataField: 'name',
            text: i18n.t('admin.labels.item_name').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '20%' };
            }
          }, {
            dataField: 'item_type',
            text: i18n.t('admin.labels.item_type').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '9%' };
            }
          }, {
            dataField: 'path',
            text: i18n.t('admin.labels.item_thumb').toUpperCase(),
            sort: true
          }, {
            dataField: 'widget_name',
            text: i18n.t('admin.labels.widget_url').toUpperCase(),
            sort: true,
          },{
            dataField: 'moreOptions',
            formatter: this.rankFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
          }];
          const columnsUploadInfo = [{
            dataField: 'item_name',
            text: i18n.t('admin.labels.item_name').toUpperCase(),
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            },
            sort: false,
          }, {
            dataField: 'message',
            text: 'Message'.toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '50%' };
            },
          }, {
            text: 'status'.toUpperCase(),
            formatter: this.statusFormatter,
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            },
          }];
          const options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
          };
          const paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
          };
          const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
          }];

        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        }
        const selectRowProp = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.multiSelectCalled,
            onSelectAll: this.multiSelectAllCalled,
        };
        
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content libraries-tabel">
                        <div className="admin-custom-table-header searh_custom_header">
                            <button data-bs-toggle="modal" data-bs-target="#addItemModal" className="btn btn-primary mb-3 mt-1 btn-add-educationlink">{i18n.t('admin.libraries.add_modal.title')}</button>
                            <button data-bs-target="#bulkUploadModal" data-bs-toggle="modal" className="btn btn-primary mb-3 mt-1 ml-3 pl-3 pr-3 btn-delete-multiple">{i18n.t('admin.education_links.btn_import')}</button>
                            <button onClick={this.bulkDelete} disabled={this.state.deleteMultipleItems.length <= 0} data-bs-toggle="modal" className="btn btn-primary mb-3 mt-1 ml-3 pl-3 pr-3 btn-delete-multiple">{i18n.t('admin.education_links.btn_delete_multi')}</button>
                            <div className="search-input mb-3 mt-1" title={i18n.t('admin.placeholder.search_box')}>
                                {/* data-i18n="[placeholder]file.open:search-placeholder"  */}
                                <input onKeyDown={this._handleKeyDown} onChange={this.handleChange} name="query" type="text" className="form-control" id="glb_input_search_text" placeholder={i18n.t('admin.libraries.search_box')} />
                                <a href={void (0)} onClick={this.doSearch} className="lh-icon-os-search btn-search" id="btn_search_in_library"></a>
                            </div>
                        </div>
                        <BootstrapTable
                            keyField='id'
                            options={options}
                            data={this.state.data}
                            fetchInfo={{ dataTotalSize: this.state.total_count }}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            bordered={false}
                            noDataIndication={() => <NoDataIndication />}
                            remote
                            pagination={paginationFactory(paginatorOptions)}
                            onTableChange={this.onTableChange}
                            selectRow={ selectRowProp }
                            //rowEvents={rowEvents}
                        />
                    </div>
                    {/* Add Item Modal */}
                    <div className="modal fade" id="addItemModal" tabIndex="-1">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <ValidatorForm
                                    id="addItemForm"
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className=""
                                >
                                    <div className="modal-header modal-image-header">
                                        <div className="modal-header-content">
                                            <h4 className="modal-title">{i18n.t("admin.libraries.add_modal.title")}</h4>
                                        </div>
                                        <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="middle-sec">
                                            <div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('admin.labels.select_library')}</span>
                                                    </div>
                                                    <div>
                                                        <SelectValidator
                                                            tabIndex='1'
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            placeholder={i18n.t('admin.placeholder.select_library')}
                                                            name="library_id"
                                                            options={this.state.libraries}
                                                            value={this.state.library_id}
                                                            onChange={this.handleChange}
                                                            isValidate={this.state.isValidate}
                                                            validators={["required"]}
                                                            errorMessages={[
                                                                i18n.t('admin.errors.require_library')
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('admin.labels.select_category')}</span>
                                                    </div>
                                                    <div>
                                                        <SelectValidator
                                                            tabIndex='1'
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            placeholder={i18n.t('admin.placeholder.select_category')}
                                                            name="category_id"
                                                            options={this.state.selectedCategories}
                                                            value={this.state.category_id}
                                                            onChange={this.handleChange}
                                                            isValidate={this.state.isValidate}
                                                            validators={["required"]}
                                                            errorMessages={[
                                                                i18n.t('admin.errors.require_category')
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('admin.labels.select_type')}</span>
                                                    </div>
                                                    <div>
                                                        <SelectValidator
                                                            tabIndex='1'
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            placeholder={i18n.t('admin.placeholder.select_type')}
                                                            name="selected_type"
                                                            options={this.state.types}
                                                            value={this.state.selected_type}
                                                            onChange={this.handleChange}
                                                            isValidate={this.state.isValidate}
                                                            validators={["required"]}
                                                            errorMessages={[
                                                                i18n.t('admin.errors.require_type')
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.name")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="name"
                                                            placeholder={i18n.t("admin.placeholder.name")}
                                                            value={this.state.name}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_name")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.thumb_url")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='3'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="file"
                                                            name="thumb_url"
                                                            placeholder={i18n.t("admin.placeholder.thumb_url")}
                                                            value={this.state.thumb_url}
                                                            noValidate
                                                            // isValidate={this.state.isValidate}
                                                            // validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            // errorMessages={[
                                                            //     i18n.t("admin.errors.require_thumb_url")
                                                            // ]}
                                                        />
                                                    </div>
                                                </div>
                                                { this.state.selected_type == 'LibExtraWeb' ?  
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.widget_url")}</span>
                                                    </div>
                                                    <div>
                                                    <TextValidator
                                                        tabIndex='2'
                                                        wrapperClass=''
                                                        className={"form-control mt-2 mb-4"}
                                                        autoComplete="off"
                                                        type="text"
                                                        name="widget_name"
                                                        placeholder={i18n.t("admin.placeholder.item_widget_name")}
                                                        value={this.state.widget_name}
                                                        noValidate
                                                        // isValidate={this.state.isValidate}
                                                        // noValidate
                                                        // validators={["required"]}
                                                        // errorMessages={[
                                                        // i18n.t("admin.errors.require_widget_name")
                                                        // ]}
                                                        onChange={this.handleChange}
                                                        onKeyDown={this.handleKeyDown}
                                                    />
                                                    </div>
                                                </div> : null}
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.keywords")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            accept="image/*"
                                                            type="text"
                                                            name="search_keywords"
                                                            placeholder={i18n.t("admin.placeholder.keywords")}
                                                            value={this.state.search_keywords}
                                                            noValidate
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal">Cancel</button>
                                        <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                            <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                            :
                                            <React.Fragment>{i18n.t('admin.libraries.add_modal.btn_add')}</React.Fragment>
                                        }</button>
                                    </div>
                                </ValidatorForm>
                                <Errors
                                    hasError={this.state.hasError}
                                    type="warning"
                                    message={this.state.errorMessage}
                                    status={this.state.status}
                                />
                            </div>
                        </div>
                    </div>
                     {/* Bulk Upload Item Modal */}
                    <div className="modal fade" id="bulkUploadModal" tabIndex="-2">
                        <div className="modal-dialog modal-lg">
                            { this.state.upload_info ? 
                                <div className="modal-content">
                                    {/* Bulk Upload Div Display Status Of Upload */}
                                    <div className="modal-header modal-image-header">
                                            <div className="modal-header-content">
                                                <h4 className="modal-title">{i18n.t('admin.upload_info.title')}</h4>
                                            </div>
                                            <button type="button" className="close" data-bs-dismiss="modal" onClick={this.reloadUpload}><span className="lh-icon-os-close"></span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <span>
                                                {i18n.t('admin.upload_info.message')}
                                            </span>
                                        </div>
                                        <div className="upload-info-table">
                                            <BootstrapTable
                                                keyField='id'
                                                options={options}
                                                data={this.state.upload_info}
                                                columns={columnsUploadInfo}
                                                bordered={false}
                                                noDataIndication={() => <NoDataIndication />}
                                                remote
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                            <button type="button" className="btn btn-lg  btn-accenta mt-2" data-bs-dismiss="modal" onClick={this.reloadUpload}>{i18n.t('admin.btn_ok')}</button>
                                    </div>
                                </div> 
                                :
                                <div className="modal-content">
                                    {/* Bulk Upload Form */}
                                    <ValidatorForm
                                        id="bulkUploadForm"
                                        ref="form"
                                        onSubmit={this.handleBulkUpload}
                                        onError={this.handleOnError}
                                        instantValidate={false}
                                        className=""
                                    >
                                        <div className="modal-header modal-image-header">
                                            <div className="modal-header-content">
                                                <h4 className="modal-title">{i18n.t("admin.libraries.upload_modal.title")}</h4>
                                            </div>
                                            <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                        </div>
                                        <div className="modal-body">
                                            <div class="mt-1 mb-3" style={{display: 'flex', justifyContent: 'right'}}>
                                                <a href={this.state.download_url} download>Download Sample</a>
                                            </div>
                                            <div className="middle-sec">
                                                <div>
                                                    <div className="two-column-grid">
                                                        <div className="user-label text-primary">
                                                            <span>{i18n.t('admin.labels.select_library')}</span>
                                                        </div>
                                                        <div>
                                                            <SelectValidator
                                                                tabIndex='1'
                                                                className={"form-control"}
                                                                autoComplete="off"
                                                                placeholder={i18n.t('admin.placeholder.select_library')}
                                                                name="upload_library_id"
                                                                options={this.state.libraries}
                                                                value={this.state.upload_library_id}
                                                                onChange={this.handleChange}
                                                                isValidate={this.state.isValidate}
                                                                validators={["required"]}
                                                                errorMessages={[
                                                                    i18n.t('admin.errors.require_library')
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="two-column-grid">
                                                        <div className="user-label text-primary">
                                                            <span>{i18n.t('admin.labels.select_category')}</span>
                                                        </div>
                                                        <div>
                                                            <SelectValidator
                                                                tabIndex='1'
                                                                className={"form-control"}
                                                                autoComplete="off"
                                                                placeholder={i18n.t('admin.placeholder.select_category')}
                                                                name="upload_category_id"
                                                                options={this.state.selectedCategories}
                                                                value={this.state.upload_category_id}
                                                                onChange={this.handleChange}
                                                                isValidate={this.state.isValidate}
                                                                validators={["required"]}
                                                                errorMessages={[
                                                                    i18n.t('admin.errors.require_category')
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="two-column-grid">
                                                        <div className="user-label text-primary">
                                                            <span>{i18n.t("admin.labels.upload_file")}</span>
                                                        </div>
                                                        <div>
                                                            <TextValidator
                                                                tabIndex='3'
                                                                wrapperClass=''
                                                                className={"form-control"}
                                                                autoComplete="off"
                                                                type="file"
                                                                name="upload_file"
                                                                placeholder={i18n.t("admin.placeholder.upload_file")}
                                                                accept=".zip,.rar,.7zip"
                                                                value={this.state.upload_file}
                                                                //noValidate
                                                                isValidate={this.state.isValidate}
                                                                validators={['required']}
                                                                onChange={this.handleChange}
                                                                onKeyDown={this.handleKeyDown}
                                                                errorMessages={[
                                                                    i18n.t("admin.errors.require_upload_file")
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal">{i18n.t('userprofile.btn_cancel')}</button>
                                            <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                                <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                                :
                                                <React.Fragment>{i18n.t('admin.libraries.upload_modal.btn_add')}</React.Fragment>
                                            }</button>
                                        </div>
                                    </ValidatorForm>
                                    <Errors
                                        hasError={this.state.hasError}
                                        type="warning"
                                        message={this.state.errorMessage}
                                        status={this.state.status}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    {/* Edit Item Modal */}
                    <div>
                        <Modal show={this.state.isEditOpen}>
                            {/* <div className="modal-dialog modal-lg">
                                <div className="modal-content"> */}
                            {/* <form id="editUserForm"> */}
                            <ValidatorForm
                            id="editItemForm"
                            ref="form"
                            onSubmit={this.handleEditSubmit}
                            onError={this.handleOnError}
                            instantValidate={false}
                            className=""
                            >
                            <div className="modal-header modal-image-header">
                                <div className="modal-header-content">
                                <h4 className="modal-title">{i18n.t('admin.libraries.edit_modal.title')}</h4>
                                </div>
                                <button type="button" className="close" onClick={this.hideEditModal}>
                                <span className="lh-icon-os-close"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="middle-sec">
                                <div className="row">
                                    <div className="user-label text-primary col-sm-4 pt-2">
                                    <span className="ln-height-4">{i18n.t("admin.labels.item_name")}</span>
                                    </div>
                                    <div className="col-sm-6 pt-2">
                                    <TextValidator
                                        tabIndex='1'
                                        wrapperClass=''
                                        className={"form-control mt-2 mb-4"}
                                        autoComplete="off"
                                        type="text"
                                        name="edit_item_name"
                                        placeholder={i18n.t("userprofile.placeholder.item_name")}
                                        value={this.state.edit_item_name}
                                        isValidate={this.state.isValidate}
                                        validators={['required']}
                                        onChange={this.handleChange}
                                        onKeyDown={this.handleKeyDown}
                                        errorMessages={[
                                            i18n.t("admin.errors.require_item_name")
                                        ]}
                                    />
                                    </div>
                                    <div className="user-label text-primary col-sm-4 pt-2">
                                        <span className="ln-height-4">{i18n.t("admin.labels.widget_url")}</span>
                                    </div>
                                    <div className="col-sm-6 pt-2">
                                    <TextValidator
                                        tabIndex='2'
                                        wrapperClass=''
                                        className={"form-control mt-2 mb-4"}
                                        autoComplete="off"
                                        type="text"
                                        name="edit_widget_name"
                                        placeholder={i18n.t("admin.placeholder.item_widget_name")}
                                        value={this.state.edit_widget_name}
                                        isValidate={this.state.isValidate}
                                        noValidate
                                        validators={["required"]}
                                        errorMessages={[
                                        i18n.t("admin.errors.require_widget_name")
                                        ]}
                                        onChange={this.handleChange}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    </div>
                                </div>
                                <div className="row">
                                    <iframe className="libraries-iframe" src={this.state.edit_widget_name} height="200"></iframe>
                                </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.deleteItem} data-id={this.state.edit_item_id}>{i18n.t('admin.labels.delete')}</button>
                                <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideEditModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                :
                                <React.Fragment>{i18n.t('admin.btn_ok')}</React.Fragment>
                                }</button>
                            </div>

                            </ValidatorForm>
                            <Errors
                            hasError={this.state.hasError}
                            type="warning"
                            message={this.state.errorMessage}
                            status={this.state.status}
                            />
                            {/* </div>
                            </div> */}

                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

export default Libraries;