import axios from 'axios';
axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
}
export default axios.create({
    baseURL: '/',
})

// export class BaseApi {
//     api = null;
//     constructor() {
//         this.api = axios.create({
//             baseURL: '/',
//         })
//     }

// }
// export class ApiHelper extends BaseApi {
//     get(url, callback) {
//         this.api.get(url)
//             .then(res => {
//                 callback(200, res);
//             })
//             .catch((error) => {
//                 callback(error.status, error);
//             });
//     }

//     post(url, params, callback) {
//         this.api.post(url, params)
//             .then(res => {
//                 callback(200, res);
//             })
//             .catch((error) => {
//                 callback(error.status, error);
//             });
//     }

//     put(url, params, callback) {
//         this.api.put(url, params)
//             .then(res => {
//                 callback(200, res);
//             })
//             .catch((error) => {
//                 callback(error.status, error);
//             });
//     }

//     delete(url, callback) {
//         this.api.delete(url)
//             .then(res => {
//                 callback(200, res);
//             })
//             .catch((error) => {
//                 callback(error.status, error);
//             });
//     }
// }
