import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import { NavDropdown } from 'react-bootstrap';
import TextValidator from "../Common/TextValidator";
import SelectValidator from "../Common/SelectValidator";
import Errors from "../Common/Errors/Errors";
import { event } from "react-ga";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import TextareaValidator from "../Common/TextareaValidator";
//import { useState } from 'react';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


class EducationalLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            libraries: [],
            per_page: 10,
            current_page: 1,
            total_count: 0,
            edit_item_id: null,
            isEditOpen: false,
            library_id: null,
            search_keywords: "",
            description: null,
            logo: null,
            selected_logo: null,
            embed_url: "",
            external_url: null,
            title: null,
            edit_title: null,
            edit_library_id: null,
            edit_embed_url: null,
            edit_external_url: null,
            edit_logo: null,
            edit_description: null,
            edit_search_keywords: null,
            edit_selected_logo: null,
            deleteMultipleItems: [],
            types: [{value: 'search', label: i18n.t('admin.education_links.types.search')}, {value: 'insert', label: i18n.t('admin.education_links.types.insert')}],
            selected_type: 'insert',
            edit_type: ''
        }
        // http://localhost:3000/react_admin/libraries
    }
    async componentDidMount() {
        await this.callApi(this.state.per_page, this.state.current_page)
        //per_page, current_page, current_type, current_status, htmlFormat = null, searchTerm = ''
    }
    async callApi(per_page, current_page) {
        this.setState({ data: [], isLoading: true });
        let { data } = await API.get('/education_contents?per_page='+per_page+'&current_page='+current_page)
        console.log(data);
        let _libraries = []
        data.libraries.forEach(element => {
            _libraries.push({value: element.id, label: element.name})
        });
        this.setState({ data: data.education_contents, total_count: data.total_count, libraries: _libraries, isLoading: false})
    }
    onTableChange = (type, newState) => {
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi(newState.sizePerPage, newState.page)
    }
    clearFields = () => {
        document.getElementById('library-form').reset();
    }

    toBulkUpload = () => {
        // window.location.href = '/admin/libraries/bulk_upload'
    }
    editItem = (event) => {
        let id = event.target.getAttribute('data-id');
        let itemInfo = this.state.data.find((element) => {
            return element.id == id
        })
        
        let type = itemInfo.search_keywords ? 'search' : 'insert';

        this.setState({ edit_item_id: itemInfo.id, edit_library_id: itemInfo.library_id, edit_title: itemInfo.title, edit_embed_url: itemInfo.embed_url, edit_external_url: itemInfo.external_url, edit_description: itemInfo.description, edit_search_keywords: itemInfo.search_keywords, edit_type: type})

        this.showEditModal()
    }
    handleEditSubmit = (event) => {
        // if (this.state.edit_name.trim().length == 0){
        //     this.setState({
        //         isValidate: true,
        //         isValid: false,
        //         hasError: true,
        //         errorMessage: i18n.t('admin.labels.require_name'),
        //         status: null
        //     });
        //     return;
        // }
        // else if(this.state.edit_thumb_url.trim().length == 0) {
        //     this.setState({
        //         isValidate: true,
        //         isValid: false,
        //         hasError: true,
        //         errorMessage: i18n.t('admin.labels.require_thumb_url'),
        //         status: null
        //     }); 
        //   return;
        // }
        if(this.state.edit_type == 'insert' && this.state.edit_embed_url.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.errors.require_embed_url'),
                status: null
            }); 
            return
        }
        else if(this.state.edit_type == 'search' && this.state.edit_search_keywords.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.errors.require_keywords'),
                status: null
            }); 
            return
        }
        this.setState({ isLoading: true});
        this.submitEditItem()
    }
    submitEditItem = () => {
        // const formData = new FormData();
        // formData.append('name', this.state.edit_name);
        // formData.append('library_id', this.state.edit_library_id);
        // formData.append('thumb_url', this.state.edit_thumb_url);
        // formData.append('file_width', this.state.edit_file_width);
        // formData.append('file_height', this.state.edit_file_height);
        // formData.append('embed_html', this.state.edit_embed_html);
        // formData.append('orig_url', this.state.edit_orig_url);
        // API.put('/active_learning_tutorials/'+this.state.edit_item_id, formData)
        //   .then(res => {
        //     window.location.reload();
        //   })
        //   .catch(function (error) {
        //   })

        const formData = new FormData();
        formData.append('title', this.state.edit_title);
        formData.append('library_id', this.state.edit_library_id);
        if(this.state.edit_embed_url)
            formData.append('embed_url', this.state.edit_embed_url || "");
        formData.append('external_url', this.state.edit_external_url);
        formData.append('description', this.state.edit_description);
        if(this.state.edit_logo)
            formData.append('logo', this.state.edit_selected_logo);
        if(this.state.edit_search_keywords)
            formData.append('search_keywords', this.state.edit_search_keywords);
        API.put('/education_contents/'+this.state.edit_item_id, formData)
        .then(res => {
            window.location.reload();
        })
        .catch(function (error) {
        })
    }
    handleOnError = errors => {
        this.setState({
          isValidate: true,
          isValid: false,
          hasError: true,
          errorMessage: getErrorMessage(errors, true),
          status: null
        });
    };
    showEditModal = () => {
        this.setState({ isEditOpen: true, isValidate: false,  isValid: true, hasError: false,errorMessage: [], status: null  })
    }
    hideEditModal = () => {
        this.setState({ isEditOpen: false })
    }
    deleteItem = (event) => {
        let id = event.target.getAttribute('data-id');
        let itemInfo = this.state.data.find((element) => {
            return element.id == id
        })
        
        API.delete('/education_contents/'+itemInfo.id)
          .then(res => {
            window.location.reload();
          })
          .catch(function (error) {
          })
    }
    handleSubmit = (event) => {
       
        if(this.state.selected_type == 'insert' && this.state.embed_url.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.errors.require_embed_url'),
                status: null
            }); 
            return
        }
        else if(this.state.selected_type == 'search' && this.state.search_keywords.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.errors.require_keywords'),
                status: null
            }); 
            return
        }
        this.setState({ isLoading: true });
        this.createNewEducationContent()
    }
    createNewEducationContent = () => {
        const formData = new FormData();
        formData.append('title', this.state.title);
        formData.append('library_id', this.state.library_id);
        if(this.state.embed_url)
            formData.append('embed_url', this.state.embed_url || "");
        formData.append('external_url', this.state.external_url);
        formData.append('description', this.state.description);
        formData.append('logo', this.state.selected_logo);
        if(this.state.search_keywords)
            formData.append('search_keywords', this.state.search_keywords);
        API.post('/education_contents', formData)
        .then(res => {
            window.location.reload();
        })
        .catch(function (error) {
        })
    }
    rankFormatter = (cell, row) => {
        let item_id = row.id
        return (
          <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item href={void (0)} data-id={item_id} onClick={this.editItem}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
            <NavDropdown.Item href={void (0)} data-id={item_id} onClick={this.deleteItem}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
          </NavDropdown>
        );
    }
    handleChange = event => {
        if(event.target.name === "logo"){
            this.setState({
                selected_logo: event.target.files[0]
            });    
        }
        if(event.target.name === "edit_logo"){
            this.setState({
                edit_selected_logo: event.target.files[0]
            });    
        }
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    removeItemFromList = (itemId) => {
        let newItems = this.state.deleteMultipleItems.filter((item) => item.id != itemId)
        setTimeout(() => {
            this.setState({deleteMultipleItems: newItems})
        })
    }
    multiSelectCalled = (row, isSelect, rowIndex, e) => {
        if(isSelect){
            this.state.deleteMultipleItems.push(row);
            setTimeout(() => {
                this.setState({deleteMultipleItems: this.state.deleteMultipleItems})
            }, 0)
        }
        else{
            this.removeItemFromList(row.id)
        }
    }
    multiSelectAllCalled = (isSelect, rows, e) => {
        if(isSelect){
            this.setState({deleteMultipleItems: rows})
        }
        else{
            this.setState({deleteMultipleItems: []})
        }
    }
    bulkDelete = (ev) => {
        const formData = new FormData();
        this.state.deleteMultipleItems.forEach((item) => {
            formData.append('items[]', item.id);
        })
        API.post('/education_contents/bulk_delete', formData)
          .then(res => {
            window.location.reload();
          })
          .catch(function (error) {
        })
    }
    render() {
        const selectRowProp = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.multiSelectCalled,
            onSelectAll: this.multiSelectAllCalled,
        };
        const columns = [{
            dataField: 'title',
            text: i18n.t('admin.labels.title').toUpperCase(),
            sort: true
          }, {
            dataField: 'description',
            text: i18n.t('admin.labels.description').toUpperCase(),
            sort: true,
          }, {
            dataField: 'search_keywords',
            text: i18n.t('admin.labels.keywords').toUpperCase(),
            sort: true,
          },{
            dataField: 'external_url',
            text: i18n.t('admin.labels.external_url').toUpperCase(),
            sort: true
          },{
            dataField: 'embed_url',
            text: i18n.t('admin.labels.embed_url').toUpperCase(),
            sort: true
          },
          {
            dataField: 'library_name',
            text: i18n.t('admin.labels.library_name').toUpperCase(),
            sort: false,
          },{
            dataField: 'moreOptions',
            formatter: this.rankFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
          }];
          const options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
          };
          const paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
          };
          const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
          }];

        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        }
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content libraries-tabel">
                        <div className="admin-custom-table-header">
                            <button data-bs-toggle="modal" data-bs-target="#addEducationLinkModal" className="btn btn-primary mb-3 mt-1 btn-add-educationlink">{i18n.t('admin.education_links.btn_add')}</button>
                            <button onClick={this.bulkDelete} disabled={this.state.deleteMultipleItems.length <= 0} data-bs-toggle="modal" className="btn btn-primary mb-3 mt-1 ml-3 pl-3 pr-3 btn-delete-multiple">{i18n.t('admin.education_links.btn_delete_multi')}</button>
                        </div>
                        <BootstrapTable
                            keyField='id'
                            options={options}
                            data={this.state.data}
                            fetchInfo={{ dataTotalSize: this.state.total_count }}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            bordered={false}
                            noDataIndication={() => <NoDataIndication />}
                            remote
                            pagination={paginationFactory(paginatorOptions)}
                            onTableChange={this.onTableChange}
                            selectRow={ selectRowProp }
                            //rowEvents={rowEvents}
                        />
                    </div>
                    {/* Add Tutorials Modal */}
                    <div className="modal fade" id="addEducationLinkModal" tabIndex="-1">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <ValidatorForm
                                    id="addEducationLinkForm"
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className=""
                                >
                                    <div className="modal-header modal-image-header">
                                        <div className="modal-header-content">
                                            <h4 className="modal-title">{i18n.t("admin.education_links.add_modal.title")}</h4>
                                        </div>
                                        <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="middle-sec">
                                            <div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('admin.labels.select_library')}</span>
                                                    </div>
                                                    <div>
                                                        <SelectValidator
                                                            tabIndex='1'
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            placeholder={i18n.t('admin.placeholder.select_library')}
                                                            name="library_id"
                                                            options={this.state.libraries}
                                                            value={this.state.library_id}
                                                            onChange={this.handleChange}
                                                            isValidate={this.state.isValidate}
                                                            validators={["required"]}
                                                            errorMessages={[
                                                                i18n.t('admin.errors.require_library')
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('admin.labels.select_edu_type')}</span>
                                                    </div>
                                                    <div>
                                                        <SelectValidator
                                                            tabIndex='1'
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            placeholder={i18n.t('admin.placeholder.select_edu_type')}
                                                            name="selected_type"
                                                            options={this.state.types}
                                                            value={this.state.selected_type}
                                                            onChange={this.handleChange}
                                                            isValidate={this.state.isValidate}
                                                            validators={["required"]}
                                                            errorMessages={[
                                                                i18n.t('admin.errors.require_edu_type')
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.title")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="title"
                                                            placeholder={i18n.t("admin.placeholder.title")}
                                                            value={this.state.title}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_title")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.description")}</span>
                                                    </div>
                                                    <div>
                                                        <TextareaValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            name="description"
                                                            placeholder={i18n.t("admin.placeholder.description")}
                                                            value={this.state.description}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_thumb_url")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.logo")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='3'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="file"
                                                            name="logo"
                                                            placeholder={i18n.t("admin.placeholder.logo")}
                                                            value={this.state.logo}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_logo")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.external_url")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="external_url"
                                                            placeholder={i18n.t("admin.placeholder.external_url")}
                                                            value={this.state.external_url}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_external_url")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.selected_type == 'insert' ? 
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.embed_url")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="embed_url"
                                                            placeholder={i18n.t("admin.placeholder.embed_url")}
                                                            value={this.state.embed_url}
                                                            novalidate
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                        />
                                                    </div>
                                                </div> : null }
                                                {this.state.selected_type == 'search' ? 
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.keywords")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            accept="image/*"
                                                            type="text"
                                                            name="search_keywords"
                                                            placeholder={i18n.t("admin.placeholder.keywords")}
                                                            value={this.state.search_keywords}
                                                            novalidate
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                        />
                                                    </div>
                                                </div>
                                                 : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal">Cancel</button>
                                        <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                            <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                            :
                                            <React.Fragment>{i18n.t('admin.education_links.add_modal.btn_add')}</React.Fragment>
                                        }</button>
                                    </div>
                                </ValidatorForm>
                                <Errors
                                    hasError={this.state.hasError}
                                    type="warning"
                                    message={this.state.errorMessage}
                                    status={this.state.status}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Edit Tutorials Modal */}
                    <div>
                        <Modal show={this.state.isEditOpen}>
                            <ValidatorForm
                            id="editItemForm"
                            ref="form"
                            onSubmit={this.handleEditSubmit}
                            onError={this.handleOnError}
                            instantValidate={false}
                            className=""
                            >
                            <div className="modal-header modal-image-header">
                                <div className="modal-header-content">
                                <h4 className="modal-title">{i18n.t('admin.education_links.edit_modal.title')}</h4>
                                </div>
                                <button type="button" className="close" onClick={this.hideEditModal}>
                                <span className="lh-icon-os-close"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                        <div className="middle-sec">
                                            <div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('admin.labels.select_library')}</span>
                                                    </div>
                                                    <div>
                                                        <SelectValidator
                                                            tabIndex='1'
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            placeholder={i18n.t('admin.placeholder.select_library')}
                                                            name="edit_library_id"
                                                            options={this.state.libraries}
                                                            value={this.state.edit_library_id}
                                                            onChange={this.handleChange}
                                                            isValidate={this.state.isValidate}
                                                            validators={["required"]}
                                                            errorMessages={[
                                                                i18n.t('admin.errors.require_library')
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.title")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="edit_title"
                                                            placeholder={i18n.t("admin.placeholder.title")}
                                                            value={this.state.edit_title}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_title")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.description")}</span>
                                                    </div>
                                                    <div>
                                                        <TextareaValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            name="edit_description"
                                                            placeholder={i18n.t("admin.placeholder.description")}
                                                            value={this.state.edit_description}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_thumb_url")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.logo")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='3'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="file"
                                                            name="edit_logo"
                                                            placeholder={i18n.t("admin.placeholder.logo")}
                                                            value={this.state.edit_logo}
                                                            novalidate
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            // errorMessages={[
                                                            //     i18n.t("admin.errors.require_logo")
                                                            // ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.external_url")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="edit_external_url"
                                                            placeholder={i18n.t("admin.placeholder.external_url")}
                                                            value={this.state.edit_external_url}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_external_url")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                { this.state.edit_type == 'insert' ? 
                                                    <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.embed_url")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="edit_embed_url"
                                                            placeholder={i18n.t("admin.placeholder.embed_url")}
                                                            value={this.state.edit_embed_url}
                                                            novalidate
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                        />
                                                    </div>
                                                    </div> : null 
                                                }
                                                { this.state.edit_type == 'search' ? 
                                                    <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.keywords")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            accept="image/*"
                                                            type="text"
                                                            name="edit_search_keywords"
                                                            placeholder={i18n.t("admin.placeholder.keywords")}
                                                            value={this.state.edit_search_keywords}
                                                            novalidate
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                        />
                                                    </div>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.deleteItem} data-id={this.state.edit_item_id}>{i18n.t('admin.labels.delete')}</button>
                                <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideEditModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                :
                                <React.Fragment>{i18n.t('admin.btn_ok')}</React.Fragment>
                                }</button>
                            </div>

                            </ValidatorForm>
                            <Errors
                            hasError={this.state.hasError}
                            type="warning"
                            message={this.state.errorMessage}
                            status={this.state.status}
                            />
                            {/* </div>
                            </div> */}

                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(EducationalLinks)
