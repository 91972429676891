import React from "react";

class Switch extends React.Component {
    constructor(props) {
        super(props);
    }

    handleChange = event => {
        if(this.props.onChange) {
            this.props.onChange({
                ...event,
                target:{
                    name: event.target.name,
                    value: !(event.target.value.toString().trim() == "true")
                }
            })
        }
    }

    render() {
        const { wrapperClass, className, swichType , prefixLabel, suffixLabel, labelClass, ...rest } = this.props;
        return (
            <div className={ "toogle-switch " + wrapperClass }>
                { prefixLabel ? <label className={labelClass}>{prefixLabel}</label> : null} 
                <label className={className}>
                    <input 
                        { ...rest}
                        ref={(r) => { this.input = r; }}
                        onChange={this.handleChange}
                    />
                    <span className={"slider " + swichType} />
                </label>
                { suffixLabel ? <label className={labelClass}>{suffixLabel}</label> : null} 
            </div>
        );
    }
}

Switch.defaultProps = {
    swichType: 'round',
    className: 'switch mr-3'
}

export default Switch;
