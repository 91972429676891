import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { getAvailableLanguages } from '../Common/i3library';
import LanguageSelection from '../LanguageOptions/LanguageSelection';
import { PACK_WELCOME_IMG } from '../Common/assets_paths';

class PackWelcome extends React.Component{
    constructor(props) {
        super(props);
        const language = this.props.match.params ? this.props.match.params.locale : 'en';
        const pack_key = this.props.match.params ? this.props.match.params.pack_key : null;
        const languagesOptions = getAvailableLanguages()
        this.state = {
          language: language,
          languageOpt: languagesOptions,
          url_locale: props.match.params.locale,
          pack_key: pack_key,
          next_page_url : pack_key ? language+"/packsignup/"+pack_key+"/register" : language+"/packsignup/register",
          pathname : pack_key ? "packsignup/"+pack_key : "packsignup"
        }
    }
    render(){
        return (
          <React.Fragment>
            <LandingStepBase pack_signup={true} container={
                <div className="container"> 
                     <div className="row" style={{pointerEvents: this.state.disableClick ? 'none' : 'all'}}>
                        <div className="col-sm-12 p-0 text-center">
                            <div className="headtxt animated fadeInDown mt-5">
                                <h1 className={'text-white'}>{i18n.t('packsignup.welcome.header.title')} </h1>
                                <p>{i18n.t('packsignup.welcome.header.sub_title')}</p>
                            </div> 
                        
                            <div className = "main-wrapper">
                                <div className = "col-sm-12 text-center">
                                    <img src={PACK_WELCOME_IMG}></img>
                                </div>
                                <div className = "col-sm-12 p-0 text-center">
                                    <Link className={'btn-accenta btn-lg btn-block'} to={{pathname: `/${this.state.next_page_url}`}}>{i18n.t('packsignup.welcome.btn_setup')}</Link>
                                </div>
                            </div>
                        </div>
                         <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="mobile mt-3" redirectPath={this.state.pathname+"/"+this.props.location.search} /> 
                    </div>
                </div> 
            } ></LandingStepBase>
            <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="desktop lang-top-right" redirectPath={this.state.pathname+"/"+this.props.location.search} />
         </React.Fragment>
        )
    }
}

export default withTranslation()(PackWelcome);