import React, { Suspense } from "react";
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import { BrowserRouter, Route, Switch, browserHistory, useParams } from "react-router-dom";
import { Router, withRouter } from 'react-router';
//import ErrorBoundary from "./ErrorBoundry";
//import { CSSTransition, TransitionGroup, Transition } from "react-transition-group";
/*import {TimelineLite as Timeline} from "greensock";*/
//import { play, exit, enterDuration } from '../Animation'
//import { getQueryParam } from '../Common/i3library';
import Header from './Header'
import Users from "./Users";
import ViewUser from "./ViewUser";
import Distributors from "./Distributors";
import Invitations from "./Invitations";
import Payments from "./Payments";
import Vouchers from "./Vouchers";
import Reseller from "./Reseller";
import Reminders from "./Reminders";
import Enqueued from "./Delayed-Jobs/Enqueued";
import Failed from "./Delayed-Jobs/Failed";
import Pending from "./Delayed-Jobs/Pending";
import Working from "./Delayed-Jobs/Working";
import Libraries from "./Libraries";
import Preferences from "./Preferences";
import ShowJob from "./Delayed-Jobs/ShowJob";
import BoardCodes from "./BoardCodes";
import Oauth from "./Oauth";
import ViewOauth from "./ViewOauth";
import BulkUpload from "./BulkUpload";
import Keyboards from "./Keyboards";
import Email from "./Email";
import ManagePage from "./Splash-Page/SplashPage";
import ManageArticles from "./Splash-Page/Articles";
import NewPage from "./Splash-Page/NewSplashPage";
import EditPage from "./Splash-Page/EditSplashPage";
import NewArticle from "./Splash-Page/NewArticle";
import EditArticle from "./Splash-Page/EditArticle";
import ViewReseller from "./ViewReseller";
import Institutions from "./Institutions";
import ViewInstitute from "./ViewInstitute";
import NotFound from "../Common/NotFound";
import GlobalSearch from "./GlobalSearch";
import ActiveLearning from "./ActiveLearning";
import EducationalLinks from "./EducationalLinks";
import { withTranslation } from "react-i18next";

class Landing extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="admin-container">
                <div className="primarygradient admin-header">
                    <Header {...this.props} top_admin_email={this.props.top_admin_email}/>
                </div>
                <div className="admin-body">
                    <Switch>
                        <Route exact path={`${this.props.match.url}/`} component={() => this.props.is_top_admin ? <Users {...this.props} is_top_admin={this.props.is_top_admin} is_reseller={this.props.is_reseller}/> : <NotFound />} />
                        <Route exact path={`${this.props.match.url}/users/:type`} component={() => this.props.is_top_admin ? <Users {...this.props} is_top_admin={this.props.is_top_admin} is_reseller={this.props.is_reseller}/> : <NotFound />} />
                        <Route exact path={`${this.props.match.url}/user/view/:user_id`} component={() => <ViewUser {...this.props} is_top_admin={this.props.is_top_admin} is_reseller={this.props.is_reseller}/>} />
                        {/* <Route exact path={`${this.props.match.url}/users/view`} component={() => <ViewUser {...this.props} />} /> */}
                        <Route exact path={`${this.props.match.url}/institutions/:type`} component={() => this.props.is_top_admin ? <Institutions {...this.props} /> : <NotFound />} />
                        <Route exact path={`${this.props.match.url}/institution/view/:group_id`} component={() => <ViewInstitute {...this.props} is_top_admin={this.props.is_top_admin} />} />
                        <Route exact path={`${this.props.match.url}/distributors`} component={() => this.props.is_top_admin ? <Distributors {...this.props} /> : <NotFound />} />
                        <Route exact path={`${this.props.match.url}/distributor/view/:distibutor_id`} component={() => (this.props.is_top_admin || this.props.is_distributor) ? <Reseller {...this.props} /> : <NotFound />} />
                        <Route exact path={`${this.props.match.url}/reseller/view/:reseller_id`} component={() => (this.props.is_top_admin || this.props.is_reseller || this.props.is_distributor) ? <ViewReseller {...this.props} is_top_admin={this.props.is_top_admin} is_reseller={this.props.is_reseller} /> : <NotFound />} />
                        <Route exact path={`${this.props.match.url}/invitations/:type`} component={() => this.props.is_top_admin ? <Invitations {...this.props} /> : <NotFound />} />
                        <Route exact path={`${this.props.match.url}/payments`} component={() => this.props.is_top_admin ? <Payments {...this.props} /> : <NotFound />} />
                        <Route exact path={`${this.props.match.url}/vouchers`} component={() => this.props.is_top_admin ? <Vouchers {...this.props} /> : <NotFound />} />
                        <Route exact path={`${this.props.match.url}/subscription_reminders`} component={() => <Reminders {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/email`} component={() => <Email {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/manage_pages`} component={() => <ManagePage {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/manage_pages/new`} component={() => <NewPage {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/manage_pages/edit/:page_id`} component={() => <EditPage {...this.props} />} />
                        {/* <Route exact path={`${this.props.match.url}/articles`} component={() => <ManageArticles {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/articles/new`} component={() => <NewArticle {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/articles/edit`} component={() => <EditArticle {...this.props} />} /> */}
                        {/* <Route exact path={`${this.props.match.url}/delayed_jobs/enqueued`} component={() => <Enqueued {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/delayed_jobs/pending`} component={() => <Pending {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/delayed_jobs/working`} component={() => <Working {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/delayed_jobs/failed`} component={() => <Failed {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/delayed_jobs/show`} component={() => <ShowJob {...this.props} />} /> */}
                        {/* <Route exact path={`${this.props.match.url}/libraries`} component={() => <Libraries {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/libraries/bulk_upload`} component={() => <BulkUpload {...this.props} />} /> */}
                        <Route exact path={`${this.props.match.url}/preferences`} component={() => <Preferences {...this.props} />} />
                        {/* <Route exact path={`${this.props.match.url}/physical_board_codes`} component={() => <BoardCodes {...this.props} />} /> */}
                        <Route exact path={`${this.props.match.url}/oauth`} component={() => <Oauth {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/oauth/view/:oauth_id`} component={() => <ViewOauth {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/search/:search_term/location/:location`} component={() => <GlobalSearch {...this.props} ></GlobalSearch>} />
                        <Route exact path={`${this.props.match.url}/libraries`} component={() => <Libraries {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/activelearning`} component={() => <ActiveLearning {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/keyboards`} component={() => <Keyboards {...this.props} />} />
                        <Route exact path={`${this.props.match.url}/educationallinks`} component={() => <EducationalLinks {...this.props} />} />
                    </Switch>
                </div>
                <div className="primarygradient admin-footer">
                    <div className='version-info mt-1 text-white'>
                      <p>
                        <span>{this.props.VERSION_INFO.version} ( B{this.props.VERSION_INFO.build_number} ) ( {this.props.VERSION_INFO.deployed_date} )</span>
                      </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(withRouter(Landing));