import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import PlanDetails from './PlanDetails';
import i18n from '../../packs/i18n';
import API from '../api';
import PlanItem from './PlanItem';
// import RotateSlider from '../Common/Slide';
import { DownArrow_IMG, PACK_FREE_IMG, PACK_INDI_IMG, PACK_TEAM_IMG } from "../Common/assets_paths";
import { getLocalUrl, getAvailableLanguages } from '../Common/i3library';
import { withTranslation } from 'react-i18next';
import LanguageSelection from '../LanguageOptions/LanguageSelection';

class Pricing extends React.Component {
	constructor(props) {
      super(props);
      const language = props.user_language || 'en';
      const languagesOptions = getAvailableLanguages();

      this.state = {
        plans: [],
        features: [],
        planDetails:null,
        total_plans: 3,
        plansRendered: false,
        language: language,
      	languageOpt: languagesOptions
      }
      this.refPlanDetail = React.createRef();
      this.scroll = this.scroll.bind(this);
    }
    UNSAFE_componentWillMount() {
    	this.fetchPricingPlans();
    }
    createPlanItems = async(data,features) =>{
      const currentPlan =  null;
      const userInfo = null;
      const _plans = [];
      for (var i in data) {
        if (_plans.length < this.state.total_plans) {
            let img_path = null;
            let pack_color = null;
            if(i === "free"){
                img_path = PACK_FREE_IMG;
                pack_color = "#e5c5e8";
            }
            else if( i === 'individual'){
                img_path = PACK_INDI_IMG
                pack_color = "#acb5da";
            }
            else if(i === 'team'){
                img_path = PACK_TEAM_IMG
                pack_color = "#bfd0e8";
            }
          _plans.push(<PlanItem
            handler={this.handleChoosePlan.bind(this)}
            key={i}
            plan_detail={data[i]}
            plan_key={i}
            current_plan={currentPlan}
            userInfo = {userInfo}
            muchMoreHandler={this.scroll.bind(this)}
            plan_image_path = {img_path}
            plan_background_color = {pack_color}
            is_from_pricing = {true}
          />);
        }
      }
      this.setState({
        plans: _plans,
        features: features,
        planDetails: data,
        plansRendered: true
      })
    }
    handleChoosePlan(details, key, userInfo, is_from_pricing) {
    	window.location.href = "/"+this.state.language + "/signup"
    }
    fetchPricingPlans() {
        API.get('pricing/plans')
        .then(res => {
          this.createPlanItems(res.data[0], res.data[1]);
        })
        .catch(function (error) {
            if(error.response) {
            }
            if(error.request) {
              // console.log(error.request);
            }
            else {}
        });
    }

    scroll() {
        this.refPlanDetail.refPlanSection.scrollIntoView({ speed: '10', behavior: 'smooth' });
    }
    render() {
    	const currentPlan =  null;
        const userInfo =  null;
        const upgradePlan = null;
        return (
	    	<React.Fragment>
	    		<LandingStepBase container={
	                    <div className="container">
	                        <div className="row">
	                            <div className="col-sm-12 p-0 text-center">
	                                <div className="headtxt animated fadeInDown mt-5 mb-5">
	                                    <h1 className="text-white mb-0"> {i18n.t('signup.step3.header.title_pricing')} </h1>
	                                </div>
	                            </div>
	                        </div>
	                        <div className="row package-slider">
	                            {this.state.plansRendered ?
	                                // <RotateSlider sliderClass='slider-item'>
	                                this.state.plans : null
	                                // </RotateSlider> : null
	                            }
	                        </div>
	                        <div className="row">
	                            <div className="col-sm-12 text-center mt-5 mb-2">
	                                <a href="javascript:void(0)" onClick={this.scroll} className="downlink text-decoration-none"><img src={DownArrow_IMG} /></a>
	                            </div>
	                        </div>
	                    </div>
	                }

	                subcontainer={ this.state.plansRendered ? <PlanDetails handler={this.handleChoosePlan.bind(this)} ref={(node) => { this.refPlanDetail = node; }}  features={this.state.features} plans={this.state.planDetails} is_from_pricing = {true} current_plan={currentPlan}  />:null}
	            ></LandingStepBase>
	            <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="desktop" redirectPath="pricing" />
	    	</React.Fragment>
    	);
    };

}

export default withTranslation() (Pricing);