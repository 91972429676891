import React from 'react';
import { withRouter } from "react-router-dom";
import i18n from '../../packs/i18n';
import { NotFound_IMG } from './assets_paths';
import { withTranslation } from 'react-i18next';

class NotFound extends React.Component {
    
    constructor(props) {
        super(props);
    }

    handleBackButton = () => { 
        // this.props.history.push(this.props.history.goBack());
        this.props.history.goBack();
        window.location.reload();
    }
    
    handleHomeButton = () => { 
        this.props.history.push('/');
        window.location.reload();
    }
    
    render() {
        // ILEARN-4820 Handling for Mirror route not found in react
        if(this.props.location&&this.props.location.pathname&&this.props.location.pathname.includes('/mirror/'))
        {
            window.location.href = this.props.location.pathname
            return;
        }
        //ILEARN-4820 End
        return (
            <div className="error-wrapper text-center">
                <div>
                    <h1 className="title mb-4">{i18n.t('common.notfound.title')}</h1>
                    <h1 className="subtitle mb-4">{i18n.t('common.notfound.message')}</h1>
                </div>
                <div className="m-5">
                    <img src={NotFound_IMG}  alt="Error" className='error-img' />
                </div>
                <div className="error-button-wrapper">
                    <div className="p-2">
                        <button className="btn btn-block btn-secondary pl-5 pr-5" onClick={this.handleBackButton}>BACK</button>
                    </div>
                    <div className="p-2">
                        <button className="btn btn-block btn-outline-secondary" onClick={this.handleHomeButton}>HOMEPAGE</button>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(withTranslation()(NotFound));