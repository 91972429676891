import React, { Component } from 'react';
import ReactGA from 'react-ga';

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      if(page.includes('signup/selectpackage'))
      {
        trackPage(page.split('selectpackage/')[0] + 'selectpackage');
      }
      else if (page.includes('signup/paymentmethod'))
      {
        trackPage(page.split('paymentmethod/')[0] + 'paymentmethod');
      }
      else if (page.includes('signup/payment')){
        trackPage(page.split('payment/')[0] + 'payment');
      }
      else{
        trackPage(page);
      }
      
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        if(nextPage.includes('signup/selectpackage'))
        {
          trackPage(nextPage.split('selectpackage/')[0] + 'selectpackage');
        }
        else if (nextPage.includes('signup/paymentmethod'))
        {
          trackPage(nextPage.split('paymentmethod/')[0] + 'paymentmethod');
        }
        else if (nextPage.includes('signup/payment')){
          trackPage(nextPage.split('payment/')[0] + 'payment');
        }
        else{
          trackPage(nextPage);
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}