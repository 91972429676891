import React from "react";
import LandingStepBase from "../Common/Layouts/LandingStepBase";
import { withRouter } from "react-router-dom";
import i18n from "../../packs/i18n";
import Errors from "../Common/Errors/Errors";
import { withTranslation } from "react-i18next";
class ConnectSessionMessage extends React.Component {
    constructor(props) {
        super(props);
        console.log();
    }
    handleRetry = event =>{
        window.location.href = this.props.mirror_google_oauth_retry_url
    }
    render() {
        return (
            <React.Fragment>
              <LandingStepBase pack_signup={true}
                container={
                    <div className="container mt-7">
                        <div className="row">
                            <div className="col-sm-12 p-0 text-center">
                                <div className="headtxt animated fadeInDown mt-5">
                                    <h1 className="text-white">{i18n.t("connect.retry_title")}</h1>
                                    <p className="mt-3">{this.props.mirror_google_oauth_message}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-lg-4"></div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                            <button
                            type="button"
                            className={"btn btn-accenta btn-lg btn-block mt-4"}
                            onClick={this.handleRetry}
                            >
                            {i18n.t("connect.retry_button")}
                            </button>
                            </div>
                            <div className="col-md-4 col-lg-4"></div>
                        </div>
                    </div>
                }
            ></LandingStepBase>
        </React.Fragment>
    ); 
    }
}
export default withTranslation()(withRouter(ConnectSessionMessage));