import React from 'react';
import { Link } from 'react-router-dom';
import { checkIsActivePath } from '../Common/Helpers/AppHelper';
import i18n from '../../packs/i18n';
import { getLocalUrl, isProTagAdd } from '../Common/i3library';

class UserProfileSidebar extends React.Component {

    handleLinkClickEvent = (event, path) => {
        if(this.props.location.pathname === path){
            event.preventDefault();
        }
    }
    render() {
        const isProTag = isProTagAdd();
        return (
            <React.Fragment>
                <h3 className="text-white mb-3">{i18n.t('userprofile.main_heading')}</h3>

                <ul className="list-unstyled sidebar-nav">
                    <li className={"ellipse " + (checkIsActivePath('/users/profile') ? "active" : "")}>
                        <Link onClick={ (event) => this.handleLinkClickEvent(event, getLocalUrl(this.props.match.params.locale , 'users/profile')) } to={getLocalUrl(this.props.match.params.locale , 'users/profile')} className="unstyled p-2">{i18n.t('userprofile.sidebar.1')}</Link>
                    </li>
                    <li className={"ellipse " + (checkIsActivePath(isProTag ? 'users/profile/connect-to-apps/pro' : 'users/profile/connect-to-apps') ? "active" : "")}>
                        <Link onClick={ (event) => this.handleLinkClickEvent(event, getLocalUrl(this.props.match.params.locale , isProTag ? 'users/profile/connect-to-apps/pro' : 'users/profile/connect-to-apps')) }  to={getLocalUrl(this.props.match.params.locale , isProTag ? 'users/profile/connect-to-apps/pro' : 'users/profile/connect-to-apps')} 
                        className={ "unstyled p-2 " + (isProTag ? 'pro' : '')}>{i18n.t('userprofile.sidebar.2')}</Link>
                    </li>
                    <li className={"ellipse " + (checkIsActivePath('/users/profile/admin-your-apps') ? "active" : "")}>
                        <Link onClick={ (event) => this.handleLinkClickEvent(event, getLocalUrl(this.props.match.params.locale , 'users/profile/admin-your-apps'))} to={getLocalUrl(this.props.match.params.locale , 'users/profile/admin-your-apps')} 
                        className="unstyled p-2">{i18n.t('userprofile.sidebar.3')}</Link>
                    </li>
                    <li className={"ellipse " + (checkIsActivePath(isProTag ? 'users/profile/links-accounts/pro' : '/users/profile/links-accounts') ? "active" : "")}>
                        <Link onClick={ (event) => this.handleLinkClickEvent(event, getLocalUrl(this.props.match.params.locale , isProTag ? 'users/profile/links-accounts/pro' : 'users/profile/links-accounts')) } to={getLocalUrl(this.props.match.params.locale , isProTag ? 'users/profile/links-accounts/pro' : 'users/profile/links-accounts')} 
                        className={"unstyled p-2 " + (isProTag ? 'pro' : '')}>{i18n.t('userprofile.sidebar.4')}</Link>
                    </li>
                    <li className={"ellipse " + (checkIsActivePath('/users/profile/manage-subscriptions') ? "active" : "")}>
                        <Link onClick={ (event) => this.handleLinkClickEvent(event, getLocalUrl(this.props.match.params.locale , 'users/profile/manage-subscriptions')) } to={getLocalUrl(this.props.match.params.locale , 'users/profile/manage-subscriptions')} className="unstyled p-2">{i18n.t('userprofile.sidebar.5')}</Link>
                    </li>
                    <li className={"ellipse " + (checkIsActivePath(isProTag ? 'users/profile/preferences/pro' : '/users/profile/preferences') ? "active" : "")}>
                        <Link onClick={ (event) => this.handleLinkClickEvent(event, getLocalUrl(this.props.match.params.locale , isProTag ? 'users/profile/preferences/pro' : 'users/profile/preferences')) } to={getLocalUrl(this.props.match.params.locale , isProTag ? 'users/profile/preferences/pro' : 'users/profile/preferences')} 
                        className={"unstyled p-2 " + (isProTag ? 'pro' : '')}>{i18n.t('userprofile.sidebar.6')}</Link>
                    </li>
                    <li className={"ellipse " + (checkIsActivePath('/users/profile/change_password') ? "active" : "")}>
                        <Link onClick={ (event) => this.handleLinkClickEvent(event, getLocalUrl(this.props.match.params.locale , 'users/profile/change_password')) } to={getLocalUrl(this.props.match.params.locale , 'users/profile/change_password')} className="unstyled p-2">{i18n.t('userprofile.sidebar.7')}</Link>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}

export default UserProfileSidebar;