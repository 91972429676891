import React from "react";

class ShowJob extends React.Component {
    constructor(props) {
        super(props);
    }

    routeBackToListing = () => {
        window.location.href = "/admin/delayed_jobs/enqueued"
    }

    render() {
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div className="row">
                            <div className="col back-to-listing" >
                                <span className="back-icon lh-icon-os-back" onClick={this.routeBackToListing}>
                                </span>
                                <span className="user-info">
                                    Back to Listing
                                </span>
                            </div>
                            <div className="col show-job-btn">
                                <button className="btn btn-primary mb-3 mt-1 mr-3">Delete</button>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="show-job-header">
                                Job #1234
                            </div>
                            <div className="col-md-12 library-content">
                                <div className="col-md-6 mt-3 mb-3 details-1">
                                    <div className="row g-3 align-items-center mb-3">
                                        <div className="col-auto">
                                            <label htmlFor="library" className="col-form-label">Queue: </label>
                                        </div>
                                        <div className="col-auto">
                                            <span className="show-job-details">test</span>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-items-center mb-3">
                                        <div className="col-auto">
                                            <label htmlFor="education" className="col-form-label">ID: </label>
                                        </div>
                                        <div className="col-auto">
                                            <span className="show-job-details">test</span>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-items-center mb-3">
                                        <div className="col-auto">
                                            <label htmlFor="category" className="col-form-label">Attempts: </label>
                                        </div>
                                        <div className="col-auto">
                                            <span className="show-job-details">test</span>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-items-center">
                                        <div className="col-auto">
                                            <label htmlFor="level1" className="col-form-label">Priority: </label>
                                        </div>
                                        <div className="col-auto">
                                            <span className="show-job-details">test</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mb-3 details-2">
                                    <div className="row g-3 align-items-center mb-3">
                                        <div className="col-auto">
                                            <label htmlFor="level2" className="col-form-label">Details: </label>
                                        </div>
                                        <div className="col-auto job-details">
                                            <span className="show-job-details">test</span>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-items-center mb-3">
                                        <div className="col-auto">
                                            <label htmlFor="type" className="col-form-label">Run At: </label>
                                        </div>
                                        <div className="col-auto">
                                            <span className="show-job-details">test</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShowJob;