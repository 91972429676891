import React from "react";
import i18n from "../../../packs/i18n";
import { withTranslation } from 'react-i18next';
import { CKEditor } from 'ckeditor4-react';
import { getAvailableLanguages } from "../../Common/i3library";
import API from '../../api';
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import SelectValidator from "../../Common/SelectValidator";
import TextValidator from "../../Common/TextValidator";
import Errors from "../../Common/Errors/Errors";
import { getErrorMessage } from "../../Common/Helpers/AppHelper";
import Modal from "react-bootstrap/Modal";
class EditPage extends React.Component {
    constructor(props) {
        super(props);
        let path_array = props.location.pathname.split("/manage_pages/edit/")
        const languagesOptions = getAvailableLanguages();
        this.state= {
            content: '',
            languageOpt: languagesOptions,
            language: '',
            title: '',
            subtitle: '',
            isValidate: false,
            isValid: false,
            hasError: false,
            errorMessage: "",
            current_page_id: parseInt(path_array[path_array.length - 1]),
            status: null,
            isPreviewModalOpen: false,
            current_preview_url: ''
        }
    }
    UNSAFE_componentWillMount() {
        this.getPageDetails()
    }
    async getPageDetails() {
        await API.get('/manage_pages/' + this.state.current_page_id)
            .then(res => {
                if (res.data) {
                    console.log(res.data)
                    let pageData = res.data.page;
                    this.setState({
                        language: pageData.language,
                        title: pageData.title,
                        subtitle: pageData.sub_title,
                        content: pageData.content
                    })
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                }
                if (error.request) {
                    console.log(error.request);
                }
                else {
                    console.log(error)
                }
            });
    }
    routeBackToPage = () => {
        window.location.href = '/admin/manage_pages'
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };
    handleChange = event => {
        
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value
        });
    };
    handleSubmit = event => {
        if (!this.state.shouldBlockNavigation) {
            return;
        }
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append('page_content[language]', this.state.language);
        formData.append('page_content[title]', this.state.title);
        formData.append('page_content[sub_title]', this.state.subtitle);
        formData.append('page_content[content]', this.state.content);
        let _this = this;
        API.put('/manage_pages/'+this.state.current_page_id, formData)
            .then(res => {
                _this.routeBackToPage()
            })
            .catch(function (error) {
                alert(error.message)
            })

    }
    updateContent = ( event ) => {
        if(event && event.editor){
            let data = event.editor.getData();
            this.setState({content: data})
        }
    }
    showPreviewModal = () => {
        this.setState({isPreviewModalOpen: true})
    }
    hidePreviewModal = () => {
        this.setState({isPreviewModalOpen: false, current_preview_url: null})
    }
    uploadPreviewAndDisplay = () => {
        const formData = new FormData();
        formData.append('page_content[language]', this.state.language);
        formData.append('page_content[title]', this.state.title);
        formData.append('page_content[sub_title]', this.state.subtitle);
        formData.append('page_content[content]', this.state.content);
        let _this = this;
        API.post('/manage_pages/save_preview', formData)
            .then(res => {
    
                _this.setState({current_preview_url: "/oldview/manage_pages/"+res.data+"/preview"})
                _this.showPreviewModal();

            })
            .catch(function (error) {
                alert(error.message)
            })
    }
    render() {
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div className="row back-to-listing">
                            <span className="back-icon lh-icon-os-back" onClick={this.routeBackToPage}>
                            </span>
                            <span className="oauth-view-header">{i18n.t('admin.labels.back')}</span>
                        </div>
                        <div className="row">
                            <div className="col back-to-listing" >
                                <span className="user-info pt-3">
                                    {i18n.t('admin.labels.manage_page')}
                                </span>
                            </div>
                        </div>
                        {/* <form id="newPageForm"> */}
                        <ValidatorForm
                                id="newPageForm"
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                            <div className="row mt-3">
                                <div className="col-md-12 library-content splash-page mt-5">
                                    <div className="col-md-6 mb-3 p-0">
                                        <div className="mt-2 mb-3">
                                            <div className="col-auto">
                                                <label htmlFor="library" className="col-form-label">{i18n.t("userprofile.labels.language")}</label>
                                            </div>
                                            <div className="col-auto">
                                                <SelectValidator
                                                    tabIndex='1'
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("userprofile.placeholder.language")}
                                                    name="language"
                                                    options={this.state.languageOpt}
                                                    value={this.state.language}
                                                    onChange={this.handleChange}
                                                    noValidate
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.language_required")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="col-auto">
                                                <label htmlFor="title" className="col-form-label">{i18n.t('admin.labels.title')} </label>
                                            </div>
                                            <div className="col-auto">
                                                <TextValidator
                                                        tabIndex='2'
                                                        wrapperClass=''
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admin.placeholder.title')}
                                                        type="text"
                                                        name="title"
                                                        value={this.state.title}
                                                        onKeyDown={this.handleKeyDown}
                                                        onChange={this.handleChange}
                                                        noValidate
                                                />
                                                {/* <input type="text" name="title" className="form-control" /> */}
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="col-auto">
                                                <label htmlFor="sub-title" className="col-form-label">{i18n.t('admin.labels.sub_title')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <TextValidator
                                                        tabIndex='2'
                                                        wrapperClass=''
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admin.placeholder.sub_title')}
                                                        type="text"
                                                        name="subtitle"
                                                        value={this.state.subtitle}
                                                        onKeyDown={this.handleKeyDown}
                                                        onChange={this.handleChange}
                                                        noValidate
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 p-0">
                                        <div className="mt-2 mb-3">
                                            <div className="col-auto p-0">
                                                <label htmlFor="editor" className="col-form-label">{i18n.t('admin.labels.content')} </label>
                                            </div>
                                            <div className="col p-0" style={{ width: "85%"}}>
                                                {this.state.content ? 
                                                <CKEditor 
                                                    initData={ this.state.content }
                                                    onChange={ this.updateContent}  
                                                    onInstanceReady={() => {
                                                    }}
                                                /> : null
                                                }
                                            </div>
                                        </div>
                                        <div className="mb-3" style={{ height: '6vh' }}>
                                            <button type="button" onClick={this.uploadPreviewAndDisplay} className="btn btn-primary mr-3 mt-2">{i18n.t('admin.labels.preview')}</button>
                                            <button type="submit" className="btn btn-primary mr-3 mt-2">{i18n.t('userprofile.btn_save')}</button>
                                            <button type="button" className="btn btn-primary mt-2" onClick={this.routeBackToPage}>{i18n.t('userprofile.btn_cancel')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ValidatorForm>
                        <Errors
                            hasError={this.state.hasError}
                            type="warning"
                            message={this.state.errorMessage}
                            status={this.state.status}
                        />
                    </div>
                </div>

                {/* Preview New Page Modal*/}
                <div>
                    <Modal size="lg" show={this.state.isPreviewModalOpen} id="previewPage">
                        {/* <div className="modal-dialog modal-lg"> */}
                        <div className="modal-content">
                            <div className="modal-header modal-image-header">
                                <div className="modal-header-content">
                                    <h4 className="modal-title">{i18n.t('admin.labels.preview')}</h4>
                                </div>
                                <button type="button" className="close" onClick={this.hidePreviewModal}><span className="lh-icon-os-close"></span></button>
                            </div>
                            <div className="modal-body">
                                <iframe src={this.state.current_preview_url} width="100%" style={{height: '500px', border: 0}}> </iframe>
                            </div>
                        </div>
                        {/* </div> */}
                    </Modal>
                </div>
            </div>
        )
    }
}

export default withTranslation() (EditPage);