import React from "react";
import i18n from '../../packs/i18n';
import FeatureBlockItem from "./FeatureBlockItem";
class FeatureBlock extends React.Component {
    constructor(props){
        super(props);
    }
    render(){
        const feature_itmes = [];
        Object.keys(this.props.details.items).forEach(function(key){
            feature_itmes.push(<FeatureBlockItem key={key} details={this.props.details.items[key]} />)
        }.bind(this))
        return(
            <div className="feature-block">
                <div className="feature-block-title">
                    <h3>{i18n.t(this.props.details.name)}</h3>
                </div>
                {feature_itmes}
            </div>
        )
    }

}

export default FeatureBlock;