import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import { EventEmitter } from '../Common/Events';
import API from '../api';
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import SelectValidator from "../Common/SelectValidator";
import TextValidator from "../Common/TextValidator";
import Modal from "react-bootstrap/Modal";
import Errors from "../Common/Errors/Errors";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import Users from "./Users";
import Institutes from "./Institutions";
import Distributors from "./Distributors";
class GlobalSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search_term: props.location.pathname.split('/search/')[1].split('/')[0],
            search_location: props.location.pathname.split('/location/')[1],
            invitations: [],
            users: [], 
            distributors: [],
            institutions: [],
            total_user_count: 0,
            per_page: 10,
            current_page: 1,
            total_institution_count: 0,
            total_distributor_count: 0,
            total_invitation_count: 0,
            isLoading : false,
            all_records: [],
            isTopAdmin: props.is_top_admin || false,
            orderBy: 'created_at desc',
            base_url: ''
        }
        if (!props.is_top_admin) {
            document.body.classList.add('no-topadmin')
        }
        else {
            document.body.classList.remove('no-topadmin')
        }
    }
    componentDidMount(){
        document.getElementById('glb_input_search_text').value = this.state.search_term;
        document.getElementById('glb_search_location').value = this.state.search_location;
        this.getResults()
    }
    async getResults(){
        this.setState({isLoading: true})
        let n_per_page=this.state.per_page
        if(this.state.search_location === 'all'){
            n_per_page = this.state.per_page * 4
        }
        API.get('/react_admin/global_search?search_term=' + encodeURIComponent(this.state.search_term)+'&search_location='+this.state.search_location+'&current_page='+this.state.current_page+'&per_page='+n_per_page+'&order_by=' + this.state.orderBy)
        .then(res =>  {
            console.log("res", res)
            if(res.data){
                console.log(res.data)
                if(this.state.search_location === 'user')
                    this.setState({users: res.data.users, total_user_count: res.data.total_count})
                else if (this.state.search_location === 'institute')
                    this.setState({institutions: res.data.institutes, total_institution_count: res.data.total_count})
                else if (this.state.search_location === 'distributor')
                    this.setState({distributors: res.data.distributors, total_distributor_count: res.data.total_count})
                else if (this.state.search_location === 'invitation')
                    this.setState({invitations: res.data.invitations, total_invitation_count: res.data.total_count, base_url: res.data.base_url})
                else if (this.state.search_location === 'all'){
                    res.data.users.map((user) => { user.type='User'; return user});
                    res.data.distributors.map((dist) => { dist.type='Distributor'; return dist});
                    res.data.institutes.map((inst) => { inst.type='Team'; return inst});
                    res.data.invitations.map((inv) => { inv.type='Invitation'; return inv});
                    let testMerge = [...res.data.institutes, ...res.data.distributors, ...res.data.invitations]
                    let newData = [];
                    res.data.users.forEach((usr) => {
                        let item = testMerge.find(neitem => {
                            return neitem.email === usr.email
                        })
                        if(!item){
                            newData.push(usr);
                        }
                        else{
                            //console.log("Duplicate User", usr, item);
                        }
                    })
                    testMerge = [...newData, ...testMerge]
                    this.setState({all_records: testMerge})
                }

            }
            this.setState({isLoading: false})
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response);
            }
            if (error.request) {
                console.log(error.request);
            }
            else {
                console.log(error)
            }
        })
    }
    onTableChange = (type, newState) => {
        if(newState.sortOrder && newState.sortField){
            this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
        }
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        setTimeout(() => {
            if(this.state.location !== 'all')
                this.getResults()
        },0)
        
    }
    rankFormatterUser = (cell, row) => {
        let user_id = row.id
        let is_admin = row['admin?'];
        let is_pwa = row['is_pwa'];
        return (
          <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.editUser}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
            {
              !row.is_deleted ? <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.deleteUser}>{i18n.t('admin.labels.delete')}</NavDropdown.Item> : null
            }
            {
              !row.blocked ?
                <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.blockUser}>{i18n.t('admin.labels.block')}</NavDropdown.Item>
                :
                <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.unblockUser}>{i18n.t('admin.labels.unblock')}</NavDropdown.Item>
            }
            {!is_admin ? 
            <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.releaseUser} >{i18n.t('admin.labels.release_user')}</NavDropdown.Item> : null}
            {!is_admin ?
            <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.promotUser}>{i18n.t('admin.labels.promote')}</NavDropdown.Item> : null }
            {is_admin ?
            <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.demoteUser}>{i18n.t('admin.labels.demote')}</NavDropdown.Item> : null }
            { this.props.is_top_admin ?  
            <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.changeParent} >{i18n.t('admin.labels.change_parent')}</NavDropdown.Item> : null}
            { this.props.is_top_admin && is_pwa ?  
                <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.disablePWAUser} >{i18n.t('admin.labels.disable_pwa')}</NavDropdown.Item> : null
            }
            { this.props.is_top_admin && !is_pwa ?  
                <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.enablePWAUser} >{i18n.t('admin.labels.enable_pwa')}</NavDropdown.Item> : null
            }
            {row.forgot_password_link ?
              <NavDropdown.Item href={"/" + row.forgot_password_link} target="_blank">{i18n.t('admin.labels.forgot_password')}</NavDropdown.Item>
              : null
            }
            {row.confirm_email_link ?
              <NavDropdown.Item href={"/" + row.confirm_email_link} target="_blank">{i18n.t('admin.labels.confirm_email')}</NavDropdown.Item>
              : null
            }
          </NavDropdown>
        );
    }
    disablePWAUser = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = null;
        if(this.state.search_location === 'user'){
            userInfo = this.state.users.find((element) => {
                return element.id == id
            })
        } else if (this.state.search_location === 'all'){
            userInfo = this.state.all_records.find((element) => {
                return element.id == id && element.type === 'User'
            })
        }
        if(userInfo){
            EventEmitter.dispatch('disablePWAUserSearch',  userInfo)
        }
    }
    enablePWAUser = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = null;
        if(this.state.search_location === 'user'){
            userInfo = this.state.users.find((element) => {
                return element.id == id
            })
        } else if (this.state.search_location === 'all'){
            userInfo = this.state.all_records.find((element) => {
                return element.id == id && element.type === 'User'
            })
        }
        if(userInfo){
            EventEmitter.dispatch('enablePWAUserSearch',  userInfo)
        }
    }
    editUser = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = null;
        if(this.state.search_location === 'user'){
            userInfo = this.state.users.find((element) => {
                return element.id == id
            })
        } else if (this.state.search_location === 'all'){
            userInfo = this.state.all_records.find((element) => {
                return element.id == id && element.type === 'User'
            })
        }
        if(userInfo){
            EventEmitter.dispatch('editUserSearch',  userInfo)
        }
    }
    blockUser = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('blockUserSearch', id);
    }
    unblockUser = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('unblockUserSearch', id);
    }
    deleteUser = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('deleteUserSearch', id);
    }
    promotUser = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('promotUserSearch', id);
    }
    demoteUser = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('demoteUserSearch', id);
    }
    changeParent = (event) => {
        let id = event.target.getAttribute('data-id')
        let userInfo = null;
        if(this.state.search_location === 'user'){
            userInfo = this.state.users.find((element) => {
                return element.id == id
            })
        } else if (this.state.search_location === 'all'){
            userInfo = this.state.all_records.find((element) => {
                return element.id == id && element.type === 'User'
            })
        }
        if(userInfo){
            EventEmitter.dispatch('changeParentUserSearch',  userInfo)
        }
    }
    editInstitution = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = null;
        if(this.state.search_location === 'institute'){
            userInfo = this.state.institutions.find((element) => {
                return element.id == id
            })
        } else if (this.state.search_location === 'all'){
            userInfo = this.state.all_records.find((element) => {
                return element.id == id && element.type === 'Team'
            })
        }
        if(userInfo){
            EventEmitter.dispatch('editInstituteSearch',  userInfo)
        }
    }
    deleteInstitution = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('deleteInstituteSearch',  id)
    }
    changeParentIntstitution = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = null;
        if(this.state.search_location === 'institute'){
            userInfo = this.state.institutions.find((element) => {
                return element.id == id
            })
        } else if (this.state.search_location === 'all'){
            userInfo = this.state.all_records.find((element) => {
                return element.id == id && element.type === 'Team'
            })
        }
        if(userInfo){
            EventEmitter.dispatch('changeParentInstituteSearch',  userInfo)
        }
    }
    disablePWATeam = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = null;
        if(this.state.search_location === 'institute'){
            userInfo = this.state.institutions.find((element) => {
                return element.id == id
            })
        } else if (this.state.search_location === 'all'){
            userInfo = this.state.all_records.find((element) => {
                return element.id == id && element.type === 'Team'
            })
        }
        if(userInfo){
            EventEmitter.dispatch('disablePWAInstituteSearch',  userInfo)
        }
    }
    enablePWATeam = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = null;
        if(this.state.search_location === 'institute'){
            userInfo = this.state.institutions.find((element) => {
                return element.id == id
            })
        } else if (this.state.search_location === 'all'){
            userInfo = this.state.all_records.find((element) => {
                return element.id == id && element.type === 'Team'
            })
        }
        if(userInfo){
            EventEmitter.dispatch('enablePWAInstituteSearch',  userInfo)
        }
    }
    extendMaxLimit = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = null;
        if(this.state.search_location === 'institute'){
            userInfo = this.state.institutions.find((element) => {
                return element.id == id
            })
        } else if (this.state.search_location === 'all'){
            userInfo = this.state.all_records.find((element) => {
                return element.id == id && element.type === 'Team'
            })
        }
        if(userInfo){
            EventEmitter.dispatch('extendLimitInstituteSearch',  userInfo)
        }
    }
    editDistributor = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = null;
        if(this.state.search_location === 'distributor'){
            userInfo = this.state.distributors.find((element) => {
                return element.id == id
            })
        } else if (this.state.search_location === 'all'){
            userInfo = this.state.all_records.find((element) => {
                return element.id == id && element.type === 'Distributor'
            })
        }
        console.log(userInfo)
        if(userInfo){
            EventEmitter.dispatch('editDistributorSearch',  userInfo)
        }
    }
    deleteDistributor = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('deleteDistributorSearch',  id)
    }
    handleOnError = errors => {
        this.setState({
          isValidate: true,
          isValid: false,
          hasError: true,
          errorMessage: getErrorMessage(errors, true),
          status: null
        });
    };
    institutionActions = (cell, row) => {
        let institution_id = row.id
        let is_pwa = row['is_pwa'];
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.editInstitution}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
            {this.state.isTopAdmin ? 
            <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.deleteInstitution}>{i18n.t('admin.labels.delete')}</NavDropdown.Item> : null}
            {this.state.isTopAdmin ? 
            <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.changeParentIntstitution}>{i18n.t('admin.labels.change_parent')}</NavDropdown.Item> : null }
            { this.props.is_top_admin && is_pwa ?  
                <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.disablePWATeam} >{i18n.t('admin.labels.disable_pwa')}</NavDropdown.Item> : null
            }
            { this.props.is_top_admin && !is_pwa ?  
                <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.enablePWATeam} >{i18n.t('admin.labels.enable_pwa')}</NavDropdown.Item> : null
            }
            {this.state.isTopAdmin ?
            <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.extendMaxLimit}>{i18n.t('admin.labels.extend_limit')}</NavDropdown.Item> : null }
            </NavDropdown>
        );
    }
    distributorAction = (cell, row) => {
        let distributor_id = row.id;
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void (0)} data-id={distributor_id} onClick={this.editDistributor}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
                <NavDropdown.Item href={void (0)} data-id={distributor_id} onClick={this.deleteDistributor}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
            </NavDropdown>
        );
    }
    invitationAction = (cell, row) => {
        let invitation_id = row.id
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void (0)} data-id={invitation_id} onClick={this.resendInvitation}>{i18n.t('admin.labels.resend_inv')}</NavDropdown.Item>
                <NavDropdown.Item href={void (0)} data-id={invitation_id} onClick={this.cancelInvitation}>{i18n.t('admin.labels.cancel_inv')}</NavDropdown.Item>
            </NavDropdown>
        );
    }
    resendInvitation = (event) => {
        let id = event.target.getAttribute('data-id');
        const formData = new FormData();
        formData.append('selected', id);
        API.post('/invitations/resend', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }
    cancelInvitation = (event) => {
        let id = event.target.getAttribute('data-id');
        const formData = new FormData();
        formData.append('selected', id);
        API.post('/invitations/cancel', formData)
            .then(() => {
                window.location.reload();
            })
            .catch(() => {

            })
    }
    invitationLink = (cell, row) => {
        let url = this.state.base_url + row.accept_invitation_link;
        return (
            <a href={url} target="_blank">{i18n.t('admin.labels.accept_inv')}</a>
        );
    }
    all_actions = (cell, row) => {
        if(row.type == 'User')
        {
            return this.rankFormatterUser(cell, row);
        }
        else if(row.type == 'Team'){
            return this.institutionActions(cell,row);
        }
        else if(row.type == 'Distributor'){
            return this.distributorAction(cell,row);
        }
        else if(row.type == 'Invitation'){
            return this.invitationAction(cell,row);
        }
    }
    all_data_email = (cell, row) => {
        if(row && row.type === 'User'){
            return (<a href={'/admin/user/view/'+row.id}>{row.email}</a>)
        }
        else if(row && (row.type === 'Team' || row.type === 'Distributor')){
            return (<a href={'/admin/user/view/'+row.user_id}>{row.email}</a>)
        }
        else{
            return (<a href={void(0)}>{row.email}</a>)
        }
    }
    all_data_name = (cell, row) => {
        if(row && row.type === 'User'){
            return (<a href={'/admin/user/view/'+row.id}>{row.name}</a>)
        }
        else if(row && row.type === 'Team'){
            return (<a href={'/admin/institution/view/'+row.id}>{row.name}</a>)
        }
        else if(row && row.type === 'Distributor'){
            return (<a href={'/admin/distributor/view/'+row.id}>{row.name}</a>)
        }
        else{
            return (<a href={void(0)}>{row.name}</a>)
        }
    }
    render() {
        /* User section */
        const user_columns = [{
            dataField: 'email',
            text: i18n.t('userprofile.labels.email').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '25%' };
            }
          }, {
            dataField: 'first_name',
            text: i18n.t('userprofile.labels.first_name').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '9%' };
            }
          }, {
            dataField: 'last_name',
            text: i18n.t('userprofile.labels.last_name').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '9%' };
            }
          }, {
            dataField: 'country',
            text: i18n.t('userprofile.labels.country').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '8%' };
            }
          }, {
            dataField: 'institution',
            text: i18n.t('admin.labels.institute').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '9%' };
            }
          }, 
          {
            dataField: 'disk_usage',
            text: i18n.t('admin.labels.lib_usage').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '6%' };
            }
          }, 
          {
            dataField: 'current_status',
            text: i18n.t('admin.labels.status').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '9%' };
            }
          },{
            dataField: 'current_plan_name',
            text: i18n.t('admin.labels.plan').toUpperCase(),
            sort: false
          },{
            dataField: 'days_to_pay',
            text: i18n.t('admin.labels.days_left').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
          },{
            dataField: 'order_number',
            text: i18n.t('admin.labels.order_number').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '11%' };
            }
          },{
            dataField: 'moreOptions',
            formatter: this.rankFormatterUser,
        }];
        const user_options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const user_paginator_options = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_user_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const user_default_sorted = [{
            dataField: 'created_at',
            order: 'desc'
        }];
        const user_row_events = {
            onClick: (e, row, rowIndex) => {
                let currentCellIndex = e.target.cellIndex;
                let lastCellIndex = document.querySelector('table tr:last-child td:last-child').cellIndex
                if (currentCellIndex !== lastCellIndex && currentCellIndex !== undefined) {
                    window.location.href = '/admin/user/view/' + row.id
                }
            }
        }
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        }
        /* Intiitution section */
        const institute_columns = [{
            dataField: 'email',
            text: i18n.t('userprofile.labels.email').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        }, {
            dataField: 'name',
            text: i18n.t('admin.labels.name').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '20%' };
            }
        },{
            dataField: 'get_seat_licenses',
            text: i18n.t('admin.labels.seat_license').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        }, {
            dataField: 'get_subscribed_plan_name',
            text: i18n.t('admin.labels.plan').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            }

        }, {
            dataField: 'remaining_licenses',
            text: i18n.t('admin.labels.remaining_seats').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        }, {
            dataField: 'total_users',
            text: i18n.t('admin.labels.accounts').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '8%' };
            }
        }, {
            dataField: 'days_to_pay',
            text: i18n.t('admin.labels.days_left').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        }, {
            dataField: 'order_number',
            text: i18n.t('admin.labels.order_number').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '8%' };
            }
        },{
            dataField: 'last_paid_date',
            text: i18n.t('admin.labels.last_paid_at').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        }, {
            dataField: 'moreOptions',
            formatter: this.institutionActions,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        }];

        const institute_options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const institute_paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_institution_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const institute_defaultSorted = [{
            dataField: 'created_at',
            order: 'desc'
        }];
        const institute_row_events = {
            onClick: (e, row, rowIndex) => {
                let currentCellIndex = e.target.cellIndex;
                let lastCellIndex = document.querySelector('table tr:last-child td:last-child').cellIndex
                if (currentCellIndex !== lastCellIndex && currentCellIndex !== undefined) {
                    window.location.href = '/admin/institution/view/' + row.id
                }
            }
        }
        /* Distributor section */
        const distributor_columns = [{
            dataField: 'name',
            text: i18n.t('admin.labels.name').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        }, {
            dataField: 'email',
            text: i18n.t('userprofile.labels.email').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        }, {
            dataField: 'country',
            text: i18n.t('userprofile.labels.country').toUpperCase(),
            sort: true
        }, {
            dataField: 'total_children',
            text: i18n.t('admin.labels.reseller').toUpperCase(),
            sort: false
        }, {
            dataField: 'moreOptions',
            sort: false,
            formatter: this.distributorAction,
        }];
        const distributor_default_sorted = [{
            dataField: 'created_at',
            order: 'desc'
        }];
        const distributor_options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const distributor_paginator_options = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_distributor_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };

        const distributor_row_events = {
            onClick: (e, row, rowIndex) => {
                let currentCellIndex = e.target.cellIndex;
                let lastCellIndex = document.querySelector('table tr:last-child td:last-child').cellIndex

                if (currentCellIndex !== lastCellIndex && currentCellIndex !== undefined) {
                    window.location.href = '/admin/distributor/view/' + row.id
                }
            }
        }
        /* Invitation section */
        const invitation_columns = [{
            dataField: 'email',
            text: i18n.t('userprofile.labels.email').toUpperCase(),
            headerStyle: (colum, colIndex) => {
                return { width: '25%', textAlign: 'center' };
            },
            sort: true
        }, {
            dataField: 'first_name',
            text: i18n.t('userprofile.labels.first_name').toUpperCase(),
            headerStyle: (colum, colIndex) => {
                return { width: '10%', textAlign: 'center' };
            },
            sort: true
        }, {
            dataField: 'last_name',
            text: i18n.t('userprofile.labels.last_name').toUpperCase(),
            headerStyle: (colum, colIndex) => {
                return { width: '9%', textAlign: 'center' };
            },
            sort: true
        }, {
            dataField: 'sender_name',
            text: i18n.t('admin.labels.inviter').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '10%', textAlign: 'center' };
            },
        }, {
            dataField: 'account_type',
            text: i18n.t('admin.labels.account_type').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '11%', textAlign: 'center' };
            },
        }, {
            dataField: 'number_of_years',
            text: i18n.t('admin.labels.for_years').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '9%', textAlign: 'center' };
            },
        }, {
            dataField: 'sent',
            text: i18n.t('admin.labels.sent').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '7%', textAlign: 'center' };
            },
        },
        {
            dataField: 'order_number',
            text: i18n.t('admin.labels.order_number').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '12%', textAlign: 'center' };
            }
        },
        {
            dataField: 'Invitation Link',
            formatter: this.invitationLink,
            headerStyle: (colum, colIndex) => {
                return { width: '11%', textAlign: 'center' };
            },
            sort: false
        },
        {
            dataField: 'moreOptions',
            formatter: this.invitationAction,
            headerStyle: (colum, colIndex) => {
                return { width: '5%', textAlign: 'center' };
            },
        }];
        const invitation_default_sorted = [{
            dataField: 'created_at',
            order: 'desc'
        }];
        const invitaion_options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const invitation_paginator_options = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_invitation_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const all_columns = [{
            dataField: 'name',
            text: i18n.t('admin.labels.name').toUpperCase(),
            formatter: this.all_data_name,
            sort: false,
        },{
            dataField: 'email',
            text: i18n.t('userprofile.labels.email').toUpperCase(),
            sort: false,
            formatter: this.all_data_email
        }, {
            dataField: 'type',
            text: "type".toUpperCase(),
            sort: false,
        }, { 
            text: '',
            formatter: this.all_actions,
            headerStyle: (colum, colIndex) => {
                return { width: '5%', textAlign: 'center' };
            }
        }]
        const all_paginator_options = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.all_records.length + 1,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        }
        return (
            <React.Fragment>
                <Users isFromSearch={true}></Users>
                <Institutes isFromSearch={true}></Institutes>
                <Distributors isFromSearch={true}></Distributors>
                <div className="glb-search-tabels">
                    { this.state.search_location == 'user' ? 
                        <BootstrapTable
                            keyField='email'
                            options={user_options}
                            data={this.state.users}
                            fetchInfo={{ dataTotalSize: this.state.total_user_count }}
                            columns={user_columns}
                            defaultSorted={user_default_sorted}
                            bordered={false}
                            noDataIndication={() => <NoDataIndication />}
                            remote
                            pagination={paginationFactory(user_paginator_options)}
                            onTableChange={this.onTableChange}
                            rowEvents={user_row_events}
                        />
                        : null
                    }
                    { this.state.search_location == 'institute' ?
                        <BootstrapTable
                            keyField='id'
                            options={institute_options}
                            data={this.state.institutions}
                            fetchInfo={{ dataTotalSize: this.state.total_institution_count }}
                            columns={institute_columns}
                            bordered={false}
                            defaultSorted={institute_defaultSorted}
                            noDataIndication={() => <NoDataIndication />}
                            remote
                            pagination={paginationFactory(institute_paginatorOptions)}
                            onTableChange={this.onTableChange}
                            rowEvents={institute_row_events}
                        /> : null
                    }
                    { this.state.search_location == 'distributor' ? 
                        <BootstrapTable
                            keyField='id'
                            options={distributor_options}
                            data={this.state.distributors}
                            fetchInfo={{ dataTotalSize: this.state.total_distributor_count }}
                            columns={distributor_columns}
                            defaultSorted={distributor_default_sorted}
                            bordered={false}
                            remote
                            noDataIndication={() => <NoDataIndication />}
                            pagination={paginationFactory(distributor_paginator_options)}
                            onTableChange={this.onTableChange}
                            rowEvents={distributor_row_events}
                        /> : null
                    }
                    { this.state.search_location == 'invitation' ?
                            <BootstrapTable
                                keyField='email'
                                options={invitaion_options}
                                data={this.state.invitations}
                                fetchInfo={{ dataTotalSize: this.state.total_invitation_count }}
                                columns={invitation_columns}
                                defaultSorted={invitation_default_sorted}
                                bordered={false}
                                condensed={true}
                                remote
                                noDataIndication={() => <NoDataIndication />}
                                pagination={paginationFactory(invitation_paginator_options)}
                                onTableChange={this.onTableChange}
                            /> : null
                    }
                    { this.state.search_location == 'all' ?   
                        <BootstrapTable
                            keyField='email'
                            fetchInfo={{ dataTotalSize: this.state.all_records.length + 1 }}
                            data={this.state.all_records}
                            columns={all_columns}
                            bordered={false}
                            onTableChange={this.onTableChange}
                        /> : null
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation()(GlobalSearch)