import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import { HeartSucess_IMG } from '../Common/assets_paths';
import i18n from '../../packs/i18n';
import { getLocalUrl } from '../Common/i3library';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class PackSuccess extends React.Component {
    render() {
        const { number_of_years, plan } = this.props.location.state;
        const pack_abr = parseInt(number_of_years) > 1 ? i18n.t("packsignup.success.message.txt_4") : i18n.t("packsignup.success.message.txt_3")
        let txt_pl = plan.split("_").join(" ");
        const plan_name = txt_pl.replace(/\b\w/g, function(l){ return l.toUpperCase() })
        return (
            <LandingStepBase pack_signup={true} container={
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 p-0 text-center">
                            <div className="headtxt mt-5 mb-5">
                                <h1 className="text-white mb-0">{i18n.t('signup.sucess.header.title')}</h1>
                                <p>{i18n.t('signup.sucess.header.sub_title')}</p>
                                <div className="steps">
                                    <span className="completed step mr-2 ml-2"></span><span className="completed  step  mr-2 ml-2"></span><span className="completed step  mr-2 ml-2"></span><span className="completed step mr-2 ml-2"></span><span className="completed step mr-2 ml-2"></span>
                                </div>
                                <p className="mt-2 text-quaternary text-size-20">{i18n.t('signup.sucess.header.all_cmp_txt')}</p>
                            </div>
                            <div className="registration-result text-center m-auto">
                                <img src={HeartSucess_IMG} className="animated-svg"/>
                                <p className="text-white mt-5 mb-2 text-size-24">
                                    <React.Fragment>
                                        {i18n.t('packsignup.success.message.txt_1')} <br />

                                        {i18n.t('packsignup.success.message.txt_2', {year: number_of_years, abrivation: pack_abr, plan: plan_name})}
                                    </React.Fragment>
                                </p>

                                <a href="/" className="btn btn-block m-auto mb-2 btn-outline-quinary text-size-22 btn-border-animate" style={{ width: '200px' }} >{i18n.t('signup.sucess.nav_btn')}</a>
                                
                            </div>
                        </div>
                    </div>
                </div>}>
            </LandingStepBase>
        )
    }

}

export default withTranslation()(PackSuccess);
