import React from "react";
import i18n from "../../packs/i18n";
import { NavDropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import Errors from "../Common/Errors/Errors";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import TextValidator from "../Common/TextValidator";
import TextareaValidator from "../Common/TextareaValidator";
import API from '../api';
import Modal from "react-bootstrap/Modal";
import { withTranslation } from 'react-i18next';


class Keyboards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            name: '',
            language: '',
            kbid: '',
            layout: null,
            isValid: false,
            hasError: false,
            errorMessage: "",
            isValidate: false,
            edit_name: '',
            edit_language: '',
            edit_kbid: '',
            edit_layout: '',
            current_active_id: '',
            isEditOpen: false
        }
    }
    async componentDidMount() {
        await this.callApi()
    }
    async callApi() {
        //keyboards
        this.setState({data: [], isLoading: true, total_count: 0})
        let { data } = await API.get('/keyboards')
        console.log(data);
        this.setState({data: data, isLoading: false, total_count: data.length})
    }
    handleSubmit = (event) => {
        this.setState({ isLoading: true });
        this.addNewKeyBoard()
    }
    handleEditSubmit = (event) => {
        this.setState({ isLoading: true });
        this.updateKeyBoard()
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };
    handleChange = event => {
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value
        });
    };
    handleKeyDown = event => {
        if (event.key === " " && event.target.value.trim().length === 0) {
            event.preventDefault();
        }
    };
    addNewKeyBoard = () => {
        const formData = new FormData();
        
        formData.append('keyboard[name]', this.state.name);
        formData.append('keyboard[language]', this.state.language);
        formData.append('keyboard[kbid]', this.state.kbid);
        formData.append('keyboard[layout]', this.state.layout);
        API.post('/keyboards', formData)
            .then(res => {
                if (res.data.status === "failed") {
                    //alert(res.data.message);
                    this.setState({
                        isValidate: true,
                        isValid: false,
                        hasError: true,
                        errorMessage: res.data.message,
                        status: null,
                        isLoading: false
                    });
                }
                else {
                    window.location.reload();
                }

            })
            .catch(function (error) {
            })
    }
    updateKeyBoard = () => {
        const formData = new FormData();
        
        formData.append('keyboard[name]', this.state.edit_name);
        formData.append('keyboard[language]', this.state.edit_language);
        formData.append('keyboard[kbid]', this.state.edit_kbid);
        formData.append('keyboard[layout]', this.state.edit_layout);

        API.put('/keyboards/'+this.state.current_active_id, formData)
            .then(res => {
                if (res.data.status === "failed") {
                    //alert(res.data.message);
                    this.setState({
                        isValidate: true,
                        isValid: false,
                        hasError: true,
                        errorMessage: res.data.message,
                        status: null,
                        isLoading: false
                    });
                }
                else {
                    window.location.reload();
                }

            })
            .catch(function (error) {
            })
    }
    deleteKeyboard = event => {
        let id = event.target.getAttribute('data-id');
        ///keyboards/'+this.state.current_active_id
        API.delete('/keyboards/'+id)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
                alert("Something went wrong!")
            })
    }
    onTableChange = (type, newState) => {
        if(newState.sortOrder && newState.sortField){
            this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
        }
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi()
    }
    keyboardsActions = (cell, row) => {
        let id = row.id;
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void(0)} data-id={id} onClick={this.editKeyboard} >{i18n.t('admin.labels.edit')}</NavDropdown.Item>
                <NavDropdown.Item href={void(0)} data-id={id} onClick={this.deleteKeyboard}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
            </NavDropdown>
        );
    }
    editKeyboard = (event) => {
        let id = event.target.getAttribute('data-id');
        let keyboard_info = this.state.data.find((element) => {
            return element.id == id
        });
        this.setState({edit_name: keyboard_info.name, edit_language: keyboard_info.language, edit_kbid: keyboard_info.keyboard_name, edit_layout: keyboard_info.data, current_active_id: keyboard_info.id})

        this.showEditModal()
    }
    showEditModal = () => {
        this.setState({isEditOpen: true})
    }
    hideEditModal = () => {
        this.setState({isEditOpen: false})
    }
    render() {
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        }
        const columns = [{
            dataField: 'name',
            text: i18n.t('admin.labels.keyboard_name').toUpperCase(),
            sort: false
        }, {
            dataField: 'moreOptions',
            sort: false,
            formatter: this.keyboardsActions
        }];
        const options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div className="admin-custom-table-header">
                            <button data-bs-toggle="modal" data-bs-target="#addKeyboard" className="btn btn-primary mb-3 mt-1">{i18n.t('admin.keyboard.modal_add.title')}</button>
                        </div>
                        <div className="keyboard-table mt-2">
                            <BootstrapTable
                                keyField='display_name'
                                //options={options}
                                //fetchInfo={{ dataTotalSize: this.state.total_count }}
                                data={this.state.data}
                                columns={columns}
                                bordered={false}
                                //remote
                                //onTableChange={this.onTableChange}
                                noDataIndication={() => <NoDataIndication />}
                                //pagination={paginationFactory(paginatorOptions)}
                            />
                        </div>
                    </div>
                </div>

                {/* Add Key Board Modal */}
                <div className="modal fade" id="addKeyboard" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            {/* <form id="addKeyboardForm"> */}
                            <ValidatorForm
                                id="addKeyboardForm"
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.keyboard.modal_add.title')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="name" className="col-form-label">{i18n.t('admin.labels.name')}</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control details-info"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="name"
                                                    placeholder={i18n.t("admin.placeholder.name")}
                                                    value={this.state.name}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_name")
                                                    ]}
                                                />
                                                {/* <input type="text" name="name" className="form-control details-info" /> */}
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="language" className="col-form-label">{i18n.t('admin.labels.language')}</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control details-info"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="language"
                                                    placeholder={i18n.t("admin.placeholder.language")}
                                                    value={this.state.language}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_language")
                                                    ]}
                                                />
                                                {/* <input type="text" name="language" className="form-control details-info" /> */}
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="kbid" className="col-form-label">{i18n.t('admin.labels.kbid')}</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control details-info"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="kbid"
                                                    placeholder={i18n.t("admin.placeholder.kbid")}
                                                    value={this.state.kbid}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_kbid")
                                                    ]}
                                                />
                                                {/* <input type="text" name="kbid" className="form-control details-info" /> */}
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="layout" className="col-form-label">{i18n.t('admin.labels.layout')}</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <TextareaValidator
                                                    tabIndex='3'
                                                    rows="15"
                                                    wrapperClass=''
                                                    className={"form-control details-info"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("admin.placeholder.layout")}
                                                    name="layout"
                                                    value={this.state.layout}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_layout")
                                                    ]}
                                                />
                                                {/* <textarea name="layout" className="form-control details-info" rows="15"></textarea> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                            <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                            :
                                            <React.Fragment>{i18n.t('admin.keyboard.btn_new')}</React.Fragment>
                                        }</button>
                                    {/* <button type="submit" className="btn btn-accenta">Save Keyboard</button> */}
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                                
                            {/* </form> */}
                        </div>
                    </div>
                </div>

                {/* Edit Key Board Modal */}
                <div>
                    <Modal show={this.state.isEditOpen} id="editKeyboardsModal">
                        <div className="modal-content">
                            {/* <form id="editKeyboardForm"> */}
                            <ValidatorForm
                                id="editKeyboardForm"
                                ref="form"
                                onSubmit={this.handleEditSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.keyboard.modal_edit.title')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideEditModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="name" className="col-form-label">{i18n.t('admin.labels.name')}</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control details-info"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_name"
                                                    placeholder={i18n.t("admin.placeholder.name")}
                                                    value={this.state.edit_name}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_name")
                                                    ]}
                                                />
                                                {/* <input type="text" name="name" className="form-control details-info" /> */}
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="language" className="col-form-label">{i18n.t('admin.labels.language')}</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control details-info"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_language"
                                                    placeholder={i18n.t("admin.placeholder.language")}
                                                    value={this.state.edit_language}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_language")
                                                    ]}
                                                />
                                                {/* <input type="text" name="language" className="form-control details-info" /> */}
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="kbid" className="col-form-label">{i18n.t('admin.labels.kbid')}</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control details-info"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_kbid"
                                                    placeholder={i18n.t("admin.placeholder.kbid")}
                                                    value={this.state.edit_kbid}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_kbid")
                                                    ]}
                                                />
                                                {/* <input type="text" name="kbid" className="form-control details-info" /> */}
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="layout" className="col-form-label">{i18n.t('admin.labels.layout')}</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <TextareaValidator
                                                    tabIndex='3'
                                                    rows="15"
                                                    wrapperClass=''
                                                    className={"form-control details-info"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("admin.placeholder.layout")}
                                                    name="edit_layout"
                                                    value={this.state.edit_layout}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_layout")
                                                    ]}
                                                />
                                                {/* <textarea name="layout" className="form-control details-info" rows="15"></textarea> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                            <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                            :
                                            <React.Fragment>{i18n.t('admin.keyboard.btn_update')}</React.Fragment>
                                        }</button>
                                    {/* <button type="submit" className="btn btn-accenta">Save Keyboard</button> */}
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                            {/* </form> */}
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}
export default withTranslation() (Keyboards);