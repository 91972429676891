import React from 'react'
import i18n from '../../packs/i18n';
import API from '../api';
import { Link } from 'react-router-dom'
import Errors from '../Common/Errors/Errors';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import FastTrack from './FastTrack';
import { readCookie, ENABLEQR, getLocalUrl, getQueryParam, getLocalUrlFromUrl, getAvailableLanguages, getTOSURL } from '../Common/i3library';
import { QR_IMG , FAST_TRACK_ICON_IMG} from '../Common/assets_paths';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../Common/TextValidator';
import { getErrorMessage } from '../Common/Helpers/AppHelper';
import { withTranslation } from 'react-i18next';
import LanguageSelection from '../LanguageOptions/LanguageSelection';
import Success from '../Common/Errors/Sucess';
import {createEvent} from '../Common/AnalyticsEvents'
class Login extends React.Component {

  constructor(props) {
    super(props);

    const language = props.user_language || 'en';
    const languagesOptions = getAvailableLanguages();
    this.state = {
      email: this.props.email || "",
      password: "",
      hasError: false,
      errorMessage: "",
      isFastrack: false,
      disableClick:false,
      isValidate: false,
      status: null,
      isValid: false,
      qcb_session: props.qcb_session,
      language: language,
      languageOpt: languagesOptions,
      invitation_token: null,
      invited_team_name: null,
      show_resend_activation: false,
      show_raise_a_ticket_for_blocked_or_deleted_users: false,
      redirect_lifeliqe: props.redirect_lifeliqe,
      redirect_manage_subscription: props.redirect_manage_subscription,
      from: this.props.from,
      isVLE: this.props.isVLE || false,
      brd_id: this.props.brd_id || null,
      token: this.props.tkn || null,
      unique_brd_id: this.props.ukid || null,
      vle_auth_data: this.props.auth_data || null,
      tos_url: getTOSURL()
    };
  }

  componentDidMount() {
    if(this.props.acceptTeamInvitation) {
      this.setState({
        acceptTeamInvitation: true,
        hasError: true,
        errorMessage: i18n.t('invitations.accept_decline.require_login'),
        status: 200,
        invitation_token: this.props.invitation_token,
        email: this.props.email,
        invited_team_name: this.props.invited_team_name
      });
    }
  }

  disableClick(disable) {
    this.setState({ disableClick: disable });
    return true;
  }

  UNSAFE_componentWillMount(){
    const enableqr = readCookie(ENABLEQR);
    if (enableqr === '1') {
      this.setState({
        isFastrack: true
      })
    }
  }
  resendActivationEmail = event => {
    const _this = this;
    API.get(`users/resend_confirmation?email=${encodeURIComponent(this.state.email)}`)
    .then(res => {
      if(res.status == 200 && res.data.sent === true) {
        createEvent('Login','Resend Activation Link Sucess')
        _this.setState({
          hasError: null,
          errorMessage: null,
          isValid: true,
          errorMessage: res.data.message
        });
      }

    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          createEvent('Login','Resend Activation Link Fail',error.response.data.message, 401)
          _this.setState({
            hasError: true,
            errorMessage: error.response.data.message || i18n.t('login.errors.wrong_credential'),
            status: error.response.status,
            isValid: false
          });
          if(error.response.data.resend_activation) {
            _this.setState({
              show_resend_activation: true
            });
          } else {
            _this.setState({
              show_resend_activation: false
            });
          }
        }
      }
      if (error.request) {
        createEvent('Login','Resend Activation Link Fail')
        console.log(error.request);
      }
      else {
        createEvent('Login','Resend Activation Link Fail',error.message)
        _this.setState({
          hasError: true,
          errorMessage: error.message,
          status: error.status,
          isValid: false
        })
      }
    })
    .finally(function () {
      // always executed
    });
  }

  raiseATicketForBlockedOrDeletedUsers = event => {
    const _this = this;
    createEvent('Login','Raise Ticket');
    API.get(`users/raise_ticket?email=${encodeURIComponent(this.state.email)}`)
    .then(res => {
      if(res.status == 200 && res.data.sent === true) {
       _this.setState({
          hasError: null,
          errorMessage: null,
          isValid: true,
          errorMessage: res.data.message
        });
      }

    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
         _this.setState({
            hasError: true,
            errorMessage: error.response.data.message || i18n.t('login.errors.wrong_credential'),
            status: error.response.status,
            isValid: false
          });
          if(error.response.data.resend_activation) {
            _this.setState({
              show_resend_activation: true
            });
          } else {
            _this.setState({
              show_resend_activation: false
            });
          }
        }
      }
      if (error.request) {
        console.log(error.request);
      }
      else {
        _this.setState({
          hasError: true,
          errorMessage: error.message,
          status: error.status,
          isValid: false
        })
      }
    })
    .finally(function () {
      // always executed
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.name == 'email' ? event.target.value.trim() : event.target.value
    });
  }

  handleSubmit = event => {
    const user = {
      email: this.state.email,
      password: this.state.password,
      qcb_session: this.state.qcb_session,
      team_invitation_token: this.state.invitation_token,
      acceptTeamInvitation: this.state.acceptTeamInvitation,
      team_name: this.state.invited_team_name,
      redirect_lifeliqe: this.state.redirect_lifeliqe,
      redirect_manage_subscription: this.state.redirect_manage_subscription || false
    };
    if(this.state.isVLE){
      user.brd_guid = this.state.brd_id
      user.brd_unique_id = this.state.unique_brd_id
      user.tkn = this.state.token
      user.isVLE = this.state.isVLE,
      user.auth_data = this.state.vle_auth_data
    }
    const _this = this;

    API.post('react_landing/login', user)
      .then(res => {
        window.localStorage.setItem('login_count', res.data.login_count);
        if(res.data.redirect_path) {
          window.location = decodeURIComponent(res.data.redirect_path);
        } else if(!res.data.tos_approved){
          window.location = "/"+this.props.user_language+"/accepttos?email="+encodeURIComponent(res.data.email);
        }
        else {
          window.location = "/";
        }
      })
      .catch(function (error) {
        
        if (error.response) {
          if (error.response.status == 401) {
            _this.setState({
              hasError: true,
              errorMessage: error.response.data.message || i18n.t('login.errors.wrong_credential'),
              status: error.response.status,
              isValid: false
            });
            if(error.response.data.blocked || error.response.data.is_deleted) {
              let evnMsg = error.response.data.blocked ? "Login Fail Due to blocked account": "Login Fail Due to delete account";
              createEvent('Login',evnMsg,error.response.data.message);
              _this.setState({
                show_raise_a_ticket_for_blocked_or_deleted_users: true
              })
            } else {
              createEvent('Login','Login Fail Due to Wrong Credential',error.response.data.message);
              _this.setState({
                show_raise_a_ticket_for_blocked_or_deleted_users: false
              })
            }
            if(error.response.data.resend_activation) {
              _this.setState({
                show_resend_activation: true
              });
            } else {
              _this.setState({
                show_resend_activation: false
              });
            }
          }
        }
        else{
          createEvent('Login','Login Fail Due to unknown reason',error.message || "");
        }
        if (error.request) {
          console.log(error.request);
          createEvent('Login','Login Fail Due to unknown reason',error.message || "");
        }
        else {
          
          _this.setState({
            hasError: true,
            errorMessage: error.message,
            status: error.status,
            isValid: false
          })
          createEvent('Login','Login Error',error.message || "");
        }
      })
      .finally(function () {
        // always executed
      });

    event.preventDefault();
  }

  register_vle_user = event => {
    const user = {
      vle_data: this.state.vle_auth_data,
      vle_tkn: this.state.token,
      vle_brd_id: this.state.brd_id,
      vle_brd_ukid: this.state.unique_brd_id
    };
    let _this = this;
    API.post('/react_landing/vle_register', user)
    .then(res => {
      createEvent('Register-New-VLE','New user registerd sucessfully');
      window.localStorage.setItem('login_count', res.data.login_count);
      if(res.data.redirect_path) {
        window.location = decodeURIComponent(res.data.redirect_path);
      } else {
        window.location = "/";
      }
    })
    .catch(function (error) {  
      if (error.response) {
        if (error.response.status == 401) {
          _this.setState({
            hasError: true,
            errorMessage: error.response.data.message || i18n.t('login.errors.wrong_credential'),
            status: error.response.status,
            isValid: false
          });
          if(error.response.data.blocked || error.response.data.is_deleted) {
            let evnMsg = error.response.data.blocked ? "Login Fail Due to blocked account": "Login Fail Due to delete account";
            createEvent('Login',evnMsg,error.response.data.message);
            _this.setState({
              show_raise_a_ticket_for_blocked_or_deleted_users: true
            })
          } else {
            createEvent('Login','Login Fail Due to Wrong Credential',error.response.data.message);
            _this.setState({
              show_raise_a_ticket_for_blocked_or_deleted_users: false
            })
          }
          if(error.response.data.resend_activation) {
            _this.setState({
              show_resend_activation: true
            });
          } else {
            _this.setState({
              show_resend_activation: false
            });
          }
        }
        if(error.response.status == 409){
          _this.setState({
            hasError: true,
            errorMessage: i18n.t('login.errors.already_exist'),
            status: error.response.status,
            isValid: false
          });
        }
      }
      else{
        createEvent('Login','Login Fail Due to unknown reason',error.message || "");
      }
      if (error.request) {
        console.log(error.request);
        createEvent('Login','Login Fail Due to unknown reason',error.message || "");
      }
      else {
        
        _this.setState({
          hasError: true,
          errorMessage: error.message,
          status: error.status,
          isValid: false
        })
        createEvent('Login','Login Error',error.message || "");
      }
    })
    .finally(function () {
      // always executed
    });

    event.preventDefault();
  }
  handleFastrack = event => {
    this.setState({
      isFastrack: true,
      disableClick:true
    })
  }
  handleFastrackState(value) {
    this.setState({
      isFastrack: value,
      disableClick:value
    })
  }
  handleOnError = error => {
    this.setState({
      isValidate: true,
      hasError: true,
      errorMessage: getErrorMessage(error, true),
      status: null
    });
  }
  handleRedirect = event => {
    var currentLocation = null;
    switch(event.target.id) {
      case 'office365':
        currentLocation = this.props.oauth_urls.office365;
        break;
      case 'microsoftonline':
        currentLocation = this.props.oauth_urls.microsoftonline;
        break;
      case 'facebook':
        currentLocation = this.props.oauth_urls.facebook;
        break
      case 'google':
        currentLocation = this.props.oauth_urls.google;
        break
      case 'smartschool':
        currentLocation = this.props.oauth_urls.smartschool;
        break;
      case 'klascement':
        currentLocation = this.props.oauth_urls.klascement;
        break;
   }
   window.location = currentLocation
  }
  render() {
    return (
      <React.Fragment>
        <LandingStepBase container={
          <div className="container">
            <div className="row" style={{pointerEvents: this.state.disableClick ? 'none' : 'all'}}>
              <div className="col-sm-12 p-0 text-center">
                <div className="headtxt animated fadeInDown mt-5">
                  <h1 className={'text-white'}>{i18n.t('login.greeting')} </h1>
                  { !this.state.isVLE ? <p>{i18n.t('login.who_are_you')}?</p> : <p>Do you have learnhub account?</p>}
                </div>
                <div className="main-wrapper">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    onError={this.handleOnError}
                    instantValidate={false}
                    className="white-cover"
                  >
                    <div className="col-sm-12 pb-3 pl-0 pr-0">
                      <TextValidator
                        className={'form-control form-control-lg'}
                        autoComplete="off"
                        placeholder={i18n.t('login.enter_email_lbl')}
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        isValidate={this.state.isValidate}
                        noValidate
                        validators={['required', 'isEmail']}
                        errorMessages={[i18n.t('login.errors.email_required'), i18n.t('login.errors.email_invalid')]} />
                    </div>
                    <div className="col-sm-12 pb-2 pl-0 pr-0">
                      <TextValidator
                        className={'form-control form-control-lg'}
                        autoComplete="off"
                        placeholder={i18n.t('login.enter_pass_lbl')}
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        isValidate={this.state.isValidate}
                        noValidate
                        validators={['required']}
                        errorMessages={[i18n.t('login.errors.password_required')]} />
                    </div>
                    {!this.state.isVLE ? 
                    <div className="col-sm-12 text-right pl-0 pr-0">
                      <Link to={`forgotpassword`} className={'text-accentb text-decoration-underline'}> { i18n.t('login.forgotpassword') }</Link>
                    </div>: null }
                    <button type="submit" className={'btn btn-accenta btn-lg btn-block mt-4'}> {i18n.t('login.login_btn')}  </button> 
                    {this.state.isVLE ? <button type="button" onClick={this.register_vle_user} className={'btn btn-outline-accenta btn-lg btn-block mt-4'}>or create new account</button> : null}
                    {!this.state.isVLE ? 
                    <div className="col-sm-12 text-center text-primary">
                      <div className="text-size-16 mt-4">{ i18n.t('login.login_with')}</div>
                      <div className="component-oath mt-3">
                        <div className="btn-oath btn-fasttrack btn-img"><img src={FAST_TRACK_ICON_IMG} className="img-fluid pointer"  id="fastTrack" onClick = {this.handleFastrack}></img></div>
                        <div className="btn-oath btn-office"><div className="lh-icon-service-office365 pointer"  id="office365" onClick = {this.handleRedirect}></div></div>
                        <div className="btn-oath btn-facebook"><div className="lh-icon-service-facebook pointer" id="facebook" onClick = {this.handleRedirect}></div></div>
                        <div className="btn-oath btn-Google"><div className="lh-icon-service-google pointer" id="google" onClick = {this.handleRedirect}></div></div>
                        <div className="btn-oath btn-Smartschool"><div className="lh-icon-service-smartschool pointer" id="smartschool" onClick = {this.handleRedirect}></div></div>
                        <div className="btn-oath btn-klascement"><div className="lh-icon-service-klascement pointer" id="klascement" onClick = {this.handleRedirect}></div></div>
                      </div>
                    </div> : null }
                    <div className='version-info mt-3 text-primary'>
                      <p>
                        <span>{this.props.VERSION_INFO.version} ( B{this.props.VERSION_INFO.build_number} ) ( {this.props.VERSION_INFO.deployed_date} )</span>
                      </p>
                      <div className='mt-3'>
                        <a href={this.state.tos_url} target="no_blank" className="text-accentb text-size-18 text-uline">{i18n.t('signup.step1.accept_tos_link_txt')}</a>
                      </div>
                    </div>
                  </ValidatorForm>
                  <Errors hasError={this.state.hasError} status={this.state.status} type="warning" message={this.state.errorMessage} show_resend_activation={this.state.show_resend_activation} email={this.state.email} resendActivationEmail={this.resendActivationEmail} show_raise_a_ticket_for_blocked_or_deleted_users={this.state.show_raise_a_ticket_for_blocked_or_deleted_users} raiseATicketForBlockedOrDeletedUsers={this.raiseATicketForBlockedOrDeletedUsers} />

                  <Success hasError={this.state.isValid} type='warning' message={this.state.errorMessage}/>
                </div>
              </div>
              <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="mobile mt-3" redirectPath="login" />
            </div>
            <FastTrack handler={this.handleFastrackState.bind(this)} isFastrack={this.state.isFastrack} fastrack_code={this.props.fastrack_code} fastrack_token_code={this.props.fastrack_token_code} play_url={this.props.play_url} app_url={this.props.app_url} />
          </div>}>
        </LandingStepBase>
        <LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="desktop" redirectPath="login" />
      </React.Fragment>
    )
  }
}

export default withTranslation()(Login);
