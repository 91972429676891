import React from 'react'
import ReactGA from 'react-ga4';

export const createEvent = (category,action,label,value,nonInteraction) => {
    ReactGA.event({
        category: category || null,
        action: action || null,
        label: label || null,
        value: value || null,
        nonInteraction: nonInteraction || false
    })
}