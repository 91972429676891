import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import moment from 'moment/min/moment-with-locales';

class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total_count: 0,
            per_page: 10,
            current_page: 1,
            data: [],
            togglePaymentFilter: false,
            isLoading: false,
            plans: [],
            payment_methods: [],
            payment_statuses: [],
            plan_type: '',
            payment_method: '',
            payment_status: '',
            start_date: '',
            end_date: '',
            current_date: new Date(),
            orderBy: ''
        }
    }
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        if (event.target.name === "start_date") {
            this.setState({ end_date: '' })
        }
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    };
    async componentDidMount() {
        await this.callApi(this.state.per_page, this.state.current_page, this.state.current_status)
    }

    async callApi(per_page, current_page, current_status) {
        this.setState({ data: [], isLoading: true })
        let { data } = await API.get('/oldview/payments/all_payments?per_page=' + per_page + '&current_page=' + current_page + '&order_by=' + this.state.orderBy)

        let finalPlans = new Array()
        for (var i in data.plans) {
            if (i !== 'team') {
                if (this.state.group_id) {
                    if (i !== 'free') {
                        let plan = data.plans[i];
                        let new_data = { value: plan.key, label: plan.display_text.includes('.') ? i18n.t(plan.display_text) : plan.display_text }
                        finalPlans.push(new_data)
                    }

                }
                else {
                    let plan = data.plans[i];
                    let new_data = { value: plan.key, label: plan.display_text.includes('.') ? i18n.t(plan.display_text) : plan.display_text }
                    finalPlans.push(new_data)
                }

            }

        }

        this.setState({ data: data.payments, total_count: data.total_count, plans: finalPlans, payment_methods: data.payment_methods, payment_statuses: data.payment_statuses, isLoading: false })
    }

    async filterData(per_page, current_page) {
        this.setState({ data: [], isLoading: true })
        let type = 'filter';
        let { data } = await API.get('/oldview/payments/all_payments?per_page=' + per_page + '&current_page=' + current_page + '&query=' + type + '&start_date=' + this.state.start_date + '&end_date=' + this.state.end_date + '&payment_status=' + this.state.payment_status + '&payment_method=' + this.state.payment_method + '&plan_type=' + this.state.plan_type)
        this.setState({ data: data.payments, total_count: data.total_count, isLoading: false })
        this.closeSideModal();
    }
    onTableChange = (type, newState) => {
        if(newState.sortOrder && newState.sortField){
            this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
        }
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi(newState.sizePerPage, newState.page, this.state.current_status)
    }
    closeSideModal = () => {
        this.setState({ togglePaymentFilter: false , plan_type: '',
        payment_method: '',
        payment_status: '',
        start_date: '',
        end_date: ''});
    }
    toggleSideModal = () => {
        this.setState({ togglePaymentFilter: !this.state.togglePaymentFilter });
    }
    userAction = (cell, row) => {
        if(row && row.user_info && row.user_info.path && row.user_info.name){
            return (<a href={row.user_info.path}>{row.user_info.name}</a>)
        }
        else{
            return (null)
        }
    }
    applyFilters = event => {
        this.setState({ current_page: 1 })
        this.filterData(this.state.per_page, 1);
        //, this.state.start_date, this.state.end_date, this.state.payment_method, this.state.payment_status, this.state.plan_type
    }
    render() {
        const columns = [{
            dataField: 'transaction_id',
            text: i18n.t('admin.labels.transaction_id').toUpperCase(),
            sort: true
        }, {
            dataField: 'paid_date',
            text: i18n.t('admin.labels.paid_date').toUpperCase(),
            sort: true
        }, {
            dataField: 'status',
            text: i18n.t('admin.labels.status').toUpperCase(),
            sort: false
        }, {
            dataField: 'plan',
            text: i18n.t('admin.labels.subscribe_plan').toUpperCase(),
            sort: false
        }, {
            dataField: 'method',
            text: i18n.t('admin.labels.payment_method').toUpperCase(),
            sort: false
        }, {
            dataField: 'next_pay_date',
            text: i18n.t('admin.labels.next_pay_date').toUpperCase(),
            sort: false
        }, {
            dataField: 'duration',
            text: i18n.t('admin.labels.duration').toUpperCase(),
            sort: false
        }, {
            dataField: 'payment_cycle',
            text: i18n.t('admin.labels.payment_cycle').toUpperCase(),
            sort: false
        }, {
            // dataField: 'user',
            text: i18n.t('admin.labels.payment_by').toUpperCase(),
            sort: false,
            formatter: this.userAction,
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        }];
        const defaultSorted = [{
            dataField: 'transaction_id',
            order: 'desc'
        }];
        const { togglePaymentFilter } = this.state
        // const paginatorOptions = {
        //     pageStartIndex: 1,
        //     hidePageListOnlyOnePage: true,
        //     showTotal: true
        // };
        // const rowEvents = {
        //     onClick: (e, row, rowIndex) => {
        //         let currentCellIndex = e.target.cellIndex;
        //         let lastCellIndex = document.querySelector('table tr:last-child td:last-child').cellIndex

        //         if (currentCellIndex == lastCellIndex && currentCellIndex !== undefined) {
        //             window.location.href = '/admin/users/view?id=' + rowIndex
        //         }
        //     }
        // }
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        }
        const options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', float: 'right', height: '50px' }}>
                                <span className="lh-icon-os-filter filter-icon-users p-4" onClick={this.toggleSideModal}></span>
                            </div>
                        </div>
                        <div className="payment-table">
                            <BootstrapTable
                                keyField='transaction_id'
                                options={options}
                                fetchInfo={{ dataTotalSize: this.state.total_count }}
                                data={this.state.data}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                remote
                                onTableChange={this.onTableChange}
                                noDataIndication={() => <NoDataIndication />}
                                pagination={paginationFactory(paginatorOptions)}
                            />
                        </div>
                    </div>
                </div>

                {/* Payment Table Filter */}
                <div className="sidebar-container user-filter-sidebar" style={{ display: (togglePaymentFilter ? 'block' : 'none') }}>
                    <div className="right-sidebar">
                        <div className="sidebar-header bg-primary">
                            <a href={void (0)} className="close-btn" onClick={this.closeSideModal}><span className="lh-icon-os-close"></span> </a>
                            <h1 className="sb-head-title">{i18n.t('admin.labels.filter')}</h1>
                        </div>
                        <div className="sidebar-body">
                            <div className="users-filter-body payment-filters">
                                <div>
                                    <h6>{i18n.t('admin.labels.plan')}</h6>
                                    <select onChange={this.handleChange} value={this.state.plan_type} name="plan_type" defaultValue="" className="form-control sub-filter">
                                        <option value=''>{i18n.t('admin.labels.all')}</option>
                                        {this.state.plans ? this.state.plans.map(item => {
                                            return (<option key={item.value} value={item.value}>{item.label}</option>);
                                        }) : null}
                                    </select>
                                </div>
                                <div className="mt-4">
                                    <h6>{i18n.t('admin.labels.payment_method')}</h6>
                                    <select onChange={this.handleChange} value={this.state.payment_method} name="payment_method" className="form-control sub-filter">
                                        <option value="">{i18n.t('admin.labels.all')}</option>
                                        {this.state.payment_methods ? this.state.payment_methods.map(item => {
                                            return (<option key={item} value={item}>{item}</option>);
                                        }) : null}
                                    </select>
                                </div>
                                <div className="mt-4">
                                    <h6>{i18n.t('admin.labels.payment_status')}</h6>
                                    <select onChange={this.handleChange} name="payment_status" value={this.state.payment_status} className="form-control sub-filter">
                                        <option value="">{i18n.t('admin.labels.all')}</option>
                                        {this.state.payment_statuses ? this.state.payment_statuses.map(item => {
                                            return (<option key={item} value={item}>{item}</option>);
                                        }) : null}
                                    </select>
                                </div>
                                <div class="mt-4">
                                    <div className=" mt-3">
                                        <h6>{i18n.t('admin.labels.start_date')}</h6>
                                        <input name="start_date" type="date" className="form-control sub-filter mb-3" value={moment(this.state.start_date).format("YYYY-MM-DD")} onChange={this.handleChange} max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                                        <h6>{i18n.t('admin.labels.end_date')}</h6>
                                        <input value={this.state.end_date ? moment(this.state.end_date).format("YYYY-MM-DD") : this.state.end_date} min={moment(this.state.start_date).format("YYYY-MM-DD")} max={moment(this.state.current_date).format("YYYY-MM-DD")}  onChange={this.handleChange} name="end_date" type="date" class="form-control sub-filter" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar-footer">
                            <div className="footer-action-panel justify-content-end">
                                <button className="btn btn-outline-tertiary mt-2" onClick={this.closeSideModal} >{i18n.t('userprofile.btn_cancel')}</button>
                                <button style={{ width: '6rem' }} className="btn btn-accenta ml-2" onClick={this.applyFilters}>{i18n.t('admin.btn_apply')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Payments)