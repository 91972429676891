/* this component is basically for displaying errors in any other component */
import React from 'react';
import { Error_IMG } from '../assets_paths';

//Static class for Error Warning Simple Design replace with proper css classes

const ErrorWarning = {
    color: 'orange',
    fontSize: '2em',
    margin: '7px',
    fontWeight: 'bold'
}

class Errors extends React.Component {
    constructor(props) {
      super(props);
    }

    handleBackButton = () => {
      this.props.history.goBack();
      window.location.reload();
    }

    handleHomeButton = () => {
      this.props.history.push('/');
      window.location.reload();
    }

    resendActivationLink = () => {
      this.props.resendActivationEmail();
    }

    raiseATicketForBlockedOrDeletedUsers = () => {
      this.props.raiseATicketForBlockedOrDeletedUsers();
    }

    render() {
        if (!this.props.hasError) {
          return null;
        }
        const hasObject = typeof this.props.message == 'object';
        const status = this.props.status;
        const majorError = status === 400 || status === 401 || status === 403 || status === 500 || status === 502 || status === 503 || status === 504;
        const simpleError = status !== 400 && status !== 404 && status !== 500 && status !== 403;
        return (
            (simpleError) ?
            (<div className="alert alert-danger mt-4 animated fadeInUp" role="alert">
                <span className="lh-icon-user-alert-notification text-size-48"></span>
                {
                    hasObject ?
                        <ul className="mb-0 text-left">
                            {
                                this.props.message.map((value, key) => {
                                    return <li key={key}>{value}.</li>;
                                })
                            }
                        </ul>
                        :
                          this.props.dynemichtml ?
                            <div dangerouslySetInnerHTML={{ __html : this.props.message}}>
                            </div> 
                            : 
                              <div>
                                  {this.props.message}
                                  <br />
                                  {
                                    this.props.show_resend_activation ?
                                      <a href="javascript:void(0);" className='activationLink' onClick = {this.resendActivationLink}>Resend Activation Link</a>
                                    : null
                                  }
                                  {
                                    this.props.show_raise_a_ticket_for_blocked_or_deleted_users ?
                                      <a href="javascript:void(0)" className="raiseATicketForBlockedUsers" onClick = {this.raiseATicketForBlockedOrDeletedUsers}> Raise a Ticket</a>
                                    : null
                                  }
                              </div>
                }
            </div>)
            :
            (majorError &&
            <div className="error-wrapper text-center">
                <div>
                    <h1 className="title mb-4">Oh diggity darnit...</h1>
                    <h1 className="subtitle mb-4">
                        { hasObject ?   <ul className="mb-0 text-left">
                                        {
                                            this.props.message.map((value, key) => {
                                                return <li key={key}>{value}.</li>;
                                            })
                                        }
                                        </ul>
                                        :
                                        this.props.message
                        }
                    </h1>
                </div>
                <div className="m-5">
                    <img className="errorImg" src={Error_IMG}  alt="Error"/>
                </div>
                <div className="error-button-wrapper">
                    <div className="p-2">
                        <button className="btn btn-block btn-secondary pl-5 pr-5" onClick={this.handleBackButton}>BACK</button>
                    </div>
                    <div className="p-2">
                        <button className="btn btn-block btn-outline-secondary" onClick={this.handleHomeButton}>HOMEPAGE</button>
                    </div>
                </div>
            </div>
            )
        )
    }
}


export default Errors;
