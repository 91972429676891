import React from 'react';

export default class RotateSlider extends React.Component {
    //left-pack active-pack right-pack
    state = {
        item: [],
        itemCount:0
    }
    
    constructor(props) {
      super(props);
      this.changeIndex = this.changeIndex.bind(this);
    }

    componentDidMount() {
        const rotate = document.getElementsByClassName(this.props.sliderClass);
        this.setState({
            item: rotate,
            itemCount: rotate.length
        }, () => {
            this.changeIndex(0);
        });
        
        for(let i = 0; i < rotate.length; i++) {
            rotate[i].addEventListener('click', () => {
                let action = 'none';
                let nowIndex = 0;
                [].forEach.call(rotate, function(el, index) {
                    if(el.classList.contains("active-pack")) {
                        nowIndex = index;
                    }
                });
                if (rotate[i].classList.contains("left-pack")) action = 'prev';
                else if (rotate[i].classList.contains("right-pack")) action = 'next';
                if(action == 'next') {
                    if(nowIndex == this.state.itemCount - 1) {
                        this.changeIndex(0);
                    } else {
                        this.changeIndex(nowIndex + 1);
                    }
                } else if (action == 'prev') {
                    if(nowIndex == 0) {
                        this.changeIndex(this.state.itemCount - 1);
                    } else {
                        this.changeIndex(nowIndex - 1);
                    }
                }
                
            });
        }
        
    }

    componentWillUnmount() {
        const rotate = document.getElementsByClassName(this.props.sliderClass);
        for(let i = 0; i < rotate.length; i++) {
            rotate[i].removeEventListener('click', this.changeIndex);
        }
    }

    changeIndex (nowIndex) {
        [].forEach.call(this.state.item, (el, index) => {
            el.classList.remove("active-pack");
            el.classList.remove("right-pack");
            el.classList.remove("left-pack");
        });
        if(nowIndex == this.state.itemCount - 1){
            this.state.item[0].classList.add("right-pack");
        }
        if(nowIndex == 0) {
            this.state.item[this.state.itemCount - 1].classList.add("left-pack");
        }
        [].forEach.call(this.state.item, (el, index) => {
            if(index == nowIndex) {
                el.classList.add("active-pack");
            }
            if(index == nowIndex + 1 ) {
                el.classList.add('right-pack');
            }
            if(index == nowIndex - 1 ) {
                el.classList.add('left-pack');
            }
        });
    }

    render() {
      return (
        <React.Fragment>
           { this.props.children }
        </React.Fragment>
      );
    }
};