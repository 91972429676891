import React from "react";
import i18n from "../../packs/i18n";
import API from '../api';
import { getUserInfo, getLocalUrl, setIsUserInfoReload } from "../Common/i3library";
import SweetModelAlert from "../Common/SweetModelAlert";
import { PACK_FREE_IMG, PACK_INDI_IMG, PACK_TEAM_IMG, PACK_SUB_ICON_IMG } from "../Common/assets_paths";
import Loading from "../Common/Loading";

class ManageSubscriptions extends React.Component {
    state = {
        loading: true,
        subscription: null,
        show: false,
        showUpgadeAlert: false,
        cancelled: false,
        showRenewAlert: false,
        showAdminDetail : false,
        showCancelInfoAlert: false
    }
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getData();
        const userInfo = getUserInfo();
        if(userInfo.payment_method === "wire_transfer"){
            this.setState({ showAdminDetail : true })
        }
    }
    handleCancelSubscriptionSucess = () =>{
        window.location.href = window.location.href;
        this.setState({showCancelInfoAlert: false});
    }
    handleCancleSubscription = () => {
        API.post('subscription/cancel')
            .then(res => {
                this.getData();
                setIsUserInfoReload(true);
                this.setState({showCancelInfoAlert: true});
            })
            .catch(error => {

            }).finally(() => {
                this.setState({show: false});
            })
    }

    handleConfirmUpgrade = () => {
        this.setState({
            showUpgadeAlert:false
        });
        const  userInfo = getUserInfo();
        this.props.history.push({
            pathname: getLocalUrl(this.props.match.params.locale || (userInfo && userInfo.language), `signup/selectpackage/${userInfo.persistence_token}`),
            state: { currentPlan: this.state.subscription, userInfo:  userInfo, upgradePlan : true, action: 'upgrade' }
        });
    }

    handleConfirmRenew = () => {
        this.setState({
            showRenewAlert:false
        });
        const  userInfo = getUserInfo();
        const planDetail = {
            planType: this.state.subscription.key,
            monthly_price : this.state.subscription.price,
            yearly_price : this.state.subscription.price,
            sub_plans: null,
            userInfo: null,
            display_text: this.state.subscription.display_text
        }

        this.props.history.push({
            pathname: getLocalUrl(this.props.match.params.locale || (userInfo && userInfo.language),(`signup/payment/${userInfo.persistence_token}`)),
            state: {
                planDetail:planDetail,
                selectedType: this.state.subscription.payment_cycle === 'monthly' ? 'month' : 'yearly',
                voucherCode: null,
                voucherInfo: null,
                selected_sub_plan: null,
                you_save_price: null,
                team_name: '',
                currentPlan: this.state.subscription,
                action: 'renew'
            }
        });
    }

    getData() {
        API.get('subscription/active')
            .then(res => {
                if(res.data && res.data.redirectPath) {
                    window.location = res.data.redirectPath;
                } else {
                    this.setState({subscription: res.data});
                }
            })
            .catch(error => {

            }).finally(() => {
                this.setState({loading: false});
            })
    }
    render() {
        if(this.state.loading) {
            return  <div className="profile-main-wrapper">
                      <div className="white-cover-fixheight">
                        <Loading/>
                      </div>
                    </div>
        }
        const { subscription, showAdminDetail, showCancelInfoAlert } = this.state;
        const  userInfo = getUserInfo();
        let img_path = null;
        let pack_color = null;
        if(subscription && subscription.key.indexOf('free') >= 0 ){
            img_path = PACK_FREE_IMG;
            pack_color = "#e5c5e8";
        }
        else if(subscription && subscription.key.indexOf('individual') >= 0 ){
            img_path = PACK_INDI_IMG
            pack_color = "#acb5da";
        }
        else if(subscription && subscription.key.indexOf('team') >= 0){
            img_path = PACK_TEAM_IMG
            pack_color = "#bfd0e8";
        }
                           
        return (
            <div className="profile-main-wrapper">
                <div className="white-cover-fixheight your-subscription">
                    <h3 className="text-primary mb-3">{i18n.t('userprofile.manage_subscriptions.heading')}</h3>
                    { subscription ?
                        <React.Fragment>
                            <div className="payment-option-wrapper col-sm-12 col-xs-12 col-lg-12 ml-auto mr-auto mb-3">
                                <div className="payment-option shadow-lg">
                                    <div className={subscription.is_pack_user ? "custom-control custom-radio selector-tick p-manage-subscription" : "custom-control custom-radio selector-tick"}>
                                        { subscription.is_pack_user ? 
                                            <div className = "mb-4">
                                                <img src={PACK_SUB_ICON_IMG} />
                                            </div>
                                          : null 
                                        }
                                        <div className = "mb-1">
                                            <label className="custom-control-selection" htmlFor="chk2"></label>
                                            <input type="radio" className="custom-control-input" id="chk2" name="paymenttype" defaultChecked={ subscription.active_subscription ? "checked" : ''} />
                                            <label className="custom-control-label" htmlFor="chk2"></label>
                                        </div>
                                    </div>
                                    <img src={img_path} />
                                    <div className="option-details">
                                        <h5 className="text-secondary">{i18n.t(subscription.display_text)}</h5>
                                        <p className="text-accentb m-0 mb-1">€ <span className="pay-price">{subscription.price ? subscription.price : 0}</span> { subscription.payment_cycle ? <span>/ {subscription.payment_cycle}</span> : null} </p>
                                        { subscription.max_users && subscription.min_users  ?
                                            <p className="text-size-14 text-accentb m-0">{i18n.t('userprofile.manage_subscriptions.max_users').replace(':number', subscription.max_users)}</p>
                                            : null
                                        }
                                        { subscription.has_expired == 'expired' ? <p className="text-size-14 text-danger m-0"> {i18n.t('userprofile.manage_subscriptions.subscription_expired')} </p> : null }
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                              {
                                  showCancelInfoAlert == true ? 
                                  <SweetModelAlert
                                            customClass="remove-profile"
                                            show={this.state.showCancelInfoAlert}
                                            title={i18n.t('userprofile.manage_subscriptions.cancel_subscription')}
                                            text={i18n.t('userprofile.manage_subscriptions.cancel_subscription_sucess_message')}
                                            false
                                            confirmButtonText={i18n.t('userprofile.confirm_ok')}
                                            onConfirm={this.handleCancelSubscriptionSucess}
                                    /> : null
                              }
                              {
                                subscription.key == 'free' && subscription.trial_period == true ?
                                  subscription.has_expired == 'trial-expired' ?
                                    <p className="text-size-14 text-secondary mb-0">{i18n.t('userprofile.manage_subscriptions.free_trial_expired')} {subscription.next_pay_date}</p> :
                                    <p className="text-size-14 text-secondary mb-0">{i18n.t('userprofile.manage_subscriptions.free_trial_expires')} {subscription.next_pay_date}</p>
                                  : null
                              }
                                { subscription.next_pay_date && ( subscription.has_expired != 'free' && subscription.has_expired != 'trial-expired' && subscription.has_expired != 'active' )  ?
                                        subscription.cancelled ?
                                            <p className="text-size-14 text-secondary mb-0">{i18n.t('userprofile.manage_subscriptions.cancelled_message')}</p> :
                                        subscription.has_expired == 'expired' ?
                                            <p className="text-size-14 text-secondary mb-0">{i18n.t('userprofile.manage_subscriptions.subscription_expired')} {subscription.next_pay_date}</p>
                                        : <p className="text-size-14 text-secondary mb-0">{i18n.t('userprofile.manage_subscriptions.expire_message')} {subscription.next_pay_date}</p>
                                    : null
                                }
                                { subscription.next_pay_date && (subscription.has_expired != 'free' && subscription.has_expired == 'active' && !subscription.is_active_trial_period) ?
                                    <p className="text-size-14 text-secondary mb-0">{i18n.t('userprofile.manage_subscriptions.expire_message')} {subscription.next_pay_date}</p>
                                    : null

                                }
                                {
                                    (subscription.key != 'free' && subscription.has_expired == 'active' && userInfo.payment_method != "wire_transfer" && !subscription.cancelled && !userInfo.is_team_user) ?

                                        <React.Fragment>
                                            <a href="javascript:void(0)" className="text-accenta" onClick={() => this.setState({show: true})}>{i18n.t('userprofile.manage_subscriptions.cancel_my_subscription')}</a>
                                            <SweetModelAlert
                                                customClass="remove-profile"
                                                show={this.state.show}
                                                title={i18n.t('userprofile.manage_subscriptions.cancel_subscription')}
                                                text={i18n.t('userprofile.image_delete_confirm_message')}
                                                showCancelButton
                                                confirmButtonText={i18n.t('userprofile.confirm_yes')}
                                                cancelButtonText={i18n.t('userprofile.confirm_no')}
                                                onConfirm={this.handleCancleSubscription}
                                                onCancel={() => this.setState({show:false})}
                                            />
                                        </React.Fragment> : null
                                }

                                {
                                    (subscription.has_expired == 'expired' || subscription.cancelled) && subscription.key != "team_advanced" && !userInfo.is_team_user ?
                                    <div>
                                        <a href="javascript:void(0)" onClick={() => { this.setState({ showUpgadeAlert: true })}} className="text-accenta">{i18n.t('userprofile.manage_subscriptions.upgade_my_subscription')}</a>
                                        <SweetModelAlert
                                            customClass="remove-profile"
                                            show={this.state.showUpgadeAlert}
                                            title={i18n.t('userprofile.manage_subscriptions.upgade_my_subscription')}
                                            text={i18n.t('userprofile.manage_subscriptions.confirm_upgrade_subscription')}
                                            showCancelButton
                                            confirmButtonText={i18n.t('userprofile.confirm_yes')}
                                            cancelButtonText={i18n.t('userprofile.confirm_no')}
                                            onConfirm={this.handleConfirmUpgrade}
                                            onCancel={() => this.setState({showUpgadeAlert:false})}
                                        />
                                    </div> : null
                                  }
                                  {
                                    (subscription.key == "free" && userInfo.payment_method != "wire_transfer") && 
                                    <div>
                                        <a href="javascript:void(0)" onClick={() => { this.setState({ showUpgadeAlert: true })}} className="text-accenta">{i18n.t('userprofile.manage_subscriptions.upgade_my_subscription')}</a>
                                        <SweetModelAlert
                                            customClass="remove-profile"
                                            show={this.state.showUpgadeAlert}
                                            title={i18n.t('userprofile.manage_subscriptions.upgade_my_subscription')}
                                            text={i18n.t('userprofile.manage_subscriptions.confirm_upgrade_subscription')}
                                            showCancelButton
                                            confirmButtonText={i18n.t('userprofile.confirm_yes')}
                                            cancelButtonText={i18n.t('userprofile.confirm_no')}
                                            onConfirm={this.handleConfirmUpgrade}
                                            onCancel={() => this.setState({showUpgadeAlert:false})}
                                        />
                                    </div>
                                  }
                                  {
                                    
                                    ( (subscription.has_expired == 'expired') && (userInfo.payment_method != null ) && !userInfo.is_team_user )
                                    ? <div>
                                        <a href="javascript:void(0)" onClick={this.handleConfirmRenew} className="text-accenta">{i18n.t('userprofile.manage_subscriptions.renew_my_subscription')}</a>
                                        <SweetModelAlert
                                            customClass="remove-profile"
                                            show={this.state.showRenewAlert}
                                            title={i18n.t('userprofile.manage_subscriptions.renew_my_subscription')}
                                            text={i18n.t('userprofile.manage_subscriptions.confirm_renew_subscription')}
                                            showCancelButton
                                            confirmButtonText={i18n.t('userprofile.confirm_yes')}
                                            cancelButtonText={i18n.t('userprofile.confirm_no')}
                                            onConfirm={this.handleConfirmRenew}
                                            onCancel={() => this.setState({showRenewAlert:false})}
                                        />
                                    </div> : null
                                }

                                { showAdminDetail && (subscription.has_expired == 'free' || subscription.has_expired == 'trial-expired' || subscription.has_expired == 'expired' || subscription.cancelled) &&
                                    <div className="mt-4 wire-transfer-wrapper">
                                        { (subscription.has_expired == 'free' || subscription.has_expired == 'trial-expired') ?  <h6 className="text-primary mb-1">{i18n.t('userprofile.manage_subscriptions.contact_admin_message_free')}</h6> : <h6 className="text-primary mb-1">{i18n.t('userprofile.manage_subscriptions.contact_admin_message_expired')}</h6> }
                                        <div className="wire-transfer-container">
                                            <div className="ml-3">
                                                <label className="text-primary mb-1"> {i18n.t('userprofile.manage_subscriptions.contact_admin_label_name')}
                                                    <span className="text-secondary mb-1 ml-2"> { userInfo.contact_info.name } </span>
                                                </label>
                                            </div>
                                            <div className="ml-3">
                                                <label className="text-primary mb-1">  {i18n.t('userprofile.manage_subscriptions.contact_admin_label_email')}
                                                    <span className="text-secondary mb-1 ml-2"> { userInfo.contact_info.email } </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </React.Fragment>
                        :
                        <div className="text-center">
                            <p className="text-size-14 text-secondary mb-0">
                                {
                                    userInfo.paying_admin ? i18n.t('userprofile.manage_subscriptions.no_subscription_active') : i18n.t('userprofile.manage_subscriptions.top_subscription_active')
                                }
                            </p>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ManageSubscriptions;
