import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import API from '../api';
import { NavDropdown } from 'react-bootstrap';
import { Loading_IMG } from "../Common/assets_paths";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import SelectValidator from "../Common/SelectValidator";
import TextValidator from "../Common/TextValidator";
import Errors from "../Common/Errors/Errors";
import countryList from 'react-select-country-list';
import { getAvailableLanguages } from "../Common/i3library";
import TextareaValidator from "../Common/TextareaValidator";
import CheckboxValidator from "../Common/CheckboxValidator";
import Modal from "react-bootstrap/Modal";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import moment from 'moment/min/moment-with-locales';
import Users from "./Users";
import { EventEmitter } from '../Common/Events';
class ViewUser extends React.Component {
    constructor(props) {
        super(props);
        let path_array = props.location.pathname.split("/user/view/")
        const countryOptions = countryList().getData();
        //ILEARN-4927 Tiwan Name Update 
        let taiwan_item = countryOptions.find(item => item.value == 'TW');
        taiwan_item.label = i18n.t("userprofile.labels.tiwan_label");
        let tiwan_index = countryOptions.findIndex(item => item.value == 'TW');
        countryOptions[tiwan_index] = taiwan_item;
        //ILEARN-4927 Tiwan Name Update End
        const languagesOptions = getAvailableLanguages();
        this.state = {
            current_user_id: parseInt(path_array[path_array.length - 1]),
            userInfo: {},
            subscriptions: [],
            active_subscription: {},
            base_price: 0.0,
            upgrade_plan: '',
            institution_code: '',
            institution: '',
            isValid: false,
            hasError: false,
            errorMessage: "",
            isValidate: false,
            latest_subscription: '',
            plans: [],
            can_extend: false,
            can_renew: false,
            can_wire: false,
            is_active: false,
            number_of_years: 1,
            number_of_years_ext: 1,
            isQA: false,
            countryOpt: countryOptions,
            languageOpt: languagesOptions,
            country: 'IN',
            language: 'en',
            accepted_tos: '',
            accepted_privacy_policy: '',
            tos_accept_date: '',
            current_login_at: '',
            isLoading: false,
            email_confirmed: false,
            pwd_link: '',
            latest_subscription_id: null,
            isLoading: false,
            net_amount_paid: 0.0,
            cancel_reason: '',
            cancel_reason_payment: '',
            isLoading: false,
            isCancelPaymentOpen: false,
            auto_approve: false,
            breadcrumb: [],
            isTopAdmin: props.is_top_admin,
            isResellerAdmin: props.is_reseller,
            user_is_top_admin: false,
            user_is_distributor: false,
            user_is_reseller: false,
            group_class_name: '',
            tab_selector: 'tab1',
        }
        if (!props.is_top_admin) {
            document.body.classList.add('no-topadmin')
        }
        else {
            document.body.classList.remove('no-topadmin')
        }
    }
    UNSAFE_componentWillMount() {
        this.getUserDetails()
    }
    async getUserDetails() {

        await API.get('/users/' + this.state.current_user_id)
            .then(res => {
                if (res.data) {
                    let finalPlans = new Array()
                    for (var _plan in res.data.upgradable_plans) {
                        let plan = res.data.upgradable_plans[_plan];
                        let new_data = { value: plan[1], label: plan[0].includes('.') ? i18n.t(plan[0]) : plan[0] }
                        finalPlans.push(new_data)
                    }
                    
                    document.getElementById("ext_sub_amount").value = parseFloat(res.data.base_price) * parseInt(this.state.number_of_years_ext)
                    document.getElementById("ext_sub_amount_renew").value = parseFloat(res.data.base_price) * parseInt(this.state.number_of_years_ext)
                    document.getElementById("ext_sub_amount_convert").value = parseFloat(res.data.base_price) * parseInt(this.state.number_of_years_ext)
                    this.setState({
                        isLoading: false,
                        userInfo: res.data.user,
                        subscriptions: res.data.subscriptions,
                        active_subscription: res.data.current_plan,
                        base_price: res.data.base_price,
                        can_extend: res.data.can_extend,
                        can_renew: res.data.can_renew,
                        can_wire: res.data.can_wire_transfer,
                        is_active: res.data.is_active,
                        latest_subscription: res.data.latest_subscription,
                        plans: finalPlans,
                        institution: res.data.user.institution,
                        institution_code: res.data.user.institution_code,
                        isQA: res.data.isQA,
                        country: res.data.user.country,
                        language: res.data.user.language,
                        accepted_privacy_policy: res.data.user.accepted_privacy_policy,
                        accepted_tos: res.data.user.accepted_tos,
                        tos_accept_date: res.data.user.tos_accept_date,
                        current_login_at: res.data.user.current_login_at,
                        email_confirmed: res.data.email_confirmed,
                        pwd_link: res.data.pwd_link,
                        latest_subscription_id: res.data.latest_subscription_id,
                        breadcrumb: res.data.user.breadcrumb ? res.data.user.breadcrumb.reverse() : [],
                        user_is_top_admin: res.data.top_admin,
                        user_is_distributor: res.data.is_distributor,
                        user_is_reseller: res.data.is_reseller,
                        group_class_name: res.data.group_class_name,
                        current_subscription : res.data.current_subscription,
                        net_amount_paid: parseFloat(res.data.base_price) * parseInt(this.state.number_of_years_ext)
                    })
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                }
                if (error.request) {
                    console.log(error.request);
                }
                else {
                    console.log(error)
                }
            });
    }
    routeBackToUsers = (ev) => {
        window.location.href = '/admin/users/paid';
        //window.history.back()
    }
    approvePayment = (e) => {
        let userId = this.state.userInfo.id;
        let paymentId = event.target.getAttribute('data-id');
        API.get('/oldview/user_payments/' + paymentId + '/approve?user_id=' + userId)
            .then(() => {
                window.location.reload()
            })
            .catch((error) => {

            })
    }
    showCancelPaymentModal = () => {
        this.setState({ isCancelPaymentOpen: true })
    }
    hideCancelPaymentModal = () => {
        this.setState({ isCancelPaymentOpen: false })
    }
    cancelPayment = (e) => {
        let paymentId = event.target.getAttribute('data-id');
        this.setState({ current_payment_cancel_id: paymentId })
        this.showCancelPaymentModal();

    }
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        if (event.target.name === "number_of_years") {
            if (event.target.value < 1) {
                event.preventDefault();
                return
            }
            if (event.target.value > 5) {
                event.preventDefault();
                return
            }
        }
        if (event.target.name === "number_of_years_ext") {
            if (event.target.value < 1) {
                event.preventDefault();
                return
            }
            if (event.target.value > 5) {
                event.preventDefault();
                return
            }
            const calcPrice = parseFloat(this.state.base_price) * parseInt(event.target.value)
            this.setState({ 'net_amount_paid': calcPrice })
            document.getElementById("ext_sub_amount").value = calcPrice
            document.getElementById("ext_sub_amount_renew").value = calcPrice
            document.getElementById("ext_sub_amount_convert").value = calcPrice
        }
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    };
    onPasteHandle = event => {
        event.preventDefault();
        return
    }
    handleSubmit = event => {
        if (!this.state.shouldBlockNavigation) {
            return;
        }
        this.setState({ isLoading: true });

        let userId = this.state.userInfo.id;
        let plan = this.state.upgrade_plan;
        let number_of_years = this.state.number_of_years || 1;

        const formData = new FormData();
        formData.append('user_id', userId)
        formData.append('plan', plan);
        formData.append('number_of_years', number_of_years);
        if (this.state.upgrade_plan.includes('team')) {
            formData.append('institution', this.state.institution);
            formData.append('institution_code', this.state.institution_code);
        }
        formData.append('auto_approve', this.state.auto_approve)
        API.post('/subscriptions/change_plan', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })

    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    }
    rankFormatter = (cell, row) => {
        if (!this.state.isTopAdmin) {
            return (null)
        }
        let payment_id = row.id;
        let isPending = row["pending?"];
        if (isPending) {
            return (
                <div>
                    <span className="highlightText approve" data-id={payment_id} onClick={this.approvePayment}>{i18n.t('admin.labels.approve')}</span><br />
                    <span className="highlightText cancel" data-id={payment_id} onClick={this.cancelPayment}>{i18n.t('userprofile.btn_cancel')}</span>
                </div>
            )
        }
    }
    hardDeleteUser = event => {
        let user_id = this.state.userInfo.id;
        
    }
    expireUserSubscription = event => {
        let user_id = this.state.userInfo.id;
        API.get('/oldview/users/' + user_id + '/expire_user_subscription')
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }
    
    handleSubmitExtend = event => {
        let subscription_id = this.state.latest_subscription_id;
        let userId = this.state.userInfo.id;
        let number_of_years = parseInt(this.state.number_of_years_ext) || 1;
        if (!number_of_years) {
            alert(i18n.t('admin.errors.require_number_of_year'))
            return;
        }

        let paid_amount = this.state.net_amount_paid;
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('subscription_id', subscription_id);
        formData.append('number_of_years', number_of_years);
        formData.append('paid_amount', paid_amount);

        API.post('/oldview/subscriptions/extend', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }

    handleSubmitRenew = event => {
        let subscription_id = this.state.latest_subscription_id;
        let userId = this.state.userInfo.id;
        let number_of_years = parseInt(this.state.number_of_years_ext) || 1;
        if (!number_of_years) {
            alert(i18n.t('admin.errors.require_number_of_year'))
            return;
        }

        let paid_amount = this.state.net_amount_paid;

        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('subscription_id', subscription_id);
        formData.append('number_of_years', number_of_years);
        formData.append('paid_amount', paid_amount);

        API.post('/oldview/subscriptions/renew', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }

    handleSubmitConvert = event => {
        let subscription_id = this.state.latest_subscription_id;
        let userId = this.state.userInfo.id;
        let number_of_years = parseInt(this.state.number_of_years_ext) || 1;
        if (!number_of_years) {
            alert(i18n.t('admin.errors.require_number_of_year'))
            return;
        }
        let paid_amount = this.state.net_amount_paid;

        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('subscription_id', subscription_id);
        formData.append('number_of_years', number_of_years);
        formData.append('paid_amount', paid_amount);

        API.post('/subscriptions/convert_to_wire_transfer', formData)
        .then(res => {
            window.location.reload();
        })
        .catch(function (error) {
        })
    }
    handleSubmitCancelPayment = event => {
        if (!this.state.shouldBlockNavigation) {
            return;
        }
        this.setState({ isLoading: true });
        const formData = new FormData();

        formData.append('up_id', this.state.current_payment_cancel_id);
        formData.append('user_id', this.state.userInfo.id);
        formData.append('reason', this.state.cancel_reason_payment);

        API.patch('/oldview/user_payments/cancel', formData)
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {

            })
    }

    handleSubmitCancel = event => {
        let subscription_id = this.state.latest_subscription_id;
        let userId = this.state.userInfo.id;
        let reason = this.state.cancel_reason;

        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('subscription_id', subscription_id);
        formData.append('reason', reason);

        API.patch('/oldview/subscriptions/cancel', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }
    editUser = (event) => {
        if(this.state.userInfo){
            EventEmitter.dispatch('editUserSearch',  this.state.userInfo)
        }
    }
    blockUser = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('blockUserSearch', id);
    }
    unblockUser = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('unblockUserSearch', id);
    }
    deleteUser = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('deleteUserSearch', id);
    }
    promotUser = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('promotUserSearch', id);
    }
    demoteUser = (event) => {
        let id = event.target.getAttribute('data-id');
        EventEmitter.dispatch('demoteUserSearch', id);
    }
    changeParent = (event) => {
        if(this.state.userInfo){
            EventEmitter.dispatch('changeParentUserSearch',  this.state.userInfo)
        }
    }
    enablePWA = (event) => {
        this.callEnableDisablePWAApi(this.state.userInfo.id, true);
    }
    disablePWA = (event) => {
        this.callEnableDisablePWAApi(this.state.userInfo.id, false);
    }
    callEnableDisablePWAApi = (user_id, is_pwa) => {
        const formData = new FormData();
        formData.append('is_pwa', is_pwa);
    
        API.post('/users/'+user_id+'/enable_disable_pwa', formData)
          .then(res => {
            window.location.reload()
          })
          .catch(function (error) {
    
          })
    }
    render() {
        const columns = [{
            dataField: 'fetch_transaction_id',
            text: i18n.t('admin.labels.transaction_id').toUpperCase(),
            sort: true
        }, {
            dataField: 'paid_date',
            text: i18n.t('admin.labels.paid_date').toUpperCase(),
            sort: true
        }, {
            dataField: 'paid_amount',
            text: i18n.t('admin.labels.paid_amount').toUpperCase(),
            sort: true
        }, {
            dataField: 'process_by',
            text: i18n.t('admin.labels.process_by').toUpperCase(),
            sort: true
        }, {
            dataField: 'payment_status_title',
            text: i18n.t('admin.labels.payment_status').toUpperCase(),
            sort: true
        }, {
            dataField: 'actions',
            formatter: this.rankFormatter,
        }];

        const defaultSorted = [{
            dataField: 'name',
            order: 'desc'
        }];
        const paginatorOptions = {
            pageStartIndex: 1,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        };
        const RankFormatterUser = () => {
            let user_id = this.state.userInfo.id;
            let is_deleted = this.state.userInfo.is_deleted;
            let blocked = this.state.userInfo.blocked;
            let is_admin = this.state.userInfo['admin?'];
            return (
              <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.editUser}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
                {
                  !is_deleted ? <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.deleteUser}>{i18n.t('admin.labels.delete')}</NavDropdown.Item> : null
                }
                {
                  !blocked ?
                    <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.blockUser}>{i18n.t('admin.labels.block')}</NavDropdown.Item>
                    :
                    <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.unblockUser}>{i18n.t('admin.labels.unblock')}</NavDropdown.Item>
                }
                {!is_admin ?
                <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.promotUser}>{i18n.t('admin.labels.promote')}</NavDropdown.Item> : null}
                {is_admin ?
                <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.demoteUser}>{i18n.t('admin.labels.demote')}</NavDropdown.Item> : null }
                { this.props.is_top_admin && this.state.userInfo.is_pwa ?  
                    <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.disablePWA} >{i18n.t('admin.labels.disable_pwa')}</NavDropdown.Item> : null
                }
                { this.props.is_top_admin && !this.state.userInfo.is_pwa ?  
                    <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.enablePWA} >{i18n.t('admin.labels.enable_pwa')}</NavDropdown.Item> : null
                }
                <NavDropdown.Item href={void (0)} data-id={user_id} onClick={this.changeParent} >{i18n.t('admin.labels.change_parent')}</NavDropdown.Item>

              </NavDropdown>
            );
        }
        const SubscriptionAccordian = (props) => {
            const subscr = props.subscr;
            return (
                <div className="accordion-panel">
                    <div className="panel-header" id="heading-2" style={{padding: '0.8rem'}}>
                        <a role="button" data-bs-toggle="collapse" data-bs-target={"#view-user" + subscr.id} aria-expanded="true" aria-controls={"view-user" + subscr.id}>
                            <span className="title-text">{subscr.plan}</span>
                        </a>
                    </div>
                    { subscr.plan !== 'free' ? 
                    <div id={"view-user" + subscr.id} className="panel-body collapse show" data-bs-parent="#accordion">
                        <span className="subscription-title">{i18n.t('admin.labels.subscription_detail')}</span>
                        <div className="col-md-12 mt-3 subscription-detail">
                            <div className="mb-3 ml-3">
                                <label htmlFor="Subscription" className="form-label">{i18n.t('admin.labels.subscription_id')}</label>
                                <div className="form-text">{subscr.id}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Days" className="form-label">{i18n.t('admin.labels.remaining_days')}</label>
                                <div className="form-text">{subscr.remaining_days}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Payment" className="form-label">{i18n.t('admin.labels.payment_method')}</label>
                                <div className="form-text">{subscr.payment_method_title}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Valid" className="form-label">{i18n.t('admin.labels.valid_until')}</label>
                                <div className="form-text">{subscr.valid_until}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Payment" className="form-label">{i18n.t('admin.labels.payment_cycle')}</label>
                                <div className="form-text">{subscr.payment_cycle}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Users" className="form-label">{i18n.t('admin.labels.max_user')}</label>
                                <div className="form-text">{subscr.max_users}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Status" className="form-label">{i18n.t('admin.labels.status')}</label>
                                <div className="form-text">{subscr.fetch_status}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Last" className="form-label">{i18n.t('admin.labels.last_paid_at')}</label>
                                <div className="form-text">{subscr.last_paid_date}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Next" className="form-label">{i18n.t('admin.labels.next_pay_date')}</label>
                                <div className="form-text">{subscr.next_pay_date}</div>
                            </div>
                        </div>
                        <span className="subscription-title mt-1">{i18n.t('admin.labels.transactions')}</span>
                        <div className="mt-3 transactions-table">
                            <BootstrapTable
                                keyField='id'
                                data={subscr.user_payments_details}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                noDataIndication={() => <NoDataIndication />}
                                pagination={paginationFactory(paginatorOptions)}
                                bordered={false}
                            />
                        </div>
                    </div> : null
                    }
                </div>
            )
        }
        const SubscriptionAccordians = () => {
            let accordians = []
            this.state.subscriptions.forEach((subscr) => {
                accordians.push(<SubscriptionAccordian key={subscr.id} subscr={subscr} />)
            })
            return (
                <div id="accordion" style={{marginTop: '4rem'}} class={this.state.isTopAdmin || this.state.isResellerAdmin ? '' : 'noheader'}>
                    {accordians}
                </div>
            )
        }
        let isInstitutionTextEditable = !(this.state.active_subscription && this.state.active_subscription.key && this.state.active_subscription.key.includes('team')) ? true : false;
        let isTeamPlanSelected = this.state.upgrade_plan && this.state.upgrade_plan.includes('team')
        const { institution, institution_code, breadcrumb } = this.state.userInfo
        let isHeaderRequired = this.state.isTopAdmin || this.state.isResellerAdmin ? true : false
        //console.log(this.state.current_subscription)
        isHeaderRequired = (this.state.user_is_distributor ||  this.state.user_is_top_admin ) ? false : isHeaderRequired
        return (
            <React.Fragment>
                <Users isFromSearch={true} isFromViewUser={true}></Users>
                <div className="overflow-hidden h-100 view-user-page">
                    <div className="row p-0">
                        {this.state.isTopAdmin ? <div className="col-md-4">
                                <div className="view-user-back">
                                    <span className="back-icon" onClick={this.routeBackToUsers} style={{ height: "2rem" }}><i style={{ verticalAlign: "top" }} className="lh-icon-os-back"></i>
                                    </span>&nbsp;
                                    <p>
                                        {
                                            this.state.breadcrumb.map((path, index) => (
                                                <span>
                                                    <a href={path.path}>{path.name}
                                                    </a>
                                                    {index != (this.state.breadcrumb.length - 1) ? <span> &gt; </span> : null

                                                    }
                                                </span>
                                            ))
                                        }
                                    </p>
                                </div>
                            </div> : null 
                        }
                        <div className="col-md-8 action-btn">
                            {this.state.isTopAdmin ? 
                                <span className="user-reset-password">
                                {this.state.email_confirmed ? <a href={this.state.pwd_link} target="_blank">{i18n.t('admin.labels.reset_password')}</a> : <a href={this.state.pwd_link} target="_blank">{i18n.t('admin.labels.accept_inv')}</a>}</span> : null 
                            }
                            {this.state.isTopAdmin ?
                                <React.Fragment>
                                    <RankFormatterUser></RankFormatterUser>
                                </React.Fragment>
                                : null
                            }
                        </div>
                    </div>
                    <div className="tab-bar">
                    <input id="tab1" type="radio" name="tab_selector" value="tab1" defaultChecked={true} onChange={this.handleChange} style={{display: 'none'}}/>
                    <input id="tab2" type="radio" name="tab_selector" value="tab2" style={{display: 'none'}}/>
                    <nav>
                        <ul className="tab-divider" style={{marginBottom: '0.5rem'}}>
                            <li className="tab-label-1">
                                <label htmlFor="tab1"><span className=""></span>{i18n.t('admin.labels.tab_general')}</label>
                            </li>
                            { this.state.subscriptions.length > 0  ?
                                <li className="tab-label-2">
                                    <label htmlFor="tab2"><span className=""></span>{i18n.t('admin.labels.tab_subscription')}</label>
                                </li> : null
                             }
                        </ul>
                    </nav>
                    <section>
                        <div className="tab-content tab-1" style={{padding: '0'}}>
                            <div className="row m-4">
                                <div className="col-md-12 user-details-body">
                                    <div className="col-md-6 mt-3 mb-3 details-1">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="fname" className="col-form-label">{i18n.t("userprofile.labels.first_name")}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.first_name}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="lname" className="col-form-label">{i18n.t("userprofile.labels.last_name")}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.last_name}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="email" className="col-form-label">{i18n.t("userprofile.labels.email")}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.email}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="active" className="col-form-label">{i18n.t('admin.labels.currently_active_in')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.active_subscription && this.state.active_subscription.key ? this.state.active_subscription.key : 'N/A'}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="country" className="col-form-label">{i18n.t("userprofile.labels.country")}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.country}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="lang" className="col-form-label">{i18n.t("userprofile.labels.language")}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.language}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="update" className="col-form-label">{i18n.t('admin.labels.last_updated_on')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.updated_at}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3 mb-3 details-2">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="boards" className="col-form-label">{i18n.t('admin.labels.created_boards')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.boards_count}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="blocked" className="col-form-label">{i18n.t('admin.labels.blocked')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.blocked ? "True" : "False"}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="blocked" className="col-form-label">{i18n.t('admin.labels.pwa_display_label')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.is_pwa ? "Enabled" : "Disabled"}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="oauth" className="col-form-label">{i18n.t('admin.nav_menu.setting.sub_menu.oauth')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.oauth ? "True" : "False"}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="oprovider" className="col-form-label">{i18n.t('admin.labels.oauth_provider')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.oauth || "Direct"}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="count" className="col-form-label">{i18n.t('admin.labels.login_count')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.login_count}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="confirmed" className="col-form-label">{i18n.t('admin.labels.email_confirmed')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.email_confirmed ? "True" : "False"}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="created" className="col-form-label">{i18n.t('admin.labels.created_at')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.created_at}</span>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <label htmlFor="group" className="col-form-label">{i18n.t('admin.labels.group')}</label>
                                            </div>
                                            <div className="col-auto">
                                                <span className="details-info">{this.state.userInfo.main_institution_name || 'i3'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content tab-2" style={{padding: '0'}}>
                            {isHeaderRequired ?
                                <div className="subscription-header">
                                    <button data-bs-toggle="modal" data-bs-target="#upgradeUser" className="btn btn-lg btn-primary btn-block mb-2 ml-4 subscription-action-btn">{i18n.t('admin.btn_change_plan')}</button>
                                    {
                                        this.state.latest_subscription ?
                                            <div className="subscription-other-action-container">
                                                <button data-bs-toggle="modal" data-bs-target="#cancelSubscription" className="btn btn-lg btn-primary btn-block mb-2 ml-4 subscription-action-btn">{i18n.t('userprofile.btn_cancel')}</button>
                                                {
                                                    this.state.can_extend ? <button data-bs-toggle="modal" data-bs-target="#extendSubscription" className="btn extend-btn btn-lg btn-primary btn-block ml-4 mb-2 subscription-action-btn">{i18n.t('admin.btn_extend')}</button> : null
                                                }
                                                {
                                                    this.state.can_renew ? <button data-bs-toggle="modal" data-bs-target="#renewSubscription" className="btn btn-lg btn-primary btn-block mb-2 ml-4 subscription-action-btn">{i18n.t('admin.btn_renew')}</button> : null
                                                }
                                                {
                                                    this.state.can_wire ? <button data-bs-toggle="modal" data-bs-target="#convertSubscription" className="btn btn-lg btn-primary btn-block mb-2 ml-4 subscription-action-btn">{i18n.t('admin.btn_change_payment_method')}</button> : null
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                </div> : null 
                            }
                            <div>
                                <div className="tool-sidebar subscription-accordion">
                                    <div className="body">
                                        { this.state.user_is_top_admin || this.state.user_is_reseller || this.state.user_is_distributor ?
                                         <div className="no-subscription">
                                                    <h2>{i18n.t('admin.labels.you_are')} {this.state.group_class_name} {i18n.t('admin.labels.admin')} </h2>
                                          </div> : 
                                          <SubscriptionAccordians key="subscrp_accordian" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    </div>
                    {/* Upgrade User Modal*/}
                    <div className="modal fade" id="upgradeUser" >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <ValidatorForm
                                    id="upgradeSubscriptionForm"
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className=""
                                >
                                    <div className="modal-header modal-image-header">
                                        <div className="modal-header-content">
                                            <h4 className="modal-title">{i18n.t('userprofile.manage_subscriptions.change_my_plan')}</h4>
                                        </div>
                                        <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="middle-sec">
                                            <div className="row">
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="plan" className="col-form-label pt-0">{i18n.t('admin.labels.plan')}</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <SelectValidator
                                                        tabIndex='3'
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admin.placeholder.account_type')}
                                                        name="upgrade_plan"
                                                        options={this.state.plans}
                                                        value={this.state.upgrade_plan}
                                                        onChange={this.handleChange}
                                                        isValidate={this.state.isValidate}
                                                        validators={["required"]}
                                                        errorMessages={[
                                                            i18n.t('admin.errors.require_account_type')
                                                        ]}

                                                    />
                                                </div>
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="period" className="col-form-label pt-0">{i18n.t('admin.labels.number_year')}</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <input placeholder={i18n.t('admin.placeholder.number_year')} maxLength={1} pattern="[1-5]" min="1" max="5" onChange={this.handleChange} value={this.state.number_of_years} onPaste={this.onPasteHandle} name="number_of_years" type="number" className="form-control " />
                                                </div>
                                                {
                                                    isInstitutionTextEditable && isTeamPlanSelected ?
                                                        <div className="row">
                                                            <div className="user-label col-sm-4">
                                                                <label htmlFor="institution" className="col-form-label pt-0">{i18n.t('admin.labels.institute')}</label>
                                                            </div>
                                                            <div className="col-sm-6 mb-3">
                                                                <TextValidator
                                                                    tabIndex='2'
                                                                    wrapperClass=''
                                                                    className={"form-control"}
                                                                    autoComplete="off"
                                                                    placeholder={i18n.t('admin.placeholder.institute')}
                                                                    type="text"
                                                                    name="institution"
                                                                    value={this.state.institution}
                                                                    onChange={this.handleChange}
                                                                    isValidate={this.state.isValidate}
                                                                    validators={["required"]}
                                                                    errorMessages={[
                                                                        i18n.t('admin.errors.require_institute')
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div className="user-label col-sm-4">
                                                                <label htmlFor="code" className="col-form-label pt-0">{i18n.t('admin.labels.institute_code')}</label>
                                                            </div>
                                                            <div className="col-sm-6 mb-3">
                                                                <input placeholder={i18n.t('admin.placeholder.institute_code')} onChange={this.handleChange} value={this.state.institution_code} name="institution_code" type="text" className="form-control " />
                                                            </div>
                                                        </div>
                                                        : isTeamPlanSelected ?
                                                            <div className="row">
                                                                <div className="user-label col-sm-4">
                                                                    <label htmlFor="institution" className="col-form-label pt-0">{i18n.t('admin.labels.institute')}</label>
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <input placeholder={i18n.t('admin.placeholder.institute')} disabled type="text" name="institution" className="form-control details-info" value={this.state.institution} />
                                                                </div>
                                                                <div className="user-label col-sm-4">
                                                                    <label htmlFor="code" className="col-form-label pt-0">{i18n.t('admin.labels.institute_code')}</label>
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <input disabled type="text" placeholder={i18n.t('admin.placeholder.institute_code')}name="institution_code" className="form-control details-info" value={this.state.institution_code} />
                                                                </div>
                                                            </div>
                                                            : null
                                                }
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="payment" className="col-form-label pt-0">{i18n.t('admin.labels.payment_received')}</label>
                                                </div>
                                                <div className="col-sm-2 mt-2">
                                                    <div className="custom-control-lg custom-radio">
                                                        <input type="radio" value="true" className="custom-control-input" onChange={this.handleChange} id="rdb1" name="auto_approve" />
                                                        <label className="custom-control-label" htmlFor="rdb1">{i18n.t('userprofile.confirm_yes')}</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2 mt-2">
                                                    <div className="custom-control-lg custom-radio">
                                                        <input onChange={this.handleChange} type="radio" value="false" defaultChecked={true} className="custom-control-input" id="rdb2" name="auto_approve" />
                                                        <label className="custom-control-label" htmlFor="rdb2">{i18n.t('userprofile.confirm_no')}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-accenta" data-bs-dismiss="modal" >{i18n.t('userprofile.btn_cancel')}</button>
                                        <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                            <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                            :
                                            <React.Fragment>{i18n.t('admin.btn_change_plan')}</React.Fragment>
                                        }
                                        </button>
                                    </div>
                                </ValidatorForm>
                                <Errors
                                    hasError={this.state.hasError}
                                    type="warning"
                                    message={this.state.errorMessage}
                                    status={this.state.status}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Renew Subscription Modal*/}
                    <div className="modal fade" id="renewSubscription" >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <ValidatorForm
                                    id="renewSubscriptionForm"
                                    ref="form"
                                    onSubmit={this.handleSubmitRenew}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className=""
                                >
                                    {/* <form id="extendSubscriptionForm"> */}
                                    <div className="modal-header modal-image-header">
                                        <div className="modal-header-content">
                                            <h4 className="modal-title">{i18n.t('userprofile.manage_subscriptions.renew_my_subscription')}</h4>
                                        </div>
                                        <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="middle-sec">
                                            <div className="row">
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="plan" className="col-form-label pt-0">{i18n.t('admin.labels.plan')}</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <SelectValidator
                                                        tabIndex='3'
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admin.placeholder.account_type')}
                                                        name="upgrade_plan"
                                                        options={this.state.plans}
                                                        value={this.state.active_subscription && this.state.active_subscription.key ? this.state.active_subscription.key : 'N/A'}
                                                        noValidate
                                                        disabled
                                                    />
                                                </div>
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="period" className="col-form-label pt-0">{i18n.t('admin.labels.number_year')}</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <input placeholder={i18n.t('admin.placeholder.number_year')} maxLength={1} pattern="[1-5]" min={1} max={5} onChange={this.handleChange} onPaste={this.onPasteHandle} value={this.state.number_of_years_ext} name="number_of_years_ext" type="number" className="form-control " />
                                                </div>
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="amount" className="col-form-label pt-0">{i18n.t('admin.labels.received_amount')} (€)</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <input id="ext_sub_amount_renew" disabled type="text" name="amount" className="form-control details-info" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-accenta" data-bs-dismiss="modal" >{i18n.t('userprofile.btn_cancel')}</button>
                                        <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_renew')}</button>
                                    </div>
                                </ValidatorForm>
                                <Errors
                                    hasError={this.state.hasError}
                                    type="warning"
                                    message={this.state.errorMessage}
                                    status={this.state.status}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Convert Subscription Modal */}
                    <div className="modal fade" id="convertSubscription" >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <ValidatorForm
                                    id="convertSubscriptionForm"
                                    ref="form"
                                    onSubmit={this.handleSubmitConvert}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className=""
                                >
                                    {/* <form id="extendSubscriptionForm"> */}
                                    <div className="modal-header modal-image-header">
                                        <div className="modal-header-content">
                                            <h4 className="modal-title">{i18n.t('admin.labels.text_convert')}</h4>
                                        </div>
                                        <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="middle-sec">
                                            <div className="row">
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="period" className="col-form-label pt-0">{i18n.t('admin.labels.number_year')}</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <input placeholder={i18n.t('admin.placeholder.number_year')} maxLength={1} pattern="[1-5]" min={1} max={5} onChange={this.handleChange} onPaste={this.onPasteHandle} value={this.state.number_of_years_ext} name="number_of_years_ext" type="number" className="form-control " />
                                                </div>
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="amount" className="col-form-label pt-0">{i18n.t('admin.labels.received_amount')} (€)</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <input id="ext_sub_amount_convert" disabled type="text" name="amount" className="form-control details-info" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-accenta" data-bs-dismiss="modal" >{i18n.t('userprofile.btn_cancel')}</button>
                                        <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_change')}</button>
                                    </div>
                                </ValidatorForm>
                                <Errors
                                    hasError={this.state.hasError}
                                    type="warning"
                                    message={this.state.errorMessage}
                                    status={this.state.status}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Extend Subscription Modal*/}
                    <div className="modal fade" id="extendSubscription" >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <ValidatorForm
                                    id="extendSubscriptionForm"
                                    ref="form"
                                    onSubmit={this.handleSubmitExtend}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className=""
                                >
                                    {/* <form id="extendSubscriptionForm"> */}
                                    <div className="modal-header modal-image-header">
                                        <div className="modal-header-content">
                                            <h4 className="modal-title">{i18n.t('admin.labels.extend_subscription')}</h4>
                                        </div>
                                        <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="middle-sec">
                                            <div className="row">
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="plan" className="col-form-label pt-0">{i18n.t('admin.labels.plan')}</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <SelectValidator
                                                        tabIndex='3'
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admin.placeholder.account_type')}
                                                        name="upgrade_plan"
                                                        options={this.state.plans}
                                                        value={this.state.active_subscription && this.state.active_subscription.key ? this.state.active_subscription.key : 'N/A'}
                                                        noValidate
                                                        disabled
                                                    />
                                                </div>
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="period" className="col-form-label pt-0">{i18n.t('admin.labels.number_year')}</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <input placeholder={i18n.t('admin.placeholder.number_year')} maxLength={1} pattern="[1-5]" min={1} max={5} onChange={this.handleChange} onPaste={this.onPasteHandle} value={this.state.number_of_years_ext} name="number_of_years_ext" type="number" className="form-control " />
                                                </div>
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="amount" className="col-form-label pt-0">{i18n.t('admin.labels.received_amount')} (€)</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <input id="ext_sub_amount" disabled type="text" name="amount" className="form-control details-info" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-accenta" data-bs-dismiss="modal" >{i18n.t('userprofile.btn_cancel')}</button>
                                        <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_extend')}</button>
                                    </div>
                                </ValidatorForm>
                                <Errors
                                    hasError={this.state.hasError}
                                    type="warning"
                                    message={this.state.errorMessage}
                                    status={this.state.status}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Cancel Subscription Modal */}
                    <div className="modal fade" id="cancelSubscription" >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <ValidatorForm
                                    id="cancelSubscriptionForm"
                                    ref="form"
                                    onSubmit={this.handleSubmitCancel}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className=""
                                >
                                    <div className="modal-header modal-image-header">
                                        <div className="modal-header-content">
                                            <h4 className="modal-title">{i18n.t('userprofile.manage_subscriptions.cancel_subscription')}</h4>
                                        </div>
                                        <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="middle-sec">
                                            <div className="col-md-12">
                                                {i18n.t('admin.labels.message_cancel_subscription')}
                                            </div>
                                            <div className="col-md-12">
                                                <TextareaValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t('admin.placeholder.reason')}
                                                    name="cancel_reason"
                                                    value={this.state.cancel_reason}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_reason')
                                                    ]}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-accenta" data-bs-dismiss="modal" >{i18n.t('userprofile.btn_cancel')}</button>
                                        <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_ok')}</button>
                                    </div>
                                </ValidatorForm>
                                <Errors
                                    hasError={this.state.hasError}
                                    type="warning"
                                    message={this.state.errorMessage}
                                    status={this.state.status}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Cancel Payment Modal */}
                    <div>
                        <Modal show={this.state.isCancelPaymentOpen}>
                            {/* <div className="modal-content"> */}
                            <ValidatorForm
                                id="cancelSubscriptionForm"
                                ref="form"
                                onSubmit={this.handleSubmitCancelPayment}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.labels.cancel_payment')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideCancelPaymentModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="col-md-12">
                                            {i18n.t('admin.labels.message_cancel_payment')}
                                        </div>
                                        <div className="col-md-12">
                                            <TextareaValidator
                                                tabIndex='1'
                                                wrapperClass=''
                                                className={"form-control mt-2 mb-4"}
                                                autoComplete="off"
                                                placeholder={i18n.t('admin.placeholder.reason')}
                                                name="cancel_reason_payment"
                                                value={this.state.cancel_reason_payment}
                                                isValidate={this.state.isValidate}
                                                validators={["required"]}
                                                errorMessages={[
                                                    i18n.t('admin.errors.require_reason')
                                                ]}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-accenta" onClick={this.hideCancelPaymentModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_ok')}</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                            {/* </div> */}
                        </Modal>
                    </div>
            </div>
            </React.Fragment>
        )
    }
}

export default withTranslation() (ViewUser)