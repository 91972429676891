import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import Errors from "../Common/Errors/Errors";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import TextValidator from "../Common/TextValidator";
import TextareaValidator from "../Common/TextareaValidator";
import Modal from "react-bootstrap/Modal";

class Oauth extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: false,
            total_count: 0,
            isValid: false,
            hasError: false,
            errorMessage: "",
            isValidate: false,
            name: '',
            redirect_uri: '',
            edit_name: '',
            edit_redirect_uri: '',
            edit_id: null,
            delete_id: null,
            isEditOpen: false,
            isDeleteOpen: false,
            delete_name: ''
        }
    }
    async componentDidMount() {
        await this.callApi()
    }
    async callApi() {
        this.setState({ data: [], isLoading: true });
        let { data } = await API.get('/applications');
        console.log(data)
        this.setState({ data: data.applications, total_count: data.total_count, isLoading: false });
    }
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    };
    handleKeyDown = event => {
        if (event.key === " " && event.target.value.trim().length === 0) {
            event.preventDefault();
        }
    };
    handleSubmit = event => {
        if (!this.state.shouldBlockNavigation) {
            return;
        } 
        if(this.state.name.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('connect.errors.name_required'),
                status: null
            });
            return
        }
        if(this.state.redirect_uri.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage:  i18n.t('admin.errors.redirect_uri_required'),
                status: null
            });
            return
        }
        this.setState({ isLoading: true });

        const formData = new FormData();
        formData.append('doorkeeper_application[name]', this.state.name);
        formData.append('doorkeeper_application[redirect_uri]', this.state.redirect_uri);
        API.post('/oldview/oauth/applications', formData)
        .then(res => {
            window.location.reload();
        })
        .catch(function (error) {
        })
    }
    handleOnError = errors => {
        this.setState({
          isValidate: true,
          isValid: false,
          hasError: true,
          errorMessage: getErrorMessage(errors, true),
          status: null
        });
    };
    voucherAction = (cell, row) => {
        let application_id = row.id;
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void (0)} data-id={application_id} onClick={this.editApplication}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
                <NavDropdown.Item href={void (0)} data-id={application_id} onClick={this.deleteApplication}>Delete</NavDropdown.Item>
            </NavDropdown>
        );
    }
    handleEditSubmit = event => {
        if (!this.state.shouldBlockNavigation) {
            return;
        } 
        if(this.state.edit_name.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: 'Name required!',
                status: null
            });
            return
        }
        if(this.state.edit_redirect_uri.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: 'Redirect uri required!',
                status: null
            });
            return
        }
        this.setState({ isLoading: true });

        const formData = new FormData();
        formData.append('doorkeeper_application[name]', this.state.edit_name);
        formData.append('doorkeeper_application[redirect_uri]', this.state.edit_redirect_uri);
        API.put('/oldview/oauth/applications/'+this.state.edit_id, formData)
        .then(res => {
            window.location.reload();
        })
        .catch(function (error) {
            window.location.reload();
        })
    }
    editApplication = event => {
        let id = event.target.getAttribute('data-id');
        let application_info = this.state.data.find((element) => {
            return element.id == id
        });
        this.setState({edit_name: application_info.name, edit_redirect_uri: application_info.redirect_uri, edit_id: application_info.id});
        this.showEditModal();
    }
    showEditModal = () => {
        this.setState({isEditOpen:true, isValidate: false,  isValid: true, hasError: false, errorMessage: [], status: null });
    }
    hideEditModal = () => {
        this.setState({isEditOpen:false});
    }
    deleteApplication = event => {
        let id = event.target.getAttribute('data-id');
        let application_info = this.state.data.find((element) => {
            return element.id == id
        });
        this.setState({delete_id: id, delete_name: application_info.name});
        this.showDeleteModal();
    }
    deleteApplicationSubmit = event => {
        this.setState({ isLoading: true });
        API.delete('/oldview/oauth/applications/' + this.state.delete_id)
        .then(res => {
            window.location.reload()
        })
        .catch(function (error) {
            window.location.reload()
        })
    }
    showDeleteModal = () => {
        this.setState({isDeleteOpen:true});
    }
    hideDeleteModal = () => {
        this.setState({isDeleteOpen:false});
    }
    
    render() {
        const columns = [{
            dataField: 'name',
            text: i18n.t('admin.labels.name').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '15%' };
            }
        }, {
            dataField: 'redirect_uri',
            text: i18n.t('admin.labels.callback_url').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '80%' };
            }
        }, {
            dataField: 'moreOptions',
            sort: false,
            formatter: this.voucherAction,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        }];
        const defaultSorted = [{
            dataField: 'type',
            order: 'desc'
        }];
        const paginatorOptions = {
            pageStartIndex: 1,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                let currentCellIndex = e.target.cellIndex;
                let lastCellIndex = document.querySelector('table tr:last-child td:last-child').cellIndex

                if (currentCellIndex !== lastCellIndex && currentCellIndex !== undefined) {
                    window.location.href = '/admin/oauth/view/'+row.id
                }
            }
        }
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        }
       
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div>
                            <div className="admin-custom-table-header">
                                <button data-bs-toggle="modal" data-bs-target="#addOauthClient" className="btn btn-primary mb-3 mt-1">{i18n.t('admin.oauth.btn_new')}</button>
                            </div>
                            <div className="oauth-table">
                                <BootstrapTable
                                    keyField='name'
                                    data={this.state.data}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    noDataIndication={() => <NoDataIndication />}
                                    rowEvents={rowEvents}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add Oauth Client Modal */}
                <div className="modal fade" id="addOauthClient" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            {/* <form id="addOauthClientForm"> */}
                            <ValidatorForm
                            id="addOauthClientForm"
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={this.handleOnError}
                            instantValidate={false}
                            className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.oauth.add_modal.title')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.name')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    placeholder={i18n.t('admin.placeholder.name')}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="name"
                                                    value={this.state.name}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('connect.errors.name_required')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.redirect_uri')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextareaValidator
                                                    tabIndex='4'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t('admin.placeholder.redirect_uri')}
                                                    name="redirect_uri"
                                                    value={this.state.redirect_uri}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.redirect_uri_required')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" data-bs-dismiss="modal" className="btn btn-lg btn-outline-primary">{i18n.t('userprofile.btn_cancel')} </button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                    <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                    :
                                    <React.Fragment>{i18n.t('userprofile.btn_save')}</React.Fragment>
                                    }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </div>
                </div>

                {/* Edit Oauth Client Modal */}
                <div>  
                    <Modal show={this.state.isEditOpen} id="editOauthModal">
                        <div className="modal-content">
                            <ValidatorForm
                                id="editOauthClientForm"
                                ref="form"
                                onSubmit={this.handleEditSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.oauth.edit_modal.title')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideEditModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.name')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    placeholder={i18n.t('admin.placeholder.name')}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_name"
                                                    value={this.state.edit_name}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('connect.errors.name_required')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.redirect_uri')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextareaValidator
                                                        tabIndex='4'
                                                        wrapperClass=''
                                                        className={"form-control mt-2 mb-4"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admin.placeholder.redirect_uri')}
                                                        name="edit_redirect_uri"
                                                        value={this.state.edit_redirect_uri}
                                                        isValidate={this.state.isValidate}
                                                        validators={["required"]}
                                                        errorMessages={[
                                                            i18n.t('admin.errors.redirect_uri_required')
                                                        ]}
                                                        onChange={this.handleChange}
                                                        onKeyDown={this.handleKeyDown}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.hideEditModal} className="btn btn-lg btn-outline-primary">{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('userprofile.btn_save')}</React.Fragment>
                                    }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </Modal>
                </div>

                 {/* delete Oauth Client Modal */}
                 <div>  
                    <Modal show={this.state.isDeleteOpen} id="deleteOauthModal">
                        <div className="modal-content">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.oauth.delete_modal.title')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideDeleteModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div class="row p-3">
                                        {i18n.t('admin.oauth.delete_modal.message')}&nbsp;<b>{this.state.delete_name}</b>? 
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.hideDeleteModal} className="btn btn-lg btn-outline-primary">{i18n.t('userprofile.btn_cancel')} </button>
                                    <button onClick={this.deleteApplicationSubmit} type="button" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('admin.btn_ok')}</React.Fragment>
                                    }</button>
                                </div>
                            
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default withTranslation() (Oauth)