import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import API from '../api';
import { Loading_IMG } from '../Common/assets_paths';
import Errors from "../Common/Errors/Errors";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import TextValidator from "../Common/TextValidator";
import TextareaValidator from "../Common/TextareaValidator";
import Modal from "react-bootstrap/Modal";
class ViewOauth extends React.Component {
    constructor(props) {
        super(props);
        let path_array = props.location.pathname.split("/oauth/view/")
        this.state = {
            current_application_id: parseInt(path_array[path_array.length - 1]),
            name: '',
            uid: '',
            secret: '',
            redirect_uri: [],
            isValid: false,
            hasError: false,
            errorMessage: "",
            isValidate: false,
            isLoading: false,
            scopes: '',
            isEditOpen: false,
            isDeleteOpen: false,
            delete_name: ''
        }
    }

    routeBackToOauth = () => {
        window.location.href = "/admin/oauth"
    }
    UNSAFE_componentWillMount() {
        this.getUserDetails()
    }
    async getUserDetails() {
        await API.get('/applications/' + this.state.current_application_id)
            .then(res => {
                if (res.data) {
                   this.setState({
                       name: res.data.data.name,
                       redirect_uri: res.data.data.redirect_uri.split(/\r?\n/),
                       edit_redirect_uri: res.data.data.redirect_uri,
                       secret: res.data.data.secret,
                       uid: res.data.data.uid,
                       scopes: res.data.data.scopes.join(', '),
                   })
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                }
                if (error.request) {
                    console.log(error.request);
                }
                else {
                    console.log(error)
                }
            });
    }
    handleOnError = errors => {
        this.setState({
          isValidate: true,
          isValid: false,
          hasError: true,
          errorMessage: getErrorMessage(errors, true),
          status: null
        });
    };
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    };
    handleKeyDown = event => {
        if (event.key === " " && event.target.value.trim().length === 0) {
            event.preventDefault();
        }
    };
    handleEditSubmit = event => {
        if (!this.state.shouldBlockNavigation) {
            return;
        } 
        if(this.state.name.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('connect.name_required'),
                status: null
            });
            return
        }
        if(this.state.edit_redirect_uri.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.errors.redirect_uri_required'),
                status: null
            });
            return
        }
        this.setState({ isLoading: true });

        const formData = new FormData();
        formData.append('doorkeeper_application[name]', this.state.name);
        formData.append('doorkeeper_application[redirect_uri]', this.state.edit_redirect_uri);
        API.put('/oldview/oauth/applications/'+this.state.current_application_id, formData)
        .then(res => {
            window.location.reload();
        })
        .catch(function (error) {
            window.location.reload();
        })
    }
    showEditModal = () => {
        this.setState({isEditOpen:true, isValidate: false,  isValid: true, hasError: false, errorMessage: [], status: null });
    }
    hideEditModal = () => {
        this.setState({isEditOpen:false});
    }
    deleteApplicationSubmit = event => {
        this.setState({ isLoading: true });
        let _this = this;
        API.delete('/oldview/oauth/applications/' + this.state.current_application_id)
        .then(res => {
            _this.routeBackToOauth();
        })
        .catch(function (error) {
            _this.routeBackToOauth();
        })
    }
    showDeleteModal = () => {
        this.setState({isDeleteOpen:true});
    }
    hideDeleteModal = () => {
        this.setState({isDeleteOpen:false});
    }
    render() {
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div className="row back-to-listing">
                            <span className="back-icon lh-icon-os-back" onClick={this.routeBackToOauth}>
                            </span>
                            <span className="oauth-view-header">{i18n.t('admin.labels.back')}</span>
                        </div>
                        <div className="row">
                            <div className="col back-to-listing" >
                                <span className="user-info">
                                {i18n.t('admin.labels.oauth_client')}: {this.state.name}
                                </span>
                            </div>
                            <div className="col show-job-btn">
                                <button className="btn btn-primary mb-3 mt-1 mr-3" onClick={this.showEditModal}>{i18n.t('admin.labels.edit')}</button>
                                <button className="btn btn-primary mb-3 mt-1 mr-3" onClick={this.showDeleteModal}>{i18n.t('admin.labels.delete')}</button>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12 library-content">
                                <div className="col-md-12 mt-3 mb-3 oauth-continer">
                                    <div className="row g-3 align-items-center mb-3">
                                        <div className="col-md-2">
                                            <label htmlFor="library" className="col-form-label">{i18n.t('admin.labels.application_id')}: </label>
                                        </div>
                                        <div className="col-md-9">
                                            <span className="show-job-details">{this.state.uid}</span>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-items-center mb-3">
                                        <div className="col-md-2">
                                            <label htmlFor="education" className="col-form-label">{i18n.t('admin.labels.secret')}: </label>
                                        </div>
                                        <div className="col-md-9">
                                            <span className="show-job-details">{this.state.secret}</span>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-items-center mb-3">
                                        <div className="col-md-2">
                                            <label htmlFor="category" className="col-form-label">{i18n.t('admin.labels.scopes')}: </label>
                                        </div>
                                        <div className="col-md-9">
                                            <span className="show-job-details">{this.state.scopes}</span>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-items-center">
                                        <div className="col-md-2">
                                            <label htmlFor="level1" className="col-form-label">{i18n.t('admin.labels.callback_url')}: </label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.redirect_uri ? this.state.redirect_uri.map((uri, index)=> (
                                                <div className="row mb-3"> 
                                                    <div className="col-md-9">
                                                        <span className="show-job-details">{uri}</span>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <a target="_blank" href={encodeURI("/oauth/authorize?client_id="+this.state.uid+"&redirect_uri="+uri+"&response_type=code")} className="btn btn-primary mt-2">{i18n.t('admin.btn_authorize')}</a>
                                                    </div>
                                                </div>
                                            )) : null}
                                       </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Edit Oauth Client Modal */}
                <div>  
                    <Modal show={this.state.isEditOpen} id="editOauthModal">
                        <div className="modal-content">
                            <ValidatorForm
                                id="editOauthClientForm"
                                ref="form"
                                onSubmit={this.handleEditSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.labels.edit_oauth_client')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideEditModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.name')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    placeholder={i18n.t('admin.placeholder.name')}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="name"
                                                    value={this.state.name}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('connect.errors.name_required')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <span>{i18n.t('admin.labels.redirect_uri')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextareaValidator
                                                        tabIndex='4'
                                                        wrapperClass=''
                                                        className={"form-control mt-2 mb-4"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admins.placeholder.redirect_uri')}
                                                        name="edit_redirect_uri"
                                                        value={this.state.edit_redirect_uri}
                                                        isValidate={this.state.isValidate}
                                                        validators={["required"]}
                                                        errorMessages={[
                                                            i18n.t('admins.errors.redirect_uri_required')
                                                        ]}
                                                        onChange={this.handleChange}
                                                        onKeyDown={this.handleKeyDown}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.hideEditModal} className="btn btn-lg btn-outline-primary">{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('admin.btn_ok')}</React.Fragment>
                                    }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </Modal>
                </div>

                 {/* delete Oauth Client Modal */}
                 <div>  
                    <Modal show={this.state.isDeleteOpen} id="deleteOauthModal">
                        <div className="modal-content">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.labels.delete_oauth_client')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideDeleteModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div class="row p-3">
                                            {i18n.t('admin.labels.message_delete_oauth_client')}&nbsp;<b>{this.state.name}</b>? 
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.hideDeleteModal} className="btn btn-lg btn-outline-primary">{i18n.t('userprofile.btn_cancel')}</button>
                                    <button onClick={this.deleteApplicationSubmit} type="button" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('admin.btn_ok')}</React.Fragment>
                                    }</button>
                                </div>
                            
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default withTranslation() (ViewOauth);