import React from "react";
import i18n from "../../packs/i18n";
import API from '../api';
import { getUserInfo, getLocalUrl } from "../Common/i3library";
import SweetModelAlert from "../Common/SweetModelAlert";
import Loading from "../Common/Loading";

class ManageSubscriptions extends React.Component {
    state = {
        loading: true,
        subscription: null,
        show: false,
        showUpgadeAlert: false,
        cancelled: false,
        showRenewAlert: false,
        showRenewLink: true,
        showAdminDetail: false,
        contact_info_email: null,
        contact_info_name: null
    }
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      this.getData();
      const userInfo = getUserInfo();
      if(userInfo.account_type == "free") {
        this.setState({showRenewLink: false})
      }
      if(userInfo.payment_method === "wire_transfer"){
        this.setState({ showAdminDetail : true, contact_info_email: userInfo.contact_info.email, contact_info_name: userInfo.contact_info.name })
      }
    }

    getData() {
        API.get('subscription/active')
            .then(res => {
                if(res.data && res.data.redirectPath) {
                    window.location = res.data.redirectPath;
                } else {
                    this.setState({subscription: res.data});
                }
            })
            .catch(error => {

            }).finally(() => {
                this.setState({loading: false});
            })
    }

    handleCancleSubscription = () => {
        API.post('subscription/cancel')
            .then(res => {
                this.getData();
            })
            .catch(error => {

            }).finally(() => {
                this.setState({show: false});
            })
    }

    handleConfirmUpgrade = () => {
        const userInfo = getUserInfo();
        this.props.history.push({
          pathname: getLocalUrl(this.props.match.params.locale  || (userInfo && userInfo.language), `signup/selectpackage/${userInfo.persistence_token}`),
          state: { currentPlan: this.state.subscription, userInfo:  userInfo, upgradePlan: true, action: 'upgrade' }
        });

    }

    handleConfirmRenew = () => {
        this.setState({
          showRenewAlert: false
        });
        const userInfo = getUserInfo();
        const planDetail = {
          planType: this.state.subscription.key,
          monthly_price : this.state.subscription.price,
          yearly_price : this.state.subscription.price,
          sub_plans: null,
          userInfo: null,
          display_text: this.state.subscription.display_text
        }

        this.props.history.push({
            pathname: getLocalUrl(this.props.match.params.locale,(`signup/payment/${userInfo.persistence_token}`)),
            state: {
                planDetail:planDetail,
                selectedType: this.state.subscription.payment_cycle === 'monthly' ? 'month' : 'yearly',
                voucherCode: null,
                voucherInfo: null,
                selected_sub_plan: null,
                you_save_price: null,
                team_name: '',
                currentPlan: this.state.subscription,
                action: 'renew'
            }
        });
    }

    render() {
        const subscription = this.state.subscription;
        const userInfo = getUserInfo();
        const planName = subscription && subscription.key ? subscription.key.split('_').join(' ').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()) : "Free";
        const verticalCenter = {
          transform: 'translateY(100%)'
        }
        return (
          this.state.loading ?
            <div className="profile-main-wrapper">
                <div className="white-cover-fixheight">
                    <Loading/>
                </div>
            </div>
            :

            <div className="profile-main-wrapper">
              <div className="white-cover-fixheight your-subscription">
                {/* <h3 className="text-primary mb-3">{i18n.t('userprofile.profeature.heading')}</h3> */}
                {
                  !this.state.showAdminDetail ?
                    <React.Fragment>
                      <div style={verticalCenter}>
                        <div className="text-center">
                          <p className="text-size-14 text-secondary mb-0"> {i18n.t('userprofile.profeature.heading',{plan: planName})} <br></br><br></br>{i18n.t('userprofile.profeature.heading1')}
                          {
                            
                            this.state.subscription.key != "team_advanced" &&
                              <a href="javascript:void(0)" className="text-accenta" onClick={this.handleConfirmUpgrade}>&nbsp;{i18n.t('userprofile.profeature.upgrade_here')}</a>
                          }
                          </p>
                        </div>
                        {
                          this.state.showRenewLink ?
                            <div className="text-center mt-2">
                              <span class="mb-2 text-secondary">{i18n.t('userprofile.profeature.or_text')}</span><br></br>
                              <a href="javascript:void(0)" onClick={this.handleConfirmRenew} className="text-accenta">{i18n.t('userprofile.manage_subscriptions.renew_my_subscription')}</a>
                            </div>
                          : null
                        }
                        <div className="text-center mt-2 text-secondary">
                          {i18n.t('userprofile.profeature.heading2')}
                        </div>
                      </div>
                    </React.Fragment> : null
                }
                { this.state.showAdminDetail && (subscription.has_expired == 'free' || subscription.has_expired == 'expired' || subscription.cancelled) &&
                   <React.Fragment>
                      <div style={verticalCenter}>
                        <div className="text-center">
                            <p className="text-size-14 text-secondary mb-0"> {i18n.t('userprofile.profeature.heading', {plan: planName})} </p>
                        </div>
                        <div className="text-center">
                          <div className="mt-4 wire-transfer-wrapper">
                                { (subscription.has_expired == 'free' || subscription.has_expired == 'trial-expired') ?  <h6 className="text-primary mb-1">{i18n.t('userprofile.manage_subscriptions.contact_admin_message_free')}</h6> : <h6 className="text-primary mb-1">{i18n.t('userprofile.manage_subscriptions.contact_admin_message_expired')}</h6> }
                                <div className="wire-transfer-container">
                                    <div className="ml-3">
                                        <label className="text-primary mb-1"> {i18n.t('userprofile.manage_subscriptions.contact_admin_label_name')}
                                            <span className="text-secondary mb-1 ml-2"> { userInfo.contact_info.name } </span>
                                        </label>
                                    </div>
                                    <div className="ml-3">
                                        <label className="text-primary mb-1">  {i18n.t('userprofile.manage_subscriptions.contact_admin_label_email')}
                                            <span className="text-secondary mb-1 ml-2"> { userInfo.contact_info.email } </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                          </div> 
                        <div className="text-center mt-2 text-secondary">
                          {i18n.t('userprofile.profeature.heading2')}
                        </div>
                      </div> 
                    </React.Fragment>
                }
              </div>
            </div>
        );
    }
}

export default ManageSubscriptions;
