import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import API from '../api';
import Errors from '../Common/Errors/Errors';
import TextValidator from '../Common/TextValidator';
import { ValidatorForm } from 'react-form-validator-core';
import { getErrorMessage } from '../Common/Helpers/AppHelper';
import { withTranslation } from 'react-i18next';
import { createEvent } from '../Common/AnalyticsEvents';

class ChooseNewPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: "",
            confirm_password: "",
            hasError: false,
            errorMessage: "",
            isValidate: false,
            status: null
        };

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleOnError = errors => {
        this.setState({
            isValidate: true,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    }

    handleSubmit = event => {
        const token_id = this.props.match.params.token_id;
        const user = {
            user: {
                password: this.state.password,
                password_confirmation: this.state.confirm_password,
            }
        };
        const _this = this;
        API.patch('password_resets/' + token_id, user)
            .then(res => {
                createEvent("Reset-Password", "Reset password sucess");
                window.location = "/";
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 404) {

                        createEvent("Reset-Password", "Reset password fail due to wrong email address", error.response.data.message);
                        _this.setState({
                            hasError: true,
                            errorMessage: i18n.t('forgotpassword.errors.wrong_email'),
                            status: error.response.status
                        })
                    }
                    else {
                        createEvent("Reset-Password", "Reset password fail due user block or delete", error.response.data.message);
                        _this.setState({
                            hasError: true,
                            errorMessage: error.response.data.message || i18n.t('login.errors.wrong_credential'),
                            status: error.response.status
                        })
                    }

                }
                if (error.request) {
                    console.log(error.request);
                }
                else {
                    _this.setState({
                        hasError: true,
                        errorMessage: error.message,
                        status: error.status
                    })
                }
            })
    }

    render() {
        return (
            <LandingStepBase container={
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 p-0 text-center">
                            <div className="headtxt animated fadeInDown mt-5">
                                <h1 className="text-white">{i18n.t('forgotpassword.choose_password.header.title')}</h1>
                                <p>{i18n.t('forgotpassword.choose_password.header.sub_title')}</p>
                            </div>
                            <div className="main-wrapper">
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className="white-cover"
                                >
                                    <div className="col-sm-12 pb-3 pl-0 pr-0">
                                        <TextValidator
                                            className={'form-control form-control-lg'}
                                            autoComplete="off"
                                            placeholder={i18n.t('forgotpassword.choose_password.enter_pwd_lbl')}
                                            type="password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            isValidate={this.state.isValidate}
                                            noValidate
                                            validators={['required', 'minStringLength:8']}
                                            errorMessages={[i18n.t('forgotpassword.choose_password.errors.required_pwd'), i18n.t('forgotpassword.choose_password.errors.max_pwd_length'), i18n.t('forgotpassword.choose_password.errors.pwd_match')]} />
                                    </div>
                                    <div className="col-sm-12 pb-3 pl-0 pr-0">
                                        <TextValidator
                                            className={'form-control form-control-lg'}
                                            autoComplete="off"
                                            placeholder={i18n.t('forgotpassword.choose_password.confirm_pwd_lbl')}
                                            type="password"
                                            name="confirm_password"
                                            value={this.state.confirm_password}
                                            onChange={this.handleChange}
                                            isValidate={this.state.isValidate}
                                            noValidate
                                            validators={['required', 'minStringLength:8', 'isPasswordMatch']}
                                            errorMessages={[i18n.t('forgotpassword.choose_password.errors.required_cnf_pwd'), i18n.t('forgotpassword.choose_password.errors.max_conf_pwd_length'), i18n.t('forgotpassword.choose_password.errors.pwd_match')]} />
                                    </div>
                                    <button type="submit" className={'btn btn-accenta btn-lg btn-block mt-4 text-size-22'}> {i18n.t('forgotpassword.choose_password.confirm_btn')} </button>
                                </ValidatorForm>
                                <Errors hasError={this.state.hasError} status={this.state.status} type="warning" message={this.state.errorMessage} />
                            </div>
                        </div>
                    </div>
                </div>}>
            </LandingStepBase>
        )
    }

}

export default withTranslation()(ChooseNewPassword);