import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import Errors from "../Common/Errors/Errors";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import TextValidator from "../Common/TextValidator";
import TextareaValidator from "../Common/TextareaValidator";
import SelectValidator from "../Common/SelectValidator";
import Modal from "react-bootstrap/Modal";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
class Reminders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            plans: [],
            plan_options: [],
            reminder_options: [],
            payment_cycle_options: [],
            yearly_options: [],
            monthly_options: [],
            reminders: [],
            plan: '',
            payment_cycle: "monthly",
            payment_method_id: "1",
            days: '',
            edit_plan: '',
            edit_payment_cycle: "monthly",
            edit_payment_method_id: "1",
            edit_days: '',
            isEditOpen: false, 
            current_active_id: null
        }
        this.handlePaymentMethod = this.handlePaymentMethod.bind(this);
    }
    async componentDidMount() {
        await this.callApi()
    }
    async callApi() {
        this.setState({ data: [], isLoading: true });
        let { data } = await API.get('/subscription_reminders');
        let plan_options = data.plans.map((row, i) => {return {label: row, value: row}});
        let reminder_options = data.reminder_options.map((row, i) => {return {label: row[0], value: row[1]}});
        let monthly_options = [];
        for(var i = 1 ; i <= data.monthly_tenure; i++){
            monthly_options.push({label: i, value: i})
        }
        let yearly_options = [];
        for(var j = 1 ; j <= data.yearly_tenure; j++){
            yearly_options.push({label: j, value: j})
        }
        let payment_cycle_options = [{label: 'Monthly', value: 'monthly'}, {label: 'Yearly', value: 'yearly'}]
        this.setState({ data: data.reminders, plans: data.plans, reminders: data.reminder_options,total_count: data.total_count, isLoading: false, plan_options:  plan_options, reminder_options: reminder_options, monthly_options: monthly_options, yearly_options: yearly_options, payment_cycle_options: payment_cycle_options});
    }
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        if (event.target.name == "payment_method_id") {
            if(event.target.value == "2"){
                this.setState({payment_cycle: 'yearly'})
            }
            else{
                this.setState({payment_cycle: 'monthly'})
            }
        }
        if (event.target.name == "edit_payment_method_id") {
            if(event.target.value == "2"){
                this.setState({edit_payment_cycle: 'yearly'})
            }
            else{
                this.setState({edit_payment_cycle: 'monthly'})
            }
        }
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    };
    handlePaymentMethod(event) {
        this.setState({
            paymentMethod: event.target.value
        });
    }
    handlePeriodChange = (e) => {
        this.setState({ selectedValue: e.target.value })
    }
    reminderTypeText = (cell, row) => {
        return (<p>
            {i18n.t('admin.labels.account_chrgd')} <strong>{i18n.t('admin.labels.after')} {row.days} {i18n.t('admin.labels.days')}</strong> {i18n.t('admin.labels.for_plan')} <strong>
                {row.plan.split('_').join(' ').toLowerCase()}
            </strong>
        </p>)
    }
    handleSubmit = (event) => {
        if (!this.state.shouldBlockNavigation) {
            return;
        }
        this.setState({ isLoading: true });
        this.addNewPaymentReminder()
    }
    addNewPaymentReminder = (type) => {
        const formData = new FormData();
        if(!type){
            formData.append('subscription_reminder[payment_method_id]', this.state.payment_method_id);
            formData.append('subscription_reminder[plan]', this.state.plan);
            formData.append('subscription_reminder[payment_cycle]', this.state.payment_cycle);
            formData.append('subscription_reminder[days]', this.state.days);
            API.post('/subscription_reminders', formData)
            .then(res => {

                window.location.reload();
            })
            .catch(function (error) {
                this.setState({
                    isValidate: true,
                    isValid: false,
                    hasError: true,
                    errorMessage: error.message,
                    status: null,
                    isLoading: false
                });
            })
        }
        else if (type === 'update') {
            formData.append('subscription_reminder[payment_method_id]', this.state.edit_payment_method_id);
            formData.append('subscription_reminder[plan]', this.state.edit_plan);
            formData.append('subscription_reminder[payment_cycle]', this.state.edit_payment_cycle);
            formData.append('subscription_reminder[days]', this.state.edit_days);
            API.put('/subscription_reminders/'+this.state.current_active_id, formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
                this.setState({
                    isValidate: true,
                    isValid: false,
                    hasError: true,
                    errorMessage: error.message,
                    status: null,
                    isLoading: false
                });
            })
        }
        
    }
    handleEditSubmit = (event) => {
        if (!this.state.shouldBlockNavigation) {
            return;
        }
        this.setState({ isLoading: true });
        this.addNewPaymentReminder('update')
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };
    reminderActions = (cell, row) => {
        let id = row.id;
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void(0)} data-id={id} onClick={this.editReminder} >{i18n.t('admin.labels.edit')}</NavDropdown.Item>
                {
                    row.status ? <NavDropdown.Item href={void(0)} data-id={id} onClick={this.deactivateReminder}>{i18n.t('admin.labels.deactivate')}</NavDropdown.Item> : <NavDropdown.Item href={void(0)} data-id={id} onClick={this.activateReminder}>{i18n.t('admin.labels.activate')}</NavDropdown.Item>
                }
                <NavDropdown.Item href={void(0)} data-id={id} onClick={this.deleteReminder}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
            </NavDropdown>
        );
    }
    editReminder = event => {
        let id = event.target.getAttribute('data-id');
        let reminder_info = this.state.data.find((element) => {
            return element.id == id
        });
        this.setState({edit_payment_method_id: ""+reminder_info.payment_method_id, edit_payment_cycle: reminder_info.payment_cycle, edit_plan: reminder_info.plan, edit_days: reminder_info.days, current_active_id: reminder_info.id})

        this.showEditModal()
    }
    showEditModal = () => {
        this.setState({isEditOpen: true})
    }
    hideEditModal = () => {
        this.setState({isEditOpen: false})
    }
    deactivateReminder = event => {
        let id = event.target.getAttribute('data-id');
        API.put('/subscription_reminders/'+id+'/deactivate')
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
                alert("Something went wrong!")
            })
    }
    activateReminder = event => {
        let id = event.target.getAttribute('data-id');
        API.put('/subscription_reminders/'+id+'/activate')
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
                alert("Something went wrong!")
            })
    }
    deleteReminder = event => {
        let id = event.target.getAttribute('data-id');
        API.delete('/subscription_reminders/'+id+'/remove')
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
                alert("Something went wrong!")
            })
    }
    render() {
        const paginatorOptions = {
            pageStartIndex: 1,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        }
        const columns = [{
            dataField: 'payment_method?',
            text: i18n.t('admin.labels.payment_method').toUpperCase(),
            sort: false,
        }, {
            dataField: 'payment_cycle',
            text: i18n.t('admin.labels.payment_cycle').toUpperCase(),
            sort: false
        }, {
            //dataField: 'reminder_type',
            text: i18n.t('admin.labels.reminder_type').toUpperCase(),
            formatter: this.reminderTypeText,
            headerStyle: (colum, colIndex) => {
                return { width: '55%' };
            }
            //sort: true
        }, {
            dataField: 'plan',
            text: i18n.t('admin.labels.plan').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '12%' };
            }
        }, {
            dataField: 'status_text',
            text: i18n.t('admin.labels.status').toUpperCase(),
            sort: false
        }, {
            dataField: 'moreOptions',
            sort: false,
            formatter: this.reminderActions,
        }];
        const defaultSorted = [{
            dataField: 'plan',
            order: 'asc'
        }];
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div className="mt-2 admin-custom-table-header">
                            <button data-bs-toggle="modal" data-bs-target="#addReminder" className="btn btn-primary">{i18n.t('admin.reminder.btn_new')}</button>
                        </div>
                        <div className="reminder-table mt-4">
                            <BootstrapTable
                                keyField='plan'
                                data={this.state.data}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                noDataIndication={() => <NoDataIndication />}
                            />
                        </div>
                    </div>
                </div>

                {/* Add New Reminder Modal */}
                <div className="modal fade" id="addReminder" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            {/* <form id="addReminderForm"> */}
                            <ValidatorForm
                                id="addReminderForm"
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.reminder.modal_add.title')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body reminder-modal">
                                    <div className="middle-sec">
                                        <div className="">
                                            <div className="row mb-3">
                                                <div className="user-label col-sm-6">
                                                    <span>{i18n.t('admin.labels.payment_method')}</span>
                                                </div>
                                                <div className="col-sm-6">
                                                    <SelectValidator
                                                        tabIndex='1'
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admin.placeholder.payment_method')}
                                                        name="payment_method_id"
                                                        options={this.state.reminder_options}
                                                        value={this.state.payment_method_id}
                                                        onChange={this.handleChange}
                                                        isValidate={this.state.isValidate}
                                                        noValidate
                                                        //required
                                                        validators={["required"]}
                                                        errorMessages={[
                                                            i18n.t('admin.errors.require_payment_method')
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="user-label col-sm-6">
                                                    <span>{i18n.t('admin.labels.plan')}</span>
                                                </div>
                                                <div className="col-sm-6">
                                                    <SelectValidator
                                                        tabIndex='1'
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admin.placeholder.plan')}
                                                        name="plan"
                                                        options={this.state.plan_options}
                                                        value={this.state.plan}
                                                        onChange={this.handleChange}
                                                        isValidate={this.state.isValidate}
                                                        noValidate
                                                        validators={["required"]}
                                                        errorMessages={[
                                                            i18n.t('admin.errors.require_plan')
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.payment_method_id === "1" && (
                                                    <div className="p-0">
                                                        <div className="row mb-3 pb-1">
                                                            <div className="user-label col-sm-6">
                                                                <span>{i18n.t('admin.labels.payment_cycle')}</span>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <SelectValidator
                                                                    tabIndex='1'
                                                                    className={"form-control"}
                                                                    autoComplete="off"
                                                                    placeholder={i18n.t('admin.placeholder.payment_cycle')}
                                                                    name="payment_cycle"
                                                                    options={this.state.payment_cycle_options}
                                                                    value={this.state.payment_cycle}
                                                                    onChange={this.handleChange}
                                                                    isValidate={this.state.isValidate}
                                                                    noValidate
                                                                    //required
                                                                    validators={["required"]}
                                                                    errorMessages={[
                                                                        i18n.t('admin.errors.payment_cycle')
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.payment_cycle == 'monthly' && (
                                                                <div className="row mb-3">
                                                                    <div className="user-label col-sm-6">
                                                                        <span>{i18n.t('admin.labels.days_before')}</span>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <SelectValidator
                                                                            tabIndex='1'
                                                                            className={"form-control"}
                                                                            autoComplete="off"
                                                                            placeholder={i18n.t('admin.placeholder.days_before')}
                                                                            name="days"
                                                                            options={this.state.monthly_options}
                                                                            value={this.state.days}
                                                                            onChange={this.handleChange}
                                                                            isValidate={this.state.isValidate}
                                                                            noValidate
                                                                            //required
                                                                            validators={["required"]}
                                                                            errorMessages={[
                                                                                i18n.t('admin.errors.require_number_of_days_before')
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            this.state.payment_cycle == 'yearly' && (
                                                                <div className="row mb-3">
                                                                    <div className="user-label col-sm-6">
                                                                        <span>{i18n.t('admin.labels.days_before')}</span>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <SelectValidator
                                                                            tabIndex='1'
                                                                            className={"form-control"}
                                                                            autoComplete="off"
                                                                            placeholder={i18n.t('admin.placeholder.days_before')}
                                                                            name="days"
                                                                            options={this.state.yearly_options}
                                                                            value={this.state.days}
                                                                            onChange={this.handleChange}
                                                                            isValidate={this.state.isValidate}
                                                                            noValidate
                                                                            //required
                                                                            validators={["required"]}
                                                                            errorMessages={[
                                                                                i18n.t('admin.errors.require_number_of_days_before')
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                this.state.payment_method_id === "2" && (
                                                    <div className="p-0">
                                                        <div className="row mb-3 pb-1">
                                                            <div className="user-label col-sm-6">
                                                                <span>{i18n.t('admin.labels.payment_cycle')}</span>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <SelectValidator
                                                                    tabIndex='1'
                                                                    className={"form-control"}
                                                                    autoComplete="off"
                                                                    placeholder={i18n.t('admin.placeholder.payment_cycle')}
                                                                    name="payment_cycle"
                                                                    options={this.state.payment_cycle_options}
                                                                    value={this.state.payment_cycle}
                                                                    onChange={this.handleChange}
                                                                    isValidate={this.state.isValidate}
                                                                    noValidate
                                                                    //required
                                                                    validators={["required"]}
                                                                    errorMessages={[
                                                                        i18n.t('admin.errors.require_payment_cycle')
                                                                    ]}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3 pb-1">
                                                            <div className="user-label col-sm-6">
                                                                <span>{i18n.t('admin.labels.days_before')}</span>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <SelectValidator
                                                                    tabIndex='1'
                                                                    className={"form-control"}
                                                                    autoComplete="off"
                                                                    placeholder={i18n.t('admin.placeholder.days_before')}
                                                                    name="days"
                                                                    options={this.state.yearly_options}
                                                                    value={this.state.days}
                                                                    onChange={this.handleChange}
                                                                    isValidate={this.state.isValidate}
                                                                    noValidate
                                                                    //required
                                                                    validators={["required"]}
                                                                    errorMessages={[
                                                                        i18n.t('admin.errors.require_number_of_days_before')
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal">{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('userprofile.btn_save')}</React.Fragment>
                                    }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </div>
                </div>

                {/* Edit Reminder Modal */}
                <div>
                    <Modal show={this.state.isEditOpen} id="editReminderModal">
                        <div className="modal-content">
                            {/* <form id="addReminderForm"> */}
                            <ValidatorForm
                                id="editReminderForm"
                                ref="form"
                                onSubmit={this.handleEditSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.reminder.modal_edit.title')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideEditModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body reminder-modal">
                                    <div className="middle-sec">
                                        <div className="">
                                            <div className="row mb-3">
                                                <div className="user-label col-sm-6">
                                                    <span>{i18n.t('admin.labels.payment_method')}</span>
                                                </div>
                                                <div className="col-sm-6">
                                                    <SelectValidator
                                                        tabIndex='1'
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admin.placeholder.payment_method')}
                                                        name="edit_payment_method_id"
                                                        options={this.state.reminder_options}
                                                        value={this.state.edit_payment_method_id}
                                                        onChange={this.handleChange}
                                                        isValidate={this.state.isValidate}
                                                        noValidate
                                                        //required
                                                        validators={["required"]}
                                                        errorMessages={[
                                                            i18n.t('admin.errors.require_payment_method')
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="user-label col-sm-6">
                                                    <span>{i18n.t('admin.labels.plan')}</span>
                                                </div>
                                                <div className="col-sm-6">
                                                    <SelectValidator
                                                        tabIndex='1'
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t('admin.placeholder.plan')}
                                                        name="edit_plan"
                                                        options={this.state.plan_options}
                                                        value={this.state.edit_plan}
                                                        onChange={this.handleChange}
                                                        isValidate={this.state.isValidate}
                                                        noValidate
                                                        validators={["required"]}
                                                        errorMessages={[
                                                            i18n.t('admin.errors.require_plan')
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.edit_payment_method_id === "1" && (
                                                    <div className="p-0">
                                                        <div className="row mb-3 pb-1">
                                                            <div className="user-label col-sm-6">
                                                                <span>{i18n.t('admin.labels.payment_cycle')}</span>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <SelectValidator
                                                                    tabIndex='1'
                                                                    className={"form-control"}
                                                                    autoComplete="off"
                                                                    placeholder={i18n.t('admin.placeholder.payment_cycle')}
                                                                    name="edit_payment_cycle"
                                                                    options={this.state.payment_cycle_options}
                                                                    value={this.state.edit_payment_cycle}
                                                                    onChange={this.handleChange}
                                                                    isValidate={this.state.isValidate}
                                                                    noValidate
                                                                    //required
                                                                    validators={["required"]}
                                                                    errorMessages={[
                                                                        i18n.t('admin.errors.require_payment_cycle')
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.edit_payment_cycle == 'monthly' && (
                                                                <div className="row mb-3">
                                                                    <div className="user-label col-sm-6">
                                                                        <span>{i18n.t('admin.labels.days_before')}</span>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <SelectValidator
                                                                            tabIndex='1'
                                                                            className={"form-control"}
                                                                            autoComplete="off"
                                                                            placeholder={i18n.t('admin.placeholder.days_before')}
                                                                            name="edit_days"
                                                                            options={this.state.monthly_options}
                                                                            value={this.state.edit_days}
                                                                            onChange={this.handleChange}
                                                                            isValidate={this.state.isValidate}
                                                                            noValidate
                                                                            //required
                                                                            validators={["required"]}
                                                                            errorMessages={[
                                                                                i18n.t('admin.errors.require_number_of_days_before')
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            this.state.edit_payment_cycle == 'yearly' && (
                                                                <div className="row mb-3">
                                                                    <div className="user-label col-sm-6">
                                                                        <span>{i18n.t('admin.labels.days_before')}</span>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <SelectValidator
                                                                            tabIndex='1'
                                                                            className={"form-control"}
                                                                            autoComplete="off"
                                                                            placeholder={i18n.t('admin.placeholder.days_before')}
                                                                            name="edit_days"
                                                                            options={this.state.yearly_options}
                                                                            value={this.state.edit_days}
                                                                            onChange={this.handleChange}
                                                                            isValidate={this.state.isValidate}
                                                                            noValidate
                                                                            //required
                                                                            validators={["required"]}
                                                                            errorMessages={[
                                                                                i18n.t('admin.errors.require_number_of_days_before')
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                this.state.edit_payment_method_id === "2" && (
                                                    <div className="p-0">
                                                        <div className="row mb-3 pb-1">
                                                            <div className="user-label col-sm-6">
                                                                <span>{i18n.t('admin.labels.payment_cycle')}</span>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <SelectValidator
                                                                    tabIndex='1'
                                                                    className={"form-control"}
                                                                    autoComplete="off"
                                                                    placeholder={i18n.t('admin.placeholder.payment_cycle')}
                                                                    name="edit_payment_cycle"
                                                                    options={this.state.payment_cycle_options}
                                                                    value={this.state.edit_payment_cycle}
                                                                    onChange={this.handleChange}
                                                                    isValidate={this.state.isValidate}
                                                                    noValidate
                                                                    //required
                                                                    validators={["required"]}
                                                                    errorMessages={[
                                                                        i18n.t('admin.errors.require_payment_cycle')
                                                                    ]}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3 pb-1">
                                                            <div className="user-label col-sm-6">
                                                                <span>{i18n.t('admin.labels.days_before')}</span>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <SelectValidator
                                                                    tabIndex='1'
                                                                    className={"form-control"}
                                                                    autoComplete="off"
                                                                    placeholder={i18n.t('admin.placeholder.days_before')}
                                                                    name="edit_days"
                                                                    options={this.state.yearly_options}
                                                                    value={this.state.edit_days}
                                                                    onChange={this.handleChange}
                                                                    isValidate={this.state.isValidate}
                                                                    noValidate
                                                                    //required
                                                                    validators={["required"]}
                                                                    errorMessages={[
                                                                        i18n.t('admin.errors.require_number_of_days_before')
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideEditModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('userprofile.btn_save')}</React.Fragment>
                                    }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default withTranslation() (Reminders);