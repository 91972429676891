import React from 'react';
import i18n from '../../packs/i18n';
class FeatureBlockItemValue extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        if(this.props.value === true){
            return(
                <span className="lh-icon-app-validation-check"> </span>
            )
        }
        else if(this.props.value === false){
            return(
                <span> </span>
            )
        }
        else if(typeof(this.props.value)=="string" && this.props.value.indexOf("features.") !== -1){
            return(
                <span> {i18n.t(this.props.value)} </span>
            )
        }
        else{
            return(
             <span dangerouslySetInnerHTML={{__html: this.props.value}} />
            )
        }
        
    }
}

export default FeatureBlockItemValue;