import React from 'react';

class Select extends React.Component {
 
    render() {
        const { 
            errorMessages, 
            isValidate, 
            label, 
            labelClass, 
            wrapperClass,
            showInputBottomError,
            ...rest } = this.props;
       
        const labelclass = labelClass ? labelClass : '';                         
        
        return (
            <React.Fragment>
                {label ? <label htmlFor={this.props.name} className={labelclass}>{ label } 
                        { required ? <span style={{ color: "#ffdccf"}}>*</span> : null }
                    </label> : null
                }
                <div className={wrapperClass}>
                    <select
                        {...rest}
                        ref={(r) => { this.select = r; }}
                    >   
                        { this.props.placeholder ? <option value={''}> {this.props.placeholder} </option> : null }
                        { this.props.options.map((option, key) => {
                            return <option key={key} value={option.value}> {option.label} </option>
                        })}
                    </select>
                    { showInputBottomError ? this.errorText() : null }
                </div>
            </React.Fragment>
        );
    }
 
    errorText() {
        const { isValid } = this.props;
 
        if (isValid) {
            return null;
        }
 
        return (
            <div style={{ color: 'red', textAlign: 'left' }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

Select.defaultProps = {
    options: []
}
 
export default Select;