import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import Users from "./Users";
import Institutions from "./Institutions";
import Invitations from "./Invitations";

class ViewReseller extends React.Component {
    constructor(props) {
        super(props);

        let path_array = props.location.pathname.split("/reseller/view/")

        this.state = {
            toggleViewResellerFilter: false,
            reseller_id: path_array[path_array.length - 1],
            reseller_info: {},
            voucher: {},
            isTopAdmin: false,
            breadcrumb: [],
            is_top_admin: props.is_top_admin || false,
            is_reseller: props.is_reseller || false
        }
    }
    async componentDidMount() {
        await this.callApi()
    }

    async callApi() {
        this.setState({ data: [], isLoading: true })
        let { data } = await API.get('/resellers/' + this.state.reseller_id)

        if (!data.isTopAdmin) {
            document.body.classList.add('no-topadmin')
        }
        else {
            document.body.classList.remove('no-topadmin')
        }
        this.setState({
            reseller_info: data.reseller,
            voucher: data.voucher,
            isLoading: false,
            isTopAdmin: data.isTopAdmin,
            breadcrumb: data.reseller.breadcrumb ? data.reseller.breadcrumb.reverse() : []
        })
    }
    closeSideModal = () => {
        this.setState({ toggleViewResellerFilter: false });
    }
    toggleSideModal = () => {
        this.setState({ toggleViewResellerFilter: !this.state.toggleViewResellerFilter });
    }

    routeBackToResellers() {
        window.history.back();
    }
    showFileName = () => {
        var importFile = document.getElementById('inputResellerFile');
        if (importFile.value == "") {
            fileLabel.innerHTML = i18n.t('admin.userprofile.choose_file');
        }
        else {
            var theSplit = importFile.value.split('\\');
            fileLabel.innerHTML = theSplit[theSplit.length - 1];
        }
    }
    onTableChange = (type, newState) => {
        this.setState({ current_page_user: newState.page, per_page_user: newState.sizePerPage });
        this.callApi()
    }
    hideExportModal = (event) => {
        this.setState({fromDate: '', toDate: ''})
    }
    render() {
        const { toggleViewResellerFilter } = this.state;
        return (
            <div className="overflow-hidden h-100">
                {/* <div className="startscreen h-100"> */}
                {this.state.isTopAdmin ?
                    <div className="row pl-4 pr-4 pt-2 pb-2">
                        <div className="col-md-12">
                            <div className="col-md-12 p-0">
                                <div className="col-md-4 p-0">
                                    <div className="view-user-back p-0">
                                        <span className="back-icon lh-icon-os-back" onClick={this.routeBackToResellers}></span>&nbsp;
                                        <p>
                                            {
                                                this.state.breadcrumb.map((path, index) => (
                                                    <span>
                                                        <a href={path.path}>{path.name}
                                                        </a>
                                                        {index != (this.state.breadcrumb.length - 1) ? <span> &gt; </span> : null

                                                        }
                                                    </span>
                                                ))
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
                <div className="tab-bar">
                    <input id="tab1" type="radio" name="tab-selector" defaultChecked="checked" />
                    <input id="tab2" type="radio" name="tab-selector" />
                    <input id="tab3" type="radio" name="tab-selector" />
                    <nav className="pl-4 pr-4">
                        <ul className="tab-divider">
                            <li className="tab-label-1">
                                <label htmlFor="tab1"><span className="">{i18n.t('admin.nav_menu.user.title')}</span></label>
                            </li>
                            <li className="tab-label-2">
                                <label htmlFor="tab2"><span className="">{i18n.t('admin.nav_menu.user.sub_menu.teams')}</span></label>
                            </li>
                            <li className="tab-label-3">
                                <label htmlFor="tab3"><span className="">{i18n.t('admin.nav_menu.invitation.title')}</span></label>
                            </li>
                        </ul>
                    </nav>
                    <section>
                        <div className="tab-content tab-1 reseller-table" style={{ padding: 0 }}>
                            <Users groupId={this.state.reseller_id} is_top_admin={this.state.is_top_admin} is_reseller={this.state.is_reseller}/>
                        </div>
                        <div className="tab-content tab-2 reseller-table" style={{ padding: 0 }}>
                            <Institutions groupId={this.state.reseller_id} is_top_admin={this.state.is_top_admin} is_reseller={this.state.is_reseller} />
                        </div>
                        <div className="tab-content tab-3 reseller-table" style={{ padding: 0 }}>
                            <Invitations groupId={this.state.reseller_id} />
                        </div>
                    </section>
                </div>
                {/* </div> */}

                {/* Import Modal */}
                <div className="modal fade" id="importModal" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="resellerImportForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">Import</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <span className="import-header">Guidelines</span>
                                        <span className="temp-download">Download Template</span>
                                        <div className="pt-2 pb-3">
                                            <ul className="import-points">
                                                <li>Make sure the file you import includes no typing errors</li>
                                                <li>e-mail addresses are mandatory and have to be real (no dummy addresses!)</li>
                                                <li>Address are mandatory</li>
                                                <li>The first row are considered headers</li>
                                                <li>The account_type field should :
                                                    <ul className="inner-points">
                                                        <li>be team_basic, team_intermediate, team_advanced for team license users</li>
                                                        <li>individual or free for single users</li>
                                                    </ul>
                                                </li>
                                                <li>For team licenses the team_licenses and institution fields are required</li>
                                                <li>Save the template as a .CSV file</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-8 input-group mb-3">
                                            <div className="custom-file">
                                                <input name="file" onChange={this.showFileName} accept=".csv,text/csv" type="file" className="custom-file-input" id="inputResellerFile" />
                                                <label className="custom-file-label" id="fileLabel" htmlFor="inputResellerFile">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-accenta">Import</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* View Reseller Filter */}
                <div className="sidebar-container user-filter-sidebar" style={{ display: (toggleViewResellerFilter ? 'block' : 'none') }}>
                    <div className="right-sidebar">
                        <div className="sidebar-header bg-primary">
                            <a href={void (0)} className="close-btn" onClick={this.closeSideModal}><span className="lh-icon-os-close"></span> </a>
                            <h1 className="sb-head-title">Filters</h1>
                        </div>
                        <div className="sidebar-body">
                            <div className="users-filter-body payment-filters">
                                <div>
                                    <h5 className="mb-3">By Country</h5>
                                    <select name="country" defaultValue="in" className="form-control sub-filter">
                                        <option value="select">Select Country</option>
                                        <option value="in">India</option>
                                        <option value="us">United States</option>
                                        <option value="ca">Canada</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar-footer">
                            <div className="footer-action-panel justify-content-end">
                                <button className="btn btn-outline-tertiary mt-2" onClick={this.closeSideModal} >Cancel</button>
                                <button style={{ width: '6rem' }} className="btn btn-accenta ml-2">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ViewReseller)