import React from 'react';
import i18n from '../../packs/i18n';
import { Heart_IMG, Patterns_IMG } from '../Common/assets_paths';

class PlanItem extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        free: false,
        individual: false,
        team: false,
        current_plan_key: null,
        last_used_payment_method: null
      }
    }
    handleChoosePlan = event => {
      this.props.handler(this.props.plan_detail,this.props.plan_key, this.props.userInfo);
    }
    scrollMore = event => {
        this.props.muchMoreHandler();
    }
    calculateDiscount(){
        let mprice = this.props.plan_detail.monthly_price;
        let yprice = this.props.plan_detail.yearly_price;
        return (mprice * 12) - yprice;
    }

    /** Handle Disable plan arrived from Profile page subscription */
    setDisablePlan() {
      const { current_plan } = this.props;

      if(current_plan && current_plan.key == "team_advanced") {
        this.disablePlanState(true, true, true, 'team');
      } if(current_plan && (current_plan.key == "team_basic" || current_plan.key == "team_intermediate")) {
        this.disablePlanState(true, true, false, null);
      } else if(current_plan && current_plan.key == "free") {
        this.disablePlanState(true, false, false, 'free');
      } else if(current_plan && current_plan.key == "individual") {

        if(current_plan && current_plan.last_used_payment_method == "stripe") {
          this.disablePlanState(true, false, false, 'individual');
        } else {
          this.disablePlanState(true, true, false, 'individual');
        }

      }
      if(current_plan && current_plan.last_used_payment_method) {
        this.setState({
          last_used_payment_method: current_plan.last_used_payment_method
        })
      }
    }

    disablePlanState(free, individual, team, current_plan_key) {
      this.setState({free, individual, team, current_plan_key})
    }

    componentDidMount() {
        this.setDisablePlan()
    }
    render(){
        const main_features = [];
        for(const[index,value] of this.props.plan_detail.important_features.entries()){
            main_features.push(<p key={index} className="my-1 mx-0">{i18n.t(value)}</p>)
        }
        return(
            <div className={"col-xs-12 col-md-4 px-2 slider-item " + this.props.plan_detail.class_name} >
                <div className="pack-boxed">
                    <div className="header-pack text-white pb-3" style = {{ background: this.props.plan_background_color }}>
                        <img src={this.props.plan_image_path} className="mt-5 mb-5"/>
                        <div className="desc">
                            <p className="price-info"><span className="text-size-16">€</span> <b className="text-size-36">{this.props.plan_detail.monthly_price}</b><span className="text-size-14">/ {i18n.t('signup.step3.month_txt')}</span></p>
                            { this.props.plan_detail.monthly_price !== 0 ?
                                 <p className="text-size-14">
                                 {i18n.t('signup.step3.yearly_discount_txt')} <b>{this.calculateDiscount()}€ {i18n.t('signup.step3.benifit_txt')}</b>
                                </p> : null
                            }
                        </div>
                        <div className="pattern">
                            <img src={Patterns_IMG} />
                        </div>
                    </div>
                    <div className="pt-4 pb-4 text-accentb">
                        <h4 className="text-secondary mb-3">{i18n.t(this.props.plan_detail.display_text)}</h4>
                        {main_features}
                        <a href="javascript:void(0)" onClick={this.scrollMore} className="text-tertiary text-decoration-underline">{i18n.t('signup.step3.link_more')}</a>
                        { this.state.current_plan_key == this.props.plan_key && this.state.last_used_payment_method != "stripe" ?
                            <a href="/" className="btn btn-outline-secondary btn-select-pack mt-4 mb-3 text-size-18 p-2" >
                                {
                                   i18n.t('signup.step3.your_current_plan')
                                }
                            </a>
                            :
                            <button href="javascript:void(0)"
                                onClick={this.handleChoosePlan}
                                className="btn btn-outline-secondary btn-select-pack mt-4 mb-3 text-size-18"
                                disabled={ this.state[this.props.plan_key] ? 'disabled': ''}>
                                  {
                                    this.state.current_plan_key == this.props.plan_key && this.state.last_used_payment_method == "stripe" ?
                                    i18n.t('userprofile.manage_subscriptions.renew_my_subscription')
                                    :
                                    this.props.is_from_pricing ? i18n.t('signup.step3.signup_btn') : i18n.t('signup.step3.choose_btn')
                                  }
                            </button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default PlanItem;
