import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import {Mail_IMG} from '../Common/assets_paths'
import { withTranslation } from 'react-i18next';

class PackConfirmEmail extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {

        return (
            <LandingStepBase pack_signup={true} container={
                <div className="container">
                <div className="row">
                    <div className="col-sm-12 p-0 text-center">
                        <div className="headtxt mt-5 mb-5">
                            <h1 className="text-white mb-0">{i18n.t('signup.step2.header.title')} </h1>
                            <p>{i18n.t('signup.step2.header.sub_title')}</p>
                            <div className="steps">
                                <span className="completed step mr-2 ml-2"></span><span className="activestep step  mr-2 ml-2"></span><span className="step  mr-2 ml-2"></span><span className="step mr-2 ml-2"></span><span className="step mr-2 ml-2"></span>
                            </div>
                            <p className="mt-2 text-quaternary text-size-20">{i18n.t('signup.step2.header.current_step')}</p>
                        </div>
                        <div className="main-wrapper text-center">
                            <img src={Mail_IMG} className="my-4 animated-svg" />
                            <p className="text-white mt-5 text-size-24">
                                {i18n.t('signup.step2.check_email_text')}  
                                    <span className="text-accenta"> {this.props.location.state ? this.props.location.state.email : ''}</span>
                            </p>
                        </div>
                    </div>
                </div>
                </div>}></LandingStepBase>
        )
    }

}

export default withTranslation()(PackConfirmEmail);