// Import React Libraries from the react js
import React from "react";
import TopRightControls from "../TopRightControls";
import {LOGO_IMG, PPDS_LOGO_IMG} from '../../Common/assets_paths';
import SimpleBar from 'simplebar-react'
function LandingStepBase(props) {
  return (
    <div className={'landingstepbase'}>
       <SimpleBar style={{ maxHeight: '100vh' }}> 
        <div className="main-cover container-fluid">
            
            <div className="header">
              <div className={'logo'}>
                { props && props.pack_signup ? (props.ppds_pack_signup ? 
                  <a href={void(0)}><img className="ppds-logo-img-width" src={PPDS_LOGO_IMG} /></a> : <a href={void(0)}><img src={LOGO_IMG} /></a>  ): 
                  <a href="/"><img src={LOGO_IMG} /></a>
                }
              </div>
              <TopRightControls></TopRightControls>
            </div>     
          
              {props.container} 
                
        </div>
        {props.subcontainer}
      </SimpleBar> 
    </div>
  );
}
export default LandingStepBase;

// 