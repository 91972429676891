import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

//Import all the language files here
// import transEn  from '../i18n/en.json';
// import transNl  from '../i18n/nl.json';
// import transFr  from '../i18n/fr.json';

const coreNS = 'packs';

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    fallbackLng: 'en',
    whitelist: ['ar', 'de', 'da', 'en', 'fr', 'nl', 'sv', 'cz', 'es', 'ca', 'pl', 'ru', 'et','ro','sk','lt','hr','it','fa','lv','zh','bg','hu','vi','nb'],
    //ns:['i18n'],
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged',
    },
    detection:{
        order: ['path','cookie','navigator'],
        lookupCookie:"lang",
    },
    //Define all the languages here
    // resources:{
    //     en: {
    //         translation:transEn
    //     },
    //     nl: {
    //         translation:transNl
    //     },
    //     fr: {
    //         translation:transFr
    //     }
    // }
    backend:{
        loadPath: '/{{lng}}/i18n'
    }
   
})

export default i18n;