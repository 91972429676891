import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
 
class TextareaValidator extends ValidatorComponent {
 
    render() {
        const { errorMessages, validators, requiredError, validatorListener, isValidate, label, labelClass, wrapperClass, showInputBottomError,  ...rest} = this.props;
        const className = isValidate ? (!this.state.isValid ?
                          this.props.className + " was-validated is-invalid" 
                          : this.props.className +" is-valid") : this.props.className;
        const labelclass = labelClass ? labelClass : '';                           
        
        return (
            <React.Fragment>
                {label ? <label htmlFor={this.props.name} className={labelclass}>{ label } </label> : null }
                <div className={wrapperClass}>
                    <textarea
                        {...rest}
                        ref={(r) => { this.input = r; }}
                        className={ className }
                    />
                    { showInputBottomError ? this.errorText() : null }
                </div>
            </React.Fragment>
        );
    }
 
    errorText() {
        const { isValid } = this.state;
 
        if (isValid) {
            return null;
        }
 
        return (
            <div style={{ color: "red", textAlign: 'left'}}>
                {this.getErrorMessage()}
            </div>
        );
    }
}
 
export default TextareaValidator;