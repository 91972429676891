import React from 'react';
import { withRouter } from "react-router-dom";
import i18n from '../../packs/i18n';
import { NotFound_IMG } from './assets_paths';
import { withTranslation } from 'react-i18next';
import LandingStepBase from './Layouts/LandingStepBase';
import Login from '../Login/Login';

class ConsentScreenVLE extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brd_id: props.match.params.brd_id || '',
            token: props.match.params.tkn || '',
            uniq_brd_id: props.match.params.ukid || '',
            auth_data: props.match.params.data || '',
            VERSION_INFO: props.VERSION_INFO
        }
    }
    render() {
        return (
            <React.Fragment>
                <Login VERSION_INFO={this.state.VERSION_INFO} isVLE={true} brd_id={this.state.brd_id} tkn={this.state.token} ukid={this.state.uniq_brd_id} auth_data={this.state.auth_data}></Login>
            </React.Fragment>
        )
    }

}

export default withRouter(withTranslation()(ConsentScreenVLE));