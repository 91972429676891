import React from 'react';
import { Link } from "react-router-dom";
import { withRouter, matchPath } from 'react-router-dom';

import { StepTypes, getStepByPathname } from './Helpers/LandingStepHelper'
import i18n from "../../packs/i18n";

class TopRightControls extends React.Component{

    constructor(props){
        super(props);
        let current_path = getStepByPathname(this.props.history.location.pathname);

        this.state={
            showBackButton:(
                current_path != StepTypes.login
                && current_path != StepTypes.signup_confirmemail
                && current_path != StepTypes.signup_confirmemail
                && current_path != StepTypes.signup_success
                && current_path != StepTypes.forgotpassword_choosenewpassword
                && current_path != StepTypes.signup_select_package
                && current_path != StepTypes.signup_fail
                && current_path != StepTypes.forgotpassword_confirmemail
                && current_path !== StepTypes.forgotpassword
                && current_path !== StepTypes.forgotpassword_fail
                && current_path !== StepTypes.user_profile
                && current_path !== StepTypes.connect_session
                && current_path !== StepTypes.accept_decline_invitation
                && current_path !== StepTypes.tos
                && current_path !== StepTypes.packsignup_signup
                && current_path !== StepTypes.packsignup_confirmemail
                && current_path !== StepTypes.packsignup_activate
                && current_path !== StepTypes.packsignup_success
                && current_path !== StepTypes.packsignup_fail
                && current_path !== StepTypes.packsignup
                && current_path !== StepTypes.ppdspack_welcome
                && current_path !== StepTypes.content_vle
                && current_path !== StepTypes.approve_tos
                ),
            showSignupButton:current_path === StepTypes.login
                && this.props.match.isExact,
            showSignupBackButton:current_path === StepTypes.forgotpassword,
            showBackToDashboardButton: current_path === StepTypes.user_profile,
            showPricingLink: current_path === StepTypes.login || current_path === StepTypes.signup 
        };
    }
    componentWillUnmount(){
        //this.unlisten();
    }
    render(){
        const match = matchPath(this.props.history.location.pathname, {
            path: '/:locale/(signup|login|forgotpassword)',
            exact: false,
            strict: false
          });
          var locale = match && match.params && match.params.locale ? match.params.locale  + '/' : '';
        
        return (
        <div>
            {this.state.showSignupBackButton &&
                 <div className={'start'}>
                    {this.state.showPricingLink && <Link className="mr-4 pricing-link" to={{pathname: `/${locale}pricing/`}}>{i18n.t('login.pricing_btn')}</Link> }
                    <a href={void(0)} onClick={this.props.history.goBack} className={'btn btn-back btn-lg btn-responsive btn-outline-quinary mr-2'}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            width="35.621px" height="25.955px" viewBox="0 0 35.621 25.955" enableBackground="new 0 0 35.621 25.955" xmlSpace="preserve">
                            <line className="line" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" x1="34.121" y1="13.121" x2="2.121" y2="13.121" />
                            <line className="arrow-path" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" x1="13.121" y1="2.121" x2="2.121" y2="13.121" />
                            <path className="arrow-path" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" d="M2.121,13.121l10.55,11.333" />
                        </svg>
                    </a>
                    <Link className={'btn btn-lg btn-outline-quinary btn-responsive btn-border-animate'} to={{pathname: `/${locale}signup/`}}>{i18n.t('login.signup_btn')}</Link>
                </div>
            }
            {this.state.showSignupButton  &&
                <div className={'start'} id="start_btn">
                     {this.state.showPricingLink && <Link className="mr-4 pricing-link" to={{pathname: `/${locale}pricing/`}}>{i18n.t('login.pricing_btn')}</Link> }
                    <Link className={'btn btn-lg btn-outline-quinary btn-responsive btn-border-animate'} to={{pathname: `/${locale}signup/`}}>{i18n.t('login.signup_btn')}</Link>
                </div>
            }
            
            {this.state.showBackButton  &&
                <div className={'start'}>
                {this.state.showPricingLink && <Link className="mr-4 pricing-link" to={{pathname: `/${locale}pricing/`}}>{i18n.t('login.pricing_btn')}</Link> }
                <a href={void(0)} onClick={this.props.history.goBack} className={'btn btn-back btn-lg btn-responsive btn-outline-quinary mr-2'}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="35.621px" height="25.955px" viewBox="0 0 35.621 25.955" enableBackground="new 0 0 35.621 25.955" xmlSpace="preserve">
                        <line className="line" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" x1="34.121" y1="13.121" x2="2.121" y2="13.121" />
                        <line className="arrow-path" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" x1="13.121" y1="2.121" x2="2.121" y2="13.121" />
                        <path className="arrow-path" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" d="M2.121,13.121l10.55,11.333" />
                    </svg>
                </a>
            </div>
            }
            {this.state.showBackToDashboardButton  &&
                <div className={'start'}>
                <a href="/" className={'btn btn-back btn-lg btn-responsive btn-outline-quinary mr-2'}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="35.621px" height="25.955px" viewBox="0 0 35.621 25.955" enableBackground="new 0 0 35.621 25.955" xmlSpace="preserve">
                        <line className="line" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" x1="34.121" y1="13.121" x2="2.121" y2="13.121" />
                        <line className="arrow-path" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" x1="13.121" y1="2.121" x2="2.121" y2="13.121" />
                        <path className="arrow-path" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" d="M2.121,13.121l10.55,11.333" />
                    </svg>
                </a>
            </div>
            }
        </div>
        );
    }

}

export default withRouter(TopRightControls);
