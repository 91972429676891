import React from 'react';
import i18n from '../../packs/i18n';
import { withTranslation } from 'react-i18next';
import API from '../api';

import {createEvent} from '../Common/AnalyticsEvents'

class LanguageSelection extends React.Component {
  handleLanguageChange= (event) => {
    const languageVal = event.target.value;
    const redirectPath = this.props.redirectPath;
    const _this = this;
    API.get(`user_sessions/language?lang=${languageVal}`)
      .then(res => {
        if(!redirectPath) {
          const codeValue = this.props.code ? `?code=${this.props.code}` : "";
          window.location.href = `/${languageVal}/start${codeValue}`;
        } else {
          createEvent('Language Changed',redirectPath)
          window.location.href = `/${languageVal}/${redirectPath}`;
        }
      })
      .catch(function(error) {
        if (error.response) {
          _this.setState({
            hasError: true,
            errorMessage: error.response.data.message,
            status: error.response.status
          });
        }
        if (error.request) {
          console.log(error.request);
        } else {
          _this.setState({
            hasError: true,
            errorMessage: error.message,
            status: error.status
          });
        }
      })
      .finally(function() {
        // always executed
      });
  }
  render() {
    return (
      <div className={"c-language-selection " + this.props.className}>
        <select value={this.props.language} name="language" onChange={this.handleLanguageChange} className="form-control mb-3 select-connect text-white" tabIndex="-1">
        {
          this.props.languageOpt.map((item, i) => {
            return <option key={i} value={item.value}>{item.label}</option>;
          })
        }
        </select>
      </div>
    )
  }
}

export default LanguageSelection;
