import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import Errors from "../Common/Errors/Errors";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import SelectValidator from "../Common/SelectValidator";
import TextValidator from "../Common/TextValidator";
import TextareaValidator from "../Common/TextareaValidator";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import Modal from "react-bootstrap/Modal";
import NewInvite from './NewInvite';
import { EventEmitter } from "../Common/Events";
import moment from 'moment/min/moment-with-locales';
class Institutions extends React.Component {
    constructor(props) {
        super(props);
        let __type = undefined;
        if (props && props.location && props.location.pathname) {
            let path_array = props.location.pathname.split("/institutions/")
            __type = path_array[path_array.length - 1]
        }
        this.state = {
            total_count: 0,
            current_status: props.groupId ? 'all' : 'active',
            previous_status: props.groupId ? 'all' : 'active',
            per_page: 10,
            current_page: 1,
            plans: [],
            data: [],
            type: __type || '',
            isEditOpen: false,
            edit_name: '',
            edit_address: '',
            edit_max_connect_sessions: '',
            edit_order_number: '',
            current_edit_order_number: '',
            current_edit_id: '',
            invitations: [],
            current_change_parent_edit_id: '',
            isChangeParentOpen: false,
            selected_target_group: '',
            selected_target_groups: [],
            isLoading: false,
            searchTerm: '',
            fromDate: '',
            toDate: '',
            current_date: new Date(),
            group_id: props.groupId || '',
            import_file: null,
            orderBy: 'updated_at desc',
            current_max_user_limit: '',
            new_max_user_limit: '',
            isMaxUserLimitDialogOpened: false,
            isTopAdmin: props.is_top_admin || false,
            isFromSearch: props.isFromSearch || false,
        }
        //EventEmitter.subscribe('globalSearchTrigger', (event) => { this.handleGlobalSearch(event) })
        EventEmitter.subscribe('editInstituteSearch', (event) => { this.handleEditInstituteOutSide(event) })
        EventEmitter.subscribe('deleteInstituteSearch', (event) => { this.handleDeleteInstituteOutSide(event) })
        EventEmitter.subscribe('changeParentInstituteSearch', (event) => { this.handleChangeParentInstituteOutSide(event) })
        EventEmitter.subscribe('extendLimitInstituteSearch', (event) => { this.handleExtendLimitInstituteOutSide(event) })
        EventEmitter.subscribe('disablePWAInstituteSearch', (event) => { this.handleDisablePWAOutSide(event) })
        EventEmitter.subscribe('enablePWAInstituteSearch', (event) => { this.handleEnablePWAOutSide(event) })

        ValidatorForm.addValidationRule('isLimitExtend', (value) => {
            if (parseInt(value) <= parseInt(this.state.current_max_user_limit)) {
                return false;
            }
            return true;
        });
    }
    handleDisablePWAOutSide = (val) =>{
        let insitutionInfo = val;
        this.callEnableDisablePWAApi(insitutionInfo.id, false);
    }
    handleEnablePWAOutSide = (val) => {
        let insitutionInfo = val;
        this.callEnableDisablePWAApi(insitutionInfo.id, true);
    }
    handleEditInstituteOutSide = (val) =>{
        let userInfo = val;
        this.setState({ edit_name: userInfo.name, edit_address: userInfo.address, edit_max_connect_session: userInfo.session_count, current_edit_id: userInfo.id, edit_order_number: userInfo.order_number, current_edit_order_number: userInfo.order_number})
        this.showEditModal()  
    }
    handleDeleteInstituteOutSide = (val) => {
        this.deleteInstitution(val)
    }
    handleChangeParentInstituteOutSide = (val) => {
        let userInfo = val;
        userInfo.target_groups.map((target_group) => {
            target_group["value"] = target_group.id
            let path = ''
            for(var i = target_group.breadcrumb.length -1 ; i >= 1; i--){
                if(i > 1){
                  path += target_group.breadcrumb[i].name + " -> "
                }
                else{
                  path += target_group.breadcrumb[i].name
                }
            }
            target_group["label"] = target_group.name + "\n [ "+path+" ] "
        })
        this.setState({ selected_target_groups: userInfo.target_groups, current_change_parent_edit_id: userInfo.id })
        this.showChangeParentModal();
    }
    handleExtendLimitInstituteOutSide = (val) => {
        let userInfo = val;
        this.setState({current_max_user_limit: userInfo.get_seat_licenses, new_max_user_limit:userInfo.get_seat_licenses, current_edit_id: userInfo.id})
        this.showIncreaseMaxUserLimitModal();
    }
    handleGlobalSearch = (val) => {
        this.setState({ searchTerm: val })
        this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, null, val)
    }
    institutionActions = (cell, row) => {
        let institution_id = row.id
        let is_pwa = row['is_pwa'];
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.editInstitution}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
                {this.state.isTopAdmin ? 
                <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.deleteInstitution}>{i18n.t('admin.labels.delete')}</NavDropdown.Item> : null}
                { this.props.is_top_admin && is_pwa ?  
                    <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.disablePWA} >{i18n.t('admin.labels.disable_pwa')}</NavDropdown.Item> : null
                }
                { this.props.is_top_admin && !is_pwa ?  
                    <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.enablePWA} >{i18n.t('admin.labels.enable_pwa')}</NavDropdown.Item> : null
                }
                {this.state.isTopAdmin ? 
                <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.changeParent}>{i18n.t('admin.labels.change_parent')}</NavDropdown.Item> : null }
                {this.state.isTopAdmin ?
                <NavDropdown.Item href={void (0)} data-id={institution_id} onClick={this.extendMaxLimit}>{i18n.t('admin.labels.extend_limit')}</NavDropdown.Item> : null }
            </NavDropdown>
        );
    }
    enablePWA = (event) => {
        let id = event.target.getAttribute('data-id')
        let groupInfo = this.state.data.find((element) => {
          return element.id == id
        })
        this.callEnableDisablePWAApi(groupInfo.id, true);
    }
    disablePWA = (event) => {
        let id = event.target.getAttribute('data-id')
        let groupInfo = this.state.data.find((element) => {
            return element.id == id
        })
        this.callEnableDisablePWAApi(groupInfo.id, false);
    }
    callEnableDisablePWAApi = (group_id, is_pwa) => {
        const formData = new FormData();
        formData.append('is_pwa', is_pwa);

        API.post('/groups/'+group_id+'/enable_disable_pwa', formData)
            .then(res => {
            window.location.reload()
            })
            .catch(function (error) {

            })
    }
    extendMaxLimit = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = this.state.data.find((element) => {
            return element.id == id
        })
        this.setState({current_max_user_limit: userInfo.get_seat_licenses, new_max_user_limit:userInfo.get_seat_licenses, current_edit_id: userInfo.id})
        this.showIncreaseMaxUserLimitModal();
    }
    showIncreaseMaxUserLimitModal = () => {
        this.setState({ isMaxUserLimitDialogOpened: true })
    }
    hideIncreaseMaxUserLimitModal = () => {
        this.setState({ isMaxUserLimitDialogOpened: false })
    }
    changeParent = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = this.state.data.find((element) => {
            return element.id == id
        })
        userInfo.target_groups.map((target_group) => {
            target_group["value"] = target_group.id
            let path = ''
            for(var i = target_group.breadcrumb.length -1 ; i >= 1; i--){
                if(i > 1){
                  path += target_group.breadcrumb[i].name + " -> "
                }
                else{
                  path += target_group.breadcrumb[i].name
                }
            }
            target_group["label"] = target_group.name + "\n [ "+path+" ] "
        })
        this.setState({ selected_target_groups: userInfo.target_groups, current_change_parent_edit_id: userInfo.id })
        this.showChangeParentModal();
    }
    showChangeParentModal = () => {
        this.setState({ isChangeParentOpen: true })
    }
    hideChangeParentModal = () => {
        this.setState({ isChangeParentOpen: false })
    }
    editInstitution = (event) => {
        let id = event.target.getAttribute('data-id');
        let userInfo = this.state.data.find((element) => {
            return element.id == id
        })
        this.setState({ edit_name: userInfo.name, edit_address: userInfo.address, edit_max_connect_session: userInfo.session_count, current_edit_id: userInfo.id, edit_order_number: userInfo.order_number, current_edit_order_number: userInfo.order_number})
        this.showEditModal()
    }
    showEditModal = () => {
        this.setState({ isEditOpen: true, isValidate: false,  isValid: true, hasError: false,errorMessage: [], status: null  })
    }
    hideEditModal = () => {
        this.setState({ isEditOpen: false })
    }
    hideExportModal = (event) => {
        this.setState({fromDate: '', toDate: ''})
    }
    deleteInstitution = (event) => {
        let text = i18n.t('admin.distributor.confirmation_delete')
        
        const payload = event.target ? { ids: [event.target.getAttribute('data-id')] } : {ids: [event]};
        let _this = this;
        if (confirm(text) == true) {
            API.delete('/oldview/groups/delete', { data: payload })
                .then(res => {
                    window.location.reload()
                })
                .catch(function (error) {

                })

        }
        else {
            console.log("User canceled")
        }
    }
    async componentDidMount() {
        await this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, null, this.state.searchTerm)
    }

    async callApi(per_page, current_page, current_status, htmlFormat = null, searchTerm = '') {
        if (htmlFormat != 'csv')
            this.setState({ data: [], isLoading: true });


        let { data } = await API.get('/groups?status=' + current_status + '&per_page=' + per_page + '&current_page=' + current_page + '&type=' + this.state.type + '&format=' + htmlFormat + '&searchTerm=' + encodeURIComponent(searchTerm) + '&fromDate=' + this.state.fromDate + '&toDate=' + this.state.toDate + '&group=' + this.state.group_id + '&order_by=' + this.state.orderBy)

        if (htmlFormat === 'csv') {
            let a = document.createElement('a');
            a.href = 'data:text/csv,' + encodeURI(data);
            a.target = '_blank';
            a.download = 'i3Learnhub_institutions.csv';
            a.click();
            history.pushState({}, null, window.location.href);
            document.getElementById("export_inst_mdl_cls").click();
            this.hideExportModal();
            return
        }

        let finalPlans = new Array()
        await API.get('pricing/plans')
            .then(res => {
                if (!res.data || !res.data[0])
                    return
                for (var i in res.data[0]) {
                    if (i !== 'team') {
                        let plan = res.data[0][i];
                        let new_data = { value: plan.key, label: plan.display_text.includes('.') ? i18n.t(plan.display_text) : plan.display_text }
                        finalPlans.push(new_data)
                    }

                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                }
                if (error.request) {
                    console.log(error.request);
                }
                else {
                    console.log(error)
                }
            });
        this.setState({ data: data.groups, total_count: data.total_count, isLoading: false })
    }
    onTableChange = (type, newState) => {
        if(newState.sortOrder && newState.sortField){
            this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
        }
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi(newState.sizePerPage, newState.page, this.state.current_status, null, this.state.searchTerm)
    }
    onChangeStatus = (event) => {
        this.setState({ current_status: event.target.value });
    }
    toggleSideModal = (event) => {
        this.setState({ toggleUserFilter: !this.state.toggleUserFilter });
    }
    closeSideModal = (event) => {
        this.setState({ current_status: this.state.previous_status, toggleUserFilter: false });
    }
    applyFilters = (event) => {
        this.setState({ previous_status: this.state.current_status, toggleUserFilter: false, current_page: 1 });
        this.callApi(this.state.per_page, 1, this.state.current_status, null, this.searchTerm)
    }
    handleChange = event => {
        if (event.target.name === "fromDate") {
            this.setState({ toDate: '' })
        }
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleChangeParentSelect = event => {
        this.setState({
          "selected_target_group": event.id
        });
    }
    handleChangeFile = (event) => {
        this.setState({[event.target.name]: event.target.files[0]})
    }
    handleEditSubmit = (event) => {
        this.setState({ isLoading: true });
        this.submitEditInstitution()
    }
    handleChangeParentSubmit = (event) => {
        if(!this.state.selected_target_group){
            this.setState({
              isValidate: true,
              isValid: false,
              hasError: true,
              errorMessage: i18n.t('admin.errors.require_parent'),
              status: null
            });
            return;
        }
        this.setState({ isLoading: true });
        this.submitChangePrent()
    }
    handleMaxUserLimitSubmit = (event) => {
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append('max_user', this.state.new_max_user_limit);
        API.post('/groups/'+this.state.current_edit_id+'/increase_max_user', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {

        })
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };
    submitEditInstitution = () => {
        const formData = new FormData();
        formData.append('ids[]', this.state.current_edit_id)
        formData.append('name', this.state.edit_name);
        formData.append('address', this.state.edit_address);
        if (this.state.edit_max_connect_sessions)
            formData.append('session_count', this.state.edit_max_connect_sessions);
        if (this.state.current_edit_order_number !== this.state.edit_order_number)
            formData.append('order_number', this.state.edit_order_number);
        API.patch('/oldview/groups/update', formData)
            .then(res => {
                window.location.reload();

            })
            .catch(function (error) {

            })
    }
    submitChangePrent = () => {
        //console.log(document.getElementById('target-group-select').value)
        const formData = new FormData();
        formData.append('ids[]', this.state.current_change_parent_edit_id);
        formData.append('target', this.state.selected_target_group);
        API.patch('/oldview/groups/move', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {

            })
    }
    downloadInstitutionData = (event) => {
        this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, 'csv', this.state.searchTerm)
    }
    importUserData = event => {
        const url = '/invitations/import_new';
        const formData = new FormData();
        formData.append('import[file]',this.state.import_file)
        formData.append('import[group_id]', this.state.group_id)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        API.post(url, formData,config)
          .then(res => {
            window.location.reload();
          })
          .catch(function(error) { 
            alert(error.message)
          })
    }
   
    render() {
        const columns = [
        {
            dataField: 'name',
            text: i18n.t('admin.labels.name').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '20%' };
            }
        },
        {
            dataField: 'email',
            text: i18n.t('userprofile.labels.email').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        }, {
            dataField: 'country',
            text: i18n.t('userprofile.labels.country').toUpperCase(),
            sort: true
        }, {
            dataField: 'get_seat_licenses',
            text: i18n.t('admin.labels.seat_license').toUpperCase(),
            sort: false
        }, {
            dataField: 'get_subscribed_plan_name',
            text: i18n.t('admin.labels.plan').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            }

        }, {
            dataField: 'remaining_licenses',
            text: i18n.t('admin.labels.remaining_seats').toUpperCase(),
            sort: false
        }, {
            dataField: 'total_users',
            text: i18n.t('admin.labels.accounts').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '8%' };
            }
        }, {
            dataField: 'days_to_pay',
            text: i18n.t('admin.labels.days_left').toUpperCase(),
            sort: false
        }, {
            dataField: 'order_number',
            text: i18n.t('admin.labels.order_number').toUpperCase(),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: '8%' };
            }
        },{
            dataField: 'last_paid_date',
            text: i18n.t('admin.labels.last_paid_at').toUpperCase(),
            sort: true
        }, {
            dataField: 'moreOptions',
            formatter: this.institutionActions
        }];
        const { toggleUserFilter } = this.state
        const options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const defaultSorted = [{
            dataField: 'updated_at',
            order: 'desc'
        }];
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                let currentCellIndex = e.target.cellIndex;
                //let lastCellIndex = document.querySelector('table tr:last-child td:last-child').cellIndex
                if(currentCellIndex == 0){
                    window.location.href = '/admin/institution/view/' + row.id
                }
                if(currentCellIndex == 1){
                    if(row.get_user_id)
                        window.location.href = '/admin/user/view/' + row.get_user_id
                }
                // if (currentCellIndex !== lastCellIndex && currentCellIndex !== undefined) {
                //     window.location.href = '/admin/institution/view/' + row.id
                // }
            }
        }
        
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        }
        return (
            <div className={this.state.isFromSearch ? "overflow-hidden" : "overflow-hidden h-100"}>
                {!this.state.isFromSearch ? 
                <React.Fragment>
                <div className={this.state.group_id ? "startscreen users-main-wrapper no-gradient" : "startscreen users-main-wrapper"}>
                    <div className="start-content">
                        <div className="admin-custom-table-header">
                            <button data-bs-toggle="modal" data-bs-target="#addUserModalteam" className="btn btn-primary mb-3 mt-2 btn-add-user">{i18n.t('admin.institution.btn_add')}</button>
                            <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
                                <span className="lh-icon-os-filter filter-icon-users mt-2" onClick={this.toggleSideModal}></span>
                                <span data-bs-toggle="modal" data-bs-target="#exportModalTeam" className="lh-icon-os-export filter-icon-users mt-2"></span>
                                <span className="lh-icon-os-import filter-icon-users mt-2" data-bs-toggle="modal" data-bs-target="#importModalTeam"></span>
                            </div>
                        </div>
                        <div className="institution-table">
                            <BootstrapTable
                                keyField='id'
                                options={options}
                                data={this.state.data}
                                fetchInfo={{ dataTotalSize: this.state.total_count }}
                                columns={columns}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                noDataIndication={() => <NoDataIndication />}
                                remote
                                pagination={paginationFactory(paginatorOptions)}
                                onTableChange={this.onTableChange}
                                rowEvents={rowEvents}
                            />
                        </div>
                    </div>
                </div>
                </React.Fragment> : null}
                {/* User Table Filter */}
                <div className="sidebar-container user-filter-sidebar" style={{ display: (toggleUserFilter ? 'block' : 'none') }}>
                    <div className="right-sidebar">
                        <div className="sidebar-header bg-primary">
                            <a href={void (0)} className="close-btn" onClick={this.closeSideModal}><span className="lh-icon-os-close"></span> </a>
                            <h1 className="sb-head-title">{i18n.t('admin.labels.filter')}</h1>
                        </div>
                        <div className="sidebar-body">
                            <div className="users-filter-body">
                                <div>
                                    <h5>{i18n.t('admin.labels.by_status')}</h5>
                                    <div className="custom-control-lg custom-radio mt-3 pt-2 pb-2">
                                        <input type="radio" name="status-filter_institute" checked={this.state.current_status == 'all'} className="custom-control-input" id="all_team" value="all" onChange={this.onChangeStatus} />
                                        <label className="custom-control-label pl-2" htmlFor="all_team">{i18n.t('admin.labels.all')}</label>
                                    </div>
                                    <div className="custom-control-lg custom-radio pt-2 pb-2">
                                        <input type="radio" name="status-filter_institute" checked={this.state.current_status == 'active'} className="custom-control-input" id="active_team" value="active" onChange={this.onChangeStatus} />
                                        <label className="custom-control-label pl-2" htmlFor="active_team">{i18n.t('admin.labels.active')}</label>
                                    </div>
                                    <div className="custom-control-lg custom-radio pt-2 pb-2">
                                        <input type="radio" name="status-filter_institute" checked={this.state.current_status == 'expired'} className="custom-control-input" id="expired_team" value="expired" onChange={this.onChangeStatus} />
                                        <label className="custom-control-label pl-2" htmlFor="expired_team">{i18n.t('admin.labels.expired')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar-footer">
                            <div className="footer-action-panel justify-content-end">
                                <button className="btn btn-outline-tertiary mt-2" onClick={this.closeSideModal} >{i18n.t('userprofile.btn_cancel')}</button>
                                <button onClick={this.applyFilters} style={{ width: '6rem' }} className="btn btn-accenta ml-2">{i18n.t('admin.btn_apply')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <NewInvite groupId={this.state.group_id} type="team" is_top_admin={this.props.is_top_admin} is_reseller={this.props.is_reseller}/>

                {/* Edit Institution Modal */}
                <div>
                    <Modal show={this.state.isEditOpen}>
                        <div className="modal-content">
                            <ValidatorForm
                                id="editInstituteForm"
                                ref="form"
                                onSubmit={this.handleEditSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.institution.edit_modal.title')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideEditModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label text-primary col-sm-4">
                                                <span>{i18n.t('admin.labels.name')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_name"
                                                    placeholder= {i18n.t('admin.placeholder.name')}
                                                    value={this.state.edit_name}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t("userprofile.errors.company_name_required")
                                                    ]}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="user-label text-primary col-sm-4">
                                                <span>{i18n.t("userprofile.labels.address")}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextareaValidator
                                                    tabIndex='4'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t("userprofile.placeholder.address")}
                                                    name="edit_address"
                                                    value={this.state.edit_address}
                                                    noValidate
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="user-label text-primary col-sm-4">
                                                <span>{i18n.t('admin.labels.max_conntect_session')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <input placeholder={i18n.t('admin.placeholder.max_conntect_session')} name="edit_max_connect_sessions" value={this.state.edit_max_connect_sessions} onChange={this.handleChange} type="number" className="form-control mt-2 mb-4" />
                                            </div>
                                            <div className="user-label text-primary col-sm-4 pt-2">
                                                <span>{i18n.t('admin.labels.order_number')}</span>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <input placeholder={i18n.t('admin.placeholder.order_number')}tabIndex="6" onPaste={this.onPasteHandle} onChange={this.handleChange} value={this.state.edit_order_number} name="edit_order_number" type="text" className="form-control " />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideEditModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta">{i18n.t('admin.btn_ok')}</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </Modal>
                </div>

                {/* Change Parent */}
                <div>
                    <Modal show={this.state.isChangeParentOpen}>
                        <div className="modal-content">
                            <ValidatorForm
                                id="changeResellerParentForm"
                                ref="form"
                                onSubmit={this.handleChangeParentSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.change_parent_modal.title')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideChangeParentModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="col-sm-12 pt-3">
                                                <SelectValidator
                                                    tabIndex='5'
                                                    autoComplete="off"
                                                    placeholder={i18n.t('admin.placeholder.move_parent')}
                                                    name="selected_target_group"
                                                    options={this.state.selected_target_groups}
                                                    onChange={this.handleChangeParentSelect}
                                                    isSearchable={true}
                                                />
                                                {/* <Select tabIndex='5' name="selected_target_group" onChange={this.handleChangeParentSelect}  isSearchable={true} options={this.state.selected_target_groups} required></Select> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideChangeParentModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta">{i18n.t('admin.btn_ok')}</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </Modal>
                </div>
                
                {/* Increae Max Users Limit */}
                <div>
                    <Modal show={this.state.isMaxUserLimitDialogOpened}>
                        <div className="modal-content">
                            <ValidatorForm
                                id="increaseMaxUserLimitForm"
                                ref="form"
                                onSubmit={this.handleMaxUserLimitSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.labels.extend_limit')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideIncreaseMaxUserLimitModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label text-primary col-sm-4">
                                                <span>{i18n.t('admin.labels.max_user')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    type="number"
                                                    min={this.state.current_max_user_limit}
                                                    name="new_max_user_limit"
                                                    placeholder= {i18n.t('admin.placeholder.max_user')}
                                                    value={this.state.new_max_user_limit}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required", "isLimitExtend"]}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_max_user"),
                                                        i18n.t("admin.errors.max_limit_should_increase")
                                                    ]}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideIncreaseMaxUserLimitModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta">{i18n.t('admin.btn_ok')}</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </Modal>
                </div>
                
                {/* Export Modal */}
                <div className="modal fade" id="exportModalTeam" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="exportModalForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.export_modal.title')}</h4>
                                    </div>
                                    <button id="export_inst_mdl_cls" type="button" className="close" data-bs-dismiss="modal" onClick={this.hideExportModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="page-header export-header">
                                        <span className="text-primary">{i18n.t('admin.export_modal.message')}</span>
                                    </div>
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-3">
                                                <span>{i18n.t('admin.labels.from')}</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input value={moment(this.state.fromDate).format("YYYY-MM-DD")} name="fromDate" type="date" className="form-control mt-2 mb-4" onChange={this.handleChange} max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                                            </div>
                                            <div className="user-label col-sm-3">
                                                <span>{i18n.t('admin.labels.to')}</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input value={this.state.toDate ? moment(this.state.toDate).format("YYYY-MM-DD") : this.state.toDate} min={moment(this.state.fromDate).format("YYYY-MM-DD")} onChange={this.handleChange} name="toDate" type="date" className="form-control mt-2 mb-4" max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideExportModal} data-bs-dismiss="modal">{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="button" className="btn btn-lg btn-accenta" onClick={this.downloadInstitutionData}>{i18n.t('admin.btn_export')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* Import Modal */}
                <div className="modal fade" id="importModalTeam" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <form id="userImportForm">
                            <div className="modal-header modal-image-header">
                            <div className="modal-header-content">
                                <h4 className="modal-title">{i18n.t('admin.import_modal.title')}</h4>
                            </div>
                            <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                            </div>
                            <div className="modal-body">
                            <div className="middle-sec">
                                <span className="import-header">{i18n.t('admin.import_modal.sub_title')}</span>
                                <a href="/user_import.csv" className="temp-download">{i18n.t('admin.import_modal.link_download')}</a>
                                <div className="pt-2 pb-3">
                                    <ul className="import-points">
                                        <li>{i18n.t('admin.import_modal.points.1')}</li>
                                        <li>{i18n.t('admin.import_modal.points.2')}</li>
                                        <li>{i18n.t('admin.import_modal.points.3')}</li>
                                        <li>{i18n.t('admin.import_modal.points.4')}</li>
                                        <li>{i18n.t('admin.import_modal.points.5')}
                                        <ul className="inner-points">
                                            <li>{i18n.t('admin.import_modal.points.5_1')}</li>
                                            <li>{i18n.t('admin.import_modal.points.5_2')}</li>
                                        </ul>
                                        </li>
                                        <li>{i18n.t('admin.import_modal.points.6')}</li>
                                        <li>{i18n.t('admin.import_modal.points.7')}</li>
                                    </ul>
                                </div>
                                <div className="col-md-8 input-group mb-3">
                                    <div className="custom-file">
                                    <input required="required" name="import_file" type="file" className=" custom-file-input" accept=".csv,text/csv" id="inputUserFile" onChange={this.handleChangeFile}/>
                                    <label className="custom-file-label" htmlFor="inputUserFile">{ this.state.import_file ? this.state.import_file.name : i18n.t('userprofile.choose_file') } </label>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-accenta" onClick={this.importUserData}>{i18n.t('admin.import_modal.title')}</button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Institutions)