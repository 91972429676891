export const StepTypes = {
  signup: 'signup',
  login: 'login',
  forgotpassword: 'forgotpassword',
  signup_confirmemail: 'signup_confirmemail',
  signup_success: 'signup_success',
  signup_select_package: 'signup_select_package',
  signup_select_payment: 'signup_select_payment',
  signup_confirm_payment: 'signup_confirm_payment',
  signup_fail: 'signup_fail',
  forgotpassword_choosenewpassword: "forgotpassword_choosenewpassword",
  accept_decline_invitation: "accept_decline_invitation",
  forgotpassword_confirmemail:"forgotpassword_confirmemail",
  user_profile: 'user_profile',
  forgotpassword_fail: 'forgotpassword_fail',
  connect_session: 'connect_session',
  tos: 'tos',
  pricing: 'pricing',
  packsignup: 'packsignup',
  packsignup_signup: 'packsignup_signup',
  packsignup_confirmemail: 'packsignup_confirmemail',
  packsignup_activate: "packsignup_activate",
  packsignup_success: "packsignup_success", 
  packsignup_fail: "packsignup_fail",
  ppdspack_welcome: 'ppdspack_welcom',
  content_vle: 'content_vle',
  approve_tos: 'accepttos'
}


export function  getStepByPathname (pathname) {

  if (typeof pathname === "undefined") {
    console.log("location was null");
    return;
  }

  let cleanPath = pathname.replace(/\/$/, "");

  if (cleanPath.endsWith('/login'))
  {
    return StepTypes.login;
  }
  else if (cleanPath.endsWith('/signup'))
  {
    return StepTypes.signup;
  }
  else if (cleanPath.endsWith('/forgotpassword'))
  {
    return StepTypes.forgotpassword;
  }
  else if (cleanPath.endsWith('/signup/confirmemail'))
  {
    return StepTypes.signup_confirmemail;
  }
  else if (cleanPath.endsWith('/signup/success'))
  {
    return StepTypes.signup_success;
  }
  else if (cleanPath.includes('/signup/selectpackage/'))
  {
    return StepTypes.signup_select_package;
  }
  else if (cleanPath.includes('/signup/paymentmethod/'))
  {
    return StepTypes.signup_select_payment;
  }
  else if (cleanPath.includes('/signup/payment/'))
  {
    return StepTypes.signup_confirm_payment;
  }
  else if (cleanPath.includes('/signup/fail'))
  {
    return StepTypes.signup_fail;
  }
  else if (cleanPath.endsWith('/forgotpassword/confirmemail')){
    return StepTypes.forgotpassword_confirmemail;
  }
  else if(cleanPath.includes('/forgotpassword/choosenewpassword'))
  {
    return StepTypes.forgotpassword_choosenewpassword;
  }
  else if(cleanPath.includes('/invitations/accept'))
  {
    return StepTypes.accept_decline_invitation;
  }
  else if(cleanPath.includes('/tos'))
  {
    return StepTypes.tos;
  }
  else if(cleanPath.includes('/pricing')){
    return StepTypes.pricing; 
  }
  else if(cleanPath.includes('/users/profile'))
  {
    return StepTypes.user_profile;
  } else if (cleanPath.includes('/forgotpassword/fail') || cleanPath.includes('/users/confirm_sign_up')) {
    return StepTypes.forgotpassword_fail;
  } else if (cleanPath.includes('/start')) {
    return StepTypes.connect_session;
  }
  else if (cleanPath.includes('/register'))
  {
    return StepTypes.packsignup_signup;
  }
  else if (cleanPath.endsWith('packsignup/confirmemail'))
  {
    return StepTypes.packsignup_confirmemail;
  }
  else if (cleanPath.includes('/activate'))
  {
    return StepTypes.packsignup_activate;
  }
  else if (cleanPath.endsWith('packsignup/success'))
  {
    return StepTypes.packsignup_success;
  }
  else if (cleanPath.endsWith('packsignup/fail'))
  {
    return StepTypes.packsignup_fail;
  }
  else if (cleanPath.includes('/packsignup'))
  {
    return StepTypes.packsignup;
  }
  else if (cleanPath.includes('/ppdsregisterpack'))
  {
    return StepTypes.ppdspack_welcome;
  }
  else if (cleanPath.includes('/content_vle'))
  {
    return StepTypes.content_vle;
  }
  else if (cleanPath.includes('/accepttos'))
  {
      return StepTypes.approve_tos;
  }
  return cleanPath;
}
