import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import API from '../api';
import { NavDropdown } from 'react-bootstrap';
import { Loading_IMG } from "../Common/assets_paths";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import SelectValidator from "../Common/SelectValidator";
import TextValidator from "../Common/TextValidator";
import CheckboxValidator from '../Common/CheckboxValidator';
import Errors from "../Common/Errors/Errors";
import countryList from 'react-select-country-list';
import { getAvailableLanguages } from "../Common/i3library";
import TextareaValidator from "../Common/TextareaValidator";
import Users from "./Users";
import Invitations from "./Invitations";
import Modal from "react-bootstrap/Modal";
import { getErrorMessage } from "../Common/Helpers/AppHelper";

class ViewInstitute extends React.Component {
    constructor(props) {
        super(props);
        let path_array = props.location.pathname.split("/institution/view/")
        const countryOptions = countryList().getData();
        //ILEARN-4927 Tiwan Name Update 
        let taiwan_item = countryOptions.find(item => item.value == 'TW');
        taiwan_item.label = i18n.t("userprofile.labels.tiwan_label");
        let tiwan_index = countryOptions.findIndex(item => item.value == 'TW');
        countryOptions[tiwan_index] = taiwan_item;
        //ILEARN-4927 Tiwan Name Update End
        const languagesOptions = getAvailableLanguages();
        this.state = {
            current_institute_id: parseInt(path_array[path_array.length - 1]),
            instituteInfo: {},
            subscriptions: [],
            users: [],
            invitations: [],
            total_count_invitations: [],
            active_subscription: {},
            base_price: 0.0,
            upgrade_plan: '',
            institution_code: '',
            institution: '',
            isValid: false,
            hasError: false,
            errorMessage: "",
            isValidate: false,
            latest_subscription: '',
            latest_subscription_id: null,
            plans: [],
            can_extend: false,
            can_renew: false,
            can_wire: false,
            is_active: false,
            number_of_years_ext: 1,
            isQA: false,
            countryOpt: countryOptions,
            languageOpt: languagesOptions,
            country: 'IN',
            language: 'en',
            accepted_tos: '',
            accepted_privacy_policy: '',
            tos_accept_date: '',
            current_login_at: '',
            isAllowEmailConfigrator: false,
            per_page: 10,
            current_page: 1,
            net_amount_paid: 0.0,
            cancel_reason: '',
            isLoading: false,
            isTopAdmin: props.is_top_admin || false,
            current_payment_cancel_id: '',
            cancel_reason_payment: '',
            isCancelPaymentOpen: false,
            breadcrumb: [],
            max_users: 0,
            group_all_email_providers: [],
            group_email_providers: [],
            email_provider_status: false,
            selected_email_provider: '',
            email_suffix: '',
            isEditEmailProviderOpen: false,
            edit_selected_email_provider: -1,
            edit_email_provider_status: false,
            edit_email_suffix: '',
            current_edit_email_provider_id: null
        }
        if (!props.is_top_admin) {
            document.body.classList.add('no-topadmin')
        }
        else {
            document.body.classList.remove('no-topadmin')
        }
    }
    routeBackToUsers = (ev) => {
        //window.history.back(); 
        window.location.href = '/admin/institutions/paid'
    }
    UNSAFE_componentWillMount() {
        this.getInstitueDetails(this.state.per_page, this.state.current_page)
    }

    async getInstitueDetails(per_page, current_page) {
        await API.get('/groups/' + this.state.current_institute_id + '?current_page=' + current_page + '&per_page=' + per_page)
            .then(res => {
                if (res.data) {
                    let finalPlans = new Array()
                    for (var _plan in res.data.upgradable_plans) {
                        let plan = res.data.upgradable_plans[_plan];
                        let new_data = { value: plan[1], label: plan[0].includes('.') ? i18n.t(plan[0]) : plan[0] }
                        finalPlans.push(new_data)
                    }
                    document.getElementById("ext_sub_amount").value = parseFloat(res.data.base_price) * parseInt(this.state.number_of_years_ext)
                    document.getElementById("ext_sub_amount_convert").value = parseFloat(res.data.base_price) * parseInt(this.state.number_of_years_ext)
                    if (res.data.isAllowEmailConfigrator) {
                        let group_email_provider = [];
                        res.data.group_email_providers.forEach((email_provider) => {
                            let new_data = { value: email_provider.id, label: email_provider.name}
                            group_email_provider.push(new_data)
                        })
                        this.setState({
                            instituteInfo: res.data.institution,
                            subscriptions: res.data.subscriptions,
                            users: res.data.users,
                            isAllowEmailConfigrator: res.data.isAllowEmailConfigrator, group_all_email_providers: res.data.group_all_email_providers, group_email_providers: group_email_provider,
                            active_subscription: res.data.current_plan,
                            base_price: res.data.base_price,
                            can_extend: res.data.can_extend,
                            can_renew: res.data.can_renew,
                            can_wire: res.data.can_wire_transfer,
                            is_active: res.data.is_active,
                            latest_subscription: res.data.latest_subscription,
                            plans: finalPlans,
                            total_count_users: res.data.total_users_count,
                            invitations: res.data.invitations,
                            total_count_invitations: res.data.total_invitations_count,
                            latest_subscription_id: res.data.latest_subscription_id,
                            max_users: res.data.max_users || 0,
                            isLoading: false,
                            breadcrumb: res.data.institution.breadcrumb ? res.data.institution.breadcrumb.reverse() : []
                        })
                    }
                    else {
                        this.setState({
                            instituteInfo: res.data.institution,
                            subscriptions: res.data.subscriptions,
                            users: res.data.users,
                            isAllowEmailConfigrator: res.data.isAllowEmailConfigrator,
                            active_subscription: res.data.current_plan,
                            base_price: res.data.base_price,
                            can_extend: res.data.can_extend,
                            can_renew: res.data.can_renew,
                            can_wire: res.data.can_wire_transfer,
                            is_active: res.data.is_active,
                            latest_subscription: res.data.latest_subscription,
                            plans: finalPlans,
                            total_count_users: res.data.total_users_count,
                            invitations: res.data.invitations || [],
                            total_count_invitations: res.data.total_invitations_count || 0,
                            latest_subscription_id: res.data.latest_subscription_id,
                            isLoading: false,
                            breadcrumb: res.data.institution.breadcrumb ? res.data.institution.breadcrumb.reverse() : [],
                            max_users: res.data.max_users || 0,
                        })
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                }
                if (error.request) {
                    console.log(error.request);
                }
                else {
                    console.log(error)
                }
            });
    }

    handleSubmit = event => {
        if (!this.state.shouldBlockNavigation) {
            return;
        }
        this.setState({ isLoading: true });

        let groupId = this.state.instituteInfo.id;
        let plan = this.state.upgrade_plan;
        let number_of_years = this.state.number_of_years || 1;

        const formData = new FormData();
        formData.append('group_id', groupId)
        formData.append('plan', plan);
        formData.append('number_of_years', number_of_years);
        // if(this.state.upgrade_plan.includes('team')){
        //     formData.append('institution',this.state.institution);
        //     formData.append('institution_code',this.state.institution_code);
        // }
        API.post('/subscriptions/change_plan', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })

    }
    handleSubmitExtend = event => {
        let subscription_id = this.state.latest_subscription_id;
        let groupId = this.state.instituteInfo.id;
        let number_of_years = parseInt(this.state.number_of_years_ext) || 1;
        if (!number_of_years) {
            alert(i18n.t('admin.errors.require_number_of_year'))
            return;
        }

        let paid_amount = this.state.net_amount_paid;
        const formData = new FormData();
        formData.append('group_id', groupId);
        formData.append('subscription_id', subscription_id);
        formData.append('number_of_years', number_of_years);
        formData.append('paid_amount', paid_amount);

        API.post('/oldview/subscriptions/extend', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }

    handleSubmitRenew = event => {
        let subscription_id = this.state.latest_subscription_id;
        let groupId = this.state.instituteInfo.id;
        let number_of_years = parseInt(this.state.number_of_years_renw) || 1;
        if (!number_of_years) {
            alert(i18n.t('admin.errors.require_number_of_year'))
            return;
        }

        let paid_amount = this.state.net_amount_paid;

        const formData = new FormData();
        formData.append('group_id', groupId);
        formData.append('subscription_id', subscription_id);
        formData.append('number_of_years', number_of_years);
        formData.append('paid_amount', paid_amount);

        API.post('/oldview/subscriptions/renew', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }
    handleSubmitConvert = event => {
        let subscription_id = this.state.latest_subscription_id;
        let groupId = this.state.instituteInfo.id;
        let number_of_years = parseInt(this.state.number_of_years_ext) || 1;
        if (!number_of_years) {
            alert(i18n.t('admin.errors.require_number_of_year'))
            return;
        }
        let paid_amount = this.state.net_amount_paid;

        const formData = new FormData();
        formData.append('group_id', groupId);
        formData.append('subscription_id', subscription_id);
        formData.append('number_of_years', number_of_years);
        formData.append('paid_amount', paid_amount);

        API.post('/subscriptions/convert_to_wire_transfer', formData)
        .then(res => {
            window.location.reload();
        })
        .catch(function (error) {
        })
    }
    handleSubmitCancel = event => {
        let subscription_id = this.state.latest_subscription_id;
        let groupId = this.state.instituteInfo.id;
        let reason = this.state.cancel_reason;

        const formData = new FormData();
        formData.append('group_id', groupId);
        formData.append('subscription_id', subscription_id);
        formData.append('reason', reason);

        API.patch('/oldview/subscriptions/cancel', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    }
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        if (event.target.name === "number_of_years") {
            if (event.target.value < 1) {
                event.preventDefault();
                return
            }
            if (event.target.value > 5) {
                event.preventDefault();
                return
            }
        }

        if (event.target.name === "number_of_years_ext") {
            if (event.target.value < 1) {
                event.preventDefault();
                return
            }
            if (event.target.value > 5) {
                event.preventDefault();
                return
            }
            const calcPrice = parseFloat(this.state.base_price) * parseInt(event.target.value)
            this.setState({ 'net_amount_paid': calcPrice })
            document.getElementById("ext_sub_amount").value = calcPrice
            document.getElementById("ext_sub_amount_convert").value = calcPrice 
            //parseFloat(res.data.base_price) * parseInt(this.state.number_of_years_ext)
        }
        if (event.target.name === "number_of_years_renw") {
            if (event.target.value < 1) {
                event.preventDefault();
                return
            }
            if (event.target.value > 5) {
                event.preventDefault();
                return
            }
            const calcPrice = parseFloat(this.state.base_price) * parseInt(event.target.value)
            this.setState({ 'net_amount_paid': calcPrice })
            document.getElementById("renw_sub_amount").value = calcPrice
        }
        console.log(event.target.name, event.target.value)
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    };
    approvePayment = (e) => {
        let groupId = this.state.instituteInfo.id;
        let paymentId = event.target.getAttribute('data-id');
        API.get('/oldview/user_payments/' + paymentId + '/approve?group_id=' + groupId)
            .then(() => {
                window.location.reload()
            })
            .catch((error) => {

            })
    }
    showCancelPaymentModal = () => {
        this.setState({ isCancelPaymentOpen: true })
    }
    hideCancelPaymentModal = () => {
        this.setState({ isCancelPaymentOpen: false })
    }
    cancelPayment = (e) => {
        let paymentId = event.target.getAttribute('data-id');
        this.setState({ current_payment_cancel_id: paymentId })
        this.showCancelPaymentModal();

    }
    handleSubmitCancelPayment = event => {
        if (!this.state.shouldBlockNavigation) {
            return;
        }
        this.setState({ isLoading: true });
        const formData = new FormData();

        formData.append('up_id', this.state.current_payment_cancel_id);
        formData.append('group_id', this.state.instituteInfo.id);
        formData.append('reason', this.state.cancel_reason_payment);

        API.patch('/oldview/user_payments/cancel', formData)
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {

            })
    }
    rankFormatterTransection = (cell, row) => {
        if (!this.state.isTopAdmin) {
            return (null)
        }
        let payment_id = row.id;
        let isPending = row["pending?"];
        if (isPending) {
            return (
                <div>
                    <span className="highlightText approve" data-id={payment_id} onClick={this.approvePayment}>{i18n.t('admin.labels.approve')}</span><br />
                    <span className="highlightText cancel" data-id={payment_id} onClick={this.cancelPayment}>{i18n.t('userprofile.btn_cancel')}</span>
                </div>
            )
        }
    }
    groupEmailOptions = (cell, row) => {
        let email_id = row.id
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void (0)} data-id={email_id} onClick={this.editEmailProvider}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
                <NavDropdown.Item href={void (0)} data-id={email_id} onClick={this.deleteEmailProvider}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
            </NavDropdown>
        );
    }
    editEmailProvider = (event) => {
        let id = event.target.getAttribute('data-id');
        let edit_email_provider_info = this.state.group_all_email_providers.find((element) => {
            return element.id == id
        })

        this.setState({ edit_email_suffix: edit_email_provider_info.email_suffix, edit_selected_email_provider: edit_email_provider_info.group_email_provider_id,
        edit_email_provider_status: edit_email_provider_info.status, current_edit_email_provider_id: edit_email_provider_info.id})

        this.showEditEmailProvider();
    }
    deleteEmailProvider = (event) => {
        let id = event.target.getAttribute('data-id');
        API.delete('/group_email_provider_settings/'+id)
        .then(() => {
            window.location.reload();
        })
        .catch((error) => {

        })
    }
    handleEmailProviderSubmit = (event) => {
        //group_email_provider_settings
        const formData = new FormData();
        formData.append('provider', this.state.selected_email_provider);
        formData.append('group_id', this.state.instituteInfo.id);
        formData.append('email_suffix', this.state.email_suffix);
        formData.append('status', this.state.email_provider_status);
        API.post('/group_email_provider_settings', formData)
        .then(() => {
            window.location.reload();
        })
        .catch((error) => {

        })
    }
    handleEditEmailProviderSubmit = (event) => {
        const formData = new FormData();
        formData.append('email_suffix', this.state.edit_email_suffix);
        formData.append('status', this.state.edit_email_provider_status);
        API.put('/group_email_provider_settings/'+this.state.current_edit_email_provider_id, formData)
        .then(() => {
            window.location.reload();
        })
        .catch((error) => {

        })
    }
    hideEditEmailProvider = (event) => {
        this.setState({isEditEmailProviderOpen: false})
    }
    showEditEmailProvider = (event) => {
        this.setState({isEditEmailProviderOpen: true})
    }
    enablePWA = (event) => {
        this.callEnableDisablePWAApi(this.state.instituteInfo.id, true);
    }
    disablePWA = (event) => {
        this.callEnableDisablePWAApi(this.state.instituteInfo.id, false);
    }
    callEnableDisablePWAApi = (group_id, is_pwa) => {
        const formData = new FormData();
        formData.append('is_pwa', is_pwa);

        API.post('/groups/'+group_id+'/enable_disable_pwa', formData)
            .then(res => {
            window.location.reload()
            })
            .catch(function (error) {

            })
    }
    render() {
        const columns = [{
            dataField: 'fetch_transaction_id',
            text: i18n.t('admin.labels.transaction_id').toUpperCase(),
            sort: true
        }, {
            dataField: 'paid_date',
            text: i18n.t('admin.labels.paid_date').toUpperCase(),
            sort: true
        }, {
            dataField: 'paid_amount',
            text: i18n.t('admin.labels.paid_amount').toUpperCase(),
            sort: true
        }, {
            dataField: 'process_by',
            text: i18n.t('admin.labels.process_by').toUpperCase(),
            sort: true
        }, {
            dataField: 'payment_status_title',
            text: i18n.t('admin.labels.payment_status').toUpperCase(),
            sort: true
        }, {
            dataField: 'actions',
            formatter: this.rankFormatterTransection,
        }];

        const defaultSorted = [{
            dataField: 'name',
            order: 'desc'
        }];
        const paginatorOptions = {
            pageStartIndex: 1,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        };
        const SubscriptionAccordian = (props) => {
            const subscr = props.subscr;
            return (
                <div className="accordion-panel">
                    <div className="panel-header" id={"heading-" + subscr.id}>
                        <a role="button" href={"#view-user" + subscr.id} data-bs-toggle="collapse" data-bs-target={"#view-user" + subscr.id} aria-expanded="true" aria-controls={"view-user" + subscr.id}>
                            <span className="title-text">{subscr.plan}</span>
                        </a>
                    </div>
                    <div id={"view-user" + subscr.id} className="panel-body panel-collapse collapse show" data-bs-parent="#accordion">
                        <span className="subscription-title">{i18n.t('admin.labels.subscription_detail')}</span>
                        <div className="col-md-12 mt-3 subscription-detail">
                            <div className="mb-3 ml-3">
                                <label htmlFor="Subscription" className="form-label">{i18n.t('admin.labels.subscription_id')}</label>
                                <div className="form-text">{subscr.id}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Days" className="form-label">{i18n.t('admin.labels.remaining_days')}</label>
                                <div className="form-text">{subscr.remaining_days}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Payment" className="form-label">{i18n.t('admin.labels.payment_method')}</label>
                                <div className="form-text">{subscr.payment_method_title}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Valid" className="form-label">{i18n.t('admin.labels.valid_until')}</label>
                                <div className="form-text">{subscr.valid_until}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Payment" className="form-label">{i18n.t('admin.labels.payment_cycle')}</label>
                                <div className="form-text">{subscr.payment_cycle}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Users" className="form-label">{i18n.t('admin.labels.max_user')}</label>
                                <div className="form-text">{subscr.max_users}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Status" className="form-label">{i18n.t('admin.labels.status')}</label>
                                <div className="form-text">{subscr.fetch_status}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Last" className="form-label">{i18n.t('admin.labels.last_paid_at')}</label>
                                <div className="form-text">{subscr.last_paid_date}</div>
                            </div>
                            <div className="mb-3 ml-3">
                                <label htmlFor="Next" className="form-label">{i18n.t('admin.labels.next_pay_date')}</label>
                                <div className="form-text">{subscr.next_pay_date}</div>
                            </div>
                        </div>
                        <span className="subscription-title mt-1">{i18n.t('admin.labels.transactions')}</span>
                        <div className="mt-3 transactions-table">
                            <BootstrapTable
                                keyField='id'
                                data={subscr.user_payments_details}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                noDataIndication={() => <NoDataIndication />}
                                bordered={false}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        const SubscriptionAccordians = () => {
            let accordians = []
            this.state.subscriptions.forEach((subscr) => {
                accordians.push(<SubscriptionAccordian key={subscr.id} subscr={subscr} />)
            })
            return (
                <div id="accordion" class={this.state.isTopAdmin ? '' : 'noheader'}>
                    {accordians}
                </div>
            )
        }

        let isInstitutionTextEditable = !(this.state.active_subscription && this.state.active_subscription.key && this.state.active_subscription.key.includes('team')) ? true : false;
        let isTeamPlanSelected = this.state.upgrade_plan && this.state.upgrade_plan.includes('team')
        const { name, institution_code } = this.state.instituteInfo
        const clmgap = {
            columnGap: '5px'
        }
        const groupEmailColumns = [{
            dataField: 'email_provider_name',
            text: 'Email Provider'.toUpperCase(),
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        },{
            dataField: 'email_suffix',
            text: 'Email Suffix'.toUpperCase(),
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        },{
            dataField: 'status',
            text: 'Status'.toUpperCase(),
            headerStyle: (colum, colIndex) => {
                return { width: '25%' };
            }
        },{
            dataField: 'moreOptions',
            formatter: this.groupEmailOptions
        }]
        
        return (
            <div className="overflow-hidden h-100 view-user-page">
                <div className="row p-0 pl-5 institute-info" style={clmgap}>
                    <span>{i18n.t('admin.labels.total_number_of_license')} : <b>{this.state.max_users}</b>,</span>
                    <span>{i18n.t('admin.labels.remaining_number_of_license')} : <b>{parseInt(this.state.max_users) - parseInt(this.state.total_count_users)}</b>,</span>
                    <span>{i18n.t('admin.labels.number_of_active_invitation')} : <b>{this.state.total_count_invitations}</b>,</span>
                    <span>{i18n.t('admin.labels.number_of_total_user')} : <b>{this.state.total_count_users}</b></span>
                </div>
                <div className="row p-1">
                    {this.state.isTopAdmin ? <div className="col-md-12">
                        <div className="view-user-back">
                            <span className="back-icon lh-icon-os-back" onClick={this.routeBackToUsers}>
                            </span>&nbsp;
                            <p>
                                {
                                    this.state.breadcrumb.map((path, index) => (
                                        <span>
                                            <a href={path.path}>{path.name}
                                            </a>
                                            {index != (this.state.breadcrumb.length - 1) ? <span> &gt; </span> : null

                                            }
                                        </span>
                                    ))
                                }
                            </p>
                        </div>
                    </div> : null
                    }
                </div>
                <div className="tab-bar">
                    <input id="tab1" type="radio" name="tab-selector" defaultChecked="checked" />
                    <input id="tab2" type="radio" name="tab-selector" />
                    <input id="tab3" type="radio" name="tab-selector" />
                    {this.state.isTopAdmin ? <input id="tab4" type="radio" name="tab-selector" /> : null }
                    <nav>
                        <ul className="tab-divider">
                            <li className="tab-label-1">
                                <label htmlFor="tab1"><span className="">{i18n.t('admin.labels.tab_subscription')}</span></label>
                            </li>
                            <li className="tab-label-2">
                                <label htmlFor="tab2"><span className=""></span>{i18n.t('admin.nav_menu.user.title')}</label>
                            </li>
                            <li className="tab-label-3">
                                <label htmlFor="tab3"><span className=""></span>{i18n.t('admin.nav_menu.invitation.title')}</label>
                            </li>
                            {this.state.isTopAdmin ?
                            <li className="tab-label-4 width-20">
                                <label htmlFor="tab4"><span className="">{i18n.t('admin.labels.tab_auto_team_signup')}</span></label>
                            </li> : null }
                        </ul>
                    </nav>
                    <section>
                        <div className="tab-content tab-1" style={{ padding: 0 }}>
                            {this.state.isTopAdmin ?
                                <div className="subscription-header" style={{ paddingBottom: '10px' }}>
                                    <button data-bs-toggle="modal" data-bs-target="#upgradeSubscription" className="btn btn-lg btn-primary btn-block mb-2 ml-4 subscription-action-btn">{i18n.t('admin.btn_change_plan')}</button>
                                    {
                                        this.state.latest_subscription ?
                                            <React.Fragment>
                                                {
                                                    this.state.is_active ? <button data-bs-toggle="modal" data-bs-target="#cancelSubscription" className="btn btn-lg btn-primary btn-block mb-2 ml-4  subscription-action-btn">{i18n.t('userprofile.btn_cancel')}</button> : null
                                                }
                                                {
                                                    this.state.can_extend ? <button data-bs-toggle="modal" data-bs-target="#extendSubscription" className="btn extend-btn btn-lg btn-primary btn-block mb-2 ml-4 subscription-action-btn">{i18n.t('admin.btn_extend')}</button> : null
                                                }
                                                {
                                                    this.state.can_renew ? <button data-bs-toggle="modal" data-bs-target="#renewSubscription" className="btn btn-lg btn-primary btn-block mb-2 ml-4 subscription-action-btn">{i18n.t('admin.btn_renew')}</button> : null
                                                }
                                                {
                                                    this.state.can_wire ? <button data-bs-toggle="modal" data-bs-target="#convertSubscription" className="btn btn-lg btn-primary btn-block mb-2 ml-4 subscription-action-btn">{i18n.t('admin.btn_change_payment_method')}</button> : null
                                                }
                                                {
                                                     this.props.is_top_admin && this.state.instituteInfo.is_pwa ? 
                                                     <button onClick={this.disablePWA} className="btn btn-lg btn-primary btn-block mb-2 ml-4 mr-4 subscription-action-btn">{i18n.t('admin.labels.disable_pwa')}</button> : null
                                                }
                                                {
                                                     this.props.is_top_admin && !this.state.instituteInfo.is_pwa ? 
                                                     <button onClick={this.enablePWA} className="btn btn-lg btn-primary btn-block mb-2 ml-4 mr-4 subscription-action-btn">{i18n.t('admin.labels.enable_pwa')}</button> : null
                                                }
                                            </React.Fragment>
                                            :
                                            null
                                    }
                                </div> : null
                            }
                            <div>
                                <div className="tool-sidebar subscription-accordion">
                                    <div className="body">
                                        {
                                            this.state.subscriptions.length > 0 ?
                                                <SubscriptionAccordians key="subscrp_accordian" /> :
                                                <div className="no-subscription">
                                                    <h2>{i18n.t('admin.errors.no_active_subscription')}</h2>
                                                </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content tab-2 extra-header" style={{ padding: 0 }}>
                            <Users groupId={this.state.current_institute_id} isInviteFromTeam={true} isTopAdmin={this.state.isTopAdmin}/>
                        </div>
                        <div className="tab-content tab-3 extra-header" style={{ padding: 0 }}>
                            <Invitations groupId={this.state.current_institute_id} />
                        </div>
                        {this.state.isTopAdmin ?
                        <div className="tab-content tab-4 extra-header">
                            <button data-bs-toggle="modal" data-bs-target="#addEmailProviderSettings" className="btn btn-primary mb-3 mt-2 btn-add-user">Add</button>
                            <div class="mt-3">
                                <BootstrapTable
                                    keyField='updated_at'
                                    data={this.state.group_all_email_providers}
                                    // fetchInfo={{ dataTotalSize: this.state.total_count }}
                                    columns={groupEmailColumns}
                                    bordered={false}
                                    // defaultSorted={defaultSorted}
                                    noDataIndication={() => <NoDataIndication />}
                                    //remote
                                    // pagination={paginationFactory(paginatorOptions)}
                                    // onTableChange={this.onTableChange}
                                    //rowEvents={rowEvents}
                                />
                            </div>
                        </div>
                        : null }
                    </section>
                </div>
                {/* Add Email Provider Settings*/}
                <div className="modal fade" id="addEmailProviderSettings" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <ValidatorForm
                                id="addAutomaticEmailProviderForm"
                                ref="form"
                                onSubmit={this.handleEmailProviderSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">Automatic User Registration</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <p className="mb-3 text-primary">Link this Team account with an Oauth provider. Select the provider and email identifier to enable this option.</p>
                                        <div className="two-column-grid">
                                            <div className="user-label text-primary">
                                                <span>Email Provider</span>
                                            </div>
                                            <div>
                                                <SelectValidator
                                                    tabIndex='1'
                                                    className={"form-control mt-2 mr-2"}
                                                    autoComplete="off"
                                                    placeholder='Select Email Provider'
                                                    value={this.state.selected_email_provider}
                                                    name="selected_email_provider"
                                                    options={this.state.group_email_providers}
                                                    onChange={this.handleChange}
                                                    isValidate={this.state.isValidate}
                                                    required
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        'Select Email Provider, it is required!'
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="two-column-grid">
                                            <div className="user-label text-primary">
                                                <span>Email Suffix</span>
                                            </div>
                                            <div>
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mr-2"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="email_suffix"
                                                    placeholder= 'Enter Email Suffix'
                                                    value={this.state.email_suffix}
                                                    required
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        'Enter Email Suffix, it is required!'
                                                    ]}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="two-column-grid">
                                            <div className="user-label text-primary">
                                                <span>Make Active / Deactive</span>
                                            </div>
                                            <div>
                                                <CheckboxValidator
                                                    className={'custom-control mt-2 mr-2 width-2-rem'}
                                                    autoComplete="off"
                                                    type="checkbox"
                                                    name="email_provider_status"
                                                    id="check_email_provider_status"
                                                    value={this.state.email_provider_status}
                                                    onChange={this.handleChange}
                                                    //onChange={(e) => this.handleChange(e, 'checkbox')}
                                                    noValidate
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>Add</React.Fragment>
                                    }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </div>    
                </div>
                {/* Edit Email Provider Settings */}
                 <div>
                    <Modal show={this.state.isEditEmailProviderOpen} size='lg'>
                        {/* <div className="modal-content"> */}
                        <ValidatorForm
                                id="editAutomaticEmailProviderForm"
                                ref="form"
                                onSubmit={this.handleEditEmailProviderSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                        >   
                            <div className="modal-header modal-image-header">
                                <div className="modal-header-content">
                                    <h4 className="modal-title">Edit Email Provier Setting</h4>
                                </div>
                                <button type="button" className="close" onClick={this.hideEditEmailProvider}><span className="lh-icon-os-close"></span></button>
                            </div>
                            <div className="modal-body">
                                <div className="middle-sec">
                                    <div className="two-column-grid">
                                        <div className="user-label text-primary">
                                            <span>Email Provider</span>
                                        </div>
                                        <div>
                                            <SelectValidator
                                                tabIndex='1'
                                                className={"form-control mt-2 mr-2"}
                                                autoComplete="off"
                                                placeholder='Select Email Provider'
                                                value={this.state.edit_selected_email_provider}
                                                name="edit_selected_email_provider"
                                                options={this.state.group_email_providers}
                                                onChange={this.handleChange}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="two-column-grid">
                                        <div className="user-label text-primary">
                                            <span>Email Suffix</span>
                                        </div>
                                        <div>
                                            <TextValidator
                                                tabIndex='2'
                                                wrapperClass=''
                                                className={"form-control mt-2 mr-2"}
                                                autoComplete="off"
                                                type="text"
                                                name="edit_email_suffix"
                                                placeholder= 'Enter Email Suffix'
                                                value={this.state.edit_email_suffix}
                                                required
                                                isValidate={this.state.isValidate}
                                                validators={["required"]}
                                                errorMessages={[
                                                    'Enter Email Suffix, it is required!'
                                                ]}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="two-column-grid">
                                        <div className="user-label text-primary">
                                            <span>Make Active / Deactive</span>
                                        </div>
                                        <div>
                                            <CheckboxValidator
                                                className={'custom-control mt-2 mr-2 width-2-rem'}
                                                autoComplete="off"
                                                type="checkbox"
                                                name="edit_email_provider_status"
                                                id="edit_check_email_provider_status"
                                                value={this.state.edit_email_provider_status}
                                                onChange={this.handleChange}
                                                checked={this.state.edit_email_provider_status}
                                                //onChange={(e) => this.handleChange(e, 'checkbox')}
                                                noValidate
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-tertiary" onClick={this.hideEditEmailProvider}>{i18n.t('userprofile.btn_cancel')}</button>
                                <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_ok')}</button>
                            </div>
                        </ValidatorForm>
                        <Errors
                            hasError={this.state.hasError}
                            type="warning"
                            message={this.state.errorMessage}
                            status={this.state.status}
                        />
                        {/* </div> */}
                    </Modal>
                </div>
                {/* Upgrade Subscription Modal*/}
                <div className="modal fade" id="upgradeSubscription" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <ValidatorForm
                                id="upgradeSubscriptionForm"
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('userprofile.manage_subscriptions.change_my_plan')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="plan" className="col-form-label pt-0">{i18n.t('admin.labels.plan')}</label>
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <SelectValidator
                                                    tabIndex='3'
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t('admin.placeholder.account_type')}
                                                    name="upgrade_plan"
                                                    options={this.state.plans}
                                                    value={this.state.upgrade_plan}
                                                    onChange={this.handleChange}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    required
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_account_type')
                                                    ]}

                                                />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="period" className="col-form-label pt-0">{i18n.t('admin.labels.number_year')}</label>
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <input placeholder={i18n.t('admin.placeholder.number_year')} maxLength={1} pattern="[1-5]" min="1" max="5" onChange={this.handleChange} value={this.state.number_of_years} onPaste={this.onPasteHandle} name="number_of_years" type="number" className="form-control " />
                                            </div>
                                            {
                                                isInstitutionTextEditable && isTeamPlanSelected ?
                                                    <div className="row">
                                                        <div className="user-label col-sm-4">
                                                            <label htmlFor="institution" className="col-form-label pt-0">{i18n.t('admin.labels.institute')}</label>
                                                        </div>
                                                        <div className="col-sm-6 mb-3">
                                                            <TextValidator
                                                                tabIndex='2'
                                                                wrapperClass=''
                                                                className={"form-control"}
                                                                autoComplete="off"
                                                                placeholder={i18n.t('admin.placeholder.institute')}
                                                                type="text"
                                                                name="institution"
                                                                value={this.state.institution}
                                                                onChange={this.handleChange}
                                                                isValidate={this.state.isValidate}
                                                                noValidate
                                                                required
                                                                validators={["required"]}
                                                                errorMessages={[
                                                                    i18n.t('admin.errors.require_institute')
                                                                ]}
                                                            />
                                                        </div>
                                                        <div className="user-label col-sm-4">
                                                            <label htmlFor="code" className="col-form-label pt-0">{i18n.t('admin.labels.institute_code')}</label>
                                                        </div>
                                                        <div className="col-sm-6 mb-3">
                                                            <input placeholder={i18n.t('admin.placeholder.institute_code')} onChange={this.handleChange} value={this.state.institution_code} name="institution_code" type="text" className="form-control " />
                                                        </div>
                                                    </div>
                                                    : isTeamPlanSelected ?
                                                        <div className="row">
                                                            <div className="user-label col-sm-4">
                                                                <label htmlFor="institution" className="col-form-label pt-0">{i18n.t('admin.labels.institute')}</label>
                                                            </div>
                                                            <div className="col-sm-6 mb-3">
                                                                <input placeholder={i18n.t('admin.placeholder.institute')} disabled type="text" name="institution" className="form-control details-info" value={name} />
                                                            </div>
                                                            <div className="user-label col-sm-4">
                                                                <label htmlFor="code" className="col-form-label pt-0">{i18n.t('admin.labels.institute_code')}</label>
                                                            </div>
                                                            <div className="col-sm-6 mb-3">
                                                                <input disabled type="text" placeholder={i18n.t('admin.placeholder.institute_code')}name="institution_code" className="form-control details-info" value={institution_code} />
                                                            </div>
                                                        </div>
                                                        : null
                                            }
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="payment" className="col-form-label pt-0">{i18n.t('admin.labels.payment_received')}</label>
                                            </div>
                                            <div className="col-sm-2 mt-2">
                                                <div className="custom-control-lg custom-radio">
                                                    <input type="radio" className="custom-control-input" id="rdb1" name="payment" defaultChecked={true} />
                                                    <label className="custom-control-label" htmlFor="rdb1">{i18n.t('userprofile.confirm_yes')}</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-2 mt-2">
                                                <div className="custom-control-lg custom-radio">
                                                    <input type="radio" className="custom-control-input" id="rdb2" name="payment" />
                                                    <label className="custom-control-label" htmlFor="rdb2">{i18n.t('userprofile.confirm_no')}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn  btn-outline-tertiary" data-bs-dismiss="modal" >{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('admin.btn_change_plan')}</React.Fragment>
                                    }
                                    </button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </div>
                </div>

                {/* Renew Subscription Modal*/}
                <div className="modal fade" id="renewSubscription" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <ValidatorForm
                                id="renewSubscriptionForm"
                                ref="form"
                                onSubmit={this.handleSubmitRenew}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                {/* <form id="extendSubscriptionForm"> */}
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('userprofile.manage_subscriptions.renew_my_subscription')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="plan" className="col-form-label pt-0">{i18n.t('admin.labels.plan')}</label>
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <SelectValidator
                                                    tabIndex='3'
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t('admin.placeholder.account_type')}
                                                    name="upgrade_plan"
                                                    options={this.state.plans}
                                                    value={this.state.active_subscription ? this.state.active_subscription.key : ''}
                                                    noValidate
                                                    disabled
                                                />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="period" className="col-form-label pt-0">{i18n.t('admin.labels.number_year')}</label>
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <input placeholder={i18n.t('admin.placeholder.number_year')} maxLength={1} pattern="[1-5]" min={1} max={5} onPaste={this.onPasteHandle} onChange={this.handleChange} value={this.state.number_of_years_renw} name="number_of_years_renw" type="number" className="form-control " />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="amount" className="col-form-label pt-0">{i18n.t('admin.labels.received_amount')} (€)</label>
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <input id="renw_sub_amount" disabled type="text" name="amount" className="form-control details-info" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn  btn-outline-tertiary" data-bs-dismiss="modal" >{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_renew')}</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </div>
                </div>
                 {/* Convert Subscription Modal */}
                <div className="modal fade" id="convertSubscription" >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <ValidatorForm
                                    id="convertSubscriptionForm"
                                    ref="form"
                                    onSubmit={this.handleSubmitConvert}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className=""
                                >
                                    {/* <form id="extendSubscriptionForm"> */}
                                    <div className="modal-header modal-image-header">
                                        <div className="modal-header-content">
                                            <h4 className="modal-title">{i18n.t('admin.labels.text_convert')}</h4>
                                        </div>
                                        <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="middle-sec">
                                            <div className="row">
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="period" className="col-form-label pt-0">{i18n.t('admin.labels.number_year')}</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <input placeholder={i18n.t('admin.placeholder.number_year')} maxLength={1} pattern="[1-5]" min={1} max={5} onChange={this.handleChange} onPaste={this.onPasteHandle} value={this.state.number_of_years_ext} name="number_of_years_ext" type="number" className="form-control " />
                                                </div>
                                                <div className="user-label col-sm-4">
                                                    <label htmlFor="amount" className="col-form-label pt-0">{i18n.t('admin.labels.received_amount')} (€)</label>
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <input id="ext_sub_amount_convert" disabled type="text" name="amount" className="form-control details-info" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-accenta" data-bs-dismiss="modal" >{i18n.t('userprofile.btn_cancel')}</button>
                                        <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_change')}</button>
                                    </div>
                                </ValidatorForm>
                                <Errors
                                    hasError={this.state.hasError}
                                    type="warning"
                                    message={this.state.errorMessage}
                                    status={this.state.status}
                                />
                            </div>
                        </div>
                </div>
                {/* Extend Subscription Modal*/}
                <div className="modal fade" id="extendSubscription" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <ValidatorForm
                                id="extendSubscriptionForm"
                                ref="form"
                                onSubmit={this.handleSubmitExtend}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                {/* <form id="extendSubscriptionForm"> */}
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.labels.extend_subscription')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="plan" className="col-form-label pt-0">{i18n.t('admin.labels.plan')}</label>
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <SelectValidator
                                                    tabIndex='3'
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t('admin.placeholder.account_type')}
                                                    name="upgrade_plan"
                                                    options={this.state.plans}
                                                    value={this.state.active_subscription ? this.state.active_subscription.key : ''}
                                                    noValidate
                                                    disabled
                                                />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="period" className="col-form-label pt-0">{i18n.t('admin.labels.number_year')}</label>
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <input placeholder={i18n.t('admin.placeholder.number_year')} maxLength={1} pattern="[1-5]" min={1} max={5} onChange={this.handleChange} value={this.state.number_of_years_ext} onPaste={this.onPasteHandle} name="number_of_years_ext" type="number" className="form-control " />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="amount" className="col-form-label pt-0">{i18n.t('admin.labels.received_amount')} (€)</label>
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <input id="ext_sub_amount" disabled type="text" name="amount" className="form-control details-info" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn  btn-outline-tertiary" data-bs-dismiss="modal" >{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_extend')}</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </div>
                </div>

                {/* Cancel Subscription Modal */}
                <div className="modal fade" id="cancelSubscription" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <ValidatorForm
                                id="cancelSubscriptionForm"
                                ref="form"
                                onSubmit={this.handleSubmitCancel}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('userprofile.manage_subscriptions.cancel_subscription')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="col-md-12 text-primary">
                                            {i18n.t('admin.labels.message_cancel_subscription')}
                                        </div>
                                        <div className="col-md-12">
                                            <TextareaValidator
                                                tabIndex='1'
                                                wrapperClass=''
                                                className={"form-control mt-2 mb-4"}
                                                autoComplete="off"
                                                placeholder={i18n.t('admin.placeholder.reason')}
                                                name="cancel_reason"
                                                value={this.state.cancel_reason}
                                                isValidate={this.state.isValidate}
                                                validators={["required"]}
                                                errorMessages={[
                                                    i18n.t('admin.errors.require_reason')
                                                ]}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-tertiary" data-bs-dismiss="modal" >{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_ok')}</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </div>
                </div>

                {/* Cancel Payment Modal */}
                <div>
                    <Modal show={this.state.isCancelPaymentOpen}>
                        {/* <div className="modal-content"> */}
                        <ValidatorForm
                            id="cancelSubscriptionForm"
                            ref="form"
                            onSubmit={this.handleSubmitCancelPayment}
                            onError={this.handleOnError}
                            instantValidate={false}
                            className=""
                        >
                            <div className="modal-header modal-image-header">
                                <div className="modal-header-content">
                                    <h4 className="modal-title">{i18n.t('admin.labels.cancel_payment')}</h4>
                                </div>
                                <button type="button" className="close" onClick={this.hideCancelPaymentModal}><span className="lh-icon-os-close"></span></button>
                            </div>
                            <div className="modal-body">
                                <div className="middle-sec">
                                    <div className="col-md-12">
                                        {i18n.t('admin.labels.message_cancel_payment')}
                                    </div>
                                    <div className="col-md-12">
                                        <TextareaValidator
                                            tabIndex='1'
                                            wrapperClass=''
                                            className={"form-control mt-2 mb-4"}
                                            autoComplete="off"
                                            placeholder={i18n.t('admin.placeholder.reason')}
                                            name="cancel_reason_payment"
                                            value={this.state.cancel_reason_payment}
                                            isValidate={this.state.isValidate}
                                            validators={["required"]}
                                            errorMessages={[
                                                i18n.t('admin.errors.require_reason')
                                            ]}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-tertiary" onClick={this.hideCancelPaymentModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                <button type="submit" className="btn btn-accenta">{i18n.t('admin.btn_ok')}</button>
                            </div>
                        </ValidatorForm>
                        <Errors
                            hasError={this.state.hasError}
                            type="warning"
                            message={this.state.errorMessage}
                            status={this.state.status}
                        />
                        {/* </div> */}
                    </Modal>
                </div>
            </div>

        )
    }
}

export default withTranslation()(ViewInstitute);