import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import API from '../../api';
import Modal from "react-bootstrap/Modal";
import i18n from "../../../packs/i18n";

class ManagePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
            isPreviewModalOpen: false,
            isDeleteModalOpen: false,
            current_preview_url: null,
            current_delete_id: null
        }
    }
    async componentDidMount() {
        await this.callApi()
    }
    pageActions = (cell, row) => {
        let id = row.id;
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void(0)} data-id={id} onClick={this.previewPage}>{i18n.t('admin.labels.preview')}</NavDropdown.Item>
                <NavDropdown.Item href={"/admin/manage_pages/edit/"+row.id}>{i18n.t('admin.labels.update_content')}</NavDropdown.Item>
                <NavDropdown.Item href={void(0)} data-id={id} onClick={this.deleteAction}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
            </NavDropdown>
        );
    }
    async callApi() {
        this.setState({ data: [], isLoading: true });
        let { data } = await API.get('/manage_pages');
        this.setState({ data: data.pages, isLoading: false });
    }
    routeToArticles = () => {
        window.location.href = '/admin/articles'
    }
    routeToNewPage = () => {
        window.location.href = '/admin/manage_pages/new'
    }
    previewPage = event => {
        let id = event.target.getAttribute('data-id');
        this.setState({current_preview_url: "/oldview/manage_pages/"+id+"/preview?list=true"})
        this.showPreviewModal();
    }
    deleteAction = event => {
        let id = event.target.getAttribute('data-id');
        this.setState({current_delete_id: id})
        this.showDeleteModal()
    }
    deletePage = event => {
        API.delete('/manage_pages/'+this.state.current_delete_id)
        .then(res => {
           window.location.reload();
        })
        .catch(function (error) {
            alert(error.message)
        })
    }
    showPreviewModal = () => {
        this.setState({isPreviewModalOpen: true})
    }
    hidePreviewModal = () => {
        this.setState({isPreviewModalOpen: false, current_preview_url: null})
    }
    showDeleteModal = () => {
        this.setState({isDeleteModalOpen: true})
    }
    hideDeleteModal = () => {
        this.setState({isDeleteModalOpen: false})
    }
    render() {
        const pageList = [{
            dataField: 'page',
            text: i18n.t('admin.labels.page').toUpperCase(),
            sort: false
        }, {
            dataField: 'language_name',
            text: i18n.t('userprofile.labels.language').toUpperCase(),
            sort: false
        }, {
            dataField: 'title',
            text: i18n.t('admin.labels.title').toUpperCase(),
            sort: false
        },
        {
            dataField: 'sub_title',
            text: i18n.t('admin.labels.sub_title').toUpperCase(),
            sort: false
        },{
            dataField: 'moreOptions',
            sort: false,
            formatter: this.pageActions,
        }];
        const paginatorOptions = {
            pageStartIndex: 1,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };

        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div class="admin-custom-table-header">
                            <button className="btn btn-primary mb-2 mt-3 mr-3" onClick={this.routeToNewPage}>{i18n.t('admin.labels.new_page')}</button>
                            {/* <button className="btn btn-primary mb-2 mt-3 mr-3" onClick={this.routeToArticles}>Marketplace Articles</button> */}
                        </div>
                        <div className="manage-page-table mt-2">
                            <BootstrapTable
                                keyField='id'
                                data={this.state.data}
                                columns={pageList}
                                bordered={false}
                            />
                        </div>
                    </div>
                </div>

                {/* Preview Page Modal */}
                <div>
                    <Modal size="lg" show={this.state.isPreviewModalOpen} id="previewPage">
                    {/* <div className="modal-dialog modal-lg"> */}
                        <div className="modal-content">
                            <div className="modal-header modal-image-header">
                                <div className="modal-header-content">
                                    <h4 className="modal-title">{i18n.t('admin.labels.preview')}</h4>
                                </div>
                                <button type="button" className="close" onClick={this.hidePreviewModal}><span className="lh-icon-os-close"></span></button>
                            </div>
                            <div className="modal-body" style={{padding: '1rem'}}>
                                <iframe src={this.state.current_preview_url} width="100%" style={{height: '500px', border: 0}}> </iframe>
                            </div>
                        </div>
                    {/* </div> */}
                    </Modal>
                </div>

                {/* Delete Modal */}

                <div>
                    <Modal size="lg" show={this.state.isDeleteModalOpen} id="previewPage">
                    {/* <div className="modal-dialog modal-lg"> */}
                        <div className="modal-content">
                            <div className="modal-header modal-image-header">
                                <div className="modal-header-content">
                                    <h4 className="modal-title">{i18n.t('admin.labels.delete_page_title')}</h4>
                                </div>
                                <button type="button" className="close" onClick={this.hideDeleteModal}><span className="lh-icon-os-close"></span></button>
                            </div>
                            <div className="modal-body" >
                                <div className="row">
                                    <div className="col-md-12">
                                        {i18n.t('admin.labels.delete_page_message')}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-accenta" onClick={this.hideDeleteModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                <button type="button" onClick={this.deletePage} className="btn btn-accenta">{i18n.t('userprofile.confirm_yes')}</button>
                            </div>
                        </div>
                    {/* </div> */}
                    </Modal>
                </div>
            </div>
        )
    }
}

export default ManagePage