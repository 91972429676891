import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import PlanDetails from './PlanDetails';
import i18n from '../../packs/i18n';
import API from '../api';
import PlanItem from './PlanItem';
// import RotateSlider from '../Common/Slide';
import { DownArrow_IMG, PACK_FREE_IMG, PACK_INDI_IMG, PACK_TEAM_IMG } from "../Common/assets_paths";
import { getLocalUrl, getUTCTime } from '../Common/i3library';
import { withTranslation } from 'react-i18next';
import {createEvent} from '../Common/AnalyticsEvents'
class SelectPackage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        plans: [],
        features: [],
        planDetails:null,
        total_plans: 3,
        plansRendered: false,
        subscriptionDataInfo: null
      }
      this.refPlanDetail = React.createRef();
      this.scroll = this.scroll.bind(this);
    }
    handleChoosePlan(details, key, userInfo) {
        const currentPlan =  this.props.location.state ? this.props.location.state.currentPlan : null;
        const upgradePlan = this.props.location.state ? this.props.location.state.upgradePlan : (window.history.state ? window.history.state.upgradePlan : null);
        if(upgradePlan)
          createEvent("Upgrade", "Step3",getUTCTime())
        else
          createEvent("Signup", "Step3",getUTCTime())
        
        if(key === 'free') {
            this.props.history.push({
                pathname: getLocalUrl(this.props.match.params.locale,('signup/payment/' + this.props.match.params.token_id)),
                state: { planDetail: {
                    display_text: details.display_text, monthly_price: details.monthly_price, yearly_price: details.yearly_price, planType: key, sub_plans: details.sub_plans
                }, upgradePlan}
            });
        } else {
            this.props.history.push({
                pathname: getLocalUrl(this.props.match.params.locale,('signup/paymentmethod/' + this.props.match.params.token_id)),
                state: { display_text: details.display_text, monthly_price: details.monthly_price, yearly_price: details.yearly_price, planType: key, sub_plans: details.sub_plans, currentPlan: currentPlan,userInfo: userInfo, upgradePlan }
            });
        }
    }

    UNSAFE_componentWillMount() {
      if(this.props.location.state) {
        this.fetchPricingPlans();
      } else {
        API.get('subscription/active').then(res => {
          if(res.data) {
            // ILEARN-4076 : Login/Signup and other screens should not be accessible to the user when user session is running.
            if(res.data.status === "error" && res.data.reason == "logged_out")
              window.location = res.data.redirect_url;
            this.setState({ subscriptionDataInfo : res.data }, () => {
              this.fetchPricingPlans();
            });
          }
        });
      }
    }

    createPlanItems = async(data,features) =>{
      const currentPlan =  this.props.location.state ? this.props.location.state.currentPlan : this.state.subscriptionDataInfo;
      const userInfo = this.props.location.state ? this.props.location.state.userInfo : null;
      const upgradePlan = this.props.location.state ? this.props.location.state.upgradePlan : (window.history.state ? window.history.state.upgradePlan : null);
      const _plans = [];
      for (var i in data) {
        if (_plans.length < this.state.total_plans) {
            let img_path = null;
            let pack_color = null;
            if(i === "free"){
                img_path = PACK_FREE_IMG;
                pack_color = "#e5c5e8";
            }
            else if( i === 'individual'){
                img_path = PACK_INDI_IMG
                pack_color = "#acb5da";
            }
            else if(i === 'team'){
                img_path = PACK_TEAM_IMG
                pack_color = "#bfd0e8";
            }
          _plans.push(<PlanItem
            handler={this.handleChoosePlan.bind(this)}
            key={i}
            plan_detail={data[i]}
            plan_key={i}
            current_plan={currentPlan}
            userInfo = {userInfo}
            muchMoreHandler={this.scroll.bind(this)}
            plan_image_path = {img_path}
            plan_background_color = {pack_color}
            upgradePlan =  {upgradePlan}
          />);
        }
      }
      this.setState({
        plans: _plans,
        features: features,
        planDetails: data,
        plansRendered: true
      })
    }
    fetchPricingPlans() {
        API.get('pricing/plans')
        .then(res => {
          this.createPlanItems(res.data[0], res.data[1]);
        })
        .catch(function (error) {
            if(error.response) {
            }
            if(error.request) {
              // console.log(error.request);
            }
            else {}
        });
    }

    scroll() {
        this.refPlanDetail.refPlanSection.scrollIntoView({ speed: '10', behavior: 'smooth' });
    }

    render() {
        const currentPlan =  this.props.location.state ? this.props.location.state.currentPlan : null;
        const userInfo = this.props.location.state ? this.props.location.state.userInfo : null;
        const upgradePlan = this.props.location.state ? this.props.location.state.upgradePlan : (window.history.state ? window.history.state.upgradePlan : null);
        return (
            <React.Fragment>
                {upgradePlan &&
                    <div className="selectPackageBackBtn">
                        <a href="javascript:void(0)" onClick={() => this.props.history.goBack()} className={'btn btn-back btn-lg btn-responsive btn-outline-quinary mr-2'}>
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="35.621px" height="25.955px" viewBox="0 0 35.621 25.955" enableBackground="new 0 0 35.621 25.955" xmlSpace="preserve">
                                <line className="line" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" x1="34.121" y1="13.121" x2="2.121" y2="13.121" />
                                <line className="arrow-path" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" x1="13.121" y1="2.121" x2="2.121" y2="13.121" />
                                <path className="arrow-path" fill="none" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" d="M2.121,13.121l10.55,11.333" />
                            </svg>
                        </a>
                    </div>
                }
                <LandingStepBase container={
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 p-0 text-center">
                                <div className="headtxt animated fadeInDown mt-5 mb-5">
                                    <h1 className="text-white mb-0">{ upgradePlan ? i18n.t('signup.step3.header.title_upgrade') : i18n.t('signup.step3.header.title')} </h1>
                                    <p>{i18n.t('signup.step3.header.sub_title')}</p>
                                    <div className="steps">
                                        <span className="completed step mr-2 ml-2"></span><span className="completed  step  mr-2 ml-2"></span><span className="activestep step  mr-2 ml-2"></span><span className="step mr-2 ml-2"></span><span className="step mr-2 ml-2"></span>
                                    </div>
                                    <p className="mt-2 text-quaternary text-size-20">{i18n.t('signup.step3.header.current_step')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row package-slider">
                            {this.state.plansRendered ?
                                // <RotateSlider sliderClass='slider-item'>
                                this.state.plans : null
                                // </RotateSlider> : null
                            }
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-center mt-5 mb-2">
                                <a href="javascript:void(0)" onClick={this.scroll} className="downlink text-decoration-none"><img src={DownArrow_IMG} /></a>
                            </div>
                        </div>
                    </div>
                }

                subcontainer={ this.state.plansRendered ? <PlanDetails handler={this.handleChoosePlan.bind(this)} ref={(node) => { this.refPlanDetail = node; }}  features={this.state.features} plans={this.state.planDetails} current_plan={currentPlan}  />:null}
                ></LandingStepBase>
            </React.Fragment>
        )
    }

}

export default withTranslation()(SelectPackage);
