import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import Modal from "react-bootstrap/Modal";
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
class PromoteDemoteModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: props.type,
            id: props.id,
            isOpen: props.isOpen,
            action: props.action
        }
    }
    promotUser = (event) => {
        const formData = new FormData();
        formData.append('ids[]', this.state.id)
        let _this = this;
        API.patch('/oldview/users/adminate', formData)
            .then(res => {
            window.location.reload()
            })
            .catch(function (error) {

            })
    }
    demoteUser = (event) => {
        const formData = new FormData();
        formData.append('ids[]', this.state.id)
        let _this = this;
        API.patch('/oldview/users/deadminate', formData)
            .then(res => {
            window.location.reload()
            })
            .catch(function (error) {

            })
    }
    hideModal = () => {
        this.setState({isOpen: false})
        if (this.props && this.props.hideModal)
            this.props.hideModal()
    }
    render(){
        return(
            <Modal show={this.state.isOpen} id="deleteModal">
                <div className="modal-content">
                        <div className="modal-header modal-image-header">
                            <div className="modal-header-content">
                                {
                                    this.state.action == 'promote' ? <h4 className="modal-title">{i18n.t('admin.promote_demote_modal.title_promote')}</h4> : <h4 className="modal-title">{i18n.t('admin.promote_demote_modal.title_demote')}</h4>
                                }
                                
                            </div>
                            <button type="button" className="close" onClick={this.hideModal}><span className="lh-icon-os-close"></span></button>
                        </div>
                        <div className="modal-body">
                            <div className="middle-sec">
                                <div className="col-md-12">
                                    {
                                        this.state.action == 'promote' ?
                                        <h5 className="text-primary">{i18n.t('admin.promote_demote_modal.message_promote')}</h5> : <h5 className="text-primary">{i18n.t('admin.promote_demote_modal.message_demote')}</h5>
                                    }
                                </div>
                                {
                                    this.state.action == 'promote' ?
                                        <div className="mt-3 col-md-12">
                                            {i18n.t('admin.promote_demote_modal.message2_promote')}
                                        </div> : null 
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-accenta" onClick={this.hideModal}>{i18n.t('userprofile.btn_cancel')}</button>
                            <button type="button" className="btn btn-accenta" onClick={ this.state.action == 'promote' ? this.promotUser : this.demoteUser}>{i18n.t('admin.btn_ok')}</button>
                        </div>
                </div>
            </Modal>
        )
    }

}
export default withTranslation()(PromoteDemoteModal)