import React from 'react';
import { withTranslation } from 'react-i18next';

class Success extends React.Component{
    render(){
        return <div> <h1> Success Page</h1></div>
    }

}

export default withTranslation()(Success);