import { getUserInfo } from "../i3library";

export function getErrorMessage(errors = [], isReturnArray = false) {
    const errormessages = errors.filter((e,i)=> errors.indexOf(e) >= i).reduce( (messages, error) => {
        if (error.invalid instanceof Array) {
            messages.push(error.state.errorMessages[error.invalid[0]])
            return messages;
        }
      }, []);

    if(!isReturnArray) {
        return errormessages.join(", ");
    }
    return errormessages;
}

export function checkIsActivePath(pathname) {
    if (typeof pathname === "undefined") {
        console.log("location was null");
        return false;
    }
    let cleanPath = window.location.pathname.replace(/\/$/, "");           
    if (cleanPath.endsWith(pathname)) {
        return true;
    }
    return false;
}