import React from "react";
import i18n from "../../packs/i18n";
import BootstrapTable from 'react-bootstrap-table-next';
import { withTranslation } from 'react-i18next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import Errors from "../Common/Errors/Errors";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import TextValidator from "../Common/TextValidator";
import TextareaValidator from "../Common/TextareaValidator";
import CheckboxValidator from "../Common/CheckboxValidator";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import Modal from "react-bootstrap/Modal";
import moment from 'moment/min/moment-with-locales';
import { CSVLink, CSVDownload } from "react-csv";
class Vouchers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            total_count: 0,
            per_page: 10,
            current_page: 1,
            data: [],
            resellers: [],
            toggleVoucherFilter: false,
            current_reseller_id: '',
            current_reseller_is_public: true,
            allow_team: false,
            discount: '',
            percent: 0,
            number: 0,
            isLoading: false,
            edit_voucher_code: '',
            edit_voucher_type: '',
            edit_voucher_percent: 0,
            edit_voucher_number: 0,
            edit_voucher_allow_team: false,
            current_edit_voucher_id:null, 
            isEditOpen: false,
            orderBy: 'created_at desc',
            number_of_codes: 1,
            prefix: '',
            code_length: 6,
            expiry_date: null,
            license_duration: 1,
            blk_allow_team: false,
            description: "",
            htmlFormat: '',
            voucher_codes: [],
            voucher_codes_headers: [],
            test: ""
        }
        //oldview/voucher_codes/get_all
    }
    async componentDidMount() {
        await this.callApi(this.state.per_page, this.state.current_page, 0)
    }

    async callApi(per_page, current_page, reseller_id) {
        this.setState({ data: [], resellers: [], isLoading: true })
        if (reseller_id != 0 && reseller_id != '') {
            let { data } = await API.get('/oldview/voucher_codes/get_all?per_page=' + per_page + '&current_page=' + current_page + '&reseller_id=' + reseller_id + '&order_by=' + this.state.orderBy)
            let resellerInfo = data.resellers.find((element) => {
                return element.id == reseller_id
            })
            this.setState({ data: data.vouchers, total_count: data.total_count, resellers: data.resellers, isLoading: false, current_reseller_is_public: resellerInfo.is_public })
        }
        else {
            let { data } = await API.get('/oldview/voucher_codes/get_all?per_page=' + per_page + '&current_page=' + current_page + '&order_by=' + this.state.orderBy)
            this.setState({ data: data.vouchers, total_count: data.total_count, resellers: data.resellers, isLoading: false })
        }
    }
    onTableChange = (type, newState) => {
        if(newState.sortOrder && newState.sortField){
            this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
        }
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi(newState.sizePerPage, newState.page, this.state.current_reseller_id)
    }

    toggleResellerStatus = (event) => {
        console.log("Toggle reseller status called", this.state.current_reseller_is_public)
        API.put('/oldview/voucher_codes/' + this.state.current_reseller_id + '/change_reseller_status').then(res => {
            this.setState({ current_reseller_is_public: res.data.is_public });
        }).catch((err) => {

        })
    }
    onPasteHandle = event => {
        event.preventDefault();
        return
    }
    handleSubmit = (event) => {
        const formData = new FormData();
        if(this.state.discount.trim().length <= 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.errors.require_discount_code_name'),
                status: null,
                discount: ''
            });
            return
        }
        formData.append('discount[code]', this.state.discount)
        formData.append('discount[percentage]', this.state.percent);
        formData.append('discount[max_use]', this.state.number);
        formData.append('discount[is_team_allowed]', this.state.allow_team);
        let resellerId = this.state.current_reseller_id || ''
        if (this.state.current_reseller_id && this.state.current_reseller_id !== '' && this.state.current_reseller_id !== 0)
            formData.append('reseller_id', resellerId)
        API.post('/oldview/voucher_codes', formData).then(res => {
            window.location.reload();
        }).catch((err) => {

        })
    }
    handleChange = event => {
        //const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        if (event.target.name === 'current_reseller_id') {
            let toggleVoucherFilter = false;
            if (event.target.value == 0) {
                toggleVoucherFilter = false
            }
            else {
                toggleVoucherFilter = true
            }
            this.setState({
                toggleVoucherFilter: toggleVoucherFilter
            });
        }
        if(event.target.name == 'number' || event.target.name == 'edit_voucher_number'){
            if (event.target.value < 1) {
                event.preventDefault();
                return
            }
        }

        if(event.target.name === 'prefix'){
            if(event.target.value.match("^[a-zA-Z ]*$") != null){
                this.setState({[event.target.name]: event.target.value});
            }
        }
        else{
            this.setState({
                [event.target.name]: event.target.value
            });
        }
        if (event.target.name === 'current_reseller_id') {
            this.callApi(this.state.per_page, this.state.current_page, event.target.value)
        }
    }
    handleOnError = errors => {
        this.setState({
          isValidate: true,
          isValid: false,
          hasError: true,
          errorMessage: getErrorMessage(errors, true),
          status: null
        });
    };
    handleKeyDown = event => {
        if (event.key === " " && event.target.value.trim().length === 0) {
            event.preventDefault();
        }
    };
    voucherAction = (cell, row) => {
        let voucher_id = row.id
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item data-id={voucher_id} href={void (0)} onClick={this.editVoucher}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
                <NavDropdown.Item data-id={voucher_id} href={void (0)} onClick={this.deleteVoucher}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
            </NavDropdown>
        );
    }
    editVoucher = event => {
        
        let id = event.target.getAttribute('data-id');
        let voucherInfo = this.state.data.find((element) => {
            return element.id == id
        })
        console.log(voucherInfo)

        this.setState({edit_voucher_code: voucherInfo.code, edit_voucher_number: voucherInfo.max_use, edit_voucher_percent: voucherInfo.percentage, edit_voucher_type: voucherInfo.type,current_edit_voucher_id: voucherInfo.id, edit_voucher_allow_team: voucherInfo.is_team_allowed })
    
        this.showEditModal()
          
    }
    showEditModal = () => {
        this.setState({ isEditOpen: true, isValidate: false,  isValid: true, hasError: false,errorMessage: [], status: null  })
    }
    hideEditModal = () => {
        this.setState({ isEditOpen: false })
    }
    handleEditSubmit = event => {
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append('discount[code]', this.state.edit_voucher_code)
        formData.append('discount[percentage]', this.state.edit_voucher_percent);
        formData.append('discount[max_use]', this.state.edit_voucher_number);
        formData.append('discount[is_team_allowed]', this.state.edit_voucher_allow_team);
        let resellerId = this.state.current_reseller_id || ''
        if (this.state.current_reseller_id && this.state.current_reseller_id !== '' && this.state.current_reseller_id !== 0)
            formData.append('reseller_id', resellerId)

        API.put('/oldview/voucher_codes/'+this.state.current_edit_voucher_id+'/update_coupon_code', formData).then(res => {
            window.location.reload();
        }).catch((err) => {

        })
    }
    deleteVoucher = event => {
        let id = event.target.getAttribute('data-id');
        let text = "Are you absolutely sure, you want to delete selected voucher? "
        
        if (confirm(text) == true) {
            API.delete('/oldview/voucher_codes/'+id+'/delete_coupen_code', )
                .then(res => {
                window.location.reload()
            })
            .catch(function (error) {

            })
        }
        else {
            console.log("User canceled")
        }
    }
    handleBulkDiscountCodeSubmitWithCsv = (event) => {
        this.setState({htmlFormat: 'csv'})
    }
    handleBulkDiscountCodeSubmit = (event) => {
        if(this.state.prefix.length > 0 && this.state.prefix.length < 2 ){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.errors.min_prefix_length'),
                status: null
            });
            return
        }
        else if(this.state.prefix.length > 10){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.errors.max_prefix_length'),
                status: null
            });
            return
        }
        let formData = new FormData();
        formData.append('number_of_codes', this.state.number_of_codes)
        formData.append('discount', this.state.percent)
        if(this.state.prefix)
            formData.append('prefix', this.state.prefix)
        formData.append('reseller_id', this.state.current_reseller_id)
        formData.append('code_length', this.state.code_length)
        if(this.state.expiry_date)
            formData.append('code_expiry', this.state.expiry_date)
        formData.append('license_duration', this.state.license_duration)
        formData.append('allow_team', this.state.blk_allow_team)
        if(this.state.description)
            formData.append('description', this.state.description)
        if(this.state.htmlFormat)
            formData.append('html_format', this.state.htmlFormat)
        API.post('/oldview/voucher_codes/bulk_create', formData).then(res => {
            let data = res.data;
            if(this.state.htmlFormat !== 'csv')
                window.location.reload();
            else if (this.state.htmlFormat == 'csv'){
                let csv_data = [["Voucher Code"]]
                data.codes.forEach(element => {
                    csv_data.push([element])
                });
                this.setState({voucher_codes: csv_data, voucher_codes_headers: data.fileName})
                setTimeout(() => {
                    document.getElementById('export_codes_data').click();
                },5)
            }
        }).catch((err) => {

        })
       
    }
    csvClicked = (event) => {
        setTimeout(()=>{
            window.location.reload();
        }, 500)
    }

    render() {
        const { toggleVoucherFilter } = this.state;
        const columns = [{
            dataField: 'type',
            text: i18n.t('admin.labels.type').toUpperCase(),
            sort: true
        }, {
            dataField: 'code',
            text: i18n.t('admin.labels.code').toUpperCase(),
            sort: true
        }, {
            dataField: 'percentage',
            text: i18n.t('admin.labels.amount').toUpperCase(),
            sort: true
        }, {
            dataField: 'reseller_name',
            text: i18n.t('admin.labels.reseller').toUpperCase(),
            sort: false
        }, {
            dataField: 'max_use',
            text: i18n.t('admin.labels.limit').toUpperCase(),
            sort: true
        }, {
            dataField: 'used',
            text: i18n.t('admin.labels.used').toUpperCase(),
            sort: false
        }, 
        {
            dataField: 'is_team_allowed',
            text: i18n.t('admin.labels.allow_team_tabel').toUpperCase(),
            sort: true
        },
        {
            dataField: 'license_duration',
            text: i18n.t('admin.labels.license_duration').toUpperCase(),
            sort: true
        }, 
        {
            dataField: 'expiry_date',
            text: i18n.t('admin.labels.code_expiry').toUpperCase(),
            sort: true
        },
        {
            dataField: 'created_at',
            text: i18n.t('admin.labels.created_on').toUpperCase(),
            sort: true
        }, {
            dataField: 'moreOptions',
            sort: false,
            formatter: this.voucherAction,
        }];

       
        const defaultSorted = [{
            dataField: 'created_at',
            order: 'desc'
        }];

        const options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        };
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div className="admin-custom-table-header row g-3 align-items-center">
                            <div className="col-auto" style={{ width: '17%' }}>
                                {
                                    toggleVoucherFilter ?
                                        <button data-bs-toggle="modal" data-bs-target="#addDiscountCode" className="add-discount-code btn btn-primary mb-3 mt-1">{i18n.t('admin.labels.new_discount_code')}</button>
                                        : <button data-bs-toggle="modal" data-bs-target="#addDiscountCode" className="add-discount-code btn btn-primary mb-3 mt-1">{i18n.t('admin.labels.new_global_dicount_code')}</button>
                                }
                            </div>
                            <div className="col-auto voucher-dropdown mt-2">
                                <select name="current_reseller_id" className="voucher-select1 form-control mb-3" value={this.state.current_reseller_id} onChange={this.handleChange}>
                                    <option value="0">{i18n.t('admin.placeholder.select_reseller')}</option>
                                    {this.state.resellers.map(item => {
                                        return (<option key={item.id} value={item.id}>{item.name}</option>);
                                    })}
                                </select>
                                {/* <select onChange={this.toggleResellerStatus} style={{ display: (toggleVoucherFilter ? 'block' : 'none') }} value={this.state.current_reseller_is_public} name="language" className="voucher-select2 form-control mb-3">
                                    <option value="true">{i18n.t('admin.labels.public')}</option>
                                    <option value="false">{i18n.t('admin.labels.private')}</option>
                                </select> */}
                            </div>
                            {toggleVoucherFilter ? <div>
                                <button data-bs-toggle="modal" data-bs-target="#bulkDiscountCode" className="add-discount-code btn btn-primary mb-3 mt-1">{i18n.t('admin.labels.btn_blk_discount')}</button>
                            </div> : null }
                        </div>
                        <div className="vouchers-table">
                            <BootstrapTable
                                keyField='type'
                                options={options}
                                fetchInfo={{ dataTotalSize: this.state.total_count }}
                                data={this.state.data}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                remote
                                noDataIndication={() => <NoDataIndication />}
                                pagination={paginationFactory(paginatorOptions)}
                                onTableChange={this.onTableChange}
                            />
                        </div>
                    </div>
                </div>

                {/* Add Discount Code Modal */}
                <div className="modal fade" id="addDiscountCode" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            {/* <ValidatorForm id="addDiscountCodeForm"> */}
                            <ValidatorForm
                                id="addDiscountCodeForm"
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.labels.new_discount_code')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-5">
                                                <span>{i18n.t('admin.labels.discount_code_name')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="discount"
                                                    placeholder={i18n.t('admin.placeholder.discount_code_name')}
                                                    value={this.state.discount}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_discount_code_name')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                                {/* <input name="discount" type="text" className="form-control mt-2 mb-4" /> */}
                                            </div>
                                            <div className="user-label col-sm-5">
                                                <span>{i18n.t('admin.labels.discount')} (%)</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="percent"
                                                    placeholder={i18n.t('admin.placeholder.discount')}
                                                    value={this.state.percent}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_discount')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                                {/* <input name="percent" type="number" className="form-control mt-2 mb-4" /> */}
                                            </div>
                                            <div className="user-label col-sm-5">
                                                <span>{i18n.t('admin.labels.how_many')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    type="number"
                                                    name="number"
                                                    placeholder={i18n.t('admin.placeholder.how_many')}
                                                    value={this.state.number}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    min={1}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_how_many')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onPaste={this.onPasteHandle}
                                                />
                                            </div>
                                            <div className="user-label col-sm-5 mt-2">
                                                <span>{i18n.t('admin.labels.allow_team')}</span>
                                            </div>
                                            <div className="col-sm-1 mt-2">
                                                <CheckboxValidator
                                                    className={'form-control allow-team-chkbx'}
                                                    autoComplete="off"
                                                    type="checkbox"
                                                    name="allow_team"
                                                    id="allow_team"
                                                    value={this.state.allow_team}
                                                    onChange={(e) => this.handleChange(e, 'checkbox')}
                                                    noValidate
                                                    checked={this.state.allow_team}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" data-bs-dismiss="modal" className="btn btn-lg btn-outline-primary">{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta">{i18n.t('userprofile.btn_save')}</button>
                                </div>

                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </div>
                </div>

                {/* Edit Discount Modal */}
                <div  tabIndex="-1">
                    <Modal show={this.state.isEditOpen} id="editDiscountCode">
                            <ValidatorForm
                            id="editDiscountCodeForm"
                            ref="form"
                            onSubmit={this.handleEditSubmit}
                            onError={this.handleOnError}
                            className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.labels.edit_discount_code')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideEditModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-6">
                                                <span>{i18n.t('admin.labels.discount_code_name')}</span>
                                            </div>
                                            <div className="col-sm-5">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_voucher_code"
                                                    placeholder={i18n.t('admin.placeholder.discount_code_name')}
                                                    value={this.state.edit_voucher_code}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    disabled={this.state.edit_voucher_type == 'Voucher' ? true : false}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_discount_code_name')
                                                    ]}
                                                    onChange={this.handleChange}
                                                />
                                                {/* <input name="discount" type="text" className="form-control mt-2 mb-4" /> */}
                                            </div>
                                            <div className="user-label col-sm-6">
                                                <span>{i18n.t('admin.labels.discount')} (%)</span>
                                            </div>
                                            <div className="col-sm-5">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_voucher_percent"
                                                    placeholder={i18n.t('admin.placeholder.discount')}
                                                    value={this.state.edit_voucher_percent}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_discount')
                                                    ]}
                                                    onChange={this.handleChange}
                                                />
                                                {/* <input name="percent" type="number" className="form-control mt-2 mb-4" /> */}
                                            </div>
                                            <div className="user-label col-sm-6">
                                                <span>{i18n.t('admin.labels.how_many')}</span>
                                            </div>
                                            <div className="col-sm-5">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-4"}
                                                    autoComplete="off"
                                                    type="number"
                                                    name="edit_voucher_number"
                                                    placeholder={i18n.t('admin.placeholder.how_many')}
                                                    value={this.state.edit_voucher_number}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_how_many')
                                                    ]}
                                                    min={1}
                                                    onChange={this.handleChange}
                                                    onPaste={this.onPasteHandle}
                                                />
                                            </div>
                                            <div className="user-label col-sm-6 mt-2">
                                                <span>{i18n.t('admin.labels.allow_team')}</span>
                                            </div>
                                            <div className="col-sm-1 mt-2">
                                                <CheckboxValidator
                                                    className={'form-control allow-team-chkbx'}
                                                    autoComplete="off"
                                                    type="checkbox"
                                                    name="edit_voucher_allow_team"
                                                    id="edit_voucher_allow_team"
                                                    value={this.state.edit_voucher_allow_team}
                                                    onChange={(e) => this.handleChange(e, 'checkbox')}
                                                    noValidate
                                                    checked={this.state.edit_voucher_allow_team}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.hideEditModal} className="btn btn-lg btn-outline-primary">{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta">{i18n.t('admin.btn_ok')}</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                    </Modal>
                </div>

                {/* Bulk Discount Code Modal */}
                <div className="modal fade" id="bulkDiscountCode" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <ValidatorForm
                                id="bulkDiscountCodeForm"
                                ref="form"
                                onSubmit={this.handleBulkDiscountCodeSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.labels.btn_blk_discount')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-5 mt-2 mb-2">
                                                <span>{i18n.t('admin.labels.num_of_codes')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-2"}
                                                    autoComplete="off"
                                                    type="number"
                                                    name="number_of_codes"
                                                    min={1}
                                                    max={1000}
                                                    placeholder={i18n.t('admin.placeholder.num_of_codes')}
                                                    value={this.state.number_of_codes}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_num_of_codes')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-5 mt-2 mb-2">
                                                <span>{i18n.t('admin.labels.discount')} (%)</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-2"}
                                                    autoComplete="off"
                                                    type="number"
                                                    name="percent"
                                                    min={1}
                                                    max={100}
                                                    placeholder={i18n.t('admin.placeholder.discount')}
                                                    value={this.state.percent}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_discount')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-5 mt-2 mb-2">
                                                <span>{i18n.t('admin.labels.prefix')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='12'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-2"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="prefix"
                                                    placeholder={i18n.t('admin.placeholder.prefix')}
                                                    value={this.state.prefix}
                                                    noValidate
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-5 mt-2 mb-2">
                                                <span>{i18n.t('admin.labels.code_length')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='12'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-2"}
                                                    autoComplete="off"
                                                    type="number"
                                                    name="code_length"
                                                    min={6}
                                                    max={20}
                                                    placeholder={i18n.t('admin.placeholder.code_length')}
                                                    value={this.state.code_length}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_code_length')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-5 mt-2 mb-2">
                                                <span>{i18n.t('admin.labels.code_expiry')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='12'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-2"}
                                                    autoComplete="off"
                                                    type="date"
                                                    name="expiry_date"
                                                    min={moment(new Date()).format("YYYY-MM-DD")}
                                                    placeholder={i18n.t('admin.placeholder.code_expiry')}
                                                    value={moment(this.state.expiry_date).format("YYYY-MM-DD")}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-5 mt-2 mb-2">
                                                <span>{i18n.t('admin.labels.license_duration')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextValidator
                                                    tabIndex='12'
                                                    wrapperClass=''
                                                    className={"form-control mt-2 mb-2"}
                                                    autoComplete="off"
                                                    type="number"
                                                    name="license_duration"
                                                    min={1}
                                                    max={10}
                                                    placeholder={i18n.t('admin.placeholder.license_duration')}
                                                    value={this.state.license_duration}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_license_duration')
                                                    ]}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                            <div className="user-label col-sm-5 mt-2 mb-2">
                                                <span>{i18n.t('admin.labels.allow_team')}</span>
                                            </div>
                                            <div className="col-sm-1 mt-2 mb-2">
                                                <CheckboxValidator
                                                    className={'form-control allow-team-chkbx'}
                                                    autoComplete="off"
                                                    type="checkbox"
                                                    name="blk_allow_team"
                                                    id="allow_team"
                                                    value={this.state.blk_allow_team}
                                                    onChange={(e) => this.handleChange(e, 'checkbox')}
                                                    noValidate
                                                    checked={this.state.blk_allow_team}
                                                />
                                            </div>
                                            <div className="col-sm-5 mt-2 mb-2"></div>
                                            <div className="user-label col-sm-5 mt-2 mb-2">
                                                <span>{i18n.t('admin.labels.description')}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <TextareaValidator
                                                        tabIndex='4'
                                                        wrapperClass=''
                                                        className={"form-control mt-2 mb-2"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t("admin.placeholder.description")}
                                                        name="description"
                                                        value={this.state.description}
                                                        noValidate
                                                        onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" data-bs-dismiss="modal" className="btn btn-lg btn-outline-primary">{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta">{i18n.t('admin.labels.btn_generate')}</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" onClick={this.handleBulkDiscountCodeSubmitWithCsv}>{i18n.t('admin.labels.btn_generate_export')}</button>
                                    {this.state.voucher_codes && this.state.voucher_codes.length > 0 ?
                                        <CSVLink
                                            id='export_codes_data'
                                            onClick={this.csvClicked}
                                            data={this.state.voucher_codes}
                                            filename={this.state.voucher_codes_headers}
                                            className="btn btn-primary"
                                            target="_blank"
                                            style={{visibility: 'hidden', width:'0', display: 'none'}}
                                        ></CSVLink> : null
                                    }
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                         </div>
                    </div>   
                </div>
            </div>
        )
    }
}

export default withTranslation() (Vouchers)