import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import { NavDropdown } from 'react-bootstrap';
import TextValidator from "../Common/TextValidator";
import SelectValidator from "../Common/SelectValidator";
import Errors from "../Common/Errors/Errors";
import { event } from "react-ga";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
class ActiveLearning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            libraries: [],
            per_page: 10,
            current_page: 1,
            total_count: 0,
            edit_item_id: null,
            isEditOpen: false,
            library_id: null,
            thumb_url: null,
            file_width: null,
            file_height: null,
            embed_html: null,
            orig_url: null,
            name: null,
            edit_name: null,
            edit_library_id: null,
            edit_thumb_url: null,
            edit_file_width: null,
            edit_file_height: null,
            edit_embed_html: null,
            edit_orig_url: null
        }
        // http://localhost:3000/react_admin/libraries
    }
    async componentDidMount() {
        await this.callApi(this.state.per_page, this.state.current_page)
        //per_page, current_page, current_type, current_status, htmlFormat = null, searchTerm = ''
    }
    async callApi(per_page, current_page) {
        this.setState({ data: [], isLoading: true });
        let { data } = await API.get('/active_learning_tutorials?per_page='+per_page+'&current_page='+current_page)
        let _libraries = []
        data.libraries.forEach(element => {
            _libraries.push({value: element.id, label: element.name})
        });
        this.setState({ data: data.items, total_count: data.total_count, libraries: _libraries, isLoading: false})
    }
    onTableChange = (type, newState) => {
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi(newState.sizePerPage, newState.page)
    }
    clearFields = () => {
        document.getElementById('library-form').reset();
    }

    toBulkUpload = () => {
        // window.location.href = '/admin/libraries/bulk_upload'
    }
    editItem = (event) => {
        let id = event.target.getAttribute('data-id');
        let itemInfo = this.state.data.find((element) => {
            return element.id == id
        })
        this.setState({ edit_item_id: itemInfo.id, edit_library_id: itemInfo.library_id, edit_name: itemInfo.name, edit_file_height: itemInfo.file_height, edit_file_width: itemInfo.file_width, edit_embed_html: itemInfo.embedHtml, edit_orig_url: itemInfo.orig_url, edit_thumb_url: itemInfo.thumb_url})

        this.showEditModal()
    }
    handleEditSubmit = (event) => {
        if (this.state.edit_name.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.labels.require_name'),
                status: null
            });
            return;
        }
        else if(this.state.edit_thumb_url.trim().length == 0) {
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t('admin.labels.require_thumb_url'),
                status: null
            }); 
          return;
        }
        this.setState({ isLoading: true });
        this.submitEditItem()
    }
    submitEditItem = () => {
        const formData = new FormData();
        formData.append('name', this.state.edit_name);
        formData.append('library_id', this.state.edit_library_id);
        formData.append('thumb_url', this.state.edit_thumb_url);
        formData.append('file_width', this.state.edit_file_width);
        formData.append('file_height', this.state.edit_file_height);
        formData.append('embed_html', this.state.edit_embed_html);
        formData.append('orig_url', this.state.edit_orig_url);
        API.put('/active_learning_tutorials/'+this.state.edit_item_id, formData)
          .then(res => {
            window.location.reload();
          })
          .catch(function (error) {
          })
    }
    handleOnError = errors => {
        this.setState({
          isValidate: true,
          isValid: false,
          hasError: true,
          errorMessage: getErrorMessage(errors, true),
          status: null
        });
    };
    showEditModal = () => {
        this.setState({ isEditOpen: true, isValidate: false,  isValid: true, hasError: false,errorMessage: [], status: null  })
    }
    hideEditModal = () => {
        this.setState({ isEditOpen: false })
    }
    deleteItem = (event) => {
        let id = event.target.getAttribute('data-id');
        let itemInfo = this.state.data.find((element) => {
            return element.id == id
        })
        
        API.delete('active_learning_tutorials/'+itemInfo.id)
          .then(res => {
            window.location.reload();
          })
          .catch(function (error) {
          })
    }
    handleSubmit = (event) => {
        this.setState({ isLoading: true });
        this.createNewTutorial()
    }
    createNewTutorial = () => {
        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('library_id', this.state.library_id);
        formData.append('thumb_url', this.state.thumb_url);
        formData.append('file_width', this.state.file_width);
        formData.append('file_height', this.state.file_height);
        formData.append('embed_html', this.state.embed_html);
        formData.append('orig_url', this.state.orig_url);
        API.post('/active_learning_tutorials', formData)
        .then(res => {
            window.location.reload();
        })
        .catch(function (error) {
        })
    }
    rankFormatter = (cell, row) => {
        let item_id = row.id
        return (
          <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item href={void (0)} data-id={item_id} onClick={this.editItem}>{i18n.t('admin.labels.edit')}</NavDropdown.Item>
            <NavDropdown.Item href={void (0)} data-id={item_id} onClick={this.deleteItem}>{i18n.t('admin.labels.delete')}</NavDropdown.Item>
          </NavDropdown>
        );
    }
    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        if (event.target.name === "fromDate") {
          this.setState({ toDate: '' })
        }
        this.setState({
          [event.target.name]: event.target.value,
          isLangChange
        });
    };
    render() {
        const columns = [{
            dataField: 'name',
            text: i18n.t('admin.labels.name').toUpperCase(),
            sort: true
          }, {
            dataField: 'orig_url',
            text: i18n.t('admin.labels.orig_url').toUpperCase(),
            sort: true,
          }, {
            dataField: 'thumb_url',
            text: i18n.t('admin.labels.thumb_url').toUpperCase(),
            sort: true
          }, {
            dataField: 'library_name',
            text: i18n.t('admin.labels.library_name').toUpperCase(),
            sort: true,
          },{
            dataField: 'moreOptions',
            formatter: this.rankFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
          }];
          const options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
          };
          const paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
          };
          const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
          }];

        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        }
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content libraries-tabel">
                        <div className="admin-custom-table-header">
                            <button data-bs-toggle="modal" data-bs-target="#addTutorialModal" className="btn btn-primary mb-3 mt-1 btn-add-user">{i18n.t('admin.activelearning.btn_add')}</button>
                        </div>
                        <BootstrapTable
                            keyField='id'
                            options={options}
                            data={this.state.data}
                            fetchInfo={{ dataTotalSize: this.state.total_count }}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            bordered={false}
                            noDataIndication={() => <NoDataIndication />}
                            remote
                            pagination={paginationFactory(paginatorOptions)}
                            onTableChange={this.onTableChange}
                            //rowEvents={rowEvents}
                        />
                    </div>
                    {/* Add Tutorials Modal */}
                    <div className="modal fade" id="addTutorialModal" tabIndex="-1">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <ValidatorForm
                                    id="addUserForm"
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    onError={this.handleOnError}
                                    instantValidate={false}
                                    className=""
                                >
                                    <div className="modal-header modal-image-header">
                                        <div className="modal-header-content">
                                            <h4 className="modal-title">{i18n.t("admin.activelearning.add_modal.title")}</h4>
                                        </div>
                                        <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="middle-sec">
                                            <div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('admin.labels.select_library')}</span>
                                                    </div>
                                                    <div>
                                                        <SelectValidator
                                                            tabIndex='1'
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            placeholder={i18n.t('admin.placeholder.select_library')}
                                                            name="library_id"
                                                            options={this.state.libraries}
                                                            value={this.state.library_id}
                                                            onChange={this.handleChange}
                                                            isValidate={this.state.isValidate}
                                                            validators={["required"]}
                                                            errorMessages={[
                                                                i18n.t('admin.errors.require_library')
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.name")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="name"
                                                            placeholder={i18n.t("admin.placeholder.name")}
                                                            value={this.state.name}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_name")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.thumb_url")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="thumb_url"
                                                            placeholder={i18n.t("admin.placeholder.thumb_url")}
                                                            value={this.state.thumb_url}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_thumb_url")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.file_width")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="file_width"
                                                            placeholder={i18n.t("admin.placeholder.file_width")}
                                                            value={this.state.file_width}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_file_width")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.file_height")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="file_height"
                                                            placeholder={i18n.t("admin.placeholder.file_height")}
                                                            value={this.state.file_height}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_file_height")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.embed_html")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="embed_html"
                                                            placeholder={i18n.t("admin.placeholder.embed_html")}
                                                            value={this.state.embed_html}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_embed_html")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t("admin.labels.orig_url")}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='2'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            type="text"
                                                            name="orig_url"
                                                            placeholder={i18n.t("admin.placeholder.orig_url")}
                                                            value={this.state.orig_url}
                                                            isValidate={this.state.isValidate}
                                                            validators={['required']}
                                                            onChange={this.handleChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            errorMessages={[
                                                                i18n.t("admin.errors.require_orig_url")
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal">Cancel</button>
                                        <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                            <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                            :
                                            <React.Fragment>{i18n.t('admin.labels.invite')}</React.Fragment>
                                        }</button>
                                    </div>
                                </ValidatorForm>
                                <Errors
                                    hasError={this.state.hasError}
                                    type="warning"
                                    message={this.state.errorMessage}
                                    status={this.state.status}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Edit Tutorials Modal */}
                    <div>
                        <Modal show={this.state.isEditOpen}>
                            <ValidatorForm
                            id="editItemForm"
                            ref="form"
                            onSubmit={this.handleEditSubmit}
                            onError={this.handleOnError}
                            instantValidate={false}
                            className=""
                            >
                            <div className="modal-header modal-image-header">
                                <div className="modal-header-content">
                                <h4 className="modal-title">{i18n.t('admin.activelearning.edit_modal.title')}</h4>
                                </div>
                                <button type="button" className="close" onClick={this.hideEditModal}>
                                <span className="lh-icon-os-close"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="middle-sec">
                                    <div>
                                        <div className="two-column-grid">
                                            <div className="user-label text-primary">
                                                <span>{i18n.t('admin.labels.select_library')}</span>
                                            </div>
                                            <div>
                                                <SelectValidator
                                                    tabIndex='1'
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    placeholder={i18n.t('admin.placeholder.select_library')}
                                                    name="edit_library_id"
                                                    options={this.state.libraries}
                                                    value={this.state.edit_library_id}
                                                    onChange={this.handleChange}
                                                    isValidate={this.state.isValidate}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        i18n.t('admin.errors.require_library')
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="two-column-grid">
                                            <div className="user-label text-primary">
                                                <span>{i18n.t("admin.labels.name")}</span>
                                            </div>
                                            <div>
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_name"
                                                    placeholder={i18n.t("admin.placeholder.name")}
                                                    value={this.state.edit_name}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_name")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="two-column-grid">
                                            <div className="user-label text-primary">
                                                <span>{i18n.t("admin.labels.thumb_url")}</span>
                                            </div>
                                            <div>
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_thumb_url"
                                                    placeholder={i18n.t("admin.placeholder.thumb_url")}
                                                    value={this.state.edit_thumb_url}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_thumb_url")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="two-column-grid">
                                            <div className="user-label text-primary">
                                                <span>{i18n.t("admin.labels.file_width")}</span>
                                            </div>
                                            <div>
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_file_width"
                                                    placeholder={i18n.t("admin.placeholder.file_width")}
                                                    value={this.state.edit_file_width}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_file_width")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="two-column-grid">
                                            <div className="user-label text-primary">
                                                <span>{i18n.t("admin.labels.file_height")}</span>
                                            </div>
                                            <div>
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_file_height"
                                                    placeholder={i18n.t("admin.placeholder.file_height")}
                                                    value={this.state.edit_file_height}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_file_height")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="two-column-grid">
                                            <div className="user-label text-primary">
                                                <span>{i18n.t("admin.labels.embed_html")}</span>
                                            </div>
                                            <div>
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_embed_html"
                                                    placeholder={i18n.t("admin.placeholder.embed_html")}
                                                    value={this.state.edit_embed_html}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_embed_html")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="two-column-grid">
                                            <div className="user-label text-primary">
                                                <span>{i18n.t("admin.labels.orig_url")}</span>
                                            </div>
                                            <div>
                                                <TextValidator
                                                    tabIndex='2'
                                                    wrapperClass=''
                                                    className={"form-control"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_orig_url"
                                                    placeholder={i18n.t("admin.placeholder.orig_url")}
                                                    value={this.state.edit_orig_url}
                                                    isValidate={this.state.isValidate}
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_orig_url")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.deleteItem} data-id={this.state.edit_item_id}>{i18n.t('admin.labels.delete')}</button>
                                <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" onClick={this.hideEditModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                :
                                <React.Fragment>{i18n.t('admin.btn_ok')}</React.Fragment>
                                }</button>
                            </div>

                            </ValidatorForm>
                            <Errors
                            hasError={this.state.hasError}
                            type="warning"
                            message={this.state.errorMessage}
                            status={this.state.status}
                            />
                            {/* </div>
                            </div> */}

                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

export default ActiveLearning;