import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';

const data = [
    { title: 'test', summary: 'demo', status: 'published', created_date: '01/12/2021'},
    { title: 'test2', summary: 'demo', status: 'published', created_date: '02/12/2021' },
    { title: 'test3', summary: 'demo', status: 'published', created_date: '03/12/2021' }
];

const articleList = [{
    dataField: 'title',
    text: 'title'.toUpperCase(),
    sort: true
}, {
    dataField: 'summary',
    text: 'summary'.toUpperCase(),
    sort: true
}, {
    dataField: 'status',
    text: 'status'.toUpperCase(),
    sort: true
}, {
    dataField: 'created_date',
    text: 'created date'.toUpperCase(),
    sort: true
}, {
    dataField: 'moreOptions',
    sort: true,
    formatter: articleActions,
}];

function articleActions() {
    return (
        <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item data-bs-toggle="modal" data-bs-target="#previewArticle">Preview</NavDropdown.Item>
            <NavDropdown.Item href="#">Unpublish</NavDropdown.Item>
            <NavDropdown.Item href="/admin/articles/edit">Edit</NavDropdown.Item>
            <NavDropdown.Item href="#">Delete</NavDropdown.Item>
        </NavDropdown>
    );
}

class ManageArticles extends React.Component {
    constructor(props) {
        super(props);
    }

    routeToNewPage = () => {
        window.location.href = '/admin/manage_pages/new'
    }
    routeToNewArticle = () => {
        window.location.href = '/admin/articles/new'
    }

    render() {
        const paginatorOptions = {
            pageStartIndex: 1,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };

        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <span className="page-header">Manage Articles</span>
                        <div>
                            <button className="btn btn-primary mb-2 mt-3 mr-3" onClick={this.routeToNewPage}>New Splash Page</button>
                            <button className="btn btn-primary mb-2 mt-3 mr-3" onClick={this.routeToNewArticle}>New Marketplace Articles</button>
                        </div>
                        <div className="article-table mt-2">
                            <BootstrapTable
                                keyField='title'
                                data={data}
                                columns={articleList}
                                bordered={false}
                                pagination={paginationFactory(paginatorOptions)}
                            />
                        </div>
                    </div>
                </div>

                {/* Preview Article Modal */}
                <div className="modal fade" id="previewArticle">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header modal-image-header">
                                <div className="modal-header-content">
                                    <h4 className="modal-title">Preview</h4>
                                </div>
                                <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                            </div>
                            <div className="modal-body">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManageArticles