import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
 
class CheckboxValidator extends ValidatorComponent {
    
    handleChange = event => {
        if(this.props.onChange) {
            this.props.onChange({
                ...event,
                target:{
                    name: event.target.name,
                    value: !(event.target.value.toString().trim() == "true")
                }
            })
        }
    }
    render() {
        const { errorMessages, validators, requiredError, validatorListener, isValidate, label, labelclass, onChange, showInputBottomError, ...rest} = this.props;
        const className = isValidate ? (!this.state.isValid ?
                          this.props.className + " was-validated is-invalid" 
                          : this.props.className +" is-valid") : this.props.className;
        return (
            <React.Fragment>
                <input
                    {...rest}
                    
                    ref={(r) => { this.input = r; }}
                    className={ className }
                    onChange={this.handleChange}
                />
                { label ? 
                    <label className={labelclass} htmlFor={this.props.name}>{ label }</label> : null }
                { showInputBottomError ? this.errorText() : null }
            </React.Fragment>
        );
    }
 
    errorText() {
        const { isValid } = this.state;
 
        if (isValid) {
            return null;
        }
 
        return (
            <div style={{ color: "red", textAlign: 'left'}}>
                {this.getErrorMessage()}
            </div>
        );
    }
}
 
export default CheckboxValidator;