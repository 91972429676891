import React from "react";

class EditArticle extends React.Component {
    constructor(props) {
        super(props);
    }

    routeBackToArticle = () => {
        window.location.href = '/admin/articles'
    }
    showFileName = () => {
        var image = document.getElementById('inputArticleImg');
        if (image.value == "" ) {
            fileLabel.innerHTML = "Choose file";
        }
        else {
            var theSplit = image.value.split('\\');
            fileLabel.innerHTML = theSplit[theSplit.length - 1];
        }
    }

    render() {
        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div className="row back-to-listing">
                            <span className="back-icon lh-icon-os-back" onClick={this.routeBackToArticle}>
                            </span>
                            <span className="oauth-view-header">Back</span>
                        </div>
                        <div className="row">
                            <div className="col back-to-listing" >
                                <span className="user-info pt-3">
                                    Edit Article
                                </span>
                            </div>
                        </div>
                        <form id="editArticleForm">
                            <div className="row mt-3">
                                <div className="col-md-12 library-content mt-5">
                                    <div className="col-md-6 mb-3 p-0">
                                        <div className="mt-2 mb-3">
                                            <div className="col-auto">
                                                <label htmlFor="title" className="col-form-label">Title</label>
                                            </div>
                                            <div className="col-auto">
                                                <input type="text" name="title" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="col-auto">
                                                <label htmlFor="summary" className="col-form-label">Summary</label>
                                            </div>
                                            <div className="col-auto">
                                                <input type="text" name="summary" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="col-auto">
                                                <label htmlFor="inputArticleImg" className="col-form-label">Featured Image</label>
                                            </div>
                                            <div className="col-auto">
                                                <div className="input-group mb-3">
                                                    <div className="custom-file">
                                                        <input type="file" onChange={this.showFileName} name="file" accept="image/png,image/gif,image/jpeg" className="custom-file-input" id="inputArticleImg" />
                                                        <label className="custom-file-label" id="fileLabel" for="inputArticleImg">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 p-0">
                                        <div className="mt-2 mb-3">
                                            <div className="col-auto p-0">
                                                <label htmlFor="content" className="col-form-label">Content </label>
                                            </div>
                                            <div className="col p-0" style={{ width: "80%" }}>
                                                <textarea name="content" rows="15" className="form-control">
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#previewEditArticle" className="btn btn-primary mr-3 mt-2">Preview</button>
                                            <button type="submit" className="btn btn-primary mr-3 mt-2">Save</button>
                                            <button type="submit" className="btn btn-primary mr-3 mt-2">Save & Publish</button>
                                            <button type="button" className="btn btn-primary mt-2" onClick={this.routeBackToArticle}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Preview Edit Article Modal */}
                <div className="modal fade" id="previewEditArticle">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header modal-image-header">
                                <div className="modal-header-content">
                                    <h4 className="modal-title">Preview</h4>
                                </div>
                                <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                            </div>
                            <div className="modal-body">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditArticle;