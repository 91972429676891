import React from 'react';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import { Link } from 'react-router-dom';
import { getLocalUrl, getUrlParameter } from '../Common/i3library';
import { withTranslation } from 'react-i18next';

class Fail extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const from = getUrlParameter('frm');
		return (
			<LandingStepBase container={
				<div className="container">
					<div className="row">
						<div className="col-sm-12 p-0 text-center">
							<div className="headtxt mt-5">
								<h1 className="text-white mb-3">{i18n.t('forgotpassword.fail.link_has_been_expired')}</h1>
								{
									from == 'pwd' ?
										<React.Fragment>
											<Link to={getLocalUrl(this.props.match.params.locale, 'forgotpassword')} className="btn btn-accenta"> {i18n.t('forgotpassword.fail.change_password')}</Link>
											{/* <button > {i18n.t('forgotpassword.fail.change_password')}</button> */}
											<p className="text-white m-3"> Or </p>
										</React.Fragment>
                  					:	null
								}
								<Link to={getLocalUrl(this.props.match.params.locale, 'login')} className="btn btn-outline-accenta"> {i18n.t('forgotpassword.fail.back_to_login')}</Link>
							</div>
						</div>
					</div>
				</div>}>
			</LandingStepBase>
		)
	}
}

export default withTranslation()(Fail);