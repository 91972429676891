import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import i18n from '../../packs/i18n';
import Select from 'react-select';
class SelectValidator extends ValidatorComponent {
 
    render() {
        const { 
            errorMessages, 
            validators, 
            requiredError, 
            validatorListener,
            isValidate, 
            label, 
            labelClass, 
            required,
            wrapperClass,
            showInputBottomError,
            ...rest } = this.props;
       
        const labelclass = labelClass ? labelClass : '';                         
        
        return (
            <React.Fragment>
                {label ? <label htmlFor={this.props.name} className={labelclass}>{ label } 
                        { required ? <span style={{ color: "#ffdccf"}}>*</span> : null }
                    </label> : null
                }
                <div className={wrapperClass}>
                    { this.props.isSearchable ? 
                        <Select
                            classNamePrefix="i3-spl-sel"
                            {...rest}
                            ref={(r) => { this.select = r; }}
                            style={{ border: (!this.state.isValid ? "1px solid #ffdccf" : '') }}
                        >   
                            { this.props.placeholder ? <option value={''}> {this.props.placeholder} </option> : null }
                            { this.props.options.map((option, key) => {
                                return <option key={key} value={option.value}> {option.label} </option>
                            })}
                        </Select>
                        : 
                        <select
                            {...rest}
                            ref={(r) => { this.select = r; }}
                            style={{ border: (!this.state.isValid ? "1px solid #ffdccf" : '') }}
                        >   
                            { this.props.placeholder ? <option value={''}> {this.props.placeholder} </option> : null }
                            { this.props.options.map((option, key) => {
                                return <option key={key} value={option.value}> {option.label} </option>
                            })}
                        </select>
                    }
                    
                    { showInputBottomError ? this.errorText() : null }
                </div>
            </React.Fragment>
        );
    }
 
    errorText() {
        const { isValid } = this.state;
 
        if (isValid) {
            return null;
        }
 
        return (
            <div style={{ color: 'red', textAlign: 'left' }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}
 
export default SelectValidator;