import React from "react";
import i18n from "../../packs/i18n";
import { debounce, getUserInfo, updateUserInfo, isProTagAdd } from "../Common/i3library";
import Switch from "../Common/Switch";
import Select from "../Common/Select";
import API from '../api';

class Preferences extends React.Component {
    constructor(props) {
        super(props);
        if(isProTagAdd()) {
            props.history.goBack();
        }
        const userInfo = getUserInfo();
        this.state = {
            is_full_screen: userInfo.is_full_screen,
            is_favorite: userInfo.is_favorite,
            is_double_tap: userInfo.is_double_tap,
            is_keyboard: userInfo.is_keyboard,
            autosave: userInfo.autosave,
            splashscreen: userInfo.splashscreen,
            keyboard_language: userInfo.keyboard_language,
            preferred_ratio: userInfo.preferred_ratio,
            is_imperial_notation: userInfo.is_imperial_notation || false,
            display_compass_point: userInfo.display_compass_point || false
        }
        this.callApi =  debounce(this.callApi,300);
    }
    callApi(){
        
        API.put('/users/update_preferences',{ preferences: this.state })
        .then(res => {
            //console.log(res.data);
            const { data } = res.data;
            updateUserInfo(data);
        })
        .catch( (error) => {
            if(error.response){
                
            }
            if(error.request){
               //console.log(error.request);
            }
            else{
                //console.log(error);
            }
        })
        .finally(() => {
            // always executed
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.callApi();
    };

    render() {
        const userInfo = getUserInfo();
        const keyboards = userInfo.list_keyboard_languages.map(keyboard => {
            return { label: keyboard[0], value: keyboard[1]}
        })
        const ratios = userInfo.list_preferred_ratios.map(ratio => {
            return { label: ratio[0], value: ratio[1]}
        })
        return (
            <div className="profile-main-wrapper">
                <div className="white-cover-fixheight">
                    <h3 className="text-primary mb-3"> { i18n.t('userprofile.preference.heading') }</h3>
                    <p className="mb-4 text-xs text-secondary">
                    { i18n.t('userprofile.preference.sub_text') }{" "}
                    </p>
                    <Switch
                        wrapperClass="col-12 pl-0"
                        className="switch mr-3"
                        type="checkbox" 
                        name="is_favorite" 
                        checked={this.state.is_favorite} 
                        onChange={this.handleChange}
                        suffixLabel={ i18n.t('userprofile.preference.show_favorites')}
                        labelClass="text-secondary"
                        value={this.state.is_favorite}
                    />
                    <Switch
                        wrapperClass="col-12 pl-0"
                        className="switch mr-3"
                        type="checkbox" 
                        name="is_double_tap" 
                        checked={this.state.is_double_tap} 
                        onChange={this.handleChange}
                        suffixLabel={ i18n.t('userprofile.preference.double_tap_select')}
                        labelClass="text-secondary"
                        value={this.state.is_double_tap}
                    />
                    <Switch
                        wrapperClass="col-12 pl-0"
                        className="switch mr-3"
                        type="checkbox" 
                        name="is_keyboard" 
                        checked={this.state.is_keyboard} 
                        onChange={this.handleChange}
                        suffixLabel={ i18n.t('userprofile.preference.i3_keyboard')}
                        labelClass="text-secondary"
                        value={this.state.is_keyboard}
                    />
                    <Switch
                        wrapperClass="col-12 pl-0"
                        className="switch mr-3"
                        type="checkbox" 
                        name="autosave" 
                        checked={this.state.autosave} 
                        onChange={this.handleChange}
                        suffixLabel={ i18n.t('userprofile.preference.auto_save')}
                        labelClass="text-secondary"
                        value={this.state.autosave}
                    />
                    <Switch
                        wrapperClass="col-12 pl-0"
                        className="switch mr-3"
                        type="checkbox" 
                        name="splashscreen" 
                        checked={this.state.splashscreen} 
                        onChange={this.handleChange}
                        suffixLabel={ i18n.t('userprofile.preference.hide_splash_screen')}
                        labelClass="text-secondary"
                        value={this.state.splashscreen}
                    />
                    <Switch
                        wrapperClass="col-12 pl-0"
                        className="switch mr-3"
                        type="checkbox"
                        name="is_imperial_notation"
                        checked={this.state.is_imperial_notation}
                        onChange={this.handleChange}
                        suffixLabel={ i18n.t('userprofile.preference.imperial_notation')}
                        labelClass="text-secondary"
                        value={this.state.is_imperial_notation}
                    />
                    <Switch
                        wrapperClass="col-12 pl-0"
                        className="switch mr-3"
                        type="checkbox"
                        name="display_compass_point"
                        checked={this.state.display_compass_point}
                        onChange={this.handleChange}
                        suffixLabel={ i18n.t('userprofile.preference.compass_point')}
                        labelClass="text-secondary"
                        value={this.state.display_compass_point}
                    />
                    <div className="mt-3">
                        <label
                            htmlFor={"keyboard_language"}
                            className={"col-form-label text-secondary"}
                        >
                            {i18n.t('userprofile.preference.set_default_keyboard_language')}{" "}
                        </label>
                        <div className={"col-md-8 col-xs-12 p-0"}>
                            <Select
                                className={"form-control form-control"}
                                autoComplete="off"
                                name="keyboard_language"
                                value={this.state.keyboard_language}
                                onChange={this.handleChange}
                                options={keyboards}
                                />
                        </div>
                    </div>
                    <div className="">
                        <label
                            htmlFor={"preferred_ratio"}
                            className={"col-form-label text-secondary"}
                        >
                            {i18n.t('userprofile.preference.set_preference_ratio_for_boards')}{" "}
                        </label>
                        <div className={"col-md-8 col-xs-12 p-0"}>
                            <Select
                                className={"form-control form-control"}
                                autoComplete="off"
                                name="preferred_ratio"
                                value={this.state.preferred_ratio}
                                onChange={this.handleChange}
                                options={ratios}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Preferences;
