import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

function BasicExample() {
  return (
    <Router>
      <div>
        
      <DevNav></DevNav>
        <hr />
        
        <Route exact path="/" component={Home} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgotpassword" component={ForgotPassword} />
      </div>
    </Router>
  );
}

function DevNav() {
  return (
    <ul>
          <li>
            <Link to="/">AAAAAAAAAAA</Link>
          </li>
          <li>
            <Link to="/signup">BBBBBBBBBBBBB</Link>
          </li>
          <li>
            <Link to="/forgotpassword">CCCCCCCCCCCCC</Link>
          </li>
        </ul>
  );
}

function Home() {
  return (
    <div>
      <h2>Homex</h2>
    </div>
  );
}

function Signup() {
  return (
    <div>
      <h2>Signupx</h2>
    </div>
  );
}

function ForgotPassword({ match }) {
  return (
    <div>
      <h2>ForgotPasswordx</h2>
      <ul>
        <li>
          <Link to={`${match.url}/rendering`}>Rendering with React</Link>
        </li>
        <li>
          <Link to={`${match.url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
        </li>
      </ul>

      <Route path={`${match.path}/:topicId`} component={Topic} />
      <Route
        exact
        path={match.path}
        render={() => <h3>Please select a topic.</h3>}
      />
    </div>
  );
}

function Topic({ match }) {
  return (
    <div>
      <h3>{match.params.topicId}</h3>
    </div>
  );
}

export default BasicExample;
