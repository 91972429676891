// PAYMENT STEP #2 : signup/paymentmethod
import React from 'react';
import ReactDOM from 'react-dom';
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import API from '../api';
import { debounce, getLocalUrl } from '../Common/i3library';
import Success from '../Common/Errors/Sucess';
import Errors from '../Common/Errors/Errors';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../Common/TextValidator';
import SelectValidator from '../Common/SelectValidator';
import { getErrorMessage } from '../Common/Helpers/AppHelper';
import { withTranslation } from 'react-i18next';
import { PACK_FREE_IMG, PACK_INDI_IMG, PACK_TEAM_IMG } from "../Common/assets_paths";
import { createEvent } from '../Common/AnalyticsEvents';

class PaymentMethod extends React.Component{
    constructor(props) {
        super(props);
        let sub_plans_options = [];
        const { state } = props.location;
        if(!state){
            props.history.goBack();
        }
        if(props.location.state.sub_plans){
            sub_plans_options = this.handleSubPlanOptions(props.location.state.sub_plans);
        }
        const userInfo = this.props.location.state && this.props.location.state.userInfo ? this.props.location.state.userInfo: null;
        const inst_name = userInfo && userInfo.institution_name ?userInfo.institution_name:"";
        
        let img_path = null;
        let pack_color = null;
        if(this.props.location.state.planType === "free"){
            img_path = PACK_FREE_IMG;
            pack_color = "#e5c5e8";
        }
        else if(this.props.location.state.planType === 'individual'){
            img_path = PACK_INDI_IMG
            pack_color = "#acb5da";
        }
        else if(this.props.location.state.planType === 'team'){
            img_path = PACK_TEAM_IMG
            pack_color = "#bfd0e8";
        }
        this.state = {
            voucherCode:"",
            cuurent_option_selected: "year",
            voucherInfo:null,
            hasError: false,
            errorMessage: null,
            isValid:false,
            team_size: sub_plans_options.length > 0 ? sub_plans_options[0].value : '',
            team_name: inst_name,
            hasCoupenError: false,
            sub_plans_options: sub_plans_options,
            hasTeamPlan: this.props.location.state.planType == 'team',
            monthly_price: props.location.state.monthly_price,
            yearly_price: props.location.state.yearly_price,
            you_save_price: ((props.location.state.monthly_price * 12) - props.location.state.yearly_price),
            disable_submit_btn : false,
            status: null,
            isCoupenApply:false,
            package_image: img_path
        }
        this.teamsizeRef = React.createRef();
        this.callApi =  debounce(this.callApi,300);
    }

    componentDidMount(){
        //ILEARN-3902 Fixes for the individual issues
        if(this.teamsizeRef && this.teamsizeRef.current){
            const value = this.teamsizeRef.current.select.value;
            this.handleTeamSizeSelect("team_size", value)
        }
    }

    handleSubPlanOptions(sub_plans) {
        let sub_plans_options = [];
        const { currentPlan } = this.props.location.state;

        if(currentPlan) {
            delete sub_plans[currentPlan.key];
            if(currentPlan && currentPlan.key == "team_intermediate") {
                delete sub_plans['team_basic'];
            } else if(currentPlan && currentPlan.key == "team_advanced") {
                delete sub_plans['team_basic'];
                delete sub_plans['team_intermediate'];
            }
        }
        if(Object.keys(sub_plans).length > 0) {
            Object.keys(sub_plans).forEach(function(key) {
                const plan = sub_plans[key];
                const lab = plan.min_user + '-' + plan.max_user + '  (' +  plan.display_text + ')';
                sub_plans_options.push({label:lab, value:key});
            }.bind(this))
        }
        return sub_plans_options;
    }

    handleRadio = event => {
      this.setState({
        cuurent_option_selected: event.target.value
      });
      if(this.state.voucherCode && this.state.voucherCode.toString().length > 0){
        this.callApi();
      }
    }
    handleChange = event => {
      this.setState({ disable_submit_btn : true });
      this.setState({
        [event.target.id]: event.target.value
      });
      this.callApi();
    }

    handleTeamSizeSelect = (name, value) => {
        const currentPlan = this.props.location.state.sub_plans[value];
        this.setState({
          monthly_price: currentPlan.monthly_price,
          yearly_price: currentPlan.yearly_price,
          you_save_price: ((currentPlan.monthly_price * 12) - currentPlan.yearly_price),
          [name]: value
        });
        if(this.state.voucherCode && this.state.voucherCode.toString().length > 0){
            this.callApi();
        }
    }

    handleFormChange = () => {
        if(event.target.name === 'team_size'){
            this.handleTeamSizeSelect(event.target.name, event.target.value);
        }
        else{
            this.setState({
                [event.target.name]: event.target.value
            });
        }

    }
    callApi(){
        //Handling for In between voucher code became empty 
        if(!this.state.voucherCode){
          this.setState({
            hasCoupenError:false,
            errorMessage:null,
            isValid:null,
            status: 422,
            isCoupenApply: false,
            disable_submit_btn : false,
            you_save_price: null,
            voucherInfo: null
          })
          return;
        }
        // END
        this.setState({
            isValidate: false,
            hasError: false
        });
        const subscription_type = this.state.cuurent_option_selected == "month" ? 'monthly' : 'yearly';
        const planType = this.state.team_size ? this.state.team_size : this.props.location.state.planType;
        API.get('/coupon/'+encodeURIComponent(this.state.voucherCode)+"?plan="+planType+"&subscription_type="+subscription_type)
        .then(res => {
            if(typeof res.data === 'object'){
                this.setState({
                   voucherInfo: res.data,
                   isValid:true,
                   hasCoupenError: false,
                   errorMessage:i18n.t('signup.step5.errors.200'),
                   disable_submit_btn : false,
                   status: res.status,
                   isCoupenApply: true,
                   you_save_price: null
                })
            } else {
              this.setState({
                hasCoupenError:false,
                isValid: false,
                voucherCode:"",
                voucherInfo:null,
                disable_submit_btn : false,
                isCoupenApply: false,
                you_save_price: ((this.state.monthly_price * 12) - this.state.yearly_price)
              })
            }
        })
        .catch( (error) => {
          if(error.response){
            console.log(error.response.status)
            switch(error.response.status){
              case 422:
                this.setState({
                  hasCoupenError:true,
                  errorMessage:i18n.t('signup.step5.errors.422'),
                  isValid:false,
                  status: error.response.status,
                  isCoupenApply: false
                })
                break;
              case 301:
                this.setState({
                  hasCoupenError:true,
                  errorMessage:i18n.t('signup.step5.errors.301'),
                  isValid:false,
                  status: error.response.status,
                  isCoupenApply: false
                })
                break;
              case 412:
                this.setState({
                  hasCoupenError:true,
                  errorMessage:i18n.t('signup.step5.errors.412'),
                  isValid:false,
                  status: error.response.status,
                  isCoupenApply: false
                })
                break;
              case 413:
                  this.setState({
                    hasCoupenError:true,
                    errorMessage:i18n.t('signup.step5.errors.413'),
                    isValid:false,
                    status: error.response.status,
                    isCoupenApply: false
                  })
                  break;
              case 500:
                this.setState({
                  hasCoupenError:true,
                  errorMessage:i18n.t('signup.step5.errors.500'),
                  isValid:false,
                  status: error.response.status,
                  isCoupenApply: false
                })
                break;
              default:
                this.setState({
                  hasCoupenError:false,
                  isValid: false,
                  voucherCode:"",
                  voucherInfo:null,
                  disable_submit_btn : false,
                  isCoupenApply: false,
                  you_save_price: ((this.state.monthly_price * 12) - this.state.yearly_price)
                })
                break;
            }
          }
          if(error.request){
            // console.log(error.request);
          }
          else{
            // console.log(error);
          }
        })
        .finally(() => {
          // always executed
        });
    }
    redirectToPayment(calcPrice,orgPrice){
      let sendEventmsg =  this.state.cuurent_option_selected;
      sendEventmsg+= this.state.hasTeamPlan? "__"+this.state.team_size : "__"+this.props.location.state.planType;
      
     
      
      const { currentPlan, upgradePlan } = this.props.location.state;
      if(upgradePlan)
        createEvent("Upgrade", "Payment Initiated", sendEventmsg);
      else
        createEvent("Signup", "Payment Initiated", sendEventmsg);
      this.props.history.push({
        pathname: getLocalUrl(this.props.match.params.locale,('signup/payment/' + this.props.match.params.token_id)),
        state: { planDetail:this.props.location.state, selectedType: this.state.cuurent_option_selected,voucherCode:this.state.voucherCode,voucherInfo:this.state.voucherInfo, selected_sub_plan: this.state.team_size, you_save_price: this.state.you_save_price, team_name: this.state.team_name, currentPlan: currentPlan, upgradePlan}
      });
    }
    handleOnError = errors => {
      this.setState({
        isValidate: true,
        hasError: true,
        isValid: false,
        errorMessage: getErrorMessage(errors, true),
        status: null
      });
    };

    handleSubmit = (e) => {
      if(!this.state.hasCoupenError)
          this.redirectToPayment();
    }

    render(){
        const userInfo = this.props.location.state && this.props.location.state.userInfo ? this.props.location.state.userInfo: null;
        const inst_name = userInfo && userInfo.institution_name ?userInfo.institution_name:null;
        const monthly_price = !this.state.isCoupenApply ? this.state.monthly_price : this.state.voucherInfo.monthly_discounted_amount;
        const yearly_price = !this.state.isCoupenApply ? this.state.yearly_price : this.state.voucherInfo.yearly_discounted_amount;
        const you_save_price = !this.state.isCoupenApply ? this.state.you_save_price : null;
        return (
            <LandingStepBase container={
                <div className="container">
             <div className="row">
                <div className="col-sm-12 p-0 text-center">
                    <div className="headtxt mt-5">
                        <h1 className="text-white mb-0">{i18n.t('signup.step4.header.title')}{this.props.location.state.display_text?i18n.t(this.props.location.state.display_text).toLowerCase():'free'}{i18n.t('signup.step4.header.title2')}</h1>
                        <p>{i18n.t('signup.step4.header.sub_title')}</p>
                        <div className="steps">
                            <span className="completed step mr-2 ml-2"></span><span className="completed  step  mr-2 ml-2"></span><span className="completed step  mr-2 ml-2"></span><span className="activestep step mr-2 ml-2"></span><span className="step mr-2 ml-2"></span>
                        </div>
                        <p className="mt-2 text-quaternary text-size-20">{i18n.t('signup.step4.header.current_step')}</p>
                    </div>
                    <div className="main-wrapper-lg text-center text-primary">
                    <div className="white-cover">
                            <p className="text-size-22">{i18n.t('signup.step4.payment_methd_txt')}</p>
                            <div className="payment-option-wrapper mb-2">
                                <div className={"payment-option " + (this.state.cuurent_option_selected == 'month' ? 'shadow-lg' : '') }>
                                    <label className="custom-control-selection" htmlFor="chk1"></label>
                                    <div className="custom-control custom-radio selector-tick">
                                        <input type="radio" className="custom-control-input" id="chk1" name="paymenttype" value="month" onChange={this.handleRadio} />
                                        <label className="custom-control-label" htmlFor="chk1"></label>
                                    </div>
                                    <img src={this.state.package_image} /> 
                                    <div className="option-details">
                                        <h5 className="text-secondary">{i18n.t(this.props.location.state.display_text)}</h5>
                                        <p className="text-accentb m-0 mb-1">€ <span className="pay-price">{monthly_price}</span> /{i18n.t("signup.step5.month_txt")}</p>
                                    </div>
                                </div>
                                <div className={"payment-option " + (this.state.cuurent_option_selected == 'year' ? 'shadow-lg' : '')}>
                                    <label className="custom-control-selection" htmlFor="chk2"></label>
                                    <div className="custom-control custom-radio selector-tick">
                                        <input type="radio" className="custom-control-input" id="chk2" name="paymenttype" defaultChecked="checked" value="year" onChange={this.handleRadio}  />
                                        <label className="custom-control-label" htmlFor="chk2"></label>
                                    </div>
                                    <img src={this.state.package_image} />
                                    <div className="option-details">
                                        <h5 className="text-secondary">{i18n.t(this.props.location.state.display_text)}</h5>
                                        <p className="text-accentb m-0 mb-1">€ <span className="pay-price">{yearly_price}</span> /{i18n.t("signup.step5.year_txt")}</p>
                                        {
                                          you_save_price ? <span className="text-accenta">({i18n.t("signup.step5.save_txt")} {you_save_price}€)</span> : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <ValidatorForm
                                ref="form"
                                instantValidate={false}
                                onSubmit={this.handleSubmit}
                                onKeyPress={event => {
                                    if (event.which === 13) {
                                      event.preventDefault();
                                    }
                                }}
                                onError={this.handleOnError}>
                                { this.state.hasTeamPlan ?
                                    <div className="payment-option-wrapper mb-3">
                                        <div className="col-sm-6">
                                            <p className="text-size-22 text-left">{i18n.t('signup.step4.team_name_label')}</p>

                                            <TextValidator
                                                className={"form-control"}
                                                type="text"
                                                name="team_name"
                                                placeholder={i18n.t('signup.step4.enter_team_name')}
                                                value={this.state.team_name}
                                                onChange={this.handleFormChange}
                                                isValidate={this.state.isValidate}
                                                validators={["required", "maxStringLength:50"]}
                                                errorMessages={[
                                                    i18n.t("signup.step4.errors.team_name_required"),
                                                    i18n.t("signup.step4.errors.team_name_max")
                                                ]}
                                                disabled = {inst_name? true : false}
                                                noValidate
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <p className="text-size-22 text-left">{i18n.t('signup.step4.team_size_label')}</p>
                                            <SelectValidator
                                                ref={this.teamsizeRef}
                                                className={"form-control"}
                                                autoComplete="off"
                                                name="team_size"
                                                options={this.state.sub_plans_options}
                                                value={this.state.team_size}
                                                onChange={this.handleFormChange}
                                                isValidate={this.state.isValidate}
                                                noValidate
                                                validators={["required"]}
                                                errorMessages={[
                                                    i18n.t("signup.step4.errors.team_size_required")
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    : null
                                }
                                <p className="mt-3 text-size-22">{i18n.t('signup.step4.enter_promo_txt')}</p>
                                <div className="promo-wrapper">
                                    <input type="text" autoComplete="off" className={'form-control form-control-lg text-center '+(this.state.hasCoupenError?'was-validated is-invalid':(this.state.isValid?'is-valid':'')) }  placeholder="________"  id="voucherCode" value={this.state.voucherCode} onChange={this.handleChange}/>
                                    <button type="submit" className="btn btn-accenta btn-block mb-2 mt-4 text-size-20" disabled={this.state.disable_submit_btn}>{i18n.t('signup.step4.confirm_btn')}</button>
                                </div>
                            </ValidatorForm>
                        </div>
                        <Errors hasError={this.state.hasError || this.state.hasCoupenError} type='warning' message={this.state.errorMessage} status={this.state.status}/>
                        <Success hasError={this.state.isValid} type='warning' message={this.state.errorMessage}/>
                    </div>
                </div>
            </div>
            </div>} subcontainer={null}></LandingStepBase>
        )
    }

}

export default withTranslation()(PaymentMethod);
