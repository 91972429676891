/* React component Created for managing Forgot Password Functionality */
import React from 'react'
import LandingStepBase from '../Common/Layouts/LandingStepBase';
import i18n from '../../packs/i18n';
import API from '../api';
import Errors from '../Common/Errors/Errors';
import { getErrorMessage } from '../Common/Helpers/AppHelper';
import { withTranslation } from 'react-i18next';
import { getAvailableLanguages, getQueryParam } from '../Common/i3library';
import LanguageSelection from '../LanguageOptions/LanguageSelection';

class AcceptOrDecline extends React.Component {
	constructor(props) {

		super(props);
console.log(props)
		const language = this.props.match.params ? this.props.match.params.locale : 'en';
		const languagesOptions = getAvailableLanguages();
		this.state = {
			id: null,
			invited_team_name: null,
      language: language,
      languageOpt: languagesOptions
		};
	}
	componentDidMount() {
		const values = getQueryParam(this.props.location.search)
		if(values.team_invitation_token) {
			this.setState({
				id: values.team_invitation_token,
				invited_team_name: values.team_name
			});
		}
	}
	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleOnError = errors => {
		this.setState({
			isValidate: true,
			hasError: true,
			errorMessage: getErrorMessage(errors, true),
			status: null
		});
	}

	acceptInvitation = event => {
		const invitation = {
			accept: true
		};
		const _this = this;
		API.patch(`invitations/${this.state.id}`, invitation)
			.then(res => {
				if(res.data.redirect_path) {
          window.location = res.data.redirect_path;
        } else {
          window.location = "/";
        }
			})
			.catch( (error) => {
				if (error.response) {
					_this.setState({
						hasError: true,
						isValidate: true,
						errorMessage: error.response.data.message || i18n.t('login.errors.wrong_credential'),
						status: error.response.status
					})
				}
			})
			.finally(function () {
				// always executed
			});
	}
	declineInvitation = event => {
		const invitation = {
			decline: true
		};
		const _this = this;
		API.patch(`invitations/${this.state.id}`, invitation)
			.then(res => {
				if(res.data.redirect_path) {
          window.location = res.data.redirect_path;
        } else {
          window.location = "/";
        }
			})
			.catch( (error) => {
			})
			.finally(function () {
			});
	}

	render() {
		return (

			<React.Fragment>
				<LandingStepBase container={
					<div className="container">
					<div className="row">
						<div className="col-sm-12 p-0 text-center">
							<div className="headtxt animated fadeInDown mt-5">
								<h1 className="text-white">
									{i18n.t('invitations.accept_decline.message')}
									<br />
									<div className="mt-4">
									{this.state.invited_team_name}
									</div>
								</h1>
							</div>
						</div>
					</div>
					<div className="row col-sm-8 accept_decline_btn_section" style={{margin: '0 auto'}}>
						<div className="col-sm-6">
							<a href="javascript:void(0);" onClick = {this.acceptInvitation} className={'btn btn-accenta btn-lg btn-block mt-4'}> Accept</a>
						</div>
						<div className="col-sm-6">
							<a href="javascript:void(0);" onClick = {this.declineInvitation} className={'btn btn-warning btn-lg btn-block mt-4 bg-tertiary shape mx-auto'}> Decline</a>
						</div>
					</div>
					<Errors hasError={this.state.hasError} status={this.state.status} type="warning" message={this.state.errorMessage}/>
					<LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="mobile mt-3" redirectPath={"invitations/accept"+this.props.location.search} />
				</div>}>
			</LandingStepBase>
			<LanguageSelection language={this.state.language} languageOpt={this.state.languageOpt} className="desktop" redirectPath={"invitations/accept"+this.props.location.search} />
		</React.Fragment>
		);
	}
}

export default withTranslation()(AcceptOrDecline);
