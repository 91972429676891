import React from "react";
import i18n from "../../packs/i18n";
import { getUserInfo, isProTagAdd } from "../Common/i3library";
import API from "../api";
import SweetModelAlert from "../Common/SweetModelAlert";
import Success from '../Common/Errors/Sucess';
import Errors from "../Common/Errors/Errors";
import { getUrlParameter } from '../Common/i3library';
import {createEvent} from '../Common/AnalyticsEvents';

class LinkAccounts extends React.Component {
    constructor(props) {
        super(props);
        const userInfo = getUserInfo();
        const alreadyConnected = getUrlParameter('exist');

        if(isProTagAdd()) {
            props.history.goBack();
        }
        this.state = {
            isGoogle: ( (userInfo.linked_accounts.indexOf('google')  !== -1 ) || (userInfo.oauth_connections.indexOf('google') !== -1) ) ? true : false,
            isMicrosoft: ( (userInfo.linked_accounts.indexOf('microsoft')  !== -1) || (userInfo.oauth_connections.indexOf('microsoft')  !== -1) ) ? true : false,
            isOffice365: ( (userInfo.linked_accounts.indexOf('office365') !== -1) || (userInfo.oauth_connections.indexOf('office365')  !== -1 ) ) ? true : false,
            isFaceBook: ( (userInfo.linked_accounts.indexOf('facebook') !== -1 ) || (userInfo.oauth_connections.indexOf('facebook') !== -1 ) ) ? true : false,
            isSmartSchool: ( (userInfo.linked_accounts.indexOf('smartschool') !== -1) || (userInfo.oauth_connections.indexOf('smartschool') !== -1) ) ? true : false,
            isKlascement: ( (userInfo.linked_accounts.indexOf('klascement') !== -1 ) || (userInfo.oauth_connections.indexOf('klascement') !== -1) ) ? true : false,
            isScoodle: (userInfo.linked_accounts.indexOf('scoodle') !== -1) || (userInfo.oauth_connections.indexOf('scoodle') !== -1 ) ? true : false,
            modalShow: false,
            name: '',
            hasError: alreadyConnected ? true : false,
            errorMessage: alreadyConnected ? i18n.t('userprofile.link_accounts.err_msg_alredy_connected') : "",
            successMessage: "",
            isValidate: false,
            isValid: false,
            status: null
        }
    }

    componentDidMount = () => {
        const successfullyConnected = getUrlParameter('connected');
        const connectedAppName = getUrlParameter('app_name');
        if(successfullyConnected) {
            let sucessmessage = successfullyConnected == "true"? i18n.t('userprofile.link_accounts.authorize_app',{ app : connectedAppName })  : successfullyConnected == "false" ? i18n.t('userprofile.link_accounts.un_authorize_app',{ app : connectedAppName }) : ""
            this.setState({
                hasError: false,
                isValid: true,
                successMessage: sucessmessage
            })
        }
    }

    displayButtonClass = (name) => {
        const btn_active = 'btn btn-block  btn-secondary mb-4';
        const btn_inactive = 'btn btn-block btn-outline-secondary mb-4';
        switch(name){
            case 'google':
                return this.state.isGoogle?btn_active:btn_inactive;
                break;
            case 'microsoft':
                return this.state.isMicrosoft?btn_active:btn_inactive;
                break;
            case 'office365':
                return this.state.isOffice365?btn_active:btn_inactive;
                break;
            case 'facebook':
                return this.state.isFaceBook?btn_active:btn_inactive;
                break;
            case 'smartschool':
                return this.state.isSmartSchool?btn_active:btn_inactive;
                break;
            case 'klascement':
                return this.state.isKlascement?btn_active:btn_inactive;
                break;
            case 'scoodle': 
                return this.state.isScoodle?btn_active:btn_inactive;
                break;
            default:
                return btn_inactive
                break;
        }
    }
    handleRedirectToApp = (name) => {
        switch(name){
            case 'google':
              if(this.state.isGoogle) {
                this.setState({
                  'isGoogle':!this.state.isGoogle
                });
              }
                this.redirect(this.state.isGoogle, 'google');
                break;
            case 'microsoft':
              if(this.state.isMicrosoft) {
                this.setState({
                  'isMicrosoft':!this.state.isMicrosoft
                });
              }
              this.redirect(this.state.isMicrosoft, 'microsoft');
              break;
            case 'office365':
              if(this.state.isOffice365) {
                this.setState({
                  'isOffice365':!this.state.isOffice365
                });
              }
              this.redirect(this.state.isOffice365, 'office365');
              break;
            case 'facebook':
              if(this.state.isFaceBook) {
                this.setState({
                  'isFaceBook':!this.state.isFaceBook
                });
              }
              this.redirect(this.state.isFaceBook, 'facebook');
              break;
            case 'smartschool':
              if(this.state.isSmartSchool) {
                this.setState({
                  'isSmartSchool':!this.state.isSmartSchool
                });
              }
              this.redirect(this.state.isSmartSchool, 'smartschool');
              break;
            case 'klascement':
              if(this.state.isKlascement) {
                this.setState({
                  'isKlascement':!this.state.isKlascement
                });
              }
              this.redirect(this.state.isKlascement, 'klascement');
              break;
            case 'scoodle':
                if(this.state.isScoodle) {
                    this.setState({
                      'isScoodle':!this.state.isScoodle
                    });
                  }
                  this.redirect(this.state.isScoodle, 'scoodle');
                  break;


        }
    }
    redirect = (value,providerId) => {
        if(value){
            //Disconnect the apps
            API.get('/disconnect/'+providerId+'?source=connect')
            .then(res => {
                createEvent("delink", "account_"+providerId+"_success");
                this.setState({ modalShow : false });
                this.props.callUserInfo();
                if(res.data && res.data.redirect_link)
                    window.location.href = res.data.redirect_link

            })
            .catch(function(error){
                createEvent("delink", "account_"+providerId+"_fail");
            })

        }
        else{
            //Redirect to the perticular apps
            API.get('/link/'+providerId)
            .then(res => {
                createEvent("link", "account_"+providerId+"_initiated");
                window.location.href = res.data.link_url
            })
            .catch(function(error){
                createEvent("link", "account_"+providerId+"_initfail");
            })
        }

    }

    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };

    handleLinkOption = (name, isLinked) => {
        if(isLinked){
            this.setState({ modalShow : true, name });
        }else{
            this.handleRedirectToApp(name);
        }
    }

    handleConfirmClick = () => {
        this.handleRedirectToApp(this.state.name);
    }

    handleCancelClick = () => {
        this.setState({ modalShow : false })
    }

    render() {
        const isMicrosoft = this.state.isMicrosoft;
        const isGoogle = this.state.isGoogle;
        const isOffice365 = this.state.isOffice365;
        const isFaceBook = this.state.isFaceBook;
        const isSmartSchool = this.state.isSmartSchool;
        const isKlascement = this.state.isKlascement;
        const isScoodle = this.state.isScoodle;
        return (
            <React.Fragment>

                <div className="profile-main-wrapper">
                    <div className="white-cover-fixheight">
                        <h3 className="text-primary mb-5">{i18n.t('userprofile.link_accounts.heading')}</h3>
                        <div className="mx-auto connect-to-app">
                            <button type="button" className = {this.displayButtonClass('google')} id='google' onClick={(e) => this.handleLinkOption('google', isGoogle)}>
                                <div className="btn-icon-wrapper">
                                    <i className="lh-icon-service-google"></i>
                                    <span className="label">{isGoogle && 'Unlinked'}&nbsp;{i18n.t('userprofile.link_accounts.google')}</span>
                                </div>
                            </button>
                            <button type="button" className = {this.displayButtonClass('office365')} id='office365' onClick={(e) => this.handleLinkOption('office365', isOffice365)}>
                                <div className="btn-icon-wrapper">
                                    <i className="lh-icon-service-office365"></i>
                                    <span className="label">{isOffice365 && 'Unlinked'}&nbsp;{i18n.t('userprofile.link_accounts.office_365')}</span>
                                </div>
                                </button>
                            <button type="button" className = {this.displayButtonClass('facebook')} id='facebook' onClick={(e) => this.handleLinkOption('facebook', isFaceBook)}>
                                <div className="btn-icon-wrapper">
                                    <i className="lh-icon-service-facebook"></i>
                                    <span className="label">{isFaceBook && 'Unlinked'}&nbsp;{i18n.t('userprofile.link_accounts.facebook')}</span>
                                </div>
                            </button>
                            <button type="button" className = {this.displayButtonClass('smartschool')} id='smartschool' onClick={(e) => this.handleLinkOption('smartschool', isSmartSchool)}>
                                <div className="btn-icon-wrapper">
                                    <i className="lh-icon-service-smartschool"></i>
                                    <span className="label">{isSmartSchool && 'Unlinked'}&nbsp;{i18n.t('userprofile.link_accounts.smartsschool')}</span>
                                </div>
                            </button>
                            <button type="button" className = {this.displayButtonClass('klascement')} id='klascement' onClick={(e) => this.handleLinkOption('klascement', isKlascement)}>
                                <div className="btn-icon-wrapper">
                                    <i className="lh-icon-service-klascement"></i>
                                    <span className="label">{isKlascement && 'Unlinked'}&nbsp;{i18n.t('userprofile.link_accounts.klascement')}</span>
                                </div>
                            </button>
                            <button type="button" className = {this.displayButtonClass('scoodle')} id='scoodle' onClick={(e) => this.handleLinkOption('scoodle', isScoodle)}>
                                <div className="btn-icon-wrapper">
                                    <i className="">S</i>
                                    <span className="label">{isScoodle && 'Unlinked'}&nbsp;{i18n.t('userprofile.link_accounts.scoodle')}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <Errors
                        hasError={this.state.hasError}
                        type="warning"
                        message={this.state.errorMessage}
                        status={this.state.status}
                    />
                    <Success hasError={this.state.isValid} type='warning' message={this.state.successMessage}/>
                </div>
                <SweetModelAlert
                    customClass="remove-profile"
                    show={this.state.modalShow}
                    title={i18n.t('userprofile.link_accounts.title')}
                    text={i18n.t('userprofile.link_accounts.text',{ platform : this.state.name })}
                    showCancelButton
                    confirmButtonText={i18n.t('userprofile.confirm_yes')}
                    cancelButtonText={i18n.t('userprofile.confirm_no')}
                    onConfirm={this.handleConfirmClick}
                    onCancel={this.handleCancelClick}
                />
            </React.Fragment>
        );
    }
}

export default LinkAccounts;
