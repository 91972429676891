import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
import { EventEmitter } from "../Common/Events";
import FlashMessage from 'react-flash-message'
import moment from 'moment/min/moment-with-locales';
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
class Invitations extends React.Component {
    constructor(props) {
        super(props)
        let __type = undefined;
        if (props && props.location && props.location.pathname) {
            let path_array = props.location.pathname.split("/invitations/")
            __type = path_array[path_array.length - 1]
        }
        this.state = {
            total_count: 0,
            current_status: 'active',
            previous_status: 'active',
            per_page: 10,
            current_page: 1,
            data: [],
            type: __type || 'users',
            isLoading: false,
            searchTerm: '',
            base_url: '',
            fromDate: '',
            toDate: '',
            current_date: new Date(),
            group_id: props.groupId || '',
            orderBy: ''
        }
        EventEmitter.subscribe('globalSearchTrigger', (event) => { this.handleGlobalSearch(event) })
    }
    handleGlobalSearch = (val) => {
        this.setState({ searchTerm: val })
        this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, null, val)
    }
    async componentDidMount() {
        await this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, null, this.state.searchTerm)
    }

    async callApi(per_page, current_page, current_status, htmlFormat = null, searchTerm = '') {
        if (htmlFormat != 'csv')
            this.setState({ data: [], isLoading: true });

        let { data } = await API.get('/invitations?per_page=' + per_page + '&current_page=' + current_page + '&type=' + this.state.type + '&format=' + htmlFormat + '&searchTerm=' + encodeURIComponent(searchTerm) + '&fromDate=' + this.state.fromDate + '&toDate=' + this.state.toDate + '&group=' + this.state.group_id + '&order_by=' + this.state.orderBy)

        if (htmlFormat === 'csv') {
            let a = document.createElement('a');
            a.href = 'data:text/csv,' + encodeURI(data);
            a.target = '_blank';
            a.download = 'i3Learnhub_invitations.csv';
            a.click();
            history.pushState({}, null, window.location.href);
            document.getElementById("export_inv_mdl_cls").click();
            this.hideExportModal();
            return
        }

        this.setState({ data: data.groups, base_url: data.base_url, total_count: data.total_count, isLoading: false })
    }
    onTableChange = (type, newState) => {
        if(newState.sortOrder && newState.sortField){
            this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
        }
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi(newState.sizePerPage, newState.page, this.state.current_status, null, this.state.searchTerm)
    }
    invitationAction = (cell, row) => {
        let invitation_id = row.id
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void (0)} data-id={invitation_id} onClick={this.resendInvitation}>{i18n.t('admin.labels.resend_inv')}</NavDropdown.Item>
                <NavDropdown.Item href={void (0)} data-id={invitation_id} onClick={this.cancelInvitation}>{i18n.t('admin.labels.cancel_inv')}</NavDropdown.Item>
            </NavDropdown>
        );
    }
    invitationLink = (cell, row) => {
        let url = this.state.base_url + row.accept_invitation_link;
        return (
            <a href={url} target="_blank">{i18n.t('admin.labels.accept_inv')}</a>
        );
    }
    resendInvitation = (event) => {
        let id = event.target.getAttribute('data-id');
        const formData = new FormData();
        formData.append('selected', id);
        API.post('/invitations/resend', formData)
            .then(res => {
                window.location.reload();
            })
            .catch(function (error) {
            })
    }
    cancelInvitation = (event) => {
        let id = event.target.getAttribute('data-id');
        const formData = new FormData();
        formData.append('selected', id);
        API.post('/invitations/cancel', formData)
            .then(() => {
                window.location.reload();
            })
            .catch(() => {

            })
    }
    handleChange = event => {
        if (event.target.name === "fromDate") {
            this.setState({ toDate: '' })
        }
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
        });
    }
    downloadInvitationsData = (event) => {
        this.callApi(this.state.per_page, this.state.current_page, this.state.current_status, 'csv', this.state.searchTerm)
    }
    hideExportModal = (event) => {
        this.setState({fromDate: '', toDate: ''})
    }
    render() {
        const columns = [{
            dataField: 'email',
            text: i18n.t('userprofile.labels.email').toUpperCase(),
            headerStyle: (colum, colIndex) => {
                return { width: '23%', textAlign: 'center' };
            },
            sort: true
        }, {
            dataField: 'first_name',
            text: i18n.t('userprofile.labels.first_name').toUpperCase(),
            sort: true
        }, {
            dataField: 'last_name',
            text: i18n.t('userprofile.labels.last_name').toUpperCase(),
            sort: true
        }, {
            dataField: 'sender_name',
            text: i18n.t('admin.labels.inviter').toUpperCase(),
            sort: false,
        }, {
            dataField: 'account_type',
            text: i18n.t('admin.labels.account_type').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '8%', textAlign: 'center' };
            },
        }, {
            dataField: 'number_of_years',
            text: i18n.t('admin.labels.for_years').toUpperCase(),
            sort: true
        }, {
            dataField: 'sent',
            text: i18n.t('admin.labels.sent').toUpperCase(),
            sort: true
        },
        {
            dataField: 'created_at',
            text: i18n.t('admin.labels.created_at').toUpperCase(),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '11%', textAlign: 'center' };
            }
        },
        {
            dataField: 'order_number',
            text: i18n.t('admin.labels.order_number').toUpperCase(),
            sort: true
        },
        {
            dataField: 'Invitation Link',
            formatter: this.invitationLink,
            headerStyle: (colum, colIndex) => {
                return { width: '11%', textAlign: 'center' };
            }
        },
        {
            dataField: 'moreOptions',
            formatter: this.invitationAction,
        }];
        const defaultSorted = [{
            dataField: 'email',
            order: 'desc'
        }];
        const options = {
            onPageChange: this.handlePageChange,
            onSizePerPageList: this.handleSizePerPageChange,
            page: this.state.current_page,
            sizePerPage: this.state.per_page
        };
        const paginatorOptions = {
            pageStartIndex: 1,
            page: this.state.current_page,
            totalSize: this.state.total_count,
            sizePerPage: this.state.per_page,
            hidePageListOnlyOnePage: true,
            showTotal: true
        };
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        };

        return (
            <div className="overflow-hidden h-100">
                <div className={this.state.group_id ? "startscreen users-main-wrapper no-gradient" : "startscreen users-main-wrapper"}>
                    <div className="start-content">
                        <div className="mb-3">
                            <span data-bs-toggle="modal" data-bs-target="#exportModalInvitation" className="lh-icon-os-export filter-icon-users mt-2"></span>
                        </div>
                        <div className="invitation-table">
                            <BootstrapTable
                                keyField='email'
                                options={options}
                                data={this.state.data}
                                fetchInfo={{ dataTotalSize: this.state.total_count }}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                condensed={true}
                                remote
                                noDataIndication={() => <NoDataIndication />}
                                pagination={paginationFactory(paginatorOptions)}
                                onTableChange={this.onTableChange}
                            />
                        </div>
                    </div>
                </div>

                {/* Export Modal */}
                <div className="modal fade" id="exportModalInvitation" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="exportModalForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.export_modal.title')}</h4>
                                    </div>
                                    <button id="export_inv_mdl_cls" type="button" className="close" data-bs-dismiss="modal" onClick={this.hideExportModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="page-header export-header">
                                        <span className="text-primary">{i18n.t('admin.export_modal.message')}</span>
                                    </div>
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-3">
                                                <span>{i18n.t('admin.labels.from')}</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input value={moment(this.state.fromDate).format("YYYY-MM-DD")} name="fromDate" type="date" className="form-control mt-2 mb-4" onChange={this.handleChange} max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                                            </div>
                                            <div className="user-label col-sm-3">
                                                <span>{i18n.t('admin.labels.to')}</span>
                                            </div>
                                            <div className="col-sm-8">
                                                <input value={this.state.toDate ? moment(this.state.toDate).format("YYYY-MM-DD") : this.state.toDate} min={moment(this.state.fromDate).format("YYYY-MM-DD")} onChange={this.handleChange} name="toDate" type="date" className="form-control mt-2 mb-4" max={moment(this.state.current_date).format("YYYY-MM-DD")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal" onClick={this.hideExportModal}>{i18n.t('userprofile.btn_cancel')}</button>
                                    <button type="button" className="btn btn-lg btn-accenta" onClick={this.downloadInvitationsData}>{i18n.t('admin.btn_export')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation() (Invitations)