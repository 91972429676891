import React from "react";
import i18n from "../../packs/i18n";
import { NavDropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loading_IMG } from '../Common/assets_paths';
import Errors from "../Common/Errors/Errors";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import TextValidator from "../Common/TextValidator";
import TextareaValidator from "../Common/TextareaValidator";
import API from '../api';
import Modal from "react-bootstrap/Modal";
import { withTranslation } from 'react-i18next';



class Preferences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            name: '',
            isValid: false,
            hasError: false,
            errorMessage: "",
            isValidate: false,
            edit_name: '',
            current_active_id: '',
            isEditOpen: false
        }
    }
    async componentDidMount() {
        await this.callApi()
    }
    async callApi() {
        //keyboards
        this.setState({data: [], isLoading: true, total_count: 0})
        let { data } = await API.get('/preferences')
        console.log(data);
        this.setState({data: data, isLoading: false, total_count: data.length})
    }
    handleSubmit = (event) => {
        this.setState({ isLoading: true });
        this.addNewKeyBoard()
    }
    handleEditSubmit = (event) => {
        this.setState({ isLoading: true });
        this.updateKeyBoard()
    }
    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };
    handleChange = event => {
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value
        });
    };
    handleKeyDown = event => {
        if (event.key === " " && event.target.value.trim().length === 0) {
            event.preventDefault();
        }
    };
    addNewKeyBoard = () => {
        const formData = new FormData();
        
        formData.append('preference[preference_name]', this.state.name);
        API.post('/preferences', formData)
            .then(res => {
                if (res.data.status === "failed") {
                    //alert(res.data.message);
                    this.setState({
                        isValidate: true,
                        isValid: false,
                        hasError: true,
                        errorMessage: res.data.message,
                        status: null,
                        isLoading: false
                    });
                }
                else {
                    window.location.reload();
                }

            })
            .catch(function (error) {
            })
    }
    updateKeyBoard = () => {
        const formData = new FormData();
        
        formData.append('preference[preference_name]', this.state.edit_name);
        API.put('/preferences/'+this.state.current_active_id, formData)
            .then(res => {
                if (res.data.status === "failed") {
                    //alert(res.data.message);
                    this.setState({
                        isValidate: true,
                        isValid: false,
                        hasError: true,
                        errorMessage: res.data.message,
                        status: null,
                        isLoading: false
                    });
                }
                else {
                    window.location.reload();
                }

            })
            .catch(function (error) {
            })
    }
    onTableChange = (type, newState) => {
        if(newState.sortOrder && newState.sortField){
            this.setState({orderBy: newState.sortField+" "+newState.sortOrder})
        }
        this.setState({ current_page: newState.page, per_page: newState.sizePerPage });
        this.callApi()
    }
    preferencesActions = (cell, row) => {
        let id = row.id;
        return (
            <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
                <NavDropdown.Item href={void(0)} data-id={id} onClick={this.editPreference} >{i18n.t('admin.labels.edit')}</NavDropdown.Item>
            </NavDropdown>
        );
    }
    editPreference = (event) => {
        let id = event.target.getAttribute('data-id');
        let preference_info = this.state.data.find((element) => {
            return element.id == id
        });
        this.setState({edit_name: preference_info.preference_name, current_active_id: preference_info.id})

        this.showEditModal()
    }
    showEditModal = () => {
        this.setState({isEditOpen: true})
    }
    hideEditModal = () => {
        this.setState({isEditOpen: false})
    }
    render() {
        const NoDataIndication = () => {
            if (this.state.isLoading) {
                return <div> <img src={Loading_IMG} alt="loading" className="loading" /> </div>
            }
            else {
                return <div> <span>{i18n.t('admin.no_record_text')}</span> </div>
            }
        }
        const columns = [{
            dataField: 'preference_name',
            text: i18n.t('admin.labels.name').toUpperCase(),
            sort: false
        }, {
            dataField: 'moreOptions',
            sort: false,
            formatter: this.preferencesActions
        }];
        // const options = {
        //     onPageChange: this.handlePageChange,
        //     onSizePerPageList: this.handleSizePerPageChange,
        //     page: this.state.current_page,
        //     sizePerPage: this.state.per_page
        // };
        // const paginatorOptions = {
        //     pageStartIndex: 1,
        //     page: this.state.current_page,
        //     totalSize: this.state.total_count,
        //     sizePerPage: this.state.per_page,
        //     hidePageListOnlyOnePage: true,
        //     showTotal: true
        // };

        return (
            <div className="overflow-hidden h-100">
                <div className="startscreen h-100">
                    <div className="start-content">
                        <div className="admin-custom-table-header">
                            <button data-bs-toggle="modal" data-bs-target="#addPreference" className="btn btn-primary mb-3 mt-1">{i18n.t('admin.preference.modal_add.title')}</button>
                        </div>
                        <div className="preference-table mt-2">
                            <BootstrapTable
                                keyField='name'
                                //options={options}
                                //fetchInfo={{ dataTotalSize: this.state.total_count }}
                                data={this.state.data}
                                columns={columns}
                                bordered={false}
                                //remote
                                //onTableChange={this.onTableChange}
                                noDataIndication={() => <NoDataIndication />}
                                //pagination={paginationFactory(paginatorOptions)}
                            />
                        </div>
                    </div>
                </div>

                {/* Add Preference Modal */}
                <div className="modal fade" id="addPreference" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            {/* <form id="addPreferenceForm"> */}
                            <ValidatorForm 
                            id="addPreferenceForm"
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={this.handleOnError}
                            instantValidate={false}
                            className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.preference.modal_add.title')}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="add" className="col-form-label">{i18n.t('admin.labels.name')}</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control details-info"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="name"
                                                    placeholder={i18n.t("admin.placeholder.name")}
                                                    value={this.state.name}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_name")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                            <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                            :
                                            <React.Fragment>{i18n.t('admin.keyboard.btn_new')}</React.Fragment>
                                        }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                            {/* </form> */}
                        </div>
                    </div>
                </div>

                {/* Edit Preference Modal */}
                <div>
                    <Modal show={this.state.isEditOpen} id="editPreferenceModal">
                        <div className="modal-content">
                            <ValidatorForm
                                id="editPreferenceForm"
                                ref="form"
                                onSubmit={this.handleEditSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t('admin.preference.modal_edit.title')}</h4>
                                    </div>
                                    <button type="button" className="close" onClick={this.hideEditModal}><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="add" className="col-form-label">{i18n.t('admin.labels.name')}</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <TextValidator
                                                    tabIndex='1'
                                                    wrapperClass=''
                                                    className={"form-control details-info"}
                                                    autoComplete="off"
                                                    type="text"
                                                    name="edit_name"
                                                    placeholder={i18n.t("admin.placeholder.name")}
                                                    value={this.state.edit_name}
                                                    isValidate={this.state.isValidate}
                                                    noValidate
                                                    validators={['required']}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    errorMessages={[
                                                        i18n.t("admin.errors.require_name")
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                            <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                            :
                                            <React.Fragment>{i18n.t('admin.preference.btn_update')}</React.Fragment>
                                        }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default withTranslation() (Preferences)