import React from 'react';
import QRCode from 'qrcode.react';
import {createCookie,ENABLEQR} from '../Common/i3library';
import API from '../api';
import i18n from '../../packs/i18n';
import { En_Bedge_IMG, AppStore_IMG } from '../Common/assets_paths';
import SimpleBar from 'simplebar';
class FastTrack extends React.Component{
   
    constructor(props) {
        super(props);
    }
    
    startTimer(){
        if(this.QRTimer){ 
            clearInterval(this.QRTimer);
        }
        this.QRTimer = setInterval(this.fasttrackValidate.bind(this),1000);
    }
    stopTimer(){
        if(this.QRTimer){
            clearInterval(this.QRTimer);
            delete this.QRTimer;
        }
    }
    fasttrackValidate(){
        const qrData = {
            token_code: this.props.fastrack_token_code
        }
        API.post('loginfasttrackqr',qrData)
            .then(res => {
                if(res.data.status === 'ok'){
                    window.location = res.data.redirect_url;
                }
            })
            .catch(function (error) {
                if(error.response){
                    console.log(error.response);
                    _this.setState({
                        hasError: true,
                        errorMessage: i18n.t('login.errors.wrong_credential')
                    })
                }
                if(error.request){
                    console.log(error.request);
                }
                else{
                    _this.setState({
                        hasError: true,
                        errorMessage: error.message
                    })
                }
            })
            .finally(function () {
                // always executed
        });
    }
    componentDidMount(){
        // if(props.isFastrack)
        //     document.getElementById("start_btn").style.pointerEvents="none";
        window.addEventListener('beforeunload',function(e){
            this.stopTimer();
            document.getElementsByTagName("body")[0].classList.remove('modal-open');
        }.bind(this));
    }
    componentWillUnmount(){
        document.getElementById("start_btn").style.pointerEvents="all";
        document.getElementsByTagName("body")[0].classList.remove('modal-open');
        this.stopTimer();
        window.removeEventListener('beforeunload', this.componentCleanup); 
    }
    closeComp(){
        document.getElementById("start_btn").style.pointerEvents="all";
        document.getElementsByTagName("body")[0].classList.remove('modal-open');
        this.stopTimer();
        this.props.handler(false);
    }
    render(){
        createCookie(ENABLEQR,this.props.isFastrack?'1':'0',356)
        if(!this.props.isFastrack)
        {
            this.stopTimer();
            return null;
        }
        else{
            document.getElementById("start_btn")?document.getElementById("start_btn").style.pointerEvents="none":null;
            document.getElementsByTagName("body")[0].classList.add('modal-open');
        }
        
        this.startTimer();
        return(
            <div className="fast-tack-overlay">
                <div className="main-cover container-fluid" data-simplebar>
                    <div className="header">
                        <div className={'logo'}>
                        </div>
                        <div>
                            <div className="start">
                                <a href="JavaScript:void(0);" className="btn btn-lg btn-outline-quinary btn-primary fasttrack-close w-150p" onClick={this.closeComp.bind(this)}>Close</a>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 p-0 text-center">
                                <div className="main-wrapper">
                                    <div className='white-cover text-left'>
                                        <div className='qr-code'>
                                            <QRCode value={this.props.fastrack_code} size={390} title={this.props.fastrack_code} /> 
                                        </div>
                                        <div className='action-buttons'>
                                            <a href={this.props.play_url} target="_blank"><img src={ En_Bedge_IMG  }/></a>
                                            <a href={this.props.app_url} target="_blank"><img src={ AppStore_IMG } /></a>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>            
            </div>
            
            </div>
        )
    }
}
export default FastTrack;