/* this component is basically for displaying errors in any other component */


import React from 'react';

//Static class for Error Warning Simple Design replace with proper css classes

class Success extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        if (!this.props.hasError) {
            return null;
        }
        return (            
            <div className="alert alert-success mt-4 animated fadeInUp">
                <span className="lh-icon-user-alert-notification text-size-48"></span>
                <div>
                    {this.props.message}
                </div>
            </div>
        )
    }
}


export default Success;