import React from "react";
import i18n from '../../packs/i18n';
import { ToolTip_IMG } from '../Common/assets_paths';
import FeatureBlockItemValue from './FeatureBlockItemValue'
import ReactTooltip from "../Common/Tooltip";
class FeatureBlockItem extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            show: false
        }
    }
    render(){
        const feature_itmes_values = [];
        Object.keys(this.props.details.level).forEach(function(key){
            feature_itmes_values.push(<FeatureBlockItemValue key={key} value={this.props.details.level[key]} />)
        }.bind(this))
        return(
            <div className="feature-block-line-item">
                <div className="feature-block-item-title"> 
                    <span>
                        {i18n.t(this.props.details.name)}
                            {this.props.details.comment?
                                <span style={{ width: 'auto',  position : "relative" }}>
                                    <img src={ToolTip_IMG} className="ml-2" onClick={ () => this.setState({show: true}) } style={{ opacity : (this.state.show ? 0 : 1 )}}/>
                                    <ReactTooltip show={this.state.show} onClose={() => this.setState({show: false})} text={i18n.t(this.props.details.comment)}></ReactTooltip>
                                </span>
                                :null
                            }
                    </span>
                </div>
                <div className="feature-block-item-value">
                    {feature_itmes_values}
                </div>
            </div>
        )
    }

}

export default FeatureBlockItem;