import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import API from '../api';
import { ValidatorForm, ValidationRules } from 'react-form-validator-core';
import SelectValidator from "../Common/SelectValidator";
import TextValidator from "../Common/TextValidator";
import Errors from "../Common/Errors/Errors";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { getErrorMessage } from "../Common/Helpers/AppHelper";
import countryList from 'react-select-country-list';
import { getAvailableLanguages } from "../Common/i3library";
import { Loading_IMG } from '../Common/assets_paths';

class NewInvite extends React.Component {
    constructor(props) {
        super(props);
        const countryOptions = countryList().getData();
        //ILEARN-4927 Tiwan Name Update 
        let taiwan_item = countryOptions.find(item => item.value == 'TW');
        taiwan_item.label = i18n.t("userprofile.labels.tiwan_label");
        let tiwan_index = countryOptions.findIndex(item => item.value == 'TW');
        countryOptions[tiwan_index] = taiwan_item;
        //ILEARN-4927 Tiwan Name Update End
        const languagesOptions = getAvailableLanguages();
        let _plan = undefined
        if (props.groupId) {
            if (props.type === "user") {
                _plan = 'individual';
            }
            else {
                _plan = 'team_basic';
            }
            if (props.isInviteFromTeam) {
                _plan = '';
            }
        }
        else {
            _plan = "free";
        }
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            address: '',
            number_of_years: 1,
            institution_code: '',
            institution: '',
            countryOpt: countryOptions,
            languageOpt: languagesOptions,
            country: '',
            language: '',
            plan: _plan,
            shouldBlockNavigation: false,
            isValid: false,
            hasError: false,
            errorMessage: "",
            isValidate: false,
            plans: [],
            auto_approve: "false",
            group_id: props.groupId || '',
            modal_type: props.type || '',
            order_number: '',
            is_invite_from_team: props.isInviteFromTeam || false,
            is_top_admin: props.is_top_admin || false,
            is_reseller: props.is_reseller || false
        }
    }
    async componentDidMount() {
        await this.callApi()
    }
    async callApi() {
        let finalPlans = new Array();
        await API.get('pricing/plans')
            .then(res => {
                if (!res.data || !res.data[0])
                    return
                for (var i in res.data[0]) {
                    if (i !== 'team') {
                        if (this.state.group_id) {
                            if (i !== 'free') {
                                let plan = res.data[0][i];
                                let new_data = { value: plan.key, label: plan.display_text.includes('.') ? i18n.t(plan.display_text) : plan.display_text }
                                finalPlans.push(new_data)
                            }
                        }
                        else {
                            let plan = res.data[0][i];
                            let new_data = { value: plan.key, label: plan.display_text.includes('.') ? i18n.t(plan.display_text) : plan.display_text }
                            finalPlans.push(new_data)
                        }
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                }
                if (error.request) {
                    console.log(error.request);
                }
                else {
                    console.log(error)
                }
            });

        this.setState({ plans: finalPlans });
    }
    handleSubmit = (event) => {
        if(this.state.email.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t("userprofile.errors.email_required"),
                status: null
            });
            return
        }
        else if(this.state.first_name.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t("userprofile.errors.first_name_required"),
                status: null
            });
            return
        }
        else if(this.state.last_name.trim().length == 0){
            this.setState({
                isValidate: true,
                isValid: false,
                hasError: true,
                errorMessage: i18n.t("userprofile.errors.last_name_required"),
                status: null
            });
        }
        else{
            if (this.state.plan && this.state.plan.includes('team')){
                if(this.state.institution.trim().length == 0){
                    this.setState({
                        isValidate: true,
                        isValid: false,
                        hasError: true,
                        errorMessage: i18n.t("admin.errors.require_institute"),
                        status: null
                    });
                    return
                }
                // else if(this.state.institution_code.trim().length == 0){
                //     this.setState({
                //         isValidate: true,
                //         isValid: false,
                //         hasError: true,
                //         errorMessage: getErrorMessage(errors, true),
                //         status: null
                //     });
                //     return
                // }
            }
        }
        this.setState({ isLoading: true });
        this.inviteNewUser()
    }
    inviteNewUser = () => {
        const formData = new FormData();
        const { isLangChange } = this.state;

        formData.append('invitation[email]', this.state.email.trim());
        formData.append('invitation[first_name]', this.state.first_name.trim());
        formData.append('invitation[last_name]', this.state.last_name.trim());
        formData.append('invitation[address]', this.state.address);
        formData.append('invitation[language]', this.state.language);
        formData.append('invitation[country]', this.state.country);
        formData.append('invitation[account_type]', this.state.plan);
        formData.append('invitation[institution_code]', this.state.institution_code.trim());
        formData.append('invitation[institution]', this.state.institution.trim());
        formData.append('invitation[number_of_years]', this.state.number_of_years);
        formData.append('invitation[seat_licenses]', 1);
        formData.append('invitation[auto_approve]', this.state.auto_approve);
        if (this.state.group_id) {
            formData.append('invitation[group_id]', this.state.group_id);
        }
        if(this.state.order_number){
            formData.append('invitation[order_number]', this.state.order_number)
        }
        API.post('/invitations', formData)
            .then(res => {
                if (res.data.status === "failed") {
                    //alert(res.data.message);
                    this.setState({
                        isValidate: true,
                        isValid: false,
                        hasError: true,
                        errorMessage: res.data.message,
                        status: null,
                        isLoading: false
                    });
                }
                else {
                    window.location.reload();
                }

            })
            .catch(function (error) {
            })
    }

    handleOnError = errors => {
        this.setState({
            isValidate: true,
            isValid: false,
            hasError: true,
            errorMessage: getErrorMessage(errors, true),
            status: null
        });
    };

    handleChange = event => {
        const isLangChange = event.target.name === "language" ? true : this.state.isLangChange;
        if (event.target.name == "number_of_years") {
            if (event.target.value < 1) {
                event.preventDefault();
                return
            }
            if (event.target.value > 5) {
                event.preventDefault();
                return
            }
        }
        this.setState({
            shouldBlockNavigation: true,
            [event.target.name]: event.target.value,
            isLangChange
        });
    };
    handleKeyDown = event => {
        if (event.key === " " && event.target.value.trim().length === 0) {
            event.preventDefault();
        }
    };
    onPasteHandle = event => {
        event.preventDefault();
        return
    }
    render() {
        return (
            <div className="overflow-hidden h-100">
                {/* Add User Modal */}
                <div className="modal fade" id={this.state.modal_type ? "addUserModal" + this.state.modal_type : "addUserModal"} tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <ValidatorForm
                                id="addUserForm"
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={this.handleOnError}
                                instantValidate={false}
                                className=""
                            >
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">{i18n.t("admin.new_invite_modal.title")}</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">

                                    <div className="middle-sec">
                                        <div>
                                            {this.state.is_invite_from_team ? null :
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('userprofile.labels.account_type')}</span>
                                                    </div>
                                                    <div>
                                                        <SelectValidator
                                                            tabIndex='1'
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            placeholder={i18n.t('admin.placeholder.account_type')}
                                                            name="plan"
                                                            options={this.state.plans}
                                                            value={this.state.plan}
                                                            onChange={this.handleChange}
                                                            isValidate={this.state.isValidate}
                                                            noValidate
                                                            //required
                                                            validators={["required"]}
                                                            errorMessages={[
                                                                i18n.t('admin.errors.require_account_type')
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            <div className="two-column-grid">
                                                <div className="user-label text-primary">
                                                    <span>{i18n.t("userprofile.labels.email")}</span>
                                                </div>
                                                <div>
                                                    <TextValidator
                                                        tabIndex='2'
                                                        wrapperClass=''
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        type="email"
                                                        name="email"
                                                        placeholder={i18n.t("userprofile.placeholder.email")}
                                                        value={this.state.email}
                                                        isValidate={this.state.isValidate}
                                                        noValidate
                                                        validators={['required', 'isEmail']}
                                                        onChange={this.handleChange}
                                                        onKeyDown={this.handleKeyDown}
                                                        errorMessages={[
                                                            i18n.t("userprofile.errors.email_required"),
                                                            i18n.t("userprofile.errors.email_invalid")
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="two-column-grid">
                                                <div className="user-label text-primary">
                                                    <span>{i18n.t("userprofile.labels.first_name")}</span>
                                                </div>
                                                <div>
                                                    <TextValidator
                                                        tabIndex='3'
                                                        wrapperClass=''
                                                        className={"form-control "}
                                                        autoComplete="off"
                                                        placeholder={i18n.t("userprofile.placeholder.first_name")}
                                                        type="text"
                                                        name="first_name"
                                                        value={this.state.first_name}
                                                        onChange={this.handleChange}
                                                        onKeyDown={this.handleKeyDown}
                                                        isValidate={this.state.isValidate}
                                                        noValidate
                                                        required
                                                        validators={["required", "maxStringLength:40"]}
                                                        errorMessages={[
                                                            i18n.t("userprofile.errors.first_name_required"),
                                                            i18n.t('login.errors.first_name_max_length')
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="two-column-grid">
                                                <div className="user-label text-primary">
                                                    <span>{i18n.t("userprofile.labels.last_name")}</span>
                                                </div>
                                                <div>
                                                    <TextValidator
                                                        tabIndex='4'
                                                        wrapperClass=''
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t("userprofile.placeholder.last_name")}
                                                        type="text"
                                                        name="last_name"
                                                        value={this.state.last_name}
                                                        onKeyDown={this.handleKeyDown}
                                                        onChange={this.handleChange}
                                                        isValidate={this.state.isValidate}
                                                        noValidate
                                                        required
                                                        validators={["required", "maxStringLength:40"]}
                                                        errorMessages={[
                                                            i18n.t("userprofile.errors.last_name_required"),
                                                            i18n.t('login.errors.last_name_max_length')
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.plan && this.state.plan !== "free" ?
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('admin.labels.institute')}</span>
                                                    </div>
                                                    <div>
                                                        <TextValidator
                                                            tabIndex='5'
                                                            wrapperClass=''
                                                            className={"form-control"}
                                                            autoComplete="off"
                                                            placeholder={i18n.t('admin.placeholder.institute')}
                                                            type="text"
                                                            name="institution"
                                                            value={this.state.institution}
                                                            onKeyDown={this.handleKeyDown}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div> : null
                                            }
                                            {this.state.plan && this.state.plan !== "free" ?
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('admin.labels.institute_code')}</span>
                                                    </div>
                                                    <div>
                                                        <input placeholder={i18n.t('admin.placeholder.institute_code')} tabIndex="6" onKeyDown={this.handleKeyDown} onChange={this.handleChange} value={this.state.institution_code} name="institution_code" type="name" className="form-control " />
                                                    </div>
                                                </div> : null
                                            }
                                            <div className="two-column-grid">
                                                <div className="user-label text-primary">
                                                    <span>{i18n.t("userprofile.labels.country")}</span>
                                                </div>
                                                <div>
                                                    <SelectValidator
                                                        tabIndex='7'
                                                        className={"form-control"}
                                                        labelClass={'col-sm-4 col-md-4 col-lg-4 col-xs-12 col-form-label text-primary'}
                                                        autoComplete="off"
                                                        placeholder={i18n.t("userprofile.placeholder.country")}
                                                        name="country"
                                                        options={this.state.countryOpt}
                                                        value={this.state.country}
                                                        onChange={this.handleChange}
                                                        isValidate={this.state.isValidate}
                                                        validators={['required']}
                                                        errorMessages={[
                                                            i18n.t("userprofile.errors.country_required")
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="two-column-grid">
                                                <div className="user-label text-primary">
                                                    <span>{i18n.t("userprofile.labels.language")}</span>
                                                </div>
                                                <div>
                                                    <SelectValidator
                                                        tabIndex='8'
                                                        className={"form-control"}
                                                        autoComplete="off"
                                                        placeholder={i18n.t("userprofile.placeholder.language")}
                                                        name="language"
                                                        options={this.state.languageOpt}
                                                        value={this.state.language}
                                                        onChange={this.handleChange}
                                                        noValidate
                                                        isValidate={this.state.isValidate}
                                                        validators={["required"]}
                                                        errorMessages={[
                                                            i18n.t("userprofile.errors.language_required")
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.plan && this.state.plan != 'free' ?
                                                <div className="">
                                                    <div className="two-column-grid">
                                                        <div className="user-label text-primary">
                                                            <span>{i18n.t('admin.labels.number_year')}</span>
                                                        </div>
                                                        <div >
                                                            <input placeholder={i18n.t('admin.placeholder.number_year')}maxLength={1} pattern="[1-5]" tabIndex="9" min={1} max={5} onPaste={this.onPasteHandle} onChange={this.handleChange} value={this.state.number_of_years} name="number_of_years" type="number" className="form-control " />
                                                        </div>
                                                    </div>
                                                    <div className="two-column-grid">
                                                        <div className="user-label text-primary">
                                                            <span>{i18n.t('userprofile.labels.address')}</span>
                                                        </div>
                                                        <div>
                                                            <div className="form-group">
                                                                <textarea placeholder={i18n.t('userprofile.placeholder.address')} value={this.state.address} onChange={this.handleChange} name="address" className="form-control" rows="4"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="three-column-grid">
                                                        <div className="user-label text-primary">
                                                            <span>{i18n.t('admin.labels.payment_received')}</span>
                                                        </div>
                                                        <div >
                                                            <div className="custom-control-lg custom-radio">
                                                                <input value="true" onChange={this.handleChange} type="radio" className="custom-control-input" id="rdb1" name="auto_approve" />
                                                                <label className="custom-control-label" htmlFor="rdb1">{i18n.t('userprofile.confirm_yes')}</label>
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <div className="custom-control-lg custom-radio">
                                                                <input defaultChecked={true} value="false" onChange={this.handleChange} type="radio" className="custom-control-input" id="rdb2" name="auto_approve" />
                                                                <label className="custom-control-label" htmlFor="rdb2">{i18n.t('userprofile.confirm_no')}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : null
                                            }
                                            {
                                                 (this.state.plan && this.state.plan != 'free') && (this.state.is_top_admin || this.state.is_reseller) ? 
                                                <div className="two-column-grid">
                                                    <div className="user-label text-primary">
                                                        <span>{i18n.t('admin.labels.order_number')}</span>
                                                    </div>
                                                    <div >
                                                        <input placeholder={i18n.t('admin.placeholder.order_number')} tabIndex="10" onPaste={this.onPasteHandle} onChange={this.handleChange} value={this.state.order_number} name="order_number" type="text" className="form-control " />
                                                    </div>
                                                </div> : null
                                            }

                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-outline-tertiary mt-2" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-lg btn-accenta" disabled={this.state.isLoading}>{this.state.isLoading ?
                                        <img src={Loading_IMG} height="25" width="25" alt="loading" className="loading" />
                                        :
                                        <React.Fragment>{i18n.t('admin.labels.invite')}</React.Fragment>
                                    }</button>
                                </div>
                            </ValidatorForm>
                            <Errors
                                hasError={this.state.hasError}
                                type="warning"
                                message={this.state.errorMessage}
                                status={this.state.status}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(NewInvite);