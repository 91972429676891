import React from "react";
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import Modal from "react-bootstrap/Modal";
import { Loading_IMG } from '../Common/assets_paths';
import API from '../api';
class BlockModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: props.type,
            id: props.id,
            isOpen: props.isOpen
        }
    }
    blockUser = () => {
        const formData = new FormData();
        formData.append('ids[]', this.state.id)
        API.patch('/oldview/users/block', formData)
        .then(res => {
            window.location.reload()
        })
        .catch(function (error) {
        })
    }
    hideModal = () => {
        this.setState({isOpen: false})
        if (this.props && this.props.hideModal)
            this.props.hideModal()
    }
    render(){
        return(
            <Modal show={this.state.isOpen} id="blockModal">
                <div className="modal-content">
                        <div className="modal-header modal-image-header">
                            <div className="modal-header-content">
                                <h4 className="modal-title">{i18n.t('admin.block_modal.title')}</h4>
                            </div>
                            <button type="button" className="close" onClick={this.hideModal}><span className="lh-icon-os-close"></span></button>
                        </div>
                        <div className="modal-body">
                            <div className="middle-sec">
                                <div className="col-md-12">
                                    {i18n.t('admin.block_modal.message')}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-accenta" onClick={this.hideModal}>{i18n.t('userprofile.btn_cancel')}</button>
                            <button type="button" className="btn btn-accenta" onClick={this.blockUser}>{i18n.t('admin.btn_ok')}</button>
                        </div>
                </div>
            </Modal>
        )
    }

}
export default withTranslation()(BlockModal)