import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { NavDropdown } from 'react-bootstrap'

const category = [
    { category_name: 'test' },
    { category_name: 'test2' }
];
const activity = [
    { activity: 'voucher', category: 'test' },
    { activity: 'test', category: 'test2' }
];
const template = [
    { template: 'dev1', sendgrid_id: '3240454-5465436' },
    { template: 'dev2', sendgrid_id: '2a8ba68b-7a9d-4285-a869-cb9b28f5e' }
];
const email = [
    { activity: 'forgotpassword', language: 'de', template_name: 'dev1', category: 'demo', status: 't1', configured: 'c1' },
    { activity: 'forgotpassword', language: 'en', template_name: 'dev2', category: 'demo2', status: 't2', configured: 'c2' },
    { activity: 'forgotpassword', language: 'en', template_name: 'dev2', category: 'demo2', status: 't2', configured: 'c2' }
];

const categoryColumns = [{
    dataField: 'category_name',
    text: 'category'.toUpperCase(),
    sort: true
}, {
    dataField: 'moreOptions',
    sort: true,
    formatter: categoryActions
}];
const activityColumns = [{
    dataField: 'activity',
    text: 'activity'.toUpperCase(),
    sort: true
}, {
    dataField: 'category',
    text: 'category'.toUpperCase(),
    sort: true
}, {
    dataField: 'moreOptions',
    sort: true,
    formatter: activityActions
}];
const templateColumns = [{
    dataField: 'template',
    text: 'template'.toUpperCase(),
    sort: true
}, {
    dataField: 'sendgrid_id',
    text: 'sendgrid id'.toUpperCase(),
    sort: true
}, {
    dataField: 'moreOptions',
    sort: true,
    formatter: templateActions
}];
const emailColumns = [{
    dataField: 'activity',
    text: 'activity'.toUpperCase(),
    sort: true
}, {
    dataField: 'language',
    text: 'language'.toUpperCase(),
    sort: true
}, {
    dataField: 'template_name',
    text: 'template_name'.toUpperCase(),
    sort: true
}, {
    dataField: 'category',
    text: 'category'.toUpperCase(),
    sort: true
}, {
    dataField: 'status',
    text: 'status'.toUpperCase(),
    sort: true
}, {
    dataField: 'configured',
    text: 'configured'.toUpperCase(),
    sort: true
}, {
    dataField: 'moreOptions',
    sort: true,
    formatter: emailActions
}];

function categoryActions() {
    return (
        <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item data-bs-toggle="modal" data-bs-target="#editCategory">Edit</NavDropdown.Item>
            <NavDropdown.Item href="#">Delete</NavDropdown.Item>
        </NavDropdown>
    )
}
function activityActions() {
    return (
        <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item data-bs-toggle="modal" data-bs-target="#editActivity">Edit</NavDropdown.Item>
            <NavDropdown.Item href="#">Delete</NavDropdown.Item>
        </NavDropdown>
    )
}
function templateActions() {
    return (
        <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item data-bs-toggle="modal" data-bs-target="#editTemplate">Edit</NavDropdown.Item>
            <NavDropdown.Item href="#">Delete</NavDropdown.Item>
        </NavDropdown>
    )
}
function emailActions() {
    return (
        <NavDropdown className="more-options lh-icon-os-selection-moreoptions users-table-dropdown" id="users-dropdown">
            <NavDropdown.Item data-bs-toggle="modal" data-bs-target="#editEmail">Edit</NavDropdown.Item>
            <NavDropdown.Item href="#">Delete</NavDropdown.Item>
            <NavDropdown.Item href="#">Activate</NavDropdown.Item>
            <NavDropdown.Item href="#">Configure</NavDropdown.Item>
        </NavDropdown>
    )
}

class Email extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="overflow-hidden h-100">
                <div className="email-template">
                    <div className="ml-3 mt-2">
                        <span className="page-header">Email Configuration</span>
                    </div>
                    <div className="tool-sidebar email-accordion">
                        <div className="body">
                            <div id="accordion">
                                <div className="accordion-panel">
                                    <div className="panel-header">
                                        <a role="button" data-bs-toggle="collapse" data-bs-target="#category" aria-expanded="true" aria-controls="category">
                                            <span className="title-text">Category</span>
                                        </a>
                                    </div>
                                    <div id="category" className="panel-body collapse show" data-bs-parent="#accordion">
                                        <div className="row g-3 align-items-center mb-4 mt-3">
                                            <div className="col-auto">
                                                <label htmlFor="name" className="col-form-label">Category Name*</label>
                                            </div>
                                            <div className="col-auto">
                                                <input type="text" name="name" className="form-control" />
                                            </div>
                                            <div className="col">
                                                <button style={{ width: '5rem' }} type="submit" className="btn btn-primary">Add</button>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <BootstrapTable
                                                keyField='category_name'
                                                data={category}
                                                columns={categoryColumns}
                                                bordered={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-panel">
                                    <div className="panel-header">
                                        <a role="button" data-bs-toggle="collapse" data-bs-target="#activity" aria-controls="activity">
                                            <span className="title-text">Activity</span>
                                        </a>
                                    </div>
                                    <div id="activity" className="panel-body collapse" data-bs-parent="#accordion">
                                        <div className="row g-3 align-items-center mb-4 mt-3">
                                            <div className="col-auto">
                                                <label htmlFor="activity" className="col-form-label">Email Activity Name*</label>
                                            </div>
                                            <div className="col-auto" style={{ width: '18.5rem' }}>
                                                <input type="text" name="activity" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center mb-4 mt-3">
                                            <div className="col-auto">
                                                <label style={{ marginRight: '5.2rem' }} htmlFor="category" className="col-form-label">Category*</label>
                                            </div>
                                            <div className="col-auto" style={{ width: '18.5rem' }}>
                                                <select name="category" defaultValue="select" className="form-control mb-3">
                                                    <option value="select">Please select one Category</option>
                                                    <option value="t1">test</option>
                                                </select>
                                            </div>
                                            <div className="col-auto">
                                                <button style={{ width: '5rem' }} type="submit" className="btn btn-primary">Add</button>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <BootstrapTable
                                                keyField='activity'
                                                data={activity}
                                                columns={activityColumns}
                                                bordered={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-panel">
                                    <div className="panel-header">
                                        <a role="button" data-bs-toggle="collapse" data-bs-target="#template" aria-controls="template">
                                            <span className="title-text">Template</span>
                                        </a>
                                    </div>
                                    <div id="template" className="panel-body collapse" data-bs-parent="#accordion">
                                        <div className="row g-3 align-items-center mb-4 mt-3">
                                            <div className="col-auto">
                                                <label htmlFor="template" className="col-form-label">Template Name*</label>
                                            </div>
                                            <div className="col-auto" style={{ width: '18.5rem' }}>
                                                <input type="text" name="template" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center mb-4 mt-3">
                                            <div className="col-auto">
                                                <label style={{ marginRight: '1.8rem' }} htmlFor="templateid" className="col-form-label">Template Id*</label>
                                            </div>
                                            <div className="col-auto" style={{ width: '18.5rem' }}>
                                                <input type="text" name="templateid" className="form-control" />
                                            </div>
                                            <div className="col-auto">
                                                <button style={{ width: '5rem' }} type="submit" className="btn btn-primary">Add</button>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <BootstrapTable
                                                keyField='activity'
                                                data={template}
                                                columns={templateColumns}
                                                bordered={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-panel">
                                    <div className="panel-header">
                                        <a role="button" data-bs-toggle="collapse" data-bs-target="#email" aria-controls="email">
                                            <span className="title-text">Email Configuration</span>
                                        </a>
                                    </div>
                                    <div id="email" className="panel-body collapse" data-bs-parent="#accordion">
                                        <div className="row g-3 align-items-center mb-4 mt-3">
                                            <div className="col-auto">
                                                <label style={{ marginRight: '5.2rem' }} htmlFor="category" className="col-form-label">Category*</label>
                                            </div>
                                            <div className="col-auto" style={{ width: '18.5rem' }}>
                                                <select name="category" defaultValue="select" className="form-control">
                                                    <option value="select">Please select one Category</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center mb-4 mt-3">
                                            <div className="col-auto">
                                                <label style={{ marginRight: '5.2rem' }} htmlFor="activity" className="col-form-label">Activity*</label>
                                            </div>
                                            <div className="col-auto" style={{ width: '18.5rem' }}>
                                                <select name="activity" defaultValue="select" className="form-control">
                                                    <option value="select">Please select one Activity</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center mb-4 mt-3">
                                            <div className="col-auto">
                                                <label style={{ marginRight: '5.2rem' }} htmlFor="template" className="col-form-label">Template*</label>
                                            </div>
                                            <div className="col-auto" style={{ width: '18.5rem' }}>
                                                <select name="template" defaultValue="select" className="form-control">
                                                    <option value="select">Please select one Template</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-items-center mb-4 mt-3">
                                            <div className="col-auto">
                                                <label style={{ marginRight: '5.2rem' }} htmlFor="language" className="col-form-label">Language*</label>
                                            </div>
                                            <div className="col-auto" style={{ width: '18.5rem' }}>
                                                <select name="language" defaultValue="select" className="form-control">
                                                    <option value="select">Please select one Language</option>
                                                </select>
                                            </div>
                                            <div className="col-auto">
                                                <button style={{ width: '5rem' }} type="submit" className="btn btn-primary">Add</button>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <BootstrapTable
                                                keyField='activity'
                                                data={email}
                                                columns={emailColumns}
                                                bordered={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Edit Category Modal */}
                <div className="modal fade" id="editCategory" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="editCategoryForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">Edit Category</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="name" className="col-form-label">Category Name</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <input type="text" name="name" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-lg btn-accenta">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Edit Activity Modal */}
                <div className="modal fade" id="editActivity" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="editActivityForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">Edit Activity</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="name" className="col-form-label">Email Activity Name*</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <input type="text" name="name" className="form-control" />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="category" className="col-form-label">Category*</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <select name="category" defaultValue="select" className="form-control mb-3">
                                                    <option value="select">Please select one Category</option>
                                                    <option value="t1">test</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-lg btn-accenta">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Edit Template Modal */}
                <div className="modal fade" id="editTemplate" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="editTemplateForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">Edit Template</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="template" className="col-form-label">Template Name*</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <input type="text" name="template" className="form-control" />
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="templateid" className="col-form-label">Email Activity Name*</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <input type="text" name="templateid" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-lg btn-accenta">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Edit Email Modal */}
                <div className="modal fade" id="editEmail" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form id="editEmailForm">
                                <div className="modal-header modal-image-header">
                                    <div className="modal-header-content">
                                        <h4 className="modal-title">Edit Template</h4>
                                    </div>
                                    <button type="button" className="close" data-bs-dismiss="modal"><span className="lh-icon-os-close"></span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="middle-sec">
                                        <div className="row">
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="category" className="col-form-label">Category*</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <select name="category" defaultValue="select" className="form-control mb-3">
                                                    <option value="select">Please select one Category</option>
                                                </select>
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="activity" className="col-form-label">Activity*</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <select name="activity" defaultValue="select" className="form-control mb-3">
                                                    <option value="select">Please select one Activity</option>
                                                </select>
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="template" className="col-form-label">Template*</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <select name="template" defaultValue="select" className="form-control mb-3">
                                                    <option value="select">Please select one Template</option>
                                                </select>
                                            </div>
                                            <div className="user-label col-sm-4">
                                                <label htmlFor="language" className="col-form-label">Language*</label>
                                            </div>
                                            <div className="col-sm-6 pt-3">
                                                <select name="language" defaultValue="select" className="form-control mb-3">
                                                    <option value="select">Please select one Language</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-lg btn-accenta">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Email